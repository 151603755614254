import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import "./ReadJob.css";
import translations from "../AppsMenu/Candidate/mulitlingue";
import { RiArrowGoBackFill } from "react-icons/ri";
function ReadJob() {
  const token=localStorage.getItem("token");

  const history = useHistory();
  const { id } = useParams();
  const [job, setJob] = useState([]);
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });

  useEffect(() => {
    const selectedLang = JSON.parse(localStorage.getItem("selectedLang"));
    if (selectedLang) {
      setLang(selectedLang);
    }
  }, []);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_APP_DOMAIN}/api/job-list/read/` + id, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }})
      .then((res) => {
        console.log(res);
        setJob(res.data[0]);
      })

      .catch((err) => console.log(err));
  }, []);
  const specialismsArray = job.Specialisms ? JSON.parse(job.Specialisms) : [];
  const specialismColors = [
    "badge-primary",
    "badge-success",
    "badge-danger",
    "badge-warning",
  ];
  return (
    <div className="job-container">
      <div className="job-header">
        <h1 className="job-title">{job.JobTitle}</h1>
        <br />
      </div>
      <div className="job-description">
        <h2>{lang.menu.jobDesc}</h2>
        <p>{job.JobDescription}</p>
      </div>
      <div className="job-details">
        <div className="job-details-left">
          <p>
            <strong>{lang.menu.jobType}:</strong> {job.JobType}
          </p>
          <p>
            <strong>{lang.menu.Specialisms}: </strong>
            {Array.isArray(specialismsArray) ? (
              specialismsArray.map((specialism, index) => (
                <span key={index} className="specialism-tag">
                  {specialism}
                </span>
              ))
            ) : (
              <span className="specialism-tag">{specialismsArray}</span>
            )}
          </p>
          <p>
            <strong>{lang.menu.TypeOfQualification}:</strong> {job.TypeOfQualification}
          </p>
          <p>
            <strong>{lang.menu.experience}:</strong> {job.Experience}
          </p>
          <p>
            <strong>Score:</strong> {job.PT1*100} %
          </p>
          <p>
            <strong>{lang.menu.postDate}:</strong>{" "}
            {new Date(job.PostedDate).toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
            })}
          </p>
          <p>
            <strong>{lang.menu.CloseDate}:</strong>{" "}
            {new Date(job.CloseDate).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </p>
          <p>
            <strong>{lang.menu.country}:</strong>
            {job.Country}
          </p>
        </div>
        <div className="job-details-right">
          <p>
            <strong>{lang.menu.OfferedSalary}:</strong> {job.OfferedSalary}
          </p>
          <p>
            <strong>{lang.menu.applicant}:</strong> {job.Applicant}
          </p>
          <p>
            <strong>{lang.menu.status}:{"  "}</strong>
            <span
              className={`border rounded p-2 ${
                job.Status === "Active" ? "text-secondary" : "text-danger"
              }`}
            >
              <i className={`fa fa-circle me-1`} />
              {job.Status}
            </span>
          </p>
        </div>
      </div>
  
    </div>
  );
}

export default ReadJob;
