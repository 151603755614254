import './ChatGPT.css';

const ChatMessage = ({ chat }) => {
  console.log(chat);
  return (
    <div className={`chat_message ${chat.user === "gpt" ? "chatgpt" : ""}`}>
      <div className="chat_message_center">
        <div
          className={`avatar ${chat.user === "gpt" ? "avatar2" : "avatar1"}`}
        />
        <div className="message">{chat.message}</div>
      </div>
    </div>
  );
};

export default ChatMessage;

  