import React, { useState, useEffect } from "react";
import { countries } from "countries-list";
import translations from "../../../components/AppsMenu/Candidate/mulitlingue";

function Volunteer({ user, updateUser }) {
  const [volunteerings, setVolunteerings] = useState([
    { organization: "", taskDescription: "" },
  ]);

  console.log("user", user);

  useEffect(() => {
    let storedVolunteerings=[
      { organization: "", taskDescription: "" },
    ];
    if (user.Volunteerings !== null && user.Volunteerings !== "null" && user.Volunteerings !== "") {
      


  
      storedVolunteerings = user.Volunteerings;
        while (typeof(storedVolunteerings) === "string") {
          storedVolunteerings = JSON.parse(storedVolunteerings);
  
      }

    }

    if (storedVolunteerings !=='undefined' && storedVolunteerings !== null) {
    
      setVolunteerings(storedVolunteerings);
     
    }
  }, []);
  useEffect(() => {
    updateUser(volunteerings);
  }, [volunteerings]);

  const [lang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });

  const handleAddvolunteering = () => {
    setVolunteerings([
      ...volunteerings,
      { organization: "", taskDescription: "" },
    ]);
  };

  const handleRemoveVolunteering = (index) => {
    const newVolunteerings = [...volunteerings];
    newVolunteerings.splice(index, 1);
    setVolunteerings(newVolunteerings);
  };

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const newVolunteerings = [...volunteerings];
    newVolunteerings[index][name] = value;
    setVolunteerings(newVolunteerings);
  };
  console.log("Volunteerings", JSON.stringify(volunteerings));

  return (
    <div>
      <div className="basic-form">
        <form onSubmit={(e) => e.preventDefault()} className="p-3">
          {volunteerings.map((volunteering, index) => (
            <div
              key={index}
              style={{
                border: "2px solid #86E6D7",
                marginBottom: "30px",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 1px 5px",
              }}
            >
              <svg
                onClick={() => handleRemoveVolunteering(index)}
                height="30px"
                width="30px"
                xmlns="http://www.w3.org/2000/svg"
                fill="#74188D"
                viewBox="0 0 24 24"
                stroke="#74188D"
                style={{
                  position: "relative",
                  top: 0,
                  right: -1000,
                  cursor: "pointer",
                  margin: "10px",
                }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
              <div style={{ display: "flex", "flex-direction": "row" }}>
                <div className="form-group mb-3 col-md-6 my-form-padding">
                  <label className="my-label">{lang.menu.Organization}</label>
                  <input
                    className="form-control"
                    style={{ marginTop: "3px" }}
                    type="text"
                    name="organization"
                    value={volunteering.organization}
                    onChange={(e) => handleChange(index, e)}
                    placeholder={lang.menu.Organization}
                  />
                </div>
                <div className="form-group mb-3 col-md-6 my-form-padding">
                  <label className="my-label">{lang.menu.td}</label>
                  <textarea
                    className="form-control"
                    style={{ marginTop: "3px" }}
                    type="text"
                    name="taskDescription"
                    value={volunteering.taskDescription}
                    onChange={(e) => handleChange(index, e)}
                    placeholder={lang.menu.td}
                  />
                </div>
              </div>
            </div>
          ))}
          <center>
            <svg
              cursor="pointer"
              width="40px"
              height="40px"
              onClick={handleAddvolunteering}
              stroke="#74188D"
              fill="#74188D"
              strokeWidth="0"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              className="svg-add-circle-outline"
            >
              <path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
            </svg>
          </center>
        </form>
      </div>
    </div>
  );
}


export default Volunteer;
