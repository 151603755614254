import React, { useEffect, useState } from "react";
import axios from "axios";

const UserList = () => {
  const [users, setJobListData] = useState([]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_APP_DOMAIN}/api/users`)
      .then((res) => setJobListData(res.data))
      .catch((err) => console.log(err));
  }, [users]);

  return (
    <div>
      <h2>User List</h2>
      <ul>
        {users.map((user) => (
          <li key={user.uid}>
            <strong>User ID:</strong> {user.uid}
            <br />
            <strong>Email:</strong> {user.email}
            <br />
            <strong>Display Name:</strong> {user.displayName}
            <br />
            <strong>All data:</strong> {JSON.stringify(user)}
            <br />
            <hr />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UserList;
