import React, { useEffect, useState } from 'react';
import { countries,getCountryCode } from 'countries-list';
import axios from 'axios';
import './style/onboarding.css';
import sampleImage from './side.png';
import FileInput from './file-input';
import CVicon from './upload_cv.png';
import thankYou from './thankYou .png';
import oops from './oops.png';
import logo from './logo-hirecue.png';
import GeneralInfo from './generalInfo';
import AreaOfExpertise from './AreaOfExpertise';
import TechnicalSkills from './technicalSkills';
import SoftSkills from './softSkills';
import Experiences from './experiences';
import Education from './education';
import Volunteerings from './volunteerings';
import JobPreferences from './jobPreferences';
import ContractPreferences from './contractPreferences';
import { json } from 'react-router-dom';
import translations from "../../AppsMenu/Candidate/mulitlingue"
import {
 
  Button,
} from "react-bootstrap";
import { Link, withRouter, useHistory } from "react-router-dom";
import { logout } from '../../../../store/actions/AuthActions';
import { useDispatch } from 'react-redux';
const countryNames = Object.values(countries).map(country => country.name);

function Onboarding() {
  
  const token=localStorage.getItem("token");

  const [lang] = useState(() => {
    const storedLang = localStorage.getItem('selectedLang');
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === 'Français') {
        return translations.fr;
      }
    }
    return translations.en;
  });

  const datain = JSON.parse(localStorage.getItem("userDetails")).datain

  const remLater=localStorage.getItem("remLater")

  console.log(remLater)
 
  useEffect(() => {
    
    if (datain===1 ) {history.push("/profile")}
    if (remLater==1) {history.push("/dashboard")}
  }, [datain,remLater]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 800) { // Set your minimum width limit here
        document.body.classList.add('overshrunk'); // Add class when overshrunk
        document.body.style.minWidth = '800px'; // Enforce minimum width with CSS
        document.body.style.overflowX = 'auto'; // Ensure horizontal scrolling is enabled
      } else {
        document.body.classList.remove('overshrunk'); // Remove class when not overshrunk
        document.body.style.minWidth = 'initial'; // Remove the enforced minimum width
        document.body.style.overflowX = 'initial'; // Restore default overflow behavior
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    // Call handleResize initially to enforce the minimum width on component mount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth]);

  const [showUploadPopup, setShowUploadPopup] = useState(false);
  const [noDataPopup, setNoDataPopup] = useState(false);
  const [cantSub, setCantSub] = useState(false);
  const [skiping, setSkiping] = useState(false);
  const [skiping2, setSkiping2] = useState(false);
  const [refreshData, setRefreshData ] = useState(0);
  const [showSubmitSeccessPopup, setShowSubmitSeccessPopup] = useState(false);
  const [SubmitSeccess, setSubmitSeccess] = useState(false);
  const [showSubmitFailedPopup, setShowSubmitFailedPopup] = useState(false);
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);
  const [expandedCategories, setExpandedCategories] = useState({
    generalInfo: true,
    technicalSkills: false,
    softSkills: false,
    areaOfExpertise: false,
    experiences: false,
    education: false,
    volunteerings: false,
    jobPreferences: false,
    contractPreferences: false,
  });

  const [formData, setFormData] = useState({
    generalInfo: {},
    areaOfExpertise: {},
    technicalSkills: {},
    softSkills: {},
    experiences: {},
    education: {},
    volunteerings: {},
    jobPreferences: {},
    contractPreferences: {}
  });

  const [fillError, setFillError] = useState({
  FirstName: "",
  LastName: "",
   email: "",
   country: "",
   phoneNumber: "",
   language: "",
   linkedIn: "",
   github: ""
  });

  const upRefreshData = () => {setRefreshData(refreshData +1)};





  const toggleCategory = (category) => {
    setExpandedCategories(prevState => {
      const updatedCategories = { ...prevState };
      Object.keys(updatedCategories).forEach(key => {
        updatedCategories[key] = key === category ? true : false;
      });
      return updatedCategories;
    });
  };
  const handleUploadCV = async (event) => {
    event.preventDefault();
    setShowUploadPopup(true)
  
  };
  const handleClose = () => {
    setShowUploadPopup(false);
    setNoDataPopup(false);
    setCantSub(false);
    setShowSubmitFailedPopup(false);
    setSkiping(false);
    setSkiping2(false);
    setShowLogoutPopup(false);
    if(SubmitSeccess && showSubmitSeccessPopup ){    
      setShowSubmitSeccessPopup(false);
      history.push("/dashboard");
      localStorage.setItem("remLater",1);

    }
  };
  
  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Retrieve general info from localStorage
    const GeneralInfo = JSON.parse(localStorage.getItem('GeneralInfo'));

    if (cantSub) {
      toggleCategory('generalInfo');
      return;
    }

    if (GeneralInfo=== null) {
      setNoDataPopup(true);
      return;
    }



    for (const i in GeneralInfo) {
      if (GeneralInfo[i] === "" || GeneralInfo[i] === null) {
        toggleCategory('generalInfo');
        setFillError({...fillError, [i]: `${lang.menu.tfir}` });
        return;
      }
    }
    // Retrieve skills from localStorage
    const skills = {
      TechnicalSkills: JSON.parse(localStorage.getItem('TechnicalSkills')),
      SoftSkills: JSON.parse(localStorage.getItem('SoftSkills')),
    };
  
    // Retrieve avatar image data from formData
    //const avatarImageData = GeneralInfo.avatar;
    /*const handleUpload = async (avatarImageData) => {
      try {
        // const byteCharacters = atob(avatarImageData.split(",")[1]);
        // const byteNumbers = new Array(byteCharacters.length);
        // for (let i = 0; i < byteCharacters.length; i++) {
        //   byteNumbers[i] = byteCharacters.charCodeAt(i);
        // }
        // const byteArray = new Uint8Array(byteNumbers);
        // const blob = new Blob([byteArray], { type: "image/jpeg" });

        const formData = new FormData();
        formData.append('avatar', avatarImageData);
  
        await axios.post(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/uploadAvatar`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      
        console.log('Image uploaded successfully');
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    };*/
    // Combine all form data into a single object
	const currentUser = JSON.parse(localStorage.getItem('userDetails'));

    const userId = currentUser.localId;
    const allFormData = {
      userId:userId ,
      firstName: GeneralInfo.FirstName,
      lastName: GeneralInfo.LastName,
      email: GeneralInfo.email,
      country: getCountryCode(GeneralInfo.country),
      phoneNumber: GeneralInfo.phoneNumber,
      language: GeneralInfo.language,
      linkedIn: GeneralInfo.linkedIn,
      github: GeneralInfo.github,
      skills,
      allSkills:localStorage.getItem('allSkills'),
      speciality: JSON.parse(localStorage.getItem('AreaOfExpertise')),
      experience: JSON.parse(localStorage.getItem('Experiences')),
      education: JSON.parse(localStorage.getItem('Education')),
      Volunteerings: JSON.parse(localStorage.getItem('Volunteerings')),
      JobPreferences: JSON.parse(localStorage.getItem('JobPreferences')),
      ContractPreferences: JSON.parse(localStorage.getItem('ContractPreferences')),
      // Append avatar image data to formData
    };
  
    console.log("AllData", allFormData);
  
    try {
      //const response = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/onboarding`, {
        const response = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/onboarding`, {
          method: 'POST',

        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(allFormData)
      });
  
      if (response.ok) {
        console.log('Form data submitted successfully');
        
        // Remove stored form data from localStorage
        localStorage.removeItem('GeneralInfo');
        localStorage.removeItem('AreaOfExpertise');
        localStorage.removeItem('TechnicalSkills');
        localStorage.removeItem('SoftSkills');
        localStorage.removeItem('Experiences');
        localStorage.removeItem('Education');
        localStorage.removeItem('Volunteerings');
        localStorage.removeItem('JobPreferences');
        localStorage.removeItem('ContractPreferences');
        console.log('done')
        setShowSubmitSeccessPopup(true);
        setSubmitSeccess(true);
        // Redirect to result page after successful submission
      } else {
        setShowSubmitFailedPopup(true);

        console.error('Failed to submit form data');
      }
    } catch (error) {
      setShowSubmitFailedPopup(true);
      console.error('Error submitting form data:', error);
    }
  };
  
  const history = useHistory();
  const dispatch = useDispatch();
  const handleLogoutConfirm = () => {
    dispatch(logout(history));
  };
  const handleLogout = () => {
    setShowLogoutPopup(true)
  };
  const handleSkipConfirm = () => {
    handleClose()
    history.push("/dashboard")
  }
  const handleRemindMeLaterConfirm = async () => {



    try {
      //const response = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/onboarding`, {
        const response = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/onboardingnotif`, {
          method: 'POST',

        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          uid: JSON.parse(localStorage.getItem("userDetails")).localId
        })
      });
  
      console.log(response)
      if (response.ok) {
        localStorage.setItem("remLater",1)

    handleClose()
    // Notification Code ( TO DO )
    history.push("/dashboard")
      } else {
        handleClose()
        setShowSubmitFailedPopup(true);

        console.error('Failed to create notif');
      }
    } catch (error) {
      handleClose()
      setShowSubmitFailedPopup(true);
      console.error('Error submitting form data:', error);
    }







    
  }
  const handleSkip = () => {
    setSkiping(true)
  }

  const resetError = (name) => {
    setFillError({
      ...fillError,
      [name]:""
    })
  }
  const cantSubUP = () => {
    setCantSub(true)
  }
  const cantSubDown = () => {
    setCantSub(false)

  }


  const handleRemindMeLater = () => {
    setSkiping2(true)
  }
  return (
    <div className="Onboarding"  style={{ minWidth: '1000px' }} >
      <img src={sampleImage} className="side-image" alt="Sample" />
      <img src={logo} className="logo-image" alt="Sample" />
      
      <form className='formOnboard' onSubmit={handleSubmit}>
      
        <div  className="import">
          <h2 className='single-line' >{lang.menu.hukym}</h2>
          <div style={{display: "flex","flexdirection": "row"}}>
          <h4 className='single-line' onClick={handleLogout} style={{color:'red', cursor:"pointer", marginTop:"28px", marginRight:"20px"}}>{lang.menu.Logout}</h4>
          <h4 style={{color:'lightgrey', marginTop:"23px"}}>|</h4>
          <h3  style={{margin:"20px",height:"33px",overflow: "hidden","text-overflow": "clip"}}>{lang.menu.oucv} </h3>

          <img src={CVicon} style={{width:"40px",height:"40px", margin:"15px", cursor:"pointer"}} onClick={handleUploadCV} />

          </div>
        </div>
        <GeneralInfo  cantSubDown={cantSubDown} cantSubUP={cantSubUP} resetError={resetError} lang={lang} refreshData={refreshData} fillError={fillError} expanded={expandedCategories.generalInfo} toggleCategory={toggleCategory} onFormDataChange={(data) => setFormData(prevState => ({ ...prevState, generalInfo: data }))} />
        <AreaOfExpertise
         lang={lang} 
          expandedCategories={expandedCategories}
          toggleCategory={toggleCategory}
          onFormDataChange={(data) => setFormData(prevState => ({ ...prevState, areaOfExpertise: data }))}
        />
        <TechnicalSkills
         lang={lang} 
          refreshData={refreshData}
          toggleCategory={toggleCategory}
          expandedCategories={expandedCategories}
          onFormDataChange={(data) => setFormData(prevState => ({ ...prevState, technicalSkills: data }))}
        />
        <SoftSkills
         lang={lang} 
                  refreshData={refreshData}
  toggleCategory={toggleCategory}
  expandedCategories={expandedCategories}
  onFormDataChange={(data) => setFormData(prevState => ({ ...prevState, softSkills: data }))}
/>


        <Experiences
         lang={lang} 
         refreshData={refreshData}
          toggleCategory={toggleCategory}
          expandedCategories={expandedCategories}
          onFormDataChange={(data) => setFormData(prevState => ({ ...prevState, experiences: data }))}
        />
        <Education
         lang={lang} 
                  refreshData={refreshData}

          toggleCategory={toggleCategory}
          expandedCategories={expandedCategories}
          onFormDataChange={(data) => setFormData(prevState => ({ ...prevState, education: data }))}
        />
        <Volunteerings
         lang={lang} 
          toggleCategory={toggleCategory}
          expandedCategories={expandedCategories}
          onFormDataChange={(data) => setFormData(prevState => ({ ...prevState, volunteerings: data }))}
        />
        <JobPreferences
         lang={lang} 
          toggleCategory={toggleCategory}
          expandedCategories={expandedCategories}
          onFormDataChange={(data) => setFormData(prevState => ({ ...prevState, jobPreferences: data }))}
        />
        <ContractPreferences
         lang={lang} 
          toggleCategory={toggleCategory}
          expandedCategories={expandedCategories}
          onFormDataChange={(data) => setFormData(prevState => ({ ...prevState, contractPreferences: data }))}
        />
        <div style={{padding:"10px",display:"flex", justifyContent:"space-between",backgroundColor:"white",    "flex-direction": "row",position: "sticky",bottom: "0px"}}>
          <a onClick={handleRemindMeLater} style={{cursor:"pointer",marginTop:"30px", color:"#86E6D7"}}><b>{lang.menu.nfl}</b></a>
          <button type="submit" disabled={cantSub}  className='standard-btn' style={{ width: "200px", height: "40px" }}>{lang.menu.submit}</button>
          <a onClick={handleSkip} style={{cursor:"pointer",marginTop:"30px", color:"#86E6D7"}}><b>{lang.menu.Skip}{">>"}</b></a>
        </div>
      </form>
      {showUploadPopup && (
        <>
        <div className="overlay" onClick={handleClose}></div>

        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            width: "40%",
            minWidth:"180px",

            maxWidth: "100%",
            textAlign: "center",
            border: "1px solid rgba(0, 0, 0, 0.2)",
            borderRadius: "8px",
            boxShadow: "0 0 200px rgba(0, 0, 0, 0.3)",
            zIndex:999
          }}
        >
          <FileInput  lang={lang} upRefreshData={upRefreshData} handleClose= {handleClose}/>
          
        </div>
        </>
      )}





{showSubmitSeccessPopup && (
        <>
        <div className="overlay" onClick={handleClose}></div>

        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            width: "40%",
            minWidth:"180px",

            maxWidth: "100%",
            textAlign: "center",
            border: "1px solid rgba(0, 0, 0, 0.2)",
            borderRadius: "8px",
            boxShadow: "0 0 200px rgba(0, 0, 0, 0.3)",
            zIndex:999
          }}
        >
          <img src={thankYou} style={{width:"120px",height:"120px", margin:"15px"}}  />

          <h2 className='single-line'>{lang.menu.tyfys}</h2>          
        </div>
        </>
      )}


{noDataPopup && (
        <>
        <div className="overlay" onClick={handleClose}></div>

        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            width: "40%",
            minWidth:"180px",

            maxWidth: "100%",
            textAlign: "center",
            border: "1px solid rgba(0, 0, 0, 0.2)",
            borderRadius: "8px",
            boxShadow: "0 0 200px rgba(0, 0, 0, 0.3)",
            zIndex:999
          }}
        >
         <img src={oops} style={{width:"120px",height:"120px", margin:"15px"}}  />

          <h3 >{lang.menu.pfydbs}</h3>          
        </div>
        </>
      )}









{showSubmitFailedPopup && (
        <>
        <div className="overlay" onClick={handleClose}></div>

        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            width: "40%",
            minWidth:"180px",

            maxWidth: "100%",
            textAlign: "center",
            border: "1px solid rgba(0, 0, 0, 0.2)",
            borderRadius: "8px",
            boxShadow: "0 0 200px rgba(0, 0, 0, 0.3)",
            zIndex:999
          }}
        >
         <img src={oops} style={{width:"120px",height:"120px", margin:"15px"}}  />

          <h3>{lang.menu.aeowsyiptl}</h3>          
        </div>
        </>
      )}

{skiping &&(
  <>
        <div className="overlay" onClick={handleClose}></div>

        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            width: "40%",
            minWidth:"180px",
            maxWidth: "100%",
            textAlign: "center",
            border: "1px solid rgba(0, 0, 0, 0.2)",
            borderRadius: "8px",
            boxShadow: "0 0 200px rgba(0, 0, 0, 0.3)",
            zIndex:999
          }}
        >
          <h3>{lang.menu.sownsyp}</h3>  
          <center>
            <Button style={{width:"120px" ,marginInline:"20px"}} onClick={handleSkipConfirm}>
              {lang.menu.Confirm}
            </Button>
            <Button  style={{width:"120px" ,marginInline:"20px",     backgroundColor:"#bcebe4" , color:"#74188D"}} onClick={handleClose}>
              {lang.menu.Cancel}
            </Button>
          </center>    
        </div>
        </>
)}




{skiping2 &&(
  <>
        <div className="overlay" onClick={handleClose}></div>

        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            width: "40%",
            minWidth:"180px",

            maxWidth: "100%",
            textAlign: "center",
            border: "1px solid rgba(0, 0, 0, 0.2)",
            borderRadius: "8px",
            boxShadow: "0 0 200px rgba(0, 0, 0, 0.3)",
            zIndex:999
          }}
        >
          <h3>{lang.menu.sownsyp}</h3>  
          <center>
            <Button style={{width:"120px" ,marginInline:"20px"}} onClick={handleRemindMeLaterConfirm}>
              {lang.menu.Confirm}
            </Button>
            <Button  style={{width:"120px" ,marginInline:"20px",     backgroundColor:"#bcebe4" , color:"#74188D"}} onClick={handleClose}>
              {lang.menu.Cancel}
            </Button>
          </center>    
        </div>
        </>
)}










{showLogoutPopup &&(
  <>
        <div className="overlay" onClick={handleClose}></div>

        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            width: "40%",
            minWidth:"180px",

            maxWidth: "100%",
            textAlign: "center",
            border: "1px solid rgba(0, 0, 0, 0.2)",
            borderRadius: "8px",
            boxShadow: "0 0 200px rgba(0, 0, 0, 0.3)",
            zIndex:999
          }}
        >
          <h3>{lang.menu.aysywtl}</h3>  
          <center>
            <Button style={{width:"120px" ,marginInline:"20px"}} onClick={handleLogoutConfirm}>
              {lang.menu.Confirm}
            </Button>
            <Button  style={{width:"120px" ,marginInline:"20px",     backgroundColor:"#bcebe4" , color:"#74188D"}} onClick={handleClose}>
              {lang.menu.Cancel}
            </Button>
          </center>    
        </div>
        </>
)}


    </div>
  );
}








export default Onboarding;