import React, { useState } from 'react';
import axios from 'axios';
import ChatMessage from './ChatMessage';
import './ChatGPT.css';

function ChatGPT() {
  const [input, setInput] = useState('');
  const [chatlog, setChatlog] = useState([
    {
      user: 'gpt',
      message: 'How can I help you today?',
    },
    {
      user: 'me',
      message: 'I want to use ChatGPT today',
    },
  ]);

  const clear = () => {
    setChatlog([]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let chatLogNew = [...chatlog, { user: 'me', message: input }];
    setInput('');
    setChatlog(chatLogNew);

    try {
      const response = await axios.post(`${process.env.REACT_APP_APP_DOMAIN}/flask/ChatGPT`, {
        message: chatLogNew.map((chat) => chat.message).join('\n'),
      });
      setChatlog([...chatLogNew, { user: 'gpt', message: response.data.message }]);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="ChatGPT">
      <aside className="sidemenu">
        <div className="side_menu_button" onClick={clear}>
          <span>+</span>
          New chat
        </div>
      </aside>
      <section className="chatbox1">
        <div className="card custom-card">
          <div className="card-body chat_log">
            {chatlog.map((chat, index) => (
              <ChatMessage key={index} chat={chat} />
            ))}
          </div>
        </div>

        <div className="chat_input_holder">
          <form onSubmit={handleSubmit}>
            <div className="chat_input">
              <input
                type="text"
                className="chat_input_textarea"
                value={input}
                onChange={(e) => setInput(e.target.value)}
              />
              <button type="submit" className="send_button">
                <svg
                  stroke="currentColor"
                  fill="none"
                  strokeWidth={2}
                  viewBox="0 0 24 24"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="h-4 w-4 mr-1"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M22 2 11 13M22 2l-7 20-4-9-9-4 20-7z" />
                </svg>
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}

export default ChatGPT;
