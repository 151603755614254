import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import translations from "./mulitlingue"
import { ProgressBar } from "react-bootstrap";
import swal from "sweetalert";

function FileUploader({ onFilesSelected, onUploadSuccess, onUploadPop }) {
    const token=localStorage.getItem("token");

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [filenames, setFileNames] = useState([]);
  const [fileProgress, setfileProgress] = useState([0]);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [isDownloaded, setisDownloaded] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [selectedLang, setSelectedLang] = useState(null);
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem('selectedLang');
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === 'Français') {
        return translations.fr;
      }
    }
    return translations.en;
  });
  const MAX_FILE_COUNT = 100;
  const handleFileSelect = (event) => {
    const files = event.target.files;
    const fileList = Array.from(files);
    if (fileList.length > MAX_FILE_COUNT) {
      swal(`Please select up to ${MAX_FILE_COUNT} files.`, {
        icon: "error",
      });
      return;
    }
    const fileNames = fileList.map((file) => file.name);
    setSelectedFiles(fileList);
    setButtonDisabled(fileList.length === 0);
    setFileNames(fileNames);
    onFilesSelected(fileList);
  };

  const handleSingleFileUpload = async (file, index, totalFiles) => {
    const formData = new FormData();
    formData.append('files', file);

    try {
      const currentUser = JSON.parse(localStorage.getItem('userDetails'));
      const userId = currentUser.localId;
      await axios.post(`${process.env.REACT_APP_APP_DOMAIN}/flask/candidates/uploadFiles/${userId}`, formData,{
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
);

      // Update progress
      const progress = ((index + 1) / totalFiles) * 100;
      setfileProgress(progress);

      if (progress === 100)
        setUploadSuccess(false);

    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleFileUpload = async () => {
    //onUploadPop(true);
    setUploadSuccess(true);
    for (let i = 0; i < selectedFiles.length; i++) {
      await handleSingleFileUpload(selectedFiles[i], i, selectedFiles.length);
    }
    //toast.success('Files uploaded successfully!');
    onUploadSuccess();
    //onUploadPop(false);
  };
  //toast.error('Error uploading files. Please try again later.');

  const downloadZip = () => {
    axios
      .get(`${process.env.REACT_APP_APP_DOMAIN}/flask/download_cv_upload`, {
        responseType: 'blob',
            headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/zip' });
        saveAs(blob, 'CvUpload.zip');
        setisDownloaded(true);
      })
      .catch((error) => {
        console.error('Error downloading ZIP file:', error);
      });
  };

  return (
    <div className="row">
      <div className="col-md-4">
        <div className="custom-file mb-3">
          <label className="btn btn-secondary">
            <span className="btn-icon-start text-success">
              <i className="fa fa-upload color-success" />
            </span>
            {lang.menu.upload}
            <input type="file" style={{ display: 'none' }} accept=".pdf" onChange={handleFileSelect} max={100} multiple />
          </label>
        </div>
      </div>
      <div className="col-md-5">
        <div className="mt-2">
          {filenames.length === 0 ? (
            <span>{lang.menu.NoCVs}</span>
          ) : (
            <span>
              <strong>{lang.menu.FileSeleted} </strong> {filenames.length} {lang.menu.Files}
              <div>{filenames.join(', ')}</div>
            </span>
          )}
        </div>
      </div>
      <div className="col-md-3 text-right">
        {uploadSuccess ? (
          <div className="progress-bar-container" style={{ position: 'relative', width: '100%' }}>
          <ProgressBar now={fileProgress} style={{ height: `${35}px` }} variant="success progress-animated progress-bar-striped" />
            <div style={{ position: 'absolute', width: '100%', textAlign: 'center', color: 'black', top: '50%', transform: 'translateY(-50%)' }}>
              {`${Math.round(fileProgress)}%`}
            </div>
          </div>

        ) : (
          <button
            className={`btn btn-${buttonDisabled ? 'secondary' : 'success'} btn-sm`}
            onClick={handleFileUpload}
            disabled={buttonDisabled}
          >
            {lang.menu.UploadFilesButton}
          </button>
        )}

      </div>
    </div>
  );
}

export default FileUploader;
