import React, { useEffect, useState } from "react";
import axios from "axios";
import "./CandidateData.css";

function CandidateData() {
  const [users, setUsers] = useState([]);
  const [roleOptions] = useState(["admin", "candidate", "recruiter"]);
  const [emailStatus, setEmailStatus] = useState({});

  useEffect(() => {
    fetchCandidateData();
  }, []);

  const fetchCandidateData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_APP_DOMAIN}/api/users`
      );
      setUsers(response.data);
      const emailStatusRequests = response.data.map(async (user) => {
        console.log(response.data);
        const email = user.email;
        const emailStatusResponse = await axios.get(
          `${process.env.REACT_APP_APP_DOMAIN}/api/users/${email}/emailStatus`
        );
        return { email, status: emailStatusResponse.data[0].emailVerified };
      });
      Promise.all(emailStatusRequests).then((statuses) => {
        const emailStatusMap = statuses.reduce((acc, status) => {
          acc[status.email] = status.status;
          return acc;
        }, {});
        setEmailStatus(emailStatusMap);
      });
    } catch (error) {
      console.error("Failed to fetch user list: ", error);
    }
  };

  const handleRoleChange = async (userId, role) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_APP_DOMAIN}/api/users/${userId}`,
        { role }
      );
      fetchCandidateData();
    } catch (error) {
      console.error("Failed to update user role: ", error);
    }
  };

  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-title">Candidate List</h4>
      </div>
      <div className="card-body">
        <div className="w-100 table-responsive">
          <table id="example" className="display w-100 dataTable">
            <thead>
              <tr>
                <th>ID</th>
                <th>Email</th>
                <th>Password</th>
                <th>Role</th>
                <th>Email Status</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.id}>
                  <td>{user.id}</td>
                  <td>{user.email}</td>
                  <td>{user.password}</td>
                  <td>
                    <select
                      value={user.role}
                      onChange={(e) =>
                        handleRoleChange(user.id, e.target.value)
                      }
                    >
                      {roleOptions.map((role) => (
                        <option key={role} value={role}>
                          {role}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    {emailStatus[user.email] ? "Verified" : "Not Verified"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default CandidateData;

/* const admin = require('firebase-admin');

function CandidateData() {
// Initialize the Admin SDK
admin.initializeApp({
  credential: admin.credential.applicationDefault(),
  // You can also set your service account key here instead of using applicationDefault()
  credential: admin.credential.cert('AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII'),
});

// Get a reference to the Auth service
const auth = admin.auth();

// Function to retrieve all users using pagination
async function getAllUsers() {
  try {
    let allUsers = [];
    let pageToken = undefined;

    do {
      const listUsersResult = await auth.listUsers(1000, pageToken);
      const users = listUsersResult.users;

      if (users.length > 0) {
        allUsers = allUsers.concat(users);
      }

      pageToken = listUsersResult.pageToken;
    } while (pageToken);

    return allUsers;
  } catch (error) {
    console.error('Error listing users:', error);
  }
}

// Call the function to get all users
getAllUsers()
  .then((users) => {
   console.log('All users:', users);
  })
  .catch((error) => {
    console.error('Error getting all users:', error);
  });
}
  export default CandidateData; */
