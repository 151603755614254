import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
  Modal,
  Button,
  Card,
  Table,
  Badge,
  Row,
  Col

} from "react-bootstrap";
import translations from"../AppsMenu/Candidate/mulitlingue";

const JobRoleListTest = () => {
  const [jobRoles, setJobRoles] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [passTest,setPassTest] = useState([]);
  const [userRole, setuserRole] = useState([]);
  const token=localStorage.getItem("token");

  const getUserCompanyID = async () => {
    const currentUser = JSON.parse(localStorage.getItem('userDetails'));
    const userId = currentUser.localId;
    const responseC = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
    const data = await responseC.json();
    const compId = data[0].companyID;
    return compId;
  };
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        
          const userDetailsString = localStorage.getItem("userDetails")
          const userDetails = JSON.parse(userDetailsString);
          console.log("userDetails : ",userDetails.userRole)
          const role =userDetails.userRole;
          const localId = userDetails ? userDetails.localId : null;
          const compId =await getUserCompanyID()
          const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/candidatesjobTech/${localId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }});
              console.log("response.data :",response.data)
          if (compId!=1 && role!=5) {
            
            const filteredJobs = response.data.filter(job => job.companyid === compId);
            setJobRoles(filteredJobs);
          } else {
            setJobRoles(response.data);
          }        
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchData();
  }, []);
  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
          );
          const data = await response.json();
          setPassTest(
            data.some((permission) => permission.title === "pass-psycho-test")
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);
  const [lang, setLang] = useState(() => {
		const storedLang = localStorage.getItem('selectedLang');
		if (storedLang) {
		  const parsedLang = JSON.parse(storedLang);
		  if (parsedLang.language === 'Français') {
			return translations.fr;
		  }
		}
		return translations.en;
	  });
    useEffect(() => {
  
      const selectedLang = JSON.parse(localStorage.getItem('selectedLang'));
      if (selectedLang) {
        setLang(selectedLang);
      }
    }, []);

    useEffect(() => {
      const fetchUserPermissions = async () => {
        try {
          const currentUser = JSON.parse(localStorage.getItem('userDetails'));
          if (currentUser && currentUser.localId) {
            const userId = currentUser.localId;
            const response = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
            const data = await response.json();
            setuserRole(data);
  
          }
        } catch (error) {
          console.error("Error fetching permissions details:", error);
        }
      };
    
      fetchUserPermissions();
    }, []);
console.log("tesst user: ",userRole)

  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/users`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
        setCandidates(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);
  


  return (
    <Card >
      <Card.Header>
        <Card.Title>{lang.menu.jobRole}</Card.Title>
      </Card.Header>
      <Card.Body>
      <div className="d-block">
    
        </div>
        <Table responsive>
        <thead>
          <tr>
            <th>ID</th>
            <th>Job Title</th>
            <th>Skills</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {jobRoles.map((jobRole) => (
            <tr key={jobRole.id}>
              <td>{jobRole.id}</td>
              <td>{jobRole.JobTitle}</td>
              <td>          {jobRole.skills
    .replace("[", "")
    .replace("]", "")
    .split("),")
    .map((skill, index) => {
      const skillParts = skill.replace("('", "").replace("')", "").split("',");
      return    <Badge variant="primary light"><span key={index}>{skillParts[0]}</span> {"    "}</Badge>;
    })}</td>
              <td>
                {jobRole && (
                  <>
                 
                   <Link to={`/JobRoleTests/${jobRole.id}`} className="btn btn-primary">Show job tests</Link>
        
                   
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
  
      </Card.Body>
    </Card>
  );
};

export default JobRoleListTest;
