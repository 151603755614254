export const LanguageInfo = (id) => {
  const languages = [
    {
      "id": 1,
      "name": "Java (OpenJDK 13.0.1)",
      "value": "java",
      "variable_declaration": "type "
    },
      {
        "id": 2,
        "name": "Python (3.8.1)",
        "value": "python",
        "variable_declaration": ""
      },
      {
        "id": 3,
        "name": "C (GCC 9.2.0)",
        "value": "c",
        "variable_declaration": "type "
      },
      {
        "id": 4,
        "name": "C++ (GCC 9.2.0)",
        "value": "cpp",
        "variable_declaration": "type "
      },
      {
        "id": 5,
        "name": "JavaScript (Node.js 12.14.0)",
        "value": "javascript",
        "variable_declaration": "const ",
        "output":"console.log()"
      },
      {
        "id": 63,
        "name": "JavaScript (Node.js 12.14.0)",
        "value": "javascript",
        "variable_declaration": "const ",
        "output":"console.log()"
      },
      {
        "id": 7,
        "name": "Groovy 3.0.9",
        "value": "groovy",
        "variable_declaration": "def ",
        "output": "println"
      },
      {
        "id": 8,
        "name": "JShell (Java 11)",
        "value": "jshell",
        "variable_declaration": "var ",
        "output": "System.out.println"
      }, 
      {
        "id": 9,
        "name": "Haskell (GHC 8.10.4)",
        "value": "haskell",
        "variable_declaration": "let ",
        "output": "print"
      }, 
      {
        "id": 10,
        "name": "Tcl 8.6",
        "value": "tcl",
        "variable_declaration": "set ",
        "output": "puts"
      },  
      {
        "id": 11,
        "name": "Ada (GNAT 9.3)",
        "value": "ada",
        "variable_declaration": "declare ",
        "output": "Put_Line"
      }, 
      {
        "id": 12,
        "name": "Common Lisp (SBCL 2.1.6)",
        "value": "commonlisp",
        "variable_declaration": "defparameter ",
        "output": "print"
      },
      {
        "id": 13,
        "name": "D (DMD 2.098)",
        "value": "d",
        "variable_declaration": "auto ",
        "output": "writeln"
      }, 
      {
        "id": 14,
        "name": "Elixir 1.12",
        "value": "elixir",
        "variable_declaration": "",
        "output": "IO.puts"
      }, 
      {
        "id": 15,
        "name": "Erlang (OTP 24)",
        "value": "erlang",
        "variable_declaration": "",
        "output": "io:format"
      },       
      {
        "id": 16,
        "name": "F# (.NET Core SDK 3.1.202)",
        "value": "fsharp",
        "variable_declaration": "let mutable "
      },
      {
        "id": 17,
        "name": "Lua 5.4",
        "value": "lua",
        "variable_declaration": "",
        "output": "print"
      }, 
      {
        "id": 18,
        "name": "Fortran (gfortran 11.2)",
        "value": "fortran",
        "variable_declaration": "integer :: ",
        "output": "print *,"
      },                                                 
      {
        "id": 35,
        "name": "Assembly (NASM 2.14.02)",
        "value": "assembly",
        "variable_declaration": "section .data\n"
      },
      {
        "id": 36,
        "name": "Scala 2.13",
        "value": "scala",
        "variable_declaration": "val ",
        "output": "println"
      },      
      {
        "id": 37,
        "name": "PHP (7.4.1)",
        "value": "php",
        "variable_declaration": "$"
      },
      {
        "id": 38,
        "name": "C# (Mono 6.6.0.161)",
        "value": "csharp",
        "variable_declaration": "type "
      },
      {
        "id": 39,
        "name": "Python 2.7",
        "value": "python2",
        "variable_declaration": "",
        "output": "print"
      }, 
      {
        "id": 40,
        "name": "Perl (5.28.1)",
        "value": "perl",
        "variable_declaration": "my "
      },  
      {
        "id": 41,
        "name": "Ruby (2.7.0)",
        "value": "ruby",
        "variable_declaration": ""
      },
      {
        "id": 42,
        "name": "Go 1.20",
        "value": "go",
        "variable_declaration": "var ",
        "output": "fmt.Println"
      }, 
      {
        "id": 44,
        "name": "R (4.0.0)",
        "value": "r",
        "variable_declaration": "variableName <- value"
      },
      {
        "id": 45,
        "name": "Racket 8.6",
        "value": "racket",
        "variable_declaration": "define ",
        "output": "displayln"
      }, 
      {
        "id": 46,
        "name": "OCaml 4.14",
        "value": "ocaml",
        "variable_declaration": "let ",
        "output": "print_endline"
      },      
      {
        "id": 47,
        "name": "Visual Basic.Net (vbnc 0.0.0.5943)",
        "value": "vbnet",
        "variable_declaration": "Dim variableName As type = value"
      },     
      {
        "id": 48,
        "name": "Bash (5.0.0)",
        "value": "bash",
        "variable_declaration": ""
      },
      {
        "id": 49,
        "name": "Clojure 1.11",
        "value": "clojure",
        "variable_declaration": "def ",
        "output": "println"
      },      
      {
        "id": 50,
        "name": "TypeScript (3.7.4)",
        "value": "typescript",
        "variable_declaration": "let variableName: type = value;"
      },
      {
        "id": 51,
        "name": "COBOL (GnuCOBOL 3.1.2)",
        "value": "cobol",
        "variable_declaration": "01 ",
        "output": "DISPLAY"
      },
      {
        "id": 52,
        "name": "Kotlin 1.9",
        "value": "kotlin",
        "variable_declaration": "val ",
        "output": "println"
      }, 
      {
        "id": 53,
        "name": "Pascal (FPC 3.0.4)",
        "value": "pascal",
        "variable_declaration": "var variableName: type = value;"
      },
      {
        "id": 54,
        "name": "Prolog (SWI-Prolog 8.6.5)",
        "value": "prolog",
        "variable_declaration": ":- dynamic ",
        "output": "write"
      },  
      {
        "id": 55,
        "name": "Rust 1.71",
        "value": "rust",
        "variable_declaration": "let ",
        "output": "println!"
      }, 
      {
        "id": 56,
        "name": "Swift 5.8",
        "value": "swift",
        "variable_declaration": "var ",
        "output": "print"
      }, 
      {
        "id": 57,
        "name": "Octave 6.4",
        "value": "octave",
        "variable_declaration": "",
        "output": "disp"
      }, 
      {
        "id": 58,
        "name": "Brainfuck",
        "value": "brainfuck",
        "variable_declaration": "",
        "output": "."
      },
      {
        "id": 59,
        "name": "CoffeeScript 2.6",
        "value": "coffeescript",
        "variable_declaration": "var ",
        "output": "console.log"
      },
      {
        "id": 60,
        "name": "EJS",
        "value": "ejs",
        "variable_declaration": "<% let ",
        "output": "<%= "
      }, 
      {
        "id": 61,
        "name": "MySQL 8.0",
        "value": "mysql",
        "variable_declaration": "SET @",
        "output": ""
      },
      {
        "id": 62,
        "name": "PostgreSQL 15",
        "value": "postgresql",
        "variable_declaration": "DO $$",
        "output": ""
      },
      {
        "id": 630,
        "name": "SQL Server 2019",
        "value": "sqlserver",
        "variable_declaration": "@",
        "output": "PRINT"
      },      
      {
        "id": 64,
        "name": "SQL (SQLite 3.27.2)",
        "value": "sql",
        "variable_declaration": ""
      },
      {
        "id": 65,
        "name": "MongoDB Shell (4.2.3)",
        "value": "mongodb",
        "variable_declaration": ""
      },
      {
        "id": 66,
        "name": "MariaDB 10.6",
        "value": "mariadb",
        "variable_declaration": "SET @",
        "output": ""
      },    
      {
        "id": 67,
        "name": "Redis 7.0",
        "value": "redis",
        "variable_declaration": "",
        "output": "SET"
      } 
      
    ];

    const language = languages.find((lang) => lang.id === id);
    return language || null;
  };
 