import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import translations from "../AppsMenu/Candidate/mulitlingue";
import "./PersonalityTest.css";
import swal from "sweetalert";
import { Modal,Form ,Row,Col} from "react-bootstrap";

function PersonalityTest() {
  const [viewPersonalityTest, setviewPersonalityTest] = useState([]);
  const token=localStorage.getItem("token");

  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
          );
          const data = await response.json();
          setviewPersonalityTest(
            data.some(
              (permission) => permission.title === "view-personality-test"
            )
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);
  const [showAccessDeniedModal, setShowAccessDeniedModal] = useState(true); 

  useEffect(() => {
    if (!viewPersonalityTest) {
      setShowAccessDeniedModal(true); 
    }
  }, [viewPersonalityTest]);
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  useEffect(() => {
    const selectedLang = JSON.parse(localStorage.getItem("selectedLang"));
    if (selectedLang) {
      setLang(selectedLang);
    }
  }, []);
  const [tests, setTests] = useState([]);

  useEffect(() => {
    fetchTests();
  }, []);

  const fetchTests = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_APP_DOMAIN}/api/test-list`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
      );
      setTests(response.data);
    } catch (error) {
      console.error("Error fetching tests:", error);
    }
  };
  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const [addPersonalityTest, setAddPersonalityTest] = useState([]);

  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
          );
          const data = await response.json();
          setAddPersonalityTest(
            data.some(
              (permission) => permission.title === "add-personality-test"
            )
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);

  const [deletePersonalityTest, setDeletePersonalityTest] = useState([]);

  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
          );
          const data = await response.json();
          setDeletePersonalityTest(
            data.some(
              (permission) => permission.title === "delete-personality-test"
            )
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);

  const [editPersonalityTest, setEditPersonalityTest] = useState([]);

  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
          );
          const data = await response.json();
          setEditPersonalityTest(
            data.some(
              (permission) => permission.title === "edit-personality-test"
            )
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);

  const [viewPersonalityTestResult, setviewPersonalityTestResult] = useState(
    []
  );

  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
          );
          const data = await response.json();
          setviewPersonalityTestResult(
            data.some(
              (permission) =>
                permission.title === "view-personality-test-result"
            )
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);
  const [sendPersonalityTest, setSendPersonalityTest] = useState([]);

  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
          );
          const data = await response.json();
          setSendPersonalityTest(
            data.some(
              (permission) =>
                permission.title === "send-personality-test-to-candidate"
            )
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);
  

  const [searchTerm, setSearchTerm] = useState('');
  const filterTestByTitle = () => {
    return tests.filter(test => test.name.toLowerCase().includes(searchTerm.toLowerCase()));
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleDelete = (id) => {
    // Show a confirmation dialog using SweetAlert
    swal({
      title: "Are you sure?",
      text: "Once deleted, you won't be able to recover this Test!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        // User confirmed, perform the delete action
        axios
          .delete(`${process.env.REACT_APP_APP_DOMAIN}/api/deleteTestById/` + id, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }})
          .then((res) => {
            if (res.data === "Le test de personnalité a été supprimé avec succès.") {
              console.log(res);
              swal("Poof! Personality Test has been deleted!", {
                icon: "success",
              });
              setTests((prevTests) =>
            prevTests.filter((testPer) => testPer.Id !== id)
          );
              // Add any additional logic here after successful delete
            } else {
              console.log(res.data)
              if (res.data === "Ce test est associé à un profil de travail et ne peut pas être supprimé.") {
                swal("Oops!", lang.menu.TestPerRelatedToJob, "error");
              } else {
                swal("Oops!", lang.menu.TestPerRelatedToJob, "error");
              }
            }
          })
          .catch((err) => {
            swal("Oops!", "Something went wrong!", "error");
            console.log(err);
          });
      } else {
        // User canceled the delete action
        swal("Personality Test deletion canceled!", {
          icon: "info",
        });
      }
    });
  };
  
  return (

    <> {viewPersonalityTest ? (  
    <div className="test-list-container">
      <h1>{lang.menu.PersonalityTests}</h1>
      <br />
      <br />
      <Row className="mb-3">
        <Col xs={8}>
          <Form.Group controlId="formBasicSearch">
            <Form.Control
              type="text"
              placeholder={lang.menu.serachJob}
              value={searchTerm}
              onChange={handleSearch}
            />
          </Form.Group>
        </Col>
        <Col xs={4} className="d-flex align-items-end justify-content-end">
        {addPersonalityTest ? (
          <Link to="/new-test" className="btn btn-primary test-list-button">
            <i className="fas fa-plus"></i> {lang.menu.AddNewTest}
          </Link>
        ) : (
          <></>
        )}
        </Col>
      </Row>

      <div className="test-card-list">
        {filterTestByTitle().map((test) => (
          <div className="test-card" key={test.Id}>
            <div className="test-card-content">
              <h2 className="test-card-title">{test.name}</h2>
              <p className="test-card-description">
                {showFullDescription
                  ? test.description
                  : `${test.description.substring(0, 150)}...`}
                {test.description.length > 150 && (
                  <span className="read-more-link" onClick={toggleDescription}>
                    {showFullDescription ? "Read Less" : "Read More"}
                  </span>
                )}
              </p>
              <div className="test-card-icons">
                {viewPersonalityTestResult ? (
                  <Link
                    to={`/personality-test-details/${test.Id}`}
                    className="test-details-link"
                  >
                    <i className="fas fa-eye test-details-icon fa-lg"></i>
                  </Link>
                ) : (
                  <></>
                )}
                {editPersonalityTest ? (
                  <Link
                    to={`/upload-test?testId=${test.Id}`}
                    className="test-details-link"
                  >
                    <i className="fas fa-pencil-alt test-edit-icon fa-lg"></i>
                  </Link>
                ) : (
                  <></>
                )}
              
                <Link
                  to={`/page-test-candidateView/${test.Id}`}
                  className="test-details-link"
                >
                  <i className="fas fa-arrow-circle-right fa-lg"></i>
                </Link>
                {deletePersonalityTest ? (
                  <button
                  style={{ border: "none" }}
                  onClick={() => handleDelete(test.Id)}
                >
                    <i className="fas fa-trash-alt fa-lg"></i>
                  </button>
                ) : (
                  <></>
                )}
            {/*     {sendPersonalityTest ? (
                  <Link
                    to={`/send-personality-test`}
                    className="test-details-link"
                  >
                    <i className="fas fa-envelope fa-lg"></i>
                  </Link>
                ) : (
                  <></>
                )} */}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div> ) : (
             <Modal show={showAccessDeniedModal} >
             <Modal.Header>
               <Modal.Title>{lang.menu.accessDenied}</Modal.Title>
             </Modal.Header>
             <Modal.Body>
 {lang.menu.YoudontHavePerm}
             </Modal.Body>
             <Modal.Footer>
         
             </Modal.Footer>
           </Modal>
          )}</>
  );
}

export default PersonalityTest;
