import React, { useState, useEffect } from "react";
import axios from 'axios';
import translations from "../../components/AppsMenu/Candidate/mulitlingue";
import { Link } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Badge } from "react-bootstrap";

const Alerts = ({ toggleTab, toggleChatBox }) => {
  const [alertes, setAlertes] = useState([]);
   const token=localStorage.getItem("token");

   const [lang] = useState(() => {
      const storedLang = localStorage.getItem('selectedLang');
      if (storedLang) {
         const parsedLang = JSON.parse(storedLang);
         if (parsedLang.language === 'Français') {
            return translations.fr;
         }
      }
      return translations.en;
   });
   const showFeatures = async () => {
      try {
         const currentUser = JSON.parse(localStorage.getItem("userDetails"));
         const userId = currentUser.localId;
        // const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/Alerte/findByuser/${userId}`);
          const response = await axios.get(
            `${process.env.REACT_APP_APP_DOMAIN}/api/Alerte/findByuser/${userId}`, 
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ); 
          
        
         setAlertes(response.data);
      } catch (error) {
         console.error("Error fetching features:", error);
      }
   };
   useEffect(() => {
   

      showFeatures();
   }, []);
   const onViewAlerte = async(idAlerte) => {
    
      const currentUser = JSON.parse(localStorage.getItem("userDetails"));
      const userId = currentUser.localId;
      const response = await axios.put(`${process.env.REACT_APP_APP_DOMAIN}/api/Alerte/updateAlerte/${idAlerte}/${userId}`,{}, 
         {
           headers: {
             Authorization: `Bearer ${token}`,
           },
         });
      showFeatures()
   
   } 
   return (
      <div
         className={`tab-pane fade  ${
            toggleTab === "alerts" ? "active show" : ""
         }`}
         id="alerts"
         role="tabpanel"
      >
         <div className="card mb-sm-3 mb-md-0 contacts_card">
            <div className="card-header chat-list-header text-center">
               <Link to={"#"}>
                  <svg
                     xmlns="http://www.w3.org/2000/svg"
                     xmlnsXlink="http://www.w3.org/1999/xlink"
                     width="18px"
                     height="18px"
                     viewBox="0 0 24 24"
                     version="1.1"
                  >
                     <g
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                     >
                        <rect x="0" y="0" width="24" height="24" />
                        <circle fill="#000000" cx="5" cy="12" r="2" />
                        <circle fill="#000000" cx="12" cy="12" r="2" />
                        <circle fill="#000000" cx="19" cy="12" r="2" />
                     </g>
                  </svg>
               </Link>
               <div>
                  <h6 className="mb-1">{lang.menu.Alerts}</h6>
                  <p className="mb-0">{lang.menu.showAll}</p>
               </div>
               <Link to={"#"}>
                  <svg
                     xmlns="http://www.w3.org/2000/svg"
                     xmlnsXlink="http://www.w3.org/1999/xlink"
                     width="18px"
                     height="18px"
                     viewBox="0 0 24 24"
                     version="1.1"
                  >
                     <g
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                     >
                        <rect x="0" y="0" width="24" height="24" />
                        <path
                           d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                           fill="#000000"
                           fillRule="nonzero"
                           opacity="0.3"
                        />
                        <path
                           d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                           fill="#000000"
                           fillRule="nonzero"
                        />
                     </g>
                  </svg>
               </Link>
            </div>
            <PerfectScrollbar
               className={`card-body contacts_body p-0 dlab-scroll  ${
                  toggleChatBox ? "ps ps--active-y" : ""
               }`}
               id="DZ_W_Contacts_Body1"
            >
               <ul className="contacts">
                  {alertes.length > 0 ? (
                     alertes.map((alerte) => (
                        <li key={alerte.id}>
                           <div className="d-flex bd-highlight">
                              <div className="img_cont primary">AL</div>
                              <div className="user_info">
                                 <span>{alerte.title}</span>
                                 <Badge variant="danger light">{alerte.entity}</Badge>
                                 <p>{alerte.message}</p>
                                 <a
                                    href={alerte.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={(e) => {
                                       e.preventDefault();
                                       onViewAlerte(alerte.id);
                                       window.open(alerte.url, '_blank');
                                    }}
                                 >
                                    {lang.menu.viewDetails}
                                 </a>                              </div>
                           </div>
                        </li>
                     ))
                  ) : (
                     <li className="text-center">{lang.menu.AlertsNotAvailable}</li>
                  )}
               </ul>
            </PerfectScrollbar>
            <div className="card-footer"></div>
         </div>
      </div> 
     
   );
};

export default Alerts;
