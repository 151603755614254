import React from 'react';

const Editable = ({ editFormData, roles, handleEditFormChange, handleCancelClick, handleSubmitClick }) => {

    const disabled = [
        { name: 'True', code: 1 },
        { name: 'False', code: 0 },
    ];

    return (
        <>
            <td></td> 
            <td>
                <input type="text" placeholder="Enter a name ..." name="firstName"
                    value={editFormData.firstName}
                    maxLength={16}
                    onChange={handleEditFormChange}
                />
            </td>
            <td>
                <input type="text" placeholder="Enter a last Name ..." name="lastName"
                    value={editFormData.lastName}
                    maxLength={16}
                    onChange={handleEditFormChange}
                />
            </td>
            <td>
                <input type="text" required="required" placeholder="Enter an email ..." name="email"
                    value={editFormData.email}
                    maxLength={32}
                    onChange={handleEditFormChange}
                />
            </td>
            <td>
                <select
                    name="disabled"
                    value={editFormData.disabled}
                    onChange={handleEditFormChange}
                >
                    {disabled.map((lang) => (
                        <option key={lang.code} value={lang.code}>
                            {lang.name}
                        </option>
                    ))}
                </select>
            </td>
            <td><label><span>{editFormData.company}</span></label></td>
            <td>
                <select
                    name="role"
                    value={editFormData.role}
                    onChange={handleEditFormChange}
                    required
                >
                    <option value="" disabled></option>
                    {roles.map((role) => (
                        <option key={role.roleid} value={role.roleid}>
                            {role.roleName}
                        </option>
                    ))}
                </select>
            </td>
            <td><label><span>{editFormData.lastSignInTime}</span></label></td>
            <td><label><span>{editFormData.creationTime}</span></label></td>
            <td>
                <div className="d-flex">
                    <button className="btn btn-warning shadow btn-xs sharp me-1" type="button" onClick={handleSubmitClick}>
                    <i className="las la-check-circle scale5"></i></button>
                    <button className="btn btn-danger shadow btn-xs sharp " type="button" onClick={handleCancelClick}>
                        <i className="las la-times-circle scale5"></i>
                    </button>
                </div>
            </td>
        </>
    )
}
export default Editable;