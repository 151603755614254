import React, { useState, useCallback } from 'react';
import { Badge, Card } from 'react-bootstrap';

import { useDropzone } from 'react-dropzone';

const CvManager = () => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      setUploadedFiles([...uploadedFiles, ...acceptedFiles]);
    },
  });

  const handleNewUpload = () => {
    console.log('Envoyer le fichier :', uploadedFiles);
    setUploadedFiles([]);
  };

  return (
    <Card bg="primary-hover" text="white" style={{ border: '1px solid #74188D', boxShadow: '#74188D', padding: '20px' }}>
      <Card.Body style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <Card.Title>CV Manager</Card.Title>
        <Badge variant="primary light" {...getRootProps()} style={{ color: 'black', width: '100%', textAlign: 'center', padding: '20px', cursor: 'pointer' }}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <>
              <p>Drop the files here ...</p>
              <i className="fas fa-cloud-upload fa-2x" style={{ marginTop: '10px' }}></i>
            </>
          ) : (
            <>
              <p>Drag 'n' drop some files here</p>
              <p>or click here to select files</p>
              <p>To upload file size is (Max 5Mb) and allowed file types are (.pdf)</p>
            </>
          )}
        </Badge>

        <div style={{ marginTop: '20px', width: '100%',color:"black" }}>
          <h5>Uploaded Files:</h5>
          <ul className="list-group">
            {uploadedFiles.map((file, index) => (
              <li key={index} className="list-group-item">{file.name}</li>
            ))}
          </ul>
        </div>

        <button onClick={handleNewUpload} style={{ marginTop: '20px', backgroundColor: '#43DAC2', border: 'none', color: 'white', padding: '10px 20px', cursor: 'pointer' }}>
          Upload
        </button>
      </Card.Body>
    </Card>
  );
};

export default CvManager;
