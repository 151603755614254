import React, { useState, useEffect } from "react";
import { countries } from "countries-list";

const countryNames = Object.values(countries).map((country) => country.name);

function Experiences({
  lang,
  refreshData,
  toggleCategory,
  expandedCategories,
}) {
  const [experiences, setExperiences] = useState([
    { company: "", post: "", location: "", startDate: "", endDate: "" },
  ]);

  console.log("expandedCategories", expandedCategories);

  useEffect(() => {
    const storedExperiences = localStorage.getItem("Experiences");
    if (storedExperiences) {
      setExperiences(JSON.parse(storedExperiences));
    }
  }, [refreshData]);

  useEffect(() => {
    localStorage.setItem("Experiences", JSON.stringify(experiences));
  }, [experiences]);

  const handleAddExperience = () => {
    setExperiences([
      ...experiences,
      { company: "", location: "", startDate: "", endDate: "" },
    ]);
  };

  const handleRemoveExperience = (index) => {
    const newExperiences = [...experiences];
    newExperiences.splice(index, 1);
    setExperiences(newExperiences);
  };

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const maxCharacterLimits = {
      post: 3000,
      company: 100,
    };

    if (name in maxCharacterLimits && value.length > maxCharacterLimits[name]) {
      return; // Don't update state if character limit exceeded
    }

    const newExperiences = [...experiences];
    newExperiences[index][name] = value;
    setExperiences(newExperiences);
  };

  const handleCollapse = () => {
    localStorage.setItem("Experiences", JSON.stringify(experiences));
  };

  const handleAutoResize = (event) => {
    const textarea = event.target;
    const maxHeight = 200; // Maximum height in pixels

    textarea.style.height = "auto"; // Reset the height
    if (textarea.scrollHeight > maxHeight) {
      textarea.style.height = `${maxHeight}px`;
      textarea.style.overflowY = "auto"; // Add scrollbar if content exceeds max height
    } else {
      textarea.style.height = `${textarea.scrollHeight}px`; // Set the height to the scrollHeight
      textarea.style.overflowY = "hidden"; // Hide scrollbar if content is within max height
    }
  };

  return (
    <div className="category">
      <h2
        className="single-line"
        onClick={() => {
          toggleCategory("experiences");
          handleCollapse();
        }}
      >
        {lang.menu.Experiences}
      </h2>
      {expandedCategories.experiences && (
        <div className="category-content">
          {experiences.map((experience, index) => (
            <center key={index} className="experience-block">
              <svg
                onClick={() => handleRemoveExperience(index)}
                height="30px"
                width="30px"
                xmlns="http://www.w3.org/2000/svg"
                fill="#74188D"
                viewBox="0 0 24 24"
                stroke="#74188D"
                className="remove-icon"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>

              <div style={{ display: "flex", "flex-direction": "row" }}>
                <div style={{ width: "50%" }}>
                  <input
                    className="inputOnboard"
                    type="text"
                    name="company"
                    style={{ marginTop: "30px" }}
                    value={experience.company}
                    onChange={(e) => handleChange(index, e)}
                    placeholder={lang.menu.ComN}
                  />
                </div>
                <div style={{ width: "50%" }}>
                  <select
                    className="inputOnboard"
                    style={{ marginTop: "30px" }}
                    name="country"
                    value={experience.country}
                    onChange={(e) => handleChange(index, e)}
                  >
                    <option value="">{lang.menu.ComL}</option>
                    {countryNames.map((country, i) => (
                      <option key={i} value={country}
                      disabled={country === lang.menu.ComL}
>
                        {country}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div style={{ display: "flex", "flex-direction": "row" }}>
                <div
                  style={{
                    width: "50%",
                    paddingRight: "2.5%",
                    paddingLeft: "2.5%",
                    display: "flex",
                    "flex-direction": "row",
                  }}
                >
                  <h4 style={{ marginTop: "10px", marginRight: "8px" }}>
                    {lang.menu.Started}
                  </h4>

                  <input
                    className="inputOnboard"
                    type="month"
                    name="startDate"
                    value={experience.startDate}
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    "flex-direction": "row",
                    paddingRight: "2.5%",
                    paddingLeft: "2.5%",
                  }}
                >
                  <h4 style={{ marginTop: "10px", marginRight: "8px" }}>
                    {lang.menu.Ended}
                  </h4>

                  <input
                    className="inputOnboard"
                    type="month"
                    name="endDate"
                    value={experience.endDate}
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
              </div>
              <div style={{ width: "100%" }}>
                <textarea
                  style={{ marginTop: "3px", width: "95%" }}
                  className="inputOnboard"
                  type="text"
                  name="post"
                  value={experience.post}
                  onChange={(e) => {
                    handleChange(index, e);
                    handleAutoResize(e);
                  }}
                  placeholder={lang.menu.ypd}
                />
              </div>
            </center>
          ))}
          <center>
            <svg
              cursor="pointer"
              width="40px"
              height="40px"
              onClick={handleAddExperience}
              stroke="#74188D"
              fill="#74188D"
              strokeWidth="0"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              className="svg-add-circle-outline"
            >
              <path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
            </svg>
          </center>
        </div>
      )}
    </div>
  );
}

export default Experiences;
