export const languageOptions = [
  {
    id: 63,
    name: "JavaScript (Node.js 12.14.0)",
    label: "JavaScript (Node.js 12.14.0)",
    value: "javascript",
    functionSkeleton: "function myFunction() {\n  // code here\n}",
    output: "console.log(myFunction(a));"
  },
  {
    id: 45,
    name: "Assembly (NASM 2.14.02)",
    label: "Assembly (NASM 2.14.02)",
    value: "assembly",
    functionSkeleton: "section .text\n  global _start\n_start:\n  ; code here",
    output: "; Assembly output depends on the specific function and setup"
  },
  {
    id: 46,
    name: "Bash (5.0.0)",
    label: "Bash (5.0.0)",
    value: "bash",
    functionSkeleton: "function myFunction() {\n  # code here\n}",
    output: "echo $(myFunction a);"
  },
  {
    id: 48,
    name: "C (GCC 7.4.0)",
    label: "C (GCC 7.4.0)",
    value: "c",
    functionSkeleton: "void myFunction() {\n  // code here\n}",
    output: "printf(\"%d\", myFunction(a));"
  },
  {
    id: 52,
    name: "C++ (GCC 7.4.0)",
    label: "C++ (GCC 7.4.0)",
    value: "cpp",
    functionSkeleton: "void myFunction() {\n  // code here\n}",
    output: "std::cout << myFunction(a);"
  },
  {
    id: 49,
    name: "C (GCC 8.3.0)",
    label: "C (GCC 8.3.0)",
    value: "c",
    functionSkeleton: "void myFunction() {\n  // code here\n}",
    output: "printf(\"%d\", myFunction(a));"
  },
  {
    id: 53,
    name: "C++ (GCC 8.3.0)",
    label: "C++ (GCC 8.3.0)",
    value: "cpp",
    functionSkeleton: "void myFunction() {\n  // code here\n}",
    output: "std::cout << myFunction(a);"
  },
  {
    id: 50,
    name: "C (GCC 9.2.0)",
    label: "C (GCC 9.2.0)",
    value: "c",
    functionSkeleton: "void myFunction() {\n  // code here\n}",
    output: "printf(\"%d\", myFunction(a));"
  },
  {
    id: 54,
    name: "C++ (GCC 9.2.0)",
    label: "C++ (GCC 9.2.0)",
    value: "cpp",
    functionSkeleton: "void myFunction() {\n  // code here\n}",
    output: "std::cout << myFunction(a);"
  },
  {
    id: 51,
    name: "C# (Mono 6.6.0.161)",
    label: "C# (Mono 6.6.0.161)",
    value: "csharp",
    functionSkeleton: "void MyFunction() {\n  // code here\n}",
    output: "Console.WriteLine(MyFunction(a));"
  },
  {
    id: 44,
    label: "Executable",
    name: "Executable",
    value: "exe",
    functionSkeleton: "",
    output: ""
  },
  {
    id: 87,
    name: "F# (.NET Core SDK 3.1.202)",
    label: "F# (.NET Core SDK 3.1.202)",
    value: "fsharp",
    functionSkeleton: "let myFunction() =\n  // code here\n",
    output: "printfn \"%A\" (myFunction a);"
  },
  {
    id: 62,
    name: "Java (OpenJDK 13.0.1)",
    label: "Java (OpenJDK 13.0.1)",
    value: "java",
    functionSkeleton: "void myFunction() {\n  // code here\n}",
    output: "System.out.println(myFunction(a));"
  },
  {
    id: 67,
    name: "Pascal (FPC 3.0.4)",
    label: "Pascal (FPC 3.0.4)",
    value: "pascal",
    functionSkeleton: "procedure MyFunction;\nbegin\n  // code here\nend;",
    output: "WriteLn(MyFunction(a));"
  },
  {
    id: 85,
    name: "Perl (5.28.1)",
    label: "Perl (5.28.1)",
    value: "perl",
    functionSkeleton: "sub myFunction {\n  # code here\n}",
    output: "print myFunction(a);"
  },
  {
    id: 68,
    name: "PHP (7.4.1)",
    label: "PHP (7.4.1)",
    value: "php",
    functionSkeleton: "function myFunction() {\n  // code here\n}",
    output: "echo myFunction(a);"
  },
  {
    id: 70,
    name: "Python (2.7.17)",
    label: "Python (2.7.17)",
    value: "python",
    functionSkeleton: "def myFunction():\n  # code here\n",
    output: "print(myFunction(a))"
  },
  {
    id: 71,
    name: "Python (3.8.1)",
    label: "Python (3.8.1)",
    value: "python",
    functionSkeleton: "def myFunction():\n  # code here\n",
    output: "print(myFunction(a))"
  },
  {
    id: 80,
    name: "R (4.0.0)",
    label: "R (4.0.0)",
    value: "r",
    functionSkeleton: "myFunction <- function() {\n  # code here\n}",
    output: "print(myFunction(a));"
  },
  {
    id: 72,
    name: "Ruby (2.7.0)",
    label: "Ruby (2.7.0)",
    value: "ruby",
    functionSkeleton: "def myFunction\n  # code here\nend",
    output: "puts myFunction(a);"
  },
  {
    id: 82,
    name: "SQL (SQLite 3.27.2)",
    label: "SQL (SQLite 3.27.2)",
    value: "sql",
    functionSkeleton: "",
    output: ""
  },
  {
    id: 74,
    name: "TypeScript (3.7.4)",
    label: "TypeScript (3.7.4)",
    value: "typescript",
    functionSkeleton: "function myFunction(): void {\n  // code here\n}",
    output: "console.log(myFunction(a));"
  },
  {
    id: 84,
    name: "Visual Basic.Net (vbnc 0.0.0.5943)",
    label: "Visual Basic.Net (vbnc 0.0.0.5943)",
    value: "vbnet",
    functionSkeleton: "Sub MyFunction()\n  ' code here\nEnd Sub",
    output: "Console.WriteLine(MyFunction(a));"
  }
];
