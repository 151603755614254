import React, { useState, useEffect } from 'react';
import { getCountryCode, getCountryData, countries } from 'countries-list';
import ISO6391 from 'iso-639-1';

const countryNames = Object.values(countries).map(country => country.name);
const languageCodes = ISO6391.getAllCodes();
const languageNames = languageCodes.map(code => ISO6391.getName(code));

function GeneralInfo({resetError,cantSubUP,cantSubDown,  lang, fillError, expanded, toggleCategory,refreshData}) {
  const [formData, setFormData] = useState({
    FirstName: '',
    LastName: '',
    email: '',
    //avatar: '',
    country: '',
    phoneNumber: '',
    language: '',
    linkedIn: '',
    github: ''
  });

  const [errors, setErrors] = useState({
    linkedIn: '',
    github: '',
    phoneNumber: '',
    email:''
  });

  const [phoneDisabled, setPhoneDisabled] = useState(true);

  useEffect(() => {
    const storedData = localStorage.getItem('GeneralInfo');

    if (storedData) {
      setFormData(JSON.parse(storedData));
      setPhoneDisabled(JSON.parse(storedData).country=="");

    }
  }, [refreshData]);



  const handleChange = (e) => {
    let { name, value, files } = e.target;
    const maxCharacterLimits = {
      FirstName: 45,
      LastName: 45,
      email: 100,
      linkedIn: 100,
      github: 100,
      phoneNumber: 15 // Adjust the character limit according to your requirements
    };
  
    if (name in maxCharacterLimits && value.length > maxCharacterLimits[name]) {
      return; // Don't update state if character limit exceeded
    }
    handleBlur(e)

    
    
    if (name === 'phoneNumber') {
    }
    // If the input is of type file, update the formData with the selected file
    if (name === 'avatar' && files.length > 0) {
      const selectedFile = files[0];
      // Read the selected image file as a data URL
      const reader = new FileReader();
      reader.onloadend = () => {
        // Set the avatar field in formData to the data URL of the selected image
        setFormData((prevData) => ({
          ...prevData,
          [name]: selectedFile // Store the data URL of the image
        }));
      };
      reader.readAsDataURL(selectedFile);
    } else {
      // For other input fields, update formData with their values
      setFormData((prevData) => ({
        ...prevData,
        [name]: value
      }));
    }
    
    // Handle other form fields and update localStorage
    if (name === 'country') {
      console.log(getCountryData(getCountryCode(value)));
      console.log(value);
      setPhoneDisabled(false);
    }
    if  (name === 'github' && validateGitHub(value) ) {
      value=CorrectGitHub(value)

    }
    if (name === 'linkedIn' && validateLinkedIn(value) ) {
      value=CorrectLinkedIn(value)

    }
    // Clear the corresponding error message if format is corrected
    if ((name === 'linkedIn' || name === 'github' || name === 'email') && value && errors[name]) {
      cantSubDown()
      
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: ''
      }));
    }
    resetError(name)
    // Update localStorage with the new form data
    localStorage.setItem('GeneralInfo', JSON.stringify({
      ...formData,
      [name]: value
    }));
  };
  

  const handleCollapse = () => {
  };

  const handleBlur = (e) => {
    let { name, value } = e.target;

    if (name === 'linkedIn' && value ) {
      if(!validateLinkedIn(value)){
        cantSubUP()
      setErrors((prevErrors) => ({
        ...prevErrors,
        linkedIn: `${lang.menu.peavlu}`
      }));
      }else if(validateLinkedIn(value)){
        cantSubDown()
        value=CorrectLinkedIn(value)
        setFormData((prevData) => ({
          ...prevData,
          [name]: value
        }));
        setErrors((prevErrors) => ({
         ...prevErrors,
          linkedIn: ''
        }));

      }
    }else if (name === 'email' && value ) {
      if(!validateEmail(value)){
        cantSubUP()
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: `${lang.menu.peavea}`
      }));
      }else if(validateEmail(value)){
        cantSubDown()
        setErrors((prevErrors) => ({
         ...prevErrors,
          email: ''
        }));

      }
    } else if (name === 'github' && value ) {
      if(!validateGitHub(value)){
        cantSubUP()
      setErrors((prevErrors) => ({
        ...prevErrors,
        github: `${lang.menu.peavgu}`
      }));
      }else if(validateGitHub(value)){
        cantSubDown()
        value=CorrectGitHub(value)
        setFormData((prevData) => ({
          ...prevData,
          [name]: value
        }));
        setErrors((prevErrors) => ({
         ...prevErrors,
         github: ''
        }));

      }
    } else if (name === 'phoneNumber' && !phoneDisabled && value && !validatePhoneNumber(value, formData.country)) {
      cantSubUP()

      setErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumber: `${lang.menu.peavpnftsc}`
      }));
    } else if (name === 'phoneNumber' && !phoneDisabled && value && validatePhoneNumber(value, formData.country)) {
      cantSubDown()

      setErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumber: ''
      }));
    }
  };

  // Validation for LinkedIn URL format
  const validateLinkedIn = (url) => {
    const regex = /^(https:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9-]+\/?$/;
       return regex.test(url);
  };

  const CorrectLinkedIn = (url) => {
    // Add "https://www." if missing
    if (!url.startsWith('https://www.')) {
      if (url.startsWith('www.')) {
        url = 'https://' + url;
      } else if (url.startsWith('linkedin.com')) {
        url = 'https://www.' + url;
      } else if (url.startsWith('https://linkedin.com')) {
        url = url.replace('https://', 'https://www.');
      }
    }    return url;
  };

  const validateEmail = (url) => {
    const regex =  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return regex.test(url);
  };

  // Validation for GitHub URL format
  const validateGitHub = (url) => {
    const regex = /^(https:\/\/)?(www\.)?github\.com\/[a-zA-Z0-9-]+\/?$/;
  return regex.test(url);
  };

  const CorrectGitHub = (url) => {
    // Add "https://www." if missing
    if (!url.startsWith('https://www.')) {
      if (url.startsWith('www.github.com/')) {
        url = 'https://' + url;
      } else if (url.startsWith('github.com/')) {
        url = 'https://www.' + url;
      } else if (url.startsWith('https://github.com/')) {
        url = url.replace('https://', 'https://www.');
      }
    }    return url;
  };

  // Validation for Phone Number based on selected country
  const validatePhoneNumber = (phoneNumber, selectedCountry) => {
    const countryInfo =   getCountryData(getCountryCode(formData.country));
    if (countryInfo && countryInfo.phone) {
      const regexPattern = new RegExp("\\+"+ countryInfo.phone);
      console.log(regexPattern);
      console.log(regexPattern.test(phoneNumber));
      return regexPattern.test(phoneNumber);
    }
    return false;
  };

  const phonePlaceholder = formData.country && getCountryData(getCountryCode(formData.country)) ? `${lang.menu.pn} (+${getCountryData(getCountryCode(formData.country)).phone})` : `${lang.menu.pn}`;

  return (
    <div className="category">
      <h2 className='single-line' onClick={() => { toggleCategory('generalInfo'); handleCollapse(); }}>{lang.menu.generalInfo}</h2>
      {expanded && (
        <center className="category-content">
          <div style={{ display: "flex","flex-direction": "row"}}>
          <div style={{ width:"50%"}}>
            <input   type="text" className='inputOnboard' name="FirstName" placeholder={lang.menu.FirstName} value={formData.FirstName} onChange={handleChange}  />
          {fillError.FirstName && <p style={{ color: 'red' }}>{fillError.FirstName}</p>}
          </div>
          <div style={{ width:"50%"}}>
          <input   type="text" className='inputOnboard' name="LastName" placeholder={lang.menu.LastName} value={formData.LastName} onChange={handleChange}  />
          {fillError.LastName && <p style={{ color: 'red' }}>{fillError.LastName}</p>}
          </div>
          </div>
          <div style={{ display: "flex","flex-direction": "row",justifyContent:"space-between",paddingLeft: '1%',paddingRight: '1%'}}>
          
          <div style={{ width:"50%"}}>
        <select style={{ marginTop: "3px"}} className='inputOnboard' name="country" value={formData.country} onChange={handleChange} >

            <option value="">{lang.menu.country}</option>
            {countryNames.map((country, i) => (
              <option key={i} value={country}>{country}</option>
            ))}
          </select>
          {fillError.country && <p style={{ color: 'red' }}>{fillError.country}</p>}
          </div>

          <div style={{ width:"50%"}}>
          <input style={{ marginTop: "3px"}} type="tel" className='inputOnboard' name="phoneNumber" placeholder={phonePlaceholder} value={formData.phoneNumber} onChange={handleChange} disabled={phoneDisabled} onBlur={handleBlur} />
          {errors.phoneNumber && <p style={{ color: 'red' }}>{errors.phoneNumber}</p>}
          {fillError.phoneNumber && <p style={{ color: 'red' }}>{fillError.phoneNumber}</p>}
        </div>
        <div style={{ width:"50%"}}>
          <select style={{ marginTop: "3px"}} className='inputOnboard' name="language" value={formData.language} onChange={handleChange} >

            <option value="">{lang.menu.Language}</option>
            {languageCodes.map((code, i) => (
              <option key={code} value={code}>{languageNames[i]}</option>
            ))}
          </select>
          {fillError.language && <p style={{ color: 'red' }}>{fillError.language}</p>}

        </div>
          </div>
          
          <div style={{ display: "flex","flex-direction": "row",justifyContent:"space-between",paddingLeft: '8px',paddingRight: '8px'}}>
          <div style={{ width:"50%"}}>
          <input style={{ marginTop: "3px"}} type="email" className='inputOnboard' name="email" placeholder={lang.menu.Email} value={formData.email} onChange={handleChange} onBlur={handleBlur} />
          {fillError.email && <p style={{ color: 'red' }}>{fillError.email}</p>}
          {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}
          </div>
          <div style={{ width:"50%"}}>
          <input style={{ marginTop: "3px"}} type="text" className='inputOnboard' name="linkedIn" placeholder={lang.menu.LinkedIn} value={formData.linkedIn} onChange={handleChange} onBlur={handleBlur} />
          {errors.linkedIn && <p style={{ color: 'red' }}>{errors.linkedIn}</p>}
          {fillError.linkedIn && <p style={{ color: 'red' }}>{fillError.linkedIn}</p>}
          </div>
          <div style={{ width:"50%"}}>
          <input style={{ marginTop: "3px"}} type="text" className='inputOnboard' name="github" placeholder={lang.menu.GitHub} value={formData.github} onChange={handleChange} onBlur={handleBlur} />
          {fillError.github && <p style={{ color: 'red' }}>{fillError.github}</p>}
          {errors.github && <p style={{ color: 'red' }}>{errors.github}</p>}
        </div>
        </div>



          


          
          
        </center>
      )}
    </div>
  );
}

export default GeneralInfo;
