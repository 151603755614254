import React from 'react'
import './TestCompletion.css';
import { Button } from 'react-bootstrap';
import { useHistory } from "react-router-dom";

function TestCompleted() {
  const history = useHistory();

  //redirect to the dashboard
  const redirect=()=>{
    history.push("/Dashboard/");
  }
  return (
    <div className="test-completion">
      <div className="test-completion-content">
        <h2 className='titre2-completion-test'>Congratulations!</h2>
        <p className='text-completion-test'>You have successfully completed the test.</p>
        <p className='text-completion-test'>Your results will be reviewed shortly.</p>
        <Button onClick={redirect}>return</Button>
      </div>
    </div>
  )
}

export default TestCompleted