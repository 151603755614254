import React, { useState, useEffect } from "react";
import ISO6391 from "iso-639-1";
import translations from "../../../components/AppsMenu/Candidate/mulitlingue";

function SoftSkill({ user, updateUser }) {
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [proficiencyLevels, setProficiencyLevels] = useState({});
  const proficiencyLevelsOptions = [
    "Beginner",
    "Intermediate",
    "Advanced",
    "Mastery",
  ];
  const [lang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });

  const languageCodes = ISO6391.getAllCodes();
  const languageNames = languageCodes.map((code) => ISO6391.getName(code));

  useEffect(() => {
    let storedData = null;
    if (user.skills) {

      storedData = user.skills;
      while (typeof(storedData) === "string") {
      storedData = JSON.parse(storedData);

    }
    storedData=storedData.SoftSkills;
      if (storedData) {
        const {
          selectedLanguages: storedSelectedLanguages,
          proficiencyLevels: storedProficiencyLevels,
        } = storedData;
        setSelectedLanguages(storedSelectedLanguages);
        setProficiencyLevels(storedProficiencyLevels);
      }
    }
  }, []);

  useEffect(() => {
    updateUser({ selectedLanguages, proficiencyLevels });
  }, [selectedLanguages, proficiencyLevels]);

  const handleLanguageChange = (e) => {
    const language = e.target.value;
    setSelectedLanguage(language);
    if (language && !selectedLanguages.includes(language)) {
      setSelectedLanguages([...selectedLanguages, language]);
      setProficiencyLevels({ ...proficiencyLevels, [language]: "Beginner" });
      setSelectedLanguage(""); // Reset the dropdown value
    }
  };

  const handleProficiencyLevelClick = (language, level) => {
    setProficiencyLevels({ ...proficiencyLevels, [language]: level });
  };

  const handleRemoveLanguage = (languageToRemove) => {
    setSelectedLanguages(
      selectedLanguages.filter((language) => language !== languageToRemove)
    );
    setProficiencyLevels((prevLevels) => {
      const { [languageToRemove]: removedLanguage, ...remainingLanguages } =
        prevLevels;
      return remainingLanguages;
    });
  };

  const isSelectedProficiency = (language, level) => {
    return proficiencyLevels[language] === level ? "selected" : "notselected";
  };

  return (
    <>
      <div className="basic-form">
        <form onSubmit={(e) => e.preventDefault()} className="p-3">
          <div>
            <label className="my-label">{lang.menu.SoftSkills}</label>

            <select
              className="form-control"
              value={selectedLanguage}
              onChange={handleLanguageChange}
            >
              <option value="">{lang.menu.sal}</option>
              {languageCodes.map((lang) => (
                <option
                  key={ISO6391.getName(lang)}
                  value={ISO6391.getName(lang)}
                >
                  {ISO6391.getName(lang)}
                </option>
              ))}
            </select>
          </div>
          {selectedLanguages.length > 0 && (
            <div>
              {selectedLanguages.map((language, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    "flex-direction": "row",
                    marginTop: "6px",
                  }}
                >
                  <h4 style={{ margin: 0, width: "15%" }}>{language}</h4>
                  {proficiencyLevelsOptions.map((level, index) => (
                    <button
                      style={{
                        paddingLeft: "3px",
                        paddingRight: "3px",
                        marginLeft: "3px",
                      }}
                      key={index}
                      className={isSelectedProficiency(language, level)}
                      onClick={(e) => {
                        e.preventDefault(); // Prevent default form submission
                        handleProficiencyLevelClick(language, level);
                      }}
                    >
                      {level}
                    </button>
                  ))}
                  <button
                    style={{
                      paddingLeft: "5px",
                      paddingRight: "5px",
                      marginLeft: "2%",
                      color: "white",
                      background: "#74188D",
                      borderRadius: "10px",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleRemoveLanguage(language);
                    }}
                  >
                    <b>{lang.menu.X}</b>
                  </button>
                </div>
              ))}
            </div>
          )}
        </form>
      </div>
    </>
  );
}

export default SoftSkill;
