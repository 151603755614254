const Editable = ({ editCompanyData, handleEditFormChange, handleCancelClick, handleSubmitClick }) => {

    return (
        <>
            <>
                <td><label><span>{editCompanyData.companyid}</span></label></td>
                <td>
                    <input type="text" name="companyName"
                        value={editCompanyData.companyName}
                        maxLength={16}
                        onChange={handleEditFormChange}
                    />
                </td>
                <td><label><span>{editCompanyData.founder}</span></label></td>
                <td>
                    <input type="text" required="required"  name="numberOfUsers"
                        value={editCompanyData.numberOfUsers}
                        onChange={handleEditFormChange}
                    />
                </td>
                <td>
                    <input type="text" name="comments"
                        value={editCompanyData.comments}
                        maxLength={255}
                        onChange={handleEditFormChange}
                    />
                </td>
{/*                 <td>
                <select
                        name="disabled"
                        value={editCompanyData.disabled}
                        onChange={handleEditFormChange}
                    >
                        {disabled.map((lang) => (
                            <option key={lang.code} value={lang.code}>
                                {lang.name}
                            </option>
                        ))}
                    </select>
                </td> */}
                <td>
                    <div className="d-flex">
                        <button className="btn btn-warning shadow btn-xs sharp me-1" type="button" onClick={handleSubmitClick}><i className="las la-check-circle scale5"></i></button>
                        <button className="btn btn-danger shadow btn-xs sharp " type="button" onClick={handleCancelClick}>
                            <i className="las la-times-circle scale5"></i>
                        </button>
                    </div>
                </td>
            </>
        </>
    )
}
export default Editable;