import { React, useState, useEffect } from "react";
import {
  faWandMagicSparkles,
  faSliders,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import translations from "../AppsMenu/Candidate/mulitlingue";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import { Card, Accordion } from "react-bootstrap";

const Roles_managment = () => {
  const [roleManagementPer, setRoleManagementPer] = useState([]);
	const token=localStorage.getItem("token");

  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
          );
          const data = await response.json();
          setRoleManagementPer(
            data.some(
              (permission) =>
                permission.title === "view-role-management"
            )
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);
  const [showAccessDeniedModal, setShowAccessDeniedModal] = useState(true); 

  useEffect(() => {
    if (!roleManagementPer) {
      setShowAccessDeniedModal(true); 
    }
  }, [roleManagementPer]);
  const [lang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  const [activeBordered, setActiveBordered] = useState(0);
  const [addCard, setAddCard] = useState(false);
  const [permissions, setpermissions] = useState([]);
  const [roles, setRoles] = useState([]);
  const [role, setRole] = useState({
    roleid: "",
    roleName: "",
  });

  const [userRole, setuserRole] = useState([]);

  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`
          );
          const data = await response.json();
          setuserRole(data[0]);
          if (data[0].userRole === 1) {
            await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/roles/roles`, 
              {
                headers: {
                Authorization: `Bearer ${token}`,
                }})
              .then((response) => response.json())
              .then((data) => {
                setRoles(data);
              });
          } else {
            await fetch(
              `${process.env.REACT_APP_APP_DOMAIN}/api/roles/roles/${data[0].companyID}`, 
              {
                headers: {
                Authorization: `Bearer ${token}`,
                }}
            )
              .then((response) => response.json())
              .then((data) => {
                setRoles(data);
              });
          }
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);

  const handleAddFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...role };
    newFormData[fieldName] = fieldValue;
    setRole(newFormData);
  };

  const handleChangeRole = async (e) => {
    e.preventDefault();
    setRole(() => ({
      roleid: e.target.value,
      roleName:
        roles.find((role) => role.roleid === parseInt(e.target.value))
          ?.roleName || "",
    }));
    const roleid = e.target.value;
    await fetch(
      `${process.env.REACT_APP_APP_DOMAIN}/api/roles/modules/${roleid}`, 
      {
        headers: {
        Authorization: `Bearer ${token}`,
        }}
    )
      .then((response) => response.json())
      .then((data) => {
        setpermissions(data);
        console.log("permissions 1", data);
      })
      .catch((error) => {
        console.error("Error fetching modules data:", error);
      });
  };

  const handleAddRole = () => {
    const newRole = {
      roleid: "",
      roleName: "",
    };
    setRole(newRole);
    setAddCard(true);
  };

  const handleEditRole = () => {
    setAddCard(true);
  };
  const handleDeleteRole = async () => {
    const roleid = role.roleid;
    fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/roles/delete/${roleid}`, {
      method: "DELETE",
    }, 
    {
      headers: {
      Authorization: `Bearer ${token}`,
      }})
      .then(
        swal("", lang.menu.DeleteRoleSuccess, "success").then(() => {
          window.location.reload();
        })
      )
      .catch((error) => {
        swal("", lang.menu.DeleteRoleError, "error");
      });
  };

  const handleAddFormSubmit = async (event) => {
    event.preventDefault();

    var error = false;
    var errorMsg = "";
    if (role.roleName === "") {
      error = true;
      errorMsg = lang.menu.fillName;
    }

    if (!error) {
      const newRole = {
        roleid: role.roleid,
        roleName: role.roleName,
        companyid: userRole.companyID,
      };
      const response = await fetch(
        `${process.env.REACT_APP_APP_DOMAIN}/api/roles/saveRole`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newRole),
        }
      );

      if (response.ok) {
        setAddCard(false);
        swal(lang.menu.goodJob, lang.menu.addRole, "success").then(() => {
          window.location.reload();
        });
      } else if (response.status === 777) {
        swal("Oops", lang.menu.roleExists, "error");
      } else {
        swal("Oops", lang.menu.addRoleError, "error");
      }
    } else {
      swal("Oops", errorMsg, "error");
    }
  };

  const defaultAccordion = [
    {
      title: lang.menu.jobs,
      text: "Done",
    },
    {
      title: lang.menu.candidates,
      text: "Done",
    },
    {
      title: lang.menu.perTest,
      text: "Done",
    },
    {
      title: lang.menu.testPsychoTechnique,
      text: "Done",
    },
    {
      title: lang.menu.testTechnique,
      text: "Done",
    },
    {
      title: "SideBar",
      text: "Done",
    },
    {
      title: lang.menu.Assignment,
      text: "Done",
    },
  ];

  const [allPermissions, setAllPermissions] = useState([]);
  useEffect(async ()=>{
    try{
      const response = await fetch(
      `${process.env.REACT_APP_APP_DOMAIN}/api/users/allPermissions`, 
      {
        headers: {
        Authorization: `Bearer ${token}`,
        }}
    );
    const data = await response.json();
    setAllPermissions(data);
  }catch(err){
    console.error(err);
  }
  },[]);
  const updatePermission = async (perm) => {
    const user_permissions_role = {
      roleid: role.roleid,
      permission_id: "",
    };

    //user_permissions_role.permission_id = allPermissions.find(obj => obj.title === perm).permission_id;
    console.log("perm", perm); 
    console.log("array", Array.isArray(allPermissions));
    console.log(allPermissions.find((obj) => {return obj.title == perm;}));
    user_permissions_role.permission_id = allPermissions.find(obj => obj.title == perm).id;
    console.log("user",user_permissions_role);
    
   /* switch (perm) {
      
      case "view-job":
        user_permissions_role.permission_id = 1;
        break;
      case "add-job":
        user_permissions_role.permission_id = 2;
        break;
      case "edit-job":
        user_permissions_role.permission_id = 3;
        break;
      case "delete-job":
        user_permissions_role.permission_id = 4;
        break;
      case "send-cv":
        user_permissions_role.permission_id = 5;
        break;
      case "candidate-list":
        user_permissions_role.permission_id = 6;
        break;
      case "cvs-extraction":
        user_permissions_role.permission_id = 7;
        break;
      case "list-ref":
        user_permissions_role.permission_id = 8;
        break;
      case "list-categories":
        user_permissions_role.permission_id = 9;
        break;
      case "create-category":
        user_permissions_role.permission_id = 10;
        break;
      case "import-questions-testPsycho":
        user_permissions_role.permission_id = 11;
        break;
      case "delete-all-questions-testPsycho":
        user_permissions_role.permission_id = 12;
        break;
      case "pass-psycho-test":
        user_permissions_role.permission_id = 13;
        break;
      case "list-psycho-test":
        user_permissions_role.permission_id = 14;
        break;
      case "create-psycho-test":
        user_permissions_role.permission_id = 15;
        break;
      case "delete-psycho-test":
        user_permissions_role.permission_id = 16;
        break;
      case "result-psycho-tests":
        user_permissions_role.permission_id = 17;
        break;
      case "your-psycho-tests":
        user_permissions_role.permission_id = 18;
        break;
      case "list-skills":
        user_permissions_role.permission_id = 19;
        break;
      case "create-skill":
        user_permissions_role.permission_id = 20;
        break;
      case "import-questions-testTech":
        user_permissions_role.permission_id = 21;
        break;
      case "delete-all-questions-testTech":
        user_permissions_role.permission_id = 22;
        break;
      case "pass-tech-test":
        user_permissions_role.permission_id = 23;
        break;
      case "list-tech-test":
        user_permissions_role.permission_id = 24;
        break;
      case "create-test":
        user_permissions_role.permission_id = 25;
        break;
      case "delete-tech-test":
        user_permissions_role.permission_id = 26;
        break;
      case "result-tech-tests":
        user_permissions_role.permission_id = 27;
        break;
      case "your-tech-tests":
        user_permissions_role.permission_id = 28;
        break;
      case "candidate-eval":
        user_permissions_role.permission_id = 29;
        break;

      case "candidate-assessment":
        user_permissions_role.permission_id = 30;
        break;
      case "topics":
        user_permissions_role.permission_id = 31;
        break;
      case "add-personality-test":
        user_permissions_role.permission_id = 33;
        break;
      case "delete-personality-test":
        user_permissions_role.permission_id = 34;
        break;
      case "edit-personality-test":
        user_permissions_role.permission_id = 35;
        break;
      case "view-personality-test-result":
        user_permissions_role.permission_id = 37;
        break;
      case "send-personality-test-to-candidate":
        user_permissions_role.permission_id = 38;
        break;
      case "view-personality-test":
        user_permissions_role.permission_id = 36;
        break;
	  case "job-role-list":
			user_permissions_role.permission_id = 39;
		break;
		case "jobrole-list-test":
			user_permissions_role.permission_id = 40;
		break;
		case "list-test-tech-coding":
			user_permissions_role.permission_id = 41;
		break;
		case "tech-list":
			user_permissions_role.permission_id = 42;
		break;
		case "view-candidates":
			user_permissions_role.permission_id = 43;
		break;
    case "view-PsychoTechnical-test":
			user_permissions_role.permission_id = 44;
		break;
    case "view-technical-test":
			user_permissions_role.permission_id = 45;
		break;
    case "view-coding-test":
			user_permissions_role.permission_id = 46;
		break;
    case "view-technical-QCM-test":
			user_permissions_role.permission_id = 47;
		break;
    case "add-assignment":
			user_permissions_role.permission_id = 54;
		break;
    case "update-assignment":
			user_permissions_role.permission_id = 55;
		break;
    case "delete-assignment":
			user_permissions_role.permission_id = 56;
		break;
    case "assignment-list":
			user_permissions_role.permission_id = 110;
		break;
    case "view-assignment":
			user_permissions_role.permission_id = 53;
		break;
    case "view-assignment-result":
			user_permissions_role.permission_id = 111;
		break;
      default:
        break;
    }*/
    
    await fetch(
      `${process.env.REACT_APP_APP_DOMAIN}/api/roles/updatepermission`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user_permissions_role),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setpermissions(data);
      })
      .catch((error) => {
        console.error("Error fetching modules data:", error);
      });
  };

  const chackbox = document.querySelectorAll(".bs_exam_topper input");
  const motherChackBox = document.querySelector(".bs_exam_topper_all input");
  const chackboxFun = async (perm) => {
    await updatePermission(perm);
    for (let i = 0; i < chackbox.length; i++) {
      const element = chackbox[i];
      if (!element.checked) {
        motherChackBox.checked = false;
        break;
      } else {
        motherChackBox.checked = true;
      }
    }
  };

  return (
    <> {roleManagementPer ? (  
    <>
      <div className="col-12">
        <Modal className="modal fade" show={addCard} onHide={setAddCard}>
          <div className="" role="document">
            <div className="">
              <form>
                <div className="modal-header">
                  {role.roleid ? (
                    <h4 className="modal-title fs-20">{lang.menu.EditRole}</h4>
                  ) : (
                    <h4 className="modal-title fs-20">{lang.menu.AddRole}</h4>
                  )}
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => setAddCard(false)}
                    data-dismiss="modal"
                  >
                    <span></span>
                  </button>
                </div>
                <div className="modal-body">
                  <i
                    className="flaticon-cancel-12 close"
                    data-dismiss="modal"
                  ></i>
                  <div className="add-contact-box">
                    <div className="add-contact-content">
                      <div className="form-group mb-3">
                        <div className="contact-name">
                          <input
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            name="roleName"
                            required="required"
                            value={role.roleName}
                            onChange={handleAddFormChange}
                          />
                          <span className="validation-text"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={handleAddFormSubmit}
                  >
                    {lang.menu.Add}
                  </button>
                  <button
                    type="button"
                    onClick={() => setAddCard(false)}
                    className="btn btn-danger"
                  >
                    <i className="flaticon-delete-1"></i> {lang.menu.Discard}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal>
        <div className="card full-width">
          <div className="card-header">
            <h4 className="card-title">
              <FontAwesomeIcon icon={faWandMagicSparkles} className="me-2" />
              {lang.menu.RolesManagment}
            </h4>
          </div>
          <div className="card-body">
            <div className="form-group mb-3 col-md-6 my-form-padding">
              <label className="my-label">{lang.menu.role}</label>
              <div className="d-flex align-items-center">
                <select
                  value={role.roleid}
                  onChange={(e) => handleChangeRole(e)}
                  className="form-control me-2"
                >
                  <option value="" disabled>
                    {lang.menu.Choose}
                  </option>
                  {roles.map((role) => (
                    <option key={role.roleName} value={role.roleid}>
                      {role.roleName}
                      {role.companyid === 1
                        ? ""
                        : role.companyName
                        ? ` (${role.companyName})`
                        : ""}
                    </option>
                  ))}
                </select>
                <button
                  className="btn btn-primary shadow btn-xs sharp"
                  onClick={() => handleAddRole()}
                >
                  <i className="fa fa-plus"></i>
                </button>
                {role.roleid ? (
                  <button
                    className="ms-3 btn btn-secondary shadow btn-xs sharp"
                    onClick={() => handleEditRole()}
                    disabled={
                      role.roleid === "1" ||
                      role.roleid === "2" ||
                      role.roleid === "3" ||
                      role.roleid === "4" ||
                      role.roleid === "5" 
                    }
                  >
                    <i className="fa fa-pen"></i>
                  </button>
                ) : (
                  <></>
                )}
                {role.roleid ? (
                  <button
                    className="ms-3 btn btn-danger shadow btn-xs sharp"
                    onClick={() => handleDeleteRole()}
                    disabled={
                      role.roleid === "1" ||
                      role.roleid === "2" ||
                      role.roleid === "3" ||
                      role.roleid === "4"||
                      role.roleid === "5" 
                    }
                  >
                    <i className="fa fa-trash"></i>
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
        {role.roleid ? (
          <div className="card full-width">
            <div className="card-header">
              <h4 className="card-title">
                <FontAwesomeIcon icon={faSliders} className="me-2" />
                {lang.menu.permissions}
              </h4>
            </div>
            <div className="card-body">
              <Accordion
                className="accordion accordion-danger-solid"
                defaultActiveKey="0"
              >
                <div className="accordion-item" key={0}>
                  <Accordion.Toggle
                    className={`accordion-header accordion-primary-header ${
                      activeBordered !== 0 ? "collapsed" : ""
                    }`}
                    as={Card.Text}
                    eventKey={`${0}`}
                    onClick={(event) =>
                      setActiveBordered(activeBordered === 0 ? -1 : 0)
                    }
                  >
                    {" "}
                    <span className="accordion-header-text">
                      {defaultAccordion[0].title}
                    </span>
                    <span className="accordion-header-indicator"></span>
                  </Accordion.Toggle>
                  <Accordion.Collapse
                    eventKey={`${0}`}
                    className="accordion__body accordion-primary-body"
                  >
                    <div className="accordion-body-text">
                      <div className="row ms-3">
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">
                            {lang.menu.Viewjobslist}
                          </strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-view-jobs"
                            checked={permissions.some(
                              (permission) => permission.title === "view-job"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() => chackboxFun("view-job")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">{lang.menu.Addajob}</strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-add-job"
                            checked={permissions.some(
                              (permission) => permission.title === "add-job"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() => chackboxFun("add-job")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">{lang.menu.Editajob}</strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-edit-job"
                            checked={permissions.some(
                              (permission) => permission.title === "edit-job"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() => chackboxFun("edit-job")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">
                            {lang.menu.Deleteajob}
                          </strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-delete-job"
                            checked={permissions.some(
                              (permission) => permission.title === "delete-job"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() => chackboxFun("delete-job")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
						<div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">
{lang.menu.applyJob}                       </strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-job-role-list"
                            checked={permissions.some(
                              (permission) => permission.title === "job-role-list"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() => chackboxFun("job-role-list")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
						<div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">
						  Your pre-selected offers
                          </strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-jobrole-list-test"
                            checked={permissions.some(
                              (permission) => permission.title === "jobrole-list-test"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() => chackboxFun("jobrole-list-test")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">{lang.menu.passJobTest}</strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-pass-test-from-job"
                            checked={permissions.some(
                              (permission) => permission.title === "pass-test-from-job"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() => chackboxFun("pass-test-from-job")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                      </div>
                    </div>
                  </Accordion.Collapse>
                </div>
                <div className="accordion-item" key={1}>
                  <Accordion.Toggle
                    className={`accordion-header accordion-primary-header ${
                      activeBordered !== 1 ? "collapsed" : ""
                    }`}
                    as={Card.Text}
                    eventKey={`${1}`}
                    onClick={() =>
                      setActiveBordered(activeBordered === 1 ? -1 : 1)
                    }
                  >
                    {" "}
                    <span className="accordion-header-text">
                      {defaultAccordion[1].title}
                    </span>
                    <span className="accordion-header-indicator"></span>
                  </Accordion.Toggle>
                  <Accordion.Collapse
                    eventKey={`${1}`}
                    className="accordion__body accordion-primary-body"
                  >
                    <div className="accordion-body-text">
                      <div className="row ms-3">
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">Send CV</strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-send-cv"
                            checked={permissions.some(
                              (permission) => permission.title === "send-cv"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() => chackboxFun("send-cv")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">
                            Candidate List Extraction
                          </strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-candidate-list"
                            checked={permissions.some(
                              (permission) =>
                                permission.title === "candidate-list"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() => chackboxFun("candidate-list")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">CVs data Extraction</strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-cvs-extraction"
                            checked={permissions.some(
                              (permission) =>
                                permission.title === "cvs-extraction"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() => chackboxFun("cvs-extraction")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">List Reference</strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-list-ref"
                            checked={permissions.some(
                              (permission) => permission.title === "list-ref"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() => chackboxFun("list-ref")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">Candidate Evaluation</strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-candidate-eval"
                            checked={permissions.some(
                              (permission) =>
                                permission.title === "candidate-eval"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() => chackboxFun("candidate-eval")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">Candidate Assessment</strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-candidate-assessment"
                            checked={permissions.some(
                              (permission) =>
                                permission.title === "candidate-assessment"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() => chackboxFun("candidate-assessment")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                      </div>
                    </div>
                  </Accordion.Collapse>
                </div>
                <div className="accordion-item" key={2}>
                  <Accordion.Toggle
                    className={`accordion-header accordion-primary-header ${
                      activeBordered !== 2 ? "collapsed" : ""
                    }`}
                    as={Card.Text}
                    eventKey={`${2}`}
                    onClick={() =>
                      setActiveBordered(activeBordered === 2 ? -1 : 2)
                    }
                  >
                    {" "}
                    <span className="accordion-header-text">
                      {defaultAccordion[2].title}
                    </span>
                    <span className="accordion-header-indicator"></span>
                  </Accordion.Toggle>
                  <Accordion.Collapse
                    eventKey={`${2}`}
                    className="accordion__body accordion-primary-body"
                  >
                    <div className="accordion-body-text">
                      <div className="row ms-3">
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">{lang.menu.perTestAdd}</strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-add-personality-test"
                            checked={permissions.some(
                              (permission) =>
                                permission.title === "add-personality-test"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() => chackboxFun("add-personality-test")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">
                          {lang.menu.perTestdelete}
                          </strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-delete-personality-test"
                            checked={permissions.some(
                              (permission) =>
                                permission.title === "delete-personality-test"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() =>
                              chackboxFun("delete-personality-test")
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">
                          {lang.menu.persEdit}
                          </strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-edit-personality-test"
                            checked={permissions.some(
                              (permission) =>
                                permission.title === "edit-personality-test"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() => chackboxFun("edit-personality-test")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">
                            {lang.menu.perTestView}
                          </strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-view-personality-test"
                            checked={permissions.some(
                              (permission) =>
                                permission.title === "view-personality-test"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() => chackboxFun("view-personality-test")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">
                            {lang.menu.personalityTestRes}
                          </strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-view-personality-test-result"
                            checked={permissions.some(
                              (permission) =>
                                permission.title ===
                                "view-personality-test-result"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() =>
                              chackboxFun("view-personality-test-result")
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                      {/*   <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">
                            Send personality test to candidate
                          </strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-send-personality-test-to-candidate"
                            checked={permissions.some(
                              (permission) =>
                                permission.title ===
                                "send-personality-test-to-candidate"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() =>
                              chackboxFun("send-personality-test-to-candidate")
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div> */}
                      </div>
                    </div>
                  </Accordion.Collapse>
                </div>
                <div className="accordion-item" key={3}>
                  <Accordion.Toggle
                    className={`accordion-header accordion-primary-header ${
                      activeBordered !== 3 ? "collapsed" : ""
                    }`}
                    as={Card.Text}
                    eventKey={`${3}`}
                    onClick={() =>
                      setActiveBordered(activeBordered === 3 ? -1 : 3)
                    }
                  >
                    {" "}
                    <span className="accordion-header-text">
                      {defaultAccordion[3].title}
                    </span>
                    <span className="accordion-header-indicator"></span>
                  </Accordion.Toggle>
                  <Accordion.Collapse
                    eventKey={`${3}`}
                    className="accordion__body accordion-primary-body"
                  >
                    <div className="accordion-body-text">
                      <div className="row ms-3">
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">List of Categories</strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-list-categories"
                            checked={permissions.some(
                              (permission) =>
                                permission.title === "list-categories"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() => chackboxFun("list-categories")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">New category</strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-create-category"
                            checked={permissions.some(
                              (permission) =>
                                permission.title === "create-category"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() => chackboxFun("create-category")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">
                            Import questions of test Psycho
                          </strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-import-questions-testPsycho"
                            checked={permissions.some(
                              (permission) =>
                                permission.title ===
                                "import-questions-testPsycho"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() =>
                              chackboxFun("import-questions-testPsycho")
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">
                            Delete All Questions Of Test Psycho
                          </strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-delete-all-questions-testPsycho"
                            checked={permissions.some(
                              (permission) =>
                                permission.title ===
                                "delete-all-questions-testPsycho"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() =>
                              chackboxFun("delete-all-questions-testPsycho")
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">
                            Pass a Psycho-Technical test
                          </strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-pass-psycho-test"
                            checked={permissions.some(
                              (permission) =>
                                permission.title === "pass-psycho-test"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() => chackboxFun("pass-psycho-test")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">
                            List of Psycho-Technical tests
                          </strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-list-psycho-test"
                            checked={permissions.some(
                              (permission) =>
                                permission.title === "list-psycho-test"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() => chackboxFun("list-psycho-test")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">
                            New Psycho-Technical test
                          </strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-create-psycho-test"
                            checked={permissions.some(
                              (permission) =>
                                permission.title === "create-psycho-test"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() => chackboxFun("create-psycho-test")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>

                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">
                            Delete Psycho-Technical test
                          </strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-delete-psycho-test"
                            checked={permissions.some(
                              (permission) =>
                                permission.title === "delete-psycho-test"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() => chackboxFun("list-psycho-test")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>

                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">
                            Psycho-Technical tests result
                          </strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-result-psycho-tests"
                            checked={permissions.some(
                              (permission) =>
                                permission.title === "result-psycho-tests"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() => chackboxFun("result-psycho-tests")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">
                            Your Psycho-Technical tests
                          </strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-your-psycho-tests"
                            checked={permissions.some(
                              (permission) =>
                                permission.title === "your-psycho-tests"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() => chackboxFun("your-psycho-tests")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                      </div>
                    </div>
                  </Accordion.Collapse>
                </div>
                <div className="accordion-item" key={4}>
                  <Accordion.Toggle
                    className={`accordion-header accordion-primary-header ${
                      activeBordered !== 4 ? "collapsed" : ""
                    }`}
                    as={Card.Text}
                    eventKey={`${4}`}
                    onClick={() =>
                      setActiveBordered(activeBordered === 4 ? -1 : 4)
                    }
                  >
                    {" "}
                    <span className="accordion-header-text">
                      {defaultAccordion[4].title}
                    </span>
                    <span className="accordion-header-indicator"></span>
                  </Accordion.Toggle>
                  <Accordion.Collapse
                    eventKey={`${4}`}
                    className="accordion__body accordion-primary-body"
                  >
                    <div className="accordion-body-text">
                      <div className="row ms-3">
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">List of Skills</strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-list-skills"
                            checked={permissions.some(
                              (permission) => permission.title === "list-skills"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() => chackboxFun("list-skills")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">New skill</strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-create-skill"
                            checked={permissions.some(
                              (permission) =>
                                permission.title === "create-skill"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() => chackboxFun("create-skill")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">
                            Import questions of test Tech
                          </strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-import-questions-testTech"
                            checked={permissions.some(
                              (permission) =>
                                permission.title === "import-questions-testTech"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() =>
                              chackboxFun("import-questions-testTech")
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">
                            Delete All Questions Of Test Tech
                          </strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-delete-all-questions-testTech"
                            checked={permissions.some(
                              (permission) =>
                                permission.title ===
                                "delete-all-questions-testTech"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() =>
                              chackboxFun("delete-all-questions-testTech")
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">
                            Pass a Technical test
                          </strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-pass-tech-test"
                            checked={permissions.some(
                              (permission) =>
                                permission.title === "pass-tech-test"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() => chackboxFun("pass-tech-test")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">
                            List of Technical tests
                          </strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-list-tech-test"
                            checked={permissions.some(
                              (permission) =>
                                permission.title === "list-tech-test"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() => chackboxFun("list-tech-test")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">New Technical test</strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-create-test"
                            checked={permissions.some(
                              (permission) => permission.title === "create-test"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() => chackboxFun("create-test")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>

                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">
                            Delete Technical test
                          </strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-delete-tech-test"
                            checked={permissions.some(
                              (permission) =>
                                permission.title === "delete-tech-test"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() => chackboxFun("delete-tech-test")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>

                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">
                            Technical tests result
                          </strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-result-tech-tests"
                            checked={permissions.some(
                              (permission) =>
                                permission.title === "result-tech-tests"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() => chackboxFun("result-tech-tests")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">Your Technical tests</strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-your-tech-tests"
                            checked={permissions.some(
                              (permission) =>
                                permission.title === "your-tech-tests"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() => chackboxFun("your-tech-tests")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>

                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">Topics</strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-topics"
                            checked={permissions.some(
                              (permission) => permission.title === "topics"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() => chackboxFun("topics")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
						<div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">
						  Technical Tests
                          </strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-tech-list"
                            checked={permissions.some(
                              (permission) =>
                                permission.title === "tech-list"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() => chackboxFun("tech-list")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>

						<div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">
						  Résultats de l'Évaluation des Compétences en Codage des Candidats par Offre d'Emploi
                          </strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-list-test-tech-coding"
                            checked={permissions.some(
                              (permission) =>
                                permission.title === "list-test-tech-coding"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() => chackboxFun("list-test-tech-coding")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                      </div>
                    </div>
                  </Accordion.Collapse>
                </div>
                <div className="accordion-item" key={6}>
                  <Accordion.Toggle
                    className={`accordion-header accordion-primary-header ${
                      activeBordered !== 6 ? "collapsed" : ""
                    }`}
                    as={Card.Text}
                    eventKey={`${6}`}
                    onClick={() =>
                      setActiveBordered(activeBordered === 6 ? -1 : 6)
                    }
                  >
                    {" "}
                    <span className="accordion-header-text">
                      {defaultAccordion[6].title}
                    </span>
                    <span className="accordion-header-indicator"></span>
                  </Accordion.Toggle>
                  <Accordion.Collapse
                    eventKey={`${6}`}
                    className="accordion__body accordion-primary-body"
                  >
                    <div className="accordion-body-text">
                      <div className="row ms-3">
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">{lang.menu.AssignmentList}</strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-assignment-list"
                            checked={permissions.some(
                              (permission) => permission.title === "assignment-list"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() => chackboxFun("assignment-list")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">{lang.menu.CreateNewAssignment}</strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-add-assignment"
                            checked={permissions.some(
                              (permission) =>
                                permission.title === "add-assignment"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() => chackboxFun("add-assignment")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">
                            {lang.menu.UpdateAssignment}
                          </strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-update-assignment"
                            checked={permissions.some(
                              (permission) =>
                                permission.title === "update-assignment"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() =>
                              chackboxFun("update-assignment")
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">
                            {lang.menu.DeleteAssignment}
                          </strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-delete-assignment"
                            checked={permissions.some(
                              (permission) =>
                                permission.title ===
                                "delete-assignment"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() =>
                              chackboxFun("delete-assignment")
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">
                            {lang.menu.viewAssignmentResult}
                          </strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-view-assignment-result"
                            checked={permissions.some(
                              (permission) =>
                                permission.title === "view-assignment-result"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() => chackboxFun("view-assignment-result")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>

                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">
                            {lang.menu.AssignmentCompressedFile}
                          </strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-assignment-compressed-file"
                            checked={permissions.some(
                              (permission) =>
                                permission.title === "assignment-compressed-file"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() => chackboxFun("assignment-compressed-file")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                       						
                      </div>
                    </div>
                  </Accordion.Collapse>
                </div>
                <div className="accordion-item" key={5}>
                  <Accordion.Toggle
                    className={`accordion-header accordion-primary-header ${
                      activeBordered !== 5 ? "collapsed" : ""
                    }`}
                    as={Card.Text}
                    eventKey={`${5}`}
                    onClick={() =>
                      setActiveBordered(activeBordered === 5 ? -1 : 5)
                    }
                  >
                    {" "}
                    <span className="accordion-header-text">
                      {defaultAccordion[5].title}
                    </span>
                    <span className="accordion-header-indicator"></span>
                  </Accordion.Toggle>
                  <Accordion.Collapse
                    eventKey={`${5}`}
                    className="accordion__body accordion-primary-body"
                  >
                    <div className="accordion-body-text">
                      <div className="row ms-3">
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">{lang.menu.viewCandidates}</strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-view-candidates"
                            checked={permissions.some(
                              (permission) =>
                                permission.title === "view-candidates"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() => chackboxFun("view-candidates")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">{lang.menu.viewPsycho}</strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-view-PsychoTechnical-test"
                            checked={permissions.some(
                              (permission) =>
                                permission.title === "view-PsychoTechnical-test"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() => chackboxFun("view-PsychoTechnical-test")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">{lang.menu.viewTech}</strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-view-technical-test"
                            checked={permissions.some(
                              (permission) =>
                                permission.title === "view-technical-test"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() => chackboxFun("view-technical-test")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">{lang.menu.viewCoding}</strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-view-coding-test"
                            checked={permissions.some(
                              (permission) =>
                                permission.title === "view-coding-test"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() => chackboxFun("view-coding-test")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">{lang.menu.viewTechQCM}</strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-view-technical-QCM-test"
                            checked={permissions.some(
                              (permission) =>
                                permission.title === "view-technical-QCM-test"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() => chackboxFun("view-technical-QCM-test")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <strong className="ms-2">{lang.menu.viewAssignment}</strong>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox-black"
                            id="check-view-assignment"
                            checked={permissions.some(
                              (permission) =>
                                permission.title === "view-assignment"
                            )}
                            disabled={role.roleid === "1"}
                            required=""
                            onChange={() => chackboxFun("view-assignment")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                      </div>
                    </div>
                    
                  </Accordion.Collapse>
                </div>
                
              </Accordion>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>) : (
			<Modal show={showAccessDeniedModal} >
			<Modal.Header>
			  <Modal.Title>{lang.menu.accessDenied}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
			{lang.menu.YoudontHavePerm}
			</Modal.Body>
			<Modal.Footer>
		
			</Modal.Footer>
		  </Modal>
		 )}</>
  );
};
export default Roles_managment;
