import React, { useState, useEffect, useRef } from 'react';
import { Dropdown, Row, Card, Col } from 'react-bootstrap';


const OutputWindow = ({ outputDetails }) => {
  const getOutput = () => {
    if (outputDetails && outputDetails.stdout !== null) {
   
      return (
        <pre className="px-2 py-1 font-normal text-xs text-green-500">
          {outputDetails.stdout}
        </pre>
      );
    
    } else {
      
      return (
        <pre className="px-2 py-1 font-normal text-xs text-red-500">
          {outputDetails?.stderr}
        </pre>
      );
    }
  };
  return (
    <>
   <Row style={{fontFamily:"Poppins" }}>
    <Col>
      <h1 className="font-bold text-xl bg-clip-text text-transparent bg-gradient-to-r from-slate-900 to-slate-700 mb-2">

        Output
      </h1></Col>
   
                  </Row>
      <div className="w-full h-56 bg-[#1e293b] rounded-md text-white font-normal text-sm overflow-y-auto">

  <Card style={{backgroundColor:"#e9f9fd",color:"black",width:"93%",height:"100%",textAlign:"left",fontSize:"170%",marginLeft:"3%",fontFamily:"Poppins"}}>
  {getOutput()}</Card>
      </div>
    </>
  );
};

export default OutputWindow;
