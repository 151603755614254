import React, { useEffect, useState } from "react";
import CodeEditorWindow from "./CodeEditorWindow";
import axios from "axios";
import { languageOptions1 } from "../constants/languageOptions1";
import Select from "react-select";
import { customStyles } from "../constants/customStyles";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Button,
  Row,
  Card,
  Col,
  InputGroup,
  Form,
  Table,
} from "react-bootstrap";
import { defineTheme } from "../lib/defineTheme";
import useKeyPress from "../hooks/useKeyPress";
import { useTimer } from "react-timer-hook";
import OutputWindow from "./OutputWindow";
import OutputDetails from "./OutputDetails";
import ThemeDropdown from "./ThemeDropdown";
import html2canvas from "html2canvas";
import { FaRunning } from "react-icons/fa";
import { LanguageInfo } from "../constants/extensionCode";
import swal from "sweetalert";

const Landing = ({
  idTopic,
  input1,
  expectedOutput1,
  input2,
  expectedOutput2,
  input3,
  expectedOutput3,
  LanguageId,
  Squelette,
  ExtentionCode,
  ExitTest,
  allowCopyPaste
}) => {
  const [inputValue, setInputValue] = useState(input1);
  const [codeIni, setCodeIni] = useState(Squelette);
  const [code, setCode] = useState(codeIni);
  const token=localStorage.getItem("token");

  const history = useHistory();
  useEffect(() => {
    setCodeIni(Squelette);
  }, [Squelette]);

  const [outputDetails, setOutputDetails] = useState(null);
  const [processing, setProcessing] = useState(null); //contrôler l'état de la compilation en cours
  const [theme, setTheme] = useState("cobalt");
  const [language, setLanguage] = useState(languageOptions1[0]);
  const [position, setPosition] = useState();
  let [successfulTestCount, setSuccessfulTestCount] = useState(0);
  const [testCase1Disabled, setTestCase1Disabled] = useState(false);
  const [testCase2Disabled, setTestCase2Disabled] = useState(false);
  const [testCase3Disabled, setTestCase3Disabled] = useState(false);
  const [testCasesDisabled, setTestCasesDisabled] = useState(false);
  const [runClicked, setRunClicked] = useState(false);
  const localTime = localStorage.getItem("questionsNbrCoding");
  const [resuTest, setResuTest] = useState([]);
  const updateScoreInDatabase = async (
    successfulTestCount,
    screenshotBlob,
    filename
  ) => {
    const userDetailsString = localStorage.getItem("userDetails");
    const userDetails = JSON.parse(userDetailsString);
    const selectedCandidate = userDetails ? userDetails.localId : null;
    const jobRoleId = localStorage.getItem("jobRoleId");

    try {
      const formData = new FormData();
      const tt1 = `${filename}`;

      formData.append("noteCoding", successfulTestCount);
      formData.append("ScreenCode", filename);
      formData.append("codeF", code);
      formData.append("imageContent", screenshotBlob, tt1);

      await axios.put(
        `${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/candidatesjobtestCoding/${selectedCandidate}/${jobRoleId}/${idTopic}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
    } catch (error) {
      console.error(error);
    }
  };
  const updateScore = async (successfulTestCount) => {
    const userDetailsString = localStorage.getItem("userDetails");
    const userDetails = JSON.parse(userDetailsString);
    const selectedCandidate = userDetails ? userDetails.localId : null;
    const jobRoleId = localStorage.getItem("jobRoleId");

    try {
      await axios.post(
        `${process.env.REACT_APP_APP_DOMAIN}/api/candidateExt/updateCscore`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }},
        {
          userId: selectedCandidate,
          newCscore: successfulTestCount,
          jobId: jobRoleId,
        }
      );
    } catch (error) {
      console.error(error);
    }
  };
  const captureScreenshot = async (successfulTests) => {
    const elementToCapture = document.getElementById("capture-element");

    try {
      const canvas = await html2canvas(elementToCapture);

      const screenshot = canvas.toDataURL("image/png");

      const blob = dataURItoBlob(screenshot);

      const currentDate = new Date();
      const userDetailsString = localStorage.getItem("userDetails");
      const userDetails = JSON.parse(userDetailsString);

      const selectedCandidate = userDetails ? userDetails.localId : null;
      const jobRoleId = localStorage.getItem("jobRoleId");

      const filename = `${selectedCandidate}_${jobRoleId}_${idTopic}.png`;
      updateScoreInDatabase((10 * successfulTests) / 3, blob, filename);
      updateScore((10 * successfulTests) / 3);
    } catch (error) {
      console.error("Error capturing screenshot:", error);
    }
  };

  // Fonction pour convertir une Data URI en Blob
  function dataURItoBlob(dataURI) {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  }

  const [resTest, setResTest] = useState("");
  useEffect(() => {
    const selectedLanguage = languageOptions1.find(
      (option) => option.id === LanguageId
    );

    if (selectedLanguage) {
      setLanguage(selectedLanguage);

      const selectedLanguageIndex = languageOptions1.findIndex(
        (option) => option.id === LanguageId
      );
      setPosition(selectedLanguageIndex);
    } else {
      console.error(
        `Language with id ${LanguageId} not found in languageOptions array`
      );
    }
  }, [LanguageId]);
  const [selectedTestCase, setSelectedTestCase] = useState(1);
  const [expectedOutputValue, setExpectedOutputValue] = useState("");
  const enterPress = useKeyPress("Enter");
  const ctrlPress = useKeyPress("Control");
  const [timerExpired, setTimerExpired] = useState(false);
  const onSelectChange = (sl) => {
    setLanguage(sl);
  };

  useEffect(() => {
    if (enterPress && ctrlPress) {
      handleCompile();
    }
  }, [ctrlPress, enterPress]);

  const onChange = (action, data) => {
    switch (action) {
      case "code": {
        setCode(data);
        break;
      }
      default: {
        console.warn("case not handled!", action, data);
      }
    }
  };

  const runSingleTestCase = async (languageId, code, expectedOutput) => {
    const formData = {
      language: language.value.toLowerCase(),
      stdin: code,
      files: [
        {
          name: language.name,
          content: code,
          expectedOutput,
        },
      ],
    };
    const options = {
      method: "POST",
      url: process.env.REACT_APP_RAPID_API_URL_ONECOMPILER,
      headers: {
        "content-type": "application/json",
        "X-RapidAPI-Host": process.env.REACT_APP_RAPID_API_HOST_ONECOMPILER,
        "X-RapidAPI-Key": process.env.REACT_APP_RAPID_API_KEY_ONECOMPILER,
      },
      data: formData,
    };

    try {
      const response = await axios.request(options);
      setOutputDetails(response.data);
      const Out = response.data.stdout;
      const result =
        Out.trim() === expectedOutput.trim() ? "Accepted" : "Wrong Answer";
      setResTest(result);
      if (result === "Accepted") {
        showSuccessToast(`Compiled Successfully!`);
        setSuccessfulTestCount(successfulTestCount + 1);
      } else if(result === "Wrong Answer") {
        swal("Wrong Answer", {
          icon: "error",
        });
      }
      else {showErrorToast();}
      return response.data;
    } catch (err) {
      setProcessing(false);
      showErrorToast();
      return "Error";
    }
  };
  const handleCompile = async () => {
    const testCases = [1, 2, 3, 4];
    let successfulTests = 0;
    const results = [];
    const res = [];
    for (let i = 1; i < 4; i++) {
      const { input, expectedOutput } = testCases[i];
      const result = await handleCompileTestCase(i);

      res.push(result);

      if (result[0] === "Accepted") {
        successfulTests++;
        setSuccessfulTestCount(successfulTests);
      }
      results.push(result);
    }
    if (testCases.length === 4) {
      setResuTest(res);
      await captureScreenshot(successfulTests);
      //history.push('/TestTechComplited');
    }

    ExitTest();
  };

  const handleThemeChange = (th) => {
    const theme = th;

    if (["light", "vs-dark"].includes(theme.value)) {
      setTheme(theme);
    } else {
      defineTheme(theme.value).then((_) => setTheme(theme));
    }
  };

  useEffect(() => {
    defineTheme("oceanic-next").then((_) =>
      setTheme({ value: "oceanic-next", label: "Oceanic Next" })
    );
  }, []);

  const showSuccessToast = (msg) => {
    toast.success(msg || `Compiled Successfully!`, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showErrorToast = (msg, timer) => {
    toast.error(msg || `Something went wrong! Please try again.`, {
      position: "top-right",
      autoClose: timer ? timer : 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const { seconds, minutes, hours } = useTimer({
    expiryTimestamp: new Date().getTime() + 60 * 1000,
  });

  useEffect(() => {
    if (seconds === 0 && minutes === 0 && hours === 0) {
      setTimerExpired(true);
      handleCompile();
      //ExitTest();
    }
  }, [seconds, minutes, hours]);

  const runCompilationAfterTimeout = () => {
    setTimeout(() => {
      if (!processing) {
        handleCompile();
      }
    }, 60 * 1000);
  };

  /*   useEffect(() => {
    runCompilationAfterTimeout();
  }, []); */
  const handleCompileTestCase = async (testCase) => {
    let selectedInput = "";
    let selectedExpectedOutput = "";

    switch (testCase) {
      case 1:
        selectedInput = input1;
        selectedExpectedOutput = expectedOutput1;
        break;
      case 2:
        selectedInput = input2;
        selectedExpectedOutput = expectedOutput2;
        break;
      case 3:
        selectedInput = input3;
        selectedExpectedOutput = expectedOutput3;
        break;
      default:
        break;
    }

    setInputValue(selectedInput);
    setExpectedOutputValue(selectedExpectedOutput);

    try {
      setProcessing(true);
      const languageId = language.id;
      const codeToRun = code;
      const languageInfo = LanguageInfo(LanguageId);

      const results = [];

      const funcTEST =
        languageInfo.variable_declaration +
        `a = ${selectedInput};` +
        "\n" +
        codeToRun +
        "\n" +
        ExtentionCode;

      const result = await runSingleTestCase(
        languageId,
        funcTEST,
        selectedExpectedOutput
      );

      // Compare the result with expectedOutput
      const testResult =
        result.stdout.trim() === selectedExpectedOutput.trim()
          ? "Accepted"
          : "Wrong Answer";
      setResTest(testResult);
      results.push(testResult);
      setProcessing(false);
      return results;
    } catch (err) {
      setProcessing(false);
      showErrorToast();
      return "Errors";
    }
  };

  useEffect(() => {
    if(!allowCopyPaste){
      const handlePaste = (event) => {
      event.preventDefault();
    };
    const handleCopy = (event) => {
      event.preventDefault();
    };

    const handleCut = (event) => {
      event.preventDefault();
    };

    window.addEventListener("paste", handlePaste);
    window.addEventListener("copy", handleCopy);
    window.addEventListener("cut", handleCut);
    return () => {
      window.removeEventListener("paste", handlePaste);
      window.removeEventListener("copy", handleCopy);
      window.removeEventListener("cut", handleCut);
    };}
  }, [allowCopyPaste]);
  
  
  return (
    <div id="capture-element">
      <Card>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <div className="h-4 w-full bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500"></div>
        <Row style={{ marginTop: "2%", marginLeft: "3%" }}>
          <Col style={{ display: "none" }}>
            <Select
              placeholder={`Filter By Category`}
              options={languageOptions1}
              styles={customStyles}
              defaultValue={languageOptions1[position]}
              onChange={(selectedOption) => onSelectChange(selectedOption)}
            />
          </Col>
          <Col>
            <ThemeDropdown
              handleThemeChange={handleThemeChange}
              theme={theme}
            />
          </Col>
          <Col>
            <div className="timer" style={{ marginTop: "3%" }}>
              <span id="hours">{hours}</span>:
              <span id="minutes">{minutes}</span>:
              <span id="seconds">{seconds}</span>
            </div>
          </Col>
        </Row>

        <div style={{ marginLeft: "3%", width: "93%", marginTop: "2%" }}>
          <CodeEditorWindow
            code={code}
            onChange={onChange}
            language={language?.value}
            theme={theme.value}
            squelette={codeIni}
          />
        </div>

        <OutputWindow outputDetails={outputDetails} />
        {resuTest.length > 0 && (
          <div>
            <Table
              responsive
              style={{ marginTop: "2%", marginLeft: "5%", width: "90%" }}
            >
              <thead>
                <tr>
                  <th>Test number</th>
                  <th>Results</th>
                </tr>
              </thead>
              <tbody>
                {resuTest.map((result, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{result}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
        <Row>
          <Col>
            <div className="right-container flex flex-shrink-0 w-[40%] flex-col">
              <div className="flex flex-row justify-between">
                <h1
                  className="font-bold text-xl bg-clip-text text-transparent bg-gradient-to-r from-slate-900 to-slate-700 mb-2 mt-2"
                  style={{ fontFamily: "Poppins" }}
                >
                  Test Cases
                </h1>
              </div>
              <div
                className="flex gap-x-2  mb-2"
                style={{
                  marginLeft: "23%",
                  width: "93%",
                  fontSize: "110%",
                  fontFamily: "Poppins",
                }}
              >
                <Button
                  style={{ marginRight: "10%" }}
                  variant="warning badge-xl light"
                  value="1"
                  onClick={() => {
                    setTestCase1Disabled(true);
                    handleCompileTestCase(1);
                  }}
                  disabled={testCase1Disabled || runClicked}
                >
                  Case 1
                </Button>
                <Button
                  style={{ marginRight: "10%" }}
                  variant="warning badge-xl light"
                  value="2"
                  onClick={() => {
                    setTestCase2Disabled(true);
                    handleCompileTestCase(2);
                  }}
                  disabled={testCase2Disabled || runClicked}
                >
                  Case 2
                </Button>
                <Button
                  variant="warning badge-xl light"
                  value="3"
                  onClick={() => {
                    setTestCase3Disabled(true);
                    handleCompileTestCase(3);
                  }}
                  disabled={testCase3Disabled || runClicked}
                >
                  Case 3
                </Button>
              </div>
              <InputGroup
                className="mb-2"
                style={{
                  marginLeft: "3%",
                  width: "93%",
                  fontFamily: "Poppins",
                }}
              >
                <InputGroup.Text
                  id="input-field-text"
                  className="w-40"
                  style={{ backgroundColor: "#e9f9fd" }}
                >
                  &nbsp;&nbsp;Input Value
                </InputGroup.Text>
                <Form.Control
                  id="input-field"
                  aria-describedby="basic-addon3"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                />
              </InputGroup>

              <InputGroup
                className="mb-2"
                style={{
                  marginLeft: "3%",
                  width: "93%",
                  fontFamily: "Poppins",
                }}
              >
                <InputGroup.Text
                  id="expected-output-field-text"
                  className="w-40"
                  style={{ backgroundColor: "#e9f9fd" }}
                >
                  &nbsp;&nbsp;Expected Value
                </InputGroup.Text>
                <Form.Control
                  id="expected-output-field"
                  aria-describedby="basic-addon3"
                  value={expectedOutputValue}
                  onChange={(e) => setExpectedOutputValue(e.target.value)}
                />
              </InputGroup>
            </div>
          </Col>
        </Row>
        <Row style={{ fontFamily: "Poppins" }}>
          <Button
            className="me-2"
            variant="primary light"
            style={{
              marginTop: "5%",
              marginLeft: "20%",
              width: "60%",
              marginBottom: "2%",
            }}
            onClick={() => {
              setTestCasesDisabled(true);
              handleCompile();
            }}
            disabled={testCasesDisabled || runClicked || !code}
          >
            <FaRunning size={16} /> {processing ? "Processing..." : "Run"}
          </Button>
          {/** <div style={{ marginTop: "1%", fontFamily: "Poppins" }}>
            Number of correct case output: {successfulTestCount}
          </div>*/}
        </Row>
        {/**  <Row>
          {outputDetails && <OutputDetails outputDetails={outputDetails} />}
        </Row>*/}
      </Card>
    </div>
  );
};

export default Landing;
