import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';
import { loadingToggleAction, signupAction } from '../../store/actions/AuthActions';
// image
import logo from "../../images/logo-full.png";
import pic2 from '../../images/pic2.png';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import './registration.css';
import translations from "../components/AppsMenu/Candidate/mulitlingue";

function Register(props) {
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({ firstname: '',lastname: '', email: '', password: '' });
  const [showPassword, setShowPassword] = useState(false);
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  const dispatch = useDispatch();

  function onSignUp(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errors };

    if (firstname === '') {
      errorObj.firstname = 'First name is required';
      error = true;
    }

    if (lastname === '') {
      errorObj.lastname = 'Last name is required';
      error = true;
    }

    if (email === '') {
      errorObj.email = 'Email is required';
      error = true;
    } else if (!validateEmail(email)) {
      errorObj.email = 'Invalid email format';
      error = true;
    }

    if (password === '') {
      errorObj.password = 'Password is required';
      error = true;
    } else if (!validatePassword(password)) {
      errorObj.password = 'Password does not meet the complexity requirements';
      error = true;
    } else {
      errorObj.password = '';
    }
  setErrors(errorObj);
  if (error) return;
  dispatch(loadingToggleAction(true));
  dispatch(signupAction(firstname,lastname,email, password, props.history));
 
}
const handleLinkedInSignup = () => {
  // Handle LinkedIn import logic here
  console.log('Importing from LinkedIn...');
  localStorage.setItem('linkedinUse', 'signup');
  const client_id =process.env.REACT_APP_LINKEDIN_CLIENT_ID || "78rgom6c3nciik"
  const redirect =process.env.REACT_APP_LINKEDIN_REDIRECT_LINK || "http%3A%2F%2Flocalhost%3A3000%2Ffetchlinkedin"
  console.log("client id: " + client_id)
  console.log("redirect: " + redirect)
  window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&state=997755331&scope=openid,profile,email&client_id=78rgom6c3nciik&redirect_uri=${redirect}`
};
const togglePasswordVisibility = () => {
  setShowPassword((prevState) => !prevState); // Use the showPassword state variable
};



  const validateEmail = (email) => {
    // Regular expression to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    // Regular expressions for password complexity requirements
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const digitRegex = /[0-9]/;
    const specialCharRegex = /[^a-zA-Z0-9]/;

    return (
      password.length >= 8 &&
      uppercaseRegex.test(password) &&
      lowercaseRegex.test(password) &&
      digitRegex.test(password) &&
      specialCharRegex.test(password)
    );
  };

  const getPasswordStrengthColor = () => {
    let complexity = 0;

    if (password.length >= 8) {
      complexity++;
    }

    if (/[A-Z]/.test(password)) {
      complexity++;
    }

    if (/[a-z]/.test(password)) {
      complexity++;
    }

    if (/\d/.test(password)) {
      complexity++;
    }

    if (/[^a-zA-Z0-9]/.test(password)) {
      complexity++;
    }

    if (complexity === 5) {
      return 'bg-success';
    } else if (complexity >= 3) {
      return 'bg-warning';
    } else {
      return 'bg-danger';
    }
  };

  const getPasswordHintIcon = (condition) => {
    return condition ? <span className="hint-icon">✓</span> : <span className="hint-icon">X</span>;
  
  };

  return (
    // <div className="authincation h-100 p-meddle">
    //   <div className="container h-100">
       
    //   </div>
    // </div>
     <div className="authincation d-flex flex-column flex-lg-row flex-column-fluid">
     <div className="login-aside text-center d-flex flex-column flex-row-auto ">
       <div className="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
        
         <h1 className="mb-1">{lang.menu.signAndJoin}</h1>
         <p>User Experience & Interface Design <br /> Strategy SaaS Solutions</p>
       </div>
       <div className="aside-image" style={{ backgroundImage: `url(${pic2})` }}></div>
     </div>
     <div className="container flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto ">
     <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content style-2">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-5">
                      <Link to="/login">
                        <img src={logo} alt="hirecuelogo" className='logologin'/>
                      </Link>
                    </div>
                    {/* <h3 className="text-center mb-4">Sign up for an account</h3> */}
                    {props.errorMessage && (
                      <div className='text-danger'>
                        {props.errorMessage}
                      </div>
                    )}
                    {props.successMessage && (
                      <div className='text-success'>
                        {props.successMessage}
                      </div>
                    )}
                    <form onSubmit={onSignUp}>
                      <div className="row">
                      <div className="col-6">
                      <div className="form-group mb-3">
                        <label className="mb-1">
                          <strong> {lang.menu.firstName}
                          </strong>
                        </label>
                        <input
                          value={firstname}
                          onChange={(e) => setFirstname(e.target.value)}
                          className="form-control"
                          maxLength={16}
                          placeholder={lang.menu.firstName}
                        />
                        {errors.firstname && <div className='text-danger'>{errors.firstname}</div>}
                      </div>
                      </div>
                      <div className="col-6">
                      <div className="form-group mb-3">
                        <label className="mb-1">
                          <strong>{lang.menu.LastName}</strong>
                        </label>
                        <input
                          value={lastname}
                          onChange={(e) => setLastname(e.target.value)}
                          className="form-control"
                          maxLength={16}
                          placeholder={lang.menu.LastName}
                        />
                        {errors.lastname && <div className='text-danger'>{errors.lastname}</div>}
                      </div>
                      </div>
                      </div>
                      <div className="form-group mb-3">
                        <label className="mb-1">
                          <strong>{lang.menu.email}</strong>
                        </label>
                        <input
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="form-control"
                          maxLength={60}
                          placeholder={lang.menu.typeEmail}
                        />
                        {errors.email && <div className='text-danger'>{errors.email}</div>}
                      </div>
                      <div className="form-group mb-3">
                        <label className="mb-1">
                          <strong>{lang.menu.password}</strong>
                        </label>
                        <div className="password-input-container">
                          <input
                            value={password}
                            maxLength={60}
                            onChange={(e) => {
                              setPassword(e.target.value);
                              validatePassword(e.target.value);
                            }}
                            className="form-control"
                            type={showPassword ? "text" :lang.menu.password}
                            placeholder={lang.menu.password}
                          />
                          <span className="password-toggle-icon" onClick={togglePasswordVisibility}>
                            {showPassword ? <FiEyeOff /> : <FiEye />}
                          </span>
                        </div>
                        {errors.password && <div className='text-danger'>{errors.password}</div>}
                      </div>
                      <div className="progress mb-3">
                      <div
                        className={`progress-bar ${getPasswordStrengthColor()}`}
                        role="progressbar"
                        style={{ width: `${(password.length / 16) * 100}%` }}
                        aria-valuenow={password.length}
                        aria-valuemin="0"
                        aria-valuemax="10"
                      ></div>
                       </div>
                      <div className={`password-hint ${Object.values(errors).some(error => error !== '') ? 'hint-error' : ''}`}>
                        <ul>
                          <li className={!password ? 'hint-not-typed' : (password.length >= 8 ? 'hint-typed' : '')}>
                            {getPasswordHintIcon(password.length >= 8)} {lang.menu.Caracteres8}
                          </li>
                          <li className={!password ? 'hint-not-typed' : (/[A-Z]/.test(password) ? 'hint-typed' : '')}>
                            {getPasswordHintIcon(/[A-Z]/.test(password))} {lang.menu.upperCases}
                          </li>
                          <li className={!password ? 'hint-not-typed' : (/[a-z]/.test(password) ? 'hint-typed' : '')}>
                            {getPasswordHintIcon(/[a-z]/.test(password))} {lang.menu.lowerCases}
                          </li>
                          <li className={!password ? 'hint-not-typed' : (/\d/.test(password) ? 'hint-typed' : '')}>
                            {getPasswordHintIcon(/\d/.test(password))} {lang.menu.digital}
                          </li>
                          <li className={!password ? 'hint-not-typed' : (/[^a-zA-Z0-9]/.test(password) ? 'hint-typed' : '')}>
                            {getPasswordHintIcon(/[^a-zA-Z0-9]/.test(password))} {lang.menu.specialCaracteres}
                          </li>
                        </ul>
                      </div>
                      <div className="text-center mt-4">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                        >
                          {lang.menu.Signmeup}
                        </button>
                      </div>
                  
                    </form>
                    <div className="new-account mt-3">
                      <p className="">
                      {lang.menu.haveAccount}{' '}
                        <Link className="text-login" to="/login">
                        {lang.menu.SignIn}
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
     </div>
   </div>
    
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(Register);

