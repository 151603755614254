export const isAuthenticated = (state) => {
    if (state.auth.auth.idToken) return true;
    return false;
};

export const hasRole = (state) => {
    if (state.auth.auth.userRole) return true;
    return false;
};

export const hasValidation = (state) => {
    if (state.auth.auth.adminVerified===1) return true;
    return false;
};

export const getUser = (state) => {
    if (state.auth.auth.localId) return state.auth.auth.localId;
    return '';
};