import React, { useState, useEffect } from 'react';
import { countries } from 'countries-list';

const countryNames = Object.values(countries).map(country => country.name);

function JobPreferences({  lang, toggleCategory, expandedCategories }) {
  const [jobPreferences, setJobPreferences] = useState({
    jobType: [],
    jobLocation: '',
    lookingForJob: '',
    immediatelyAvailable: ''
  });

  useEffect(() => {
    const storedJobPreferences = localStorage.getItem('JobPreferences');
    if (storedJobPreferences!=='undefined' && storedJobPreferences !== null) {
      setJobPreferences(JSON.parse(storedJobPreferences));
    }
  }, []);

  useEffect(() => {
    if (expandedCategories.jobPreferences) {
      localStorage.setItem('JobPreferences', JSON.stringify(jobPreferences));
    }
  }, [expandedCategories.jobPreferences, jobPreferences]);

  const preferredPosts = ['online', 'on site', 'hybrid'];
  const preferredPostsName = {'online':`${lang.menu.online}`, 'on site':`${lang.menu.onsite}`, 'hybrid':`${lang.menu.hybrid}`};

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    if (type === 'checkbox') {
      if (checked) {
        setJobPreferences({ ...jobPreferences, [name]: [...jobPreferences[name], value] });
      } else {
        setJobPreferences({ ...jobPreferences, [name]: jobPreferences[name].filter(item => item !== value) });
      }
    } else {
      setJobPreferences({ ...jobPreferences, [name]: value });
    }
  };

  return (
    <div className="category">
      <h2 className='single-line' onClick={() => toggleCategory('jobPreferences')}>{lang.menu.jp}</h2>
      {expandedCategories.jobPreferences && (
        <div className="category-content">
          <div className="checkbox-wrapper" style={{display: "flex", flexDirection: "row"}}>
            <h3 style={{marginRight:"20px" , marginTop:"12px"}}>{lang.menu.wiypaf}</h3>
            {preferredPosts.map((post, index) => (
              <div key={index} style={{marginTop:"19px"}} >
                <input
                
                  type="checkbox"
                  id={`checkbox-${index}`}
                  name="jobType"
                  value={post}
                  checked={jobPreferences.jobType.includes(post)}
                  onChange={handleChange}
                />
                <label className="terms-label" htmlFor={`checkbox-${index}`}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 200 200" className="checkbox-svg">
                    <mask fill="white" id={`path-1-inside-1_476_5-${index}`}>
                      <rect height="200" width="200"></rect>
                    </mask>
                    <rect mask={`url(#path-1-inside-1_476_5-${index})`} strokeWidth="40" className="checkbox-box" height="200" width="200"></rect>
                    <path strokeWidth="15" d="M52 111.018L76.9867 136L149 64" className="checkbox-tick"></path>
                  </svg>
                  <span className="label-text" style={{width:"80px"}}><b>{preferredPostsName[post]}</b></span>
                </label>
              </div>
            ))}
          </div>
          <select
            className='inputOnboard'
            name="jobLocation"
            value={jobPreferences.jobLocation}
            onChange={handleChange}
          >
            <option value="">{lang.menu.pjl}</option>
            {countryNames.map((country, index) => (
              <option key={index} value={country}
              disabled={country === lang.menu.pjl}
              >{country}</option>
            ))}
          </select>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <h3 style={{ width: "400px" }}>{lang.menu.ayalfaj}</h3>
            <div className="radio-button-container">
              <div className="radio-button">
                <input
                  type="radio"
                  className="radio-button__input"
                  id="looking-yes"
                  name="lookingForJob"
                  value="yes"
                  checked={jobPreferences.lookingForJob === 'yes'}
                  onChange={handleChange}
                />
                <label className="radio-button__label" htmlFor="looking-yes">
                  <span className="radio-button__custom"></span>
                  {lang.menu.Yes}
                </label>
              </div>
              <div className="radio-button">
                <input
                  type="radio"
                  className="radio-button__input"
                  id="looking-no"
                  name="lookingForJob"
                  value="no"
                  checked={jobPreferences.lookingForJob === 'no'}
                  onChange={handleChange}
                />
                <label className="radio-button__label" htmlFor="looking-no">
                  <span className="radio-button__custom"></span>
                  {lang.menu.No}
                </label>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <h3 style={{ width: "400px" }}>{lang.menu.ayia}</h3>
            <div className="radio-button-container">
              <div className="radio-button">
                <input
                  type="radio"
                  className="radio-button__input"
                  id="available-yes"
                  name="immediatelyAvailable"
                  value="yes"
                  checked={jobPreferences.immediatelyAvailable === 'yes'}
                  onChange={handleChange}
                />
                <label className="radio-button__label" htmlFor="available-yes">
                  <span className="radio-button__custom"></span>
                  {lang.menu.Yes}
                </label>
              </div>
              <div className="radio-button">
                <input
                  type="radio"
                  className="radio-button__input"
                  id="available-no"
                  name="immediatelyAvailable"
                  value="no"
                  checked={jobPreferences.immediatelyAvailable === 'no'}
                  onChange={handleChange}
                />
                <label className="radio-button__label" htmlFor="available-no">
                  <span className="radio-button__custom"></span>
                  {lang.menu.No}
                </label>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default JobPreferences;
