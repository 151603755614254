import React, { useState, useEffect } from 'react';

function ContractPreferences({ lang,  toggleCategory, expandedCategories }) {
  const [contractPreferences, setContractPreferences] = useState({
    contractTypes: [],
    salaryExpectation: ''
  });

  useEffect(() => {
    const storedContractPreferences = localStorage.getItem('ContractPreferences');
    if (storedContractPreferences!=='undefined' && storedContractPreferences !== null) {
      setContractPreferences(JSON.parse(storedContractPreferences));
    }
  }, []);

  useEffect(() => {
    if (expandedCategories.contractPreferences) {
      localStorage.setItem('ContractPreferences', JSON.stringify(contractPreferences));
    }
  }, [expandedCategories.contractPreferences, contractPreferences]);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setContractPreferences(prevState => ({
        ...prevState,
        contractTypes: [...prevState.contractTypes, value]
      }));
    } else {
      setContractPreferences(prevState => ({
        ...prevState,
        contractTypes: prevState.contractTypes.filter(type => type !== value)
      }));
    }
  };

  const handleSalaryChange = (event) => {
    const { value } = event.target;
    setContractPreferences(prevState => ({
      ...prevState,
      salaryExpectation: value
    }));
  };

  return (
    <div className="category">
      <h2 className='single-line' onClick={() => toggleCategory('contractPreferences')}>{lang.menu.ConP}</h2>
      {expandedCategories.contractPreferences && (
        <div className="category-content">
          <div className="checkbox-wrapper" style={{display: "flex", flexDirection: "row"}}>
            <h3 style={{marginRight:"20px", marginTop:"12px"}}>{lang.menu.wctyp}</h3>
            {['CDI', 'CDD', 'Alternation', 'Intership', 'Freelance'].map((contractType, index) => (
              <div key={index} style={{ marginTop: "19px" }}>
                <input className='inputOnboard'
                  type="checkbox"
                  id={`checkbox-${index}`}
                  name="contractType"
                  value={contractType}
                  checked={contractPreferences.contractTypes.includes(contractType)}
                  onChange={handleCheckboxChange}
                />
                <label className="terms-label" htmlFor={`checkbox-${index}`}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 200 200" className="checkbox-svg">
                    <mask fill="white" id={`path-1-inside-1_476_5-${index}`}>
                      <rect height="200" width="200"></rect>
                    </mask>
                    <rect mask={`url(#path-1-inside-1_476_5-${index})`} strokeWidth="40" className="checkbox-box" height="200" width="200"></rect>
                    <path strokeWidth="15" d="M52 111.018L76.9867 136L149 64" className="checkbox-tick"></path>
                  </svg>
                  <span className="label-text" style={{width:"80px"}}><b>{contractType}</b></span>
                </label>
              </div>
            ))}
          </div>
          <div style={{display: "flex", flexDirection: "row",left:0}}>
            <h3 style={{marginTop:"5px"}}>{lang.menu.wayse}</h3>
            <input className='inputOnboard'
              type="number"

              style={{width:"20%",marginLeft:"20px"}}
              name="salaryExpectation"
              value={contractPreferences.salaryExpectation}
              onChange={handleSalaryChange}
              placeholder={lang.menu.eyse}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ContractPreferences;
