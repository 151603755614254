import React, { useEffect, useState } from "react";
import CodeEditorWindow from "./CodeEditorWindow";
import axios from "axios";
import { languageOptions } from "../constants/languageOptions";
import Select from "react-select";
import { customStyles } from "../constants/customStyles";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Button,
  Row,
  Card,
  Col,
  InputGroup,
  Form,
  Table,
} from "react-bootstrap";
import { defineTheme } from "../lib/defineTheme";
import useKeyPress from "../hooks/useKeyPress";
import { useTimer } from "react-timer-hook";
import OutputWindow from "./OutputWindow";
import OutputDetails from "./OutputDetails";
import ThemeDropdown from "./ThemeDropdown";
import { FaRunning } from "react-icons/fa";
import { LanguageInfo } from "../constants/extensionCode";
import html2canvas from "html2canvas";
import translations from "../../AppsMenu/Candidate/mulitlingue";

const LandingRecruiter = ({
  idTopic,
  input1,
  expectedOutput1,
  input2,
  expectedOutput2,
  input3,
  expectedOutput3,
  LanguageId,
  Squelette,
  ExtentionCode,
  ExitTest,
  allowCopyPaste
}) => {
  const [inputValue, setInputValue] = useState(input1);
  const [codeIni, setCodeIni] = useState(Squelette);
  const [code, setCode] = useState(codeIni);
  const localTime = localStorage.getItem("questionsNbrCoding");
  const [resuTest, setResuTest] = useState([]);
  const [finTest, setFinTest] = useState(0);
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  useEffect(() => {
    const selectedLang = JSON.parse(localStorage.getItem("selectedLang"));
    if (selectedLang) {
      setLang(selectedLang);
    }
  }, []);
 
  useEffect(() => {
    setCodeIni(Squelette);
  }, [Squelette]);
 
  const [outputDetails, setOutputDetails] = useState(null);
  const [processing, setProcessing] = useState(null); //contrôler l'état de la compilation en cours
  const [theme, setTheme] = useState("cobalt");
  const [language, setLanguage] = useState(
    languageOptions.find((option) => option.id === LanguageId)
  );
  const [position, setPosition] = useState();
  const [successfulTestCount, setSuccessfulTestCount] = useState(0);
  const [testCase1Disabled, setTestCase1Disabled] = useState(false);
  const [testCase2Disabled, setTestCase2Disabled] = useState(false);
  const [testCase3Disabled, setTestCase3Disabled] = useState(false);
  const [testCasesDisabled, setTestCasesDisabled] = useState(false);
  const [runClicked, setRunClicked] = useState(false);
  const [timeInSeconds, setTimeInSeconds] = useState(localTime);
  const [resTest, setResTest] = useState("");

  
  useEffect(() => {
    const selectedLanguage = languageOptions.find(
      (option) => option.id === LanguageId
    );

    if (selectedLanguage) {
      setLanguage(selectedLanguage);

      const selectedLanguageIndex = languageOptions.findIndex(
        (option) => option.id === LanguageId
      );
      setPosition(selectedLanguageIndex);
      console.log("selectedLanguageIndex :",selectedLanguageIndex)

    } else {
      console.error(
        `Language with id ${LanguageId} not found in languageOptions array`
      );
    }
  }, [LanguageId]);

  const [selectedTestCase, setSelectedTestCase] = useState(1);
  const [expectedOutputValue, setExpectedOutputValue] = useState("");
  const enterPress = useKeyPress("Enter");
  const ctrlPress = useKeyPress("Control");
  const [timerExpired, setTimerExpired] = useState(false);
  const onSelectChange = (sl) => {
    console.log("selected Option...", sl);
    setLanguage(sl);
  };

  useEffect(() => {
    if (enterPress && ctrlPress) {
      console.log("enterPress", enterPress);
      console.log("ctrlPress", ctrlPress);
    }
  }, [ctrlPress, enterPress]);

  const onChange = (action, data) => {
    switch (action) {
      case "code": {
        setCode(data);
        break;
      }
      default: {
        console.warn("case not handled!", action, data);
      }
    }
  };


  const runSingleTestCase = async (languageId, code, expectedOutput) => {
    try {
      const formData = {
        language_id: languageId,
        source_code: btoa(code),
      };
      const options = {
        method: "POST",
        url: process.env.REACT_APP_RAPID_API_URL,
        params: { base64_encoded: "true", fields: "*" },
        headers: {
          "content-type": "application/json",
          "Content-Type": "application/json",
          "X-RapidAPI-Host": process.env.REACT_APP_RAPID_API_HOST,
          "X-RapidAPI-Key": process.env.REACT_APP_RAPID_API_KEY,
        },
        data: formData,
      };
 
      const response = await axios.request(options);
      const token = response.data.token;
      console.log("exp: ", expectedOutput);
      const outputDetails = await checkStatus(token, expectedOutput);
      console.log("result before1 : ", outputDetails);
      return outputDetails;
    } catch (err) {
      console.log("Error in running test case:", err);
      throw err;
    }
  };
  const handleCompile = async () => {
    const testCases = [1, 2, 3];
    let successfulTests = 0;
    const results = [];
    const res = [];
 
    for (let i = 0; i < testCases.length; i++) {
      const { input, expectedOutput } = testCases[i];
      const result = await handleCompileTestCase(testCases[i]);
      res.push(result);
      if (result === "Accepted") {
        successfulTests++;
        setSuccessfulTestCount(successfulTests);
        console.log("successfulTests (inside loop):", successfulTests);
      }
      results.push(result);
    }
    console.log("testCases.length : ", testCases.length);
    if (testCases.length === 3) {
      setResuTest(res);
    }

  };
  const handleCompileFin = async () => {
    handleCompile().then(() => {
      setTimeout(() => {
        ExitTest();
      }, 6000);
    });
  }
  const checkStatus = async (token, expectedOutput) => {
    const options = {
      method: "GET",
      url: process.env.REACT_APP_RAPID_API_URL + "/" + token,
      params: { base64_encoded: "true", fields: "*" },
      headers: {
        "X-RapidAPI-Host": process.env.REACT_APP_RAPID_API_HOST,
        "X-RapidAPI-Key": process.env.REACT_APP_RAPID_API_KEY,
      },
    };
 
    try {
      let response = await axios.request(options);
      let statusId = response.data.status?.id;
 
      // Processed - we have a result
      if (statusId === 1 || statusId === 2) {
        // still processing, wait for a while and check again
        await new Promise((resolve) => setTimeout(resolve, 2000));
        return checkStatus(token, expectedOutput);
      } else {
        setProcessing(false);
        setOutputDetails(response.data);
        console.log("response.data.stdout : ", atob(response.data.stdout));
        console.log("expectedOutput : ", expectedOutput);
        const Out = atob(response.data.stdout);
        const result =
          Out.trim() === expectedOutput.trim() ? "Accepted" : "Wrong Answer";
        setResTest(result);
        console.log("result", result);
        if (result === "Accepted") showSuccessToast(`Compiled Successfully!`);
        else if(result === "Wrong Answer") toast.error("Wrong Answer");
        else showErrorToast();
        console.log("response.data", response.data);
 
        return result;
      }
    } catch (err) {
      console.log("err", err);
      setProcessing(false);
      showErrorToast();
      return "Error";
    }
  };


 
  const handleThemeChange = (th) => {
    const theme = th;
    console.log("theme...", theme);

    if (["light", "vs-dark"].includes(theme.value)) {
      setTheme(theme);
    } else {
      defineTheme(theme.value).then((_) => setTheme(theme));
    }
  };

  useEffect(() => {
    defineTheme("oceanic-next").then((_) =>
      setTheme({ value: "oceanic-next", label: "Oceanic Next" })
    );
  }, []);

  const showSuccessToast = (msg) => {
    toast.success(msg || `Compiled Successfully!`, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
 
  const showErrorToast = (msg, timer) => {
    toast.error(msg || `Something went wrong! Please try again.`, {
      position: "top-right",
      autoClose: timer ? timer : 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const { seconds, minutes, hours } = useTimer({
    expiryTimestamp: new Date().getTime() + timeInSeconds * 1000,
  });
  useEffect(() => {
    console.log("finTest :",finTest)
    if (seconds === 0 && minutes === 0 && hours === 0 && finTest!==1) {
      setFinTest(1);

      setTimerExpired(true);
      setTestCasesDisabled(true);
      handleCompile().then(() => {
        setTimeout(() => {
         ExitTest();
        }, 6000);
      });
    }
  }, [seconds, minutes, hours,finTest]);
  
  

  const runCompilationAfterTimeout = () => {
    setTimeout(() => {
      if (!processing) {
        handleCompile();
      }
    }, 60 * 1000);
  };
 
  /*   useEffect(() => {
    runCompilationAfterTimeout();
  }, []); */
  const runSingleTestCaseBackup = async (languageId, code, expectedOutput) => {
    try {
      // Appel de l'API principale : Judge0
      const response = await runSingleTestCase(
        languageId,
        code,
        expectedOutput
      );
      return response;
    } catch (error) {
      console.error(
        "Erreur lors de l'appel de l'API principale, basculement vers l'API de secours :",
        error
      );
 
      // Appel de l'API de secours : OneCompiler
      const languageName = getLanguageNameById(languageId);
      const formData = {
        language: languageName,
        source_code: btoa(code),
      };
 
      const options = {
        method: "POST",
        url: process.env.REACT_APP_RAPID_API_URL_ONECOMPILER,
        params: { base64_encoded: "true", fields: "*" },
        headers: {
          "content-type": "application/json",
          "Content-Type": "application/json",
          "X-RapidAPI-Host": process.env.REACT_APP_RAPID_API_HOST_ONECOMPILER,
          "X-RapidAPI-Key": process.env.REACT_APP_RAPID_API_KEY_ONECOMPILER,
        },
        data: formData,
      };
 
      const response = await axios.request(options);
      return response.data;
    }
  };
  function extraireNomVariable(code) {
    console.log(code)

    const regex = /\((\w+)\)/;
    const matches = code.match(regex);
    if (matches && matches.length === 2) {
      const variableWithParentheses0 = matches[0];
      console.log("0:",variableWithParentheses0)
        const variableWithParentheses = matches[1];
        const variableName = variableWithParentheses.replace(/\(|\)/g, '');
        return variableName.trim();
    } else {
        return null; 
    }
}
function extraireTypeVariable(code) {

  const regex = /public\s+static\s+(\w+)\s+(\w+)\s*\(([\w\s,]*)\)\s*{/;
  console.log("code:",code)

  const matches = code.match(regex);

  if (matches && matches.length === 4) {
      console.log("matches:",matches)

      const variableType = matches[1];
      const variableName = matches[2];
      console.log("variableType:",variableType)
      console.log("variableName:",variableName)


      return { type: variableType.trim(), name: variableName.trim() }; 
  } else {
      return null; 
  }
}
  const handleCompileTestCase = async (testCase) => {
    let selectedInput = "";
    let selectedExpectedOutput = "";
 
    switch (testCase) {
      case 1:
        selectedInput = input1;
        selectedExpectedOutput = expectedOutput1;
        break;
      case 2:
        selectedInput = input2;
        selectedExpectedOutput = expectedOutput2;
        break;
      case 3:
        selectedInput = input3;
        selectedExpectedOutput = expectedOutput3;
        break;
      default:
        break;
    }
 
    setInputValue(selectedInput);
    setExpectedOutputValue(selectedExpectedOutput);
 
    try {
      setProcessing(true);
      const languageId = language.id;
      const codeToRun = code;
      const languageInfo = LanguageInfo(LanguageId);
 
      const results = [];
      

      let te=extraireNomVariable(ExtentionCode)

      console.log("te :",te)

    let funcTEST = ""; 

if (LanguageId === 62) {
  let pe = extraireTypeVariable(code);
  funcTEST =
    "public class Main {" + "\n " +
    languageInfo.variable_declaration + pe.type + ` ${te}=${selectedInput};` +
    "public static void main(String[] args) { \n" +
    ExtentionCode + "}\n" +
    "\n" +
    codeToRun +
    "\n}";
} else {
  funcTEST =languageInfo.variable_declaration +`${te}=${selectedInput};` +
    "\n" +
    codeToRun +
    "\n" +
    ExtentionCode;
}

      const result = await runSingleTestCaseBackup(
        languageId,
        funcTEST,
        selectedExpectedOutput
      );
      results.push(result);
      console.log("funcTEST : ", funcTEST);
      console.log("results : ", results);
      //setOutputDetails(results);
      setProcessing(false);
      console.log("result before : ", result);
      return result;
    } catch (err) {
      console.log("Error in handleCompile:", err);
      setProcessing(false);
      showErrorToast();
      return "Errors";
    }
  };

  
  /*   useEffect(() => {
    runCompilationAfterTimeout();
  }, []); */

  const getLanguageNameById = (languageId) => {
    const language = languageOptions.find((lang) => lang.id === languageId);
    return language ? language.name : "Langage inconnu";
  };


  


  return (
    <Card>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div id="capture-element">
        <Row style={{ marginTop: "2%", marginLeft: "3%" }}>
          <Col style={{ display: "none" }}>
            <Select
              placeholder={`Filter By Category`}
              options={languageOptions}
              styles={customStyles}
              defaultValue={languageOptions[position]}
              onChange={(selectedOption) => onSelectChange(selectedOption)}
            />
          </Col>
          <Col>
            <ThemeDropdown
              handleThemeChange={handleThemeChange}
              theme={theme}
            />
          </Col>
          <Col>
            <div className="timer" style={{ marginTop: "3%" }}>
              <span id="hours">{hours}</span>:
              <span id="minutes">{minutes}</span>:
              <span id="seconds">{seconds}</span>
            </div>
          </Col>
        </Row>

        <div style={{ marginLeft: "3%", width: "93%", marginTop: "2%" }}>
          <CodeEditorWindow
            code={code}
            onChange={onChange}
            language={language?.value}
            theme={theme.value}
            squelette={codeIni}
            allowCopyPaste={allowCopyPaste}
          />
        </div>

        <OutputWindow outputDetails={outputDetails} />

        {resuTest.length > 0 && (
          <div>
            <Table
              responsive
              style={{ marginTop: "2%", marginLeft: "5%", width: "90%" }}
            >
              <thead>
                <tr>
                  <th>{lang.menu.testNumber}</th>
                  <th>{lang.menu.codingRes}</th>
                </tr>
              </thead>
              <tbody>
                {resuTest.map((result, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{result}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      
        <Row>
          <Col>
            <div className="right-container flex flex-shrink-0 w-[40%] flex-col">
              <div className="flex flex-row justify-between">
                <h1
                  className="font-bold text-xl bg-clip-text text-transparent bg-gradient-to-r from-slate-900 to-slate-700 mb-2 mt-2"
                  style={{ fontFamily: "Poppins" }}
                >
                 {lang.menu.testCase}
                </h1>
              </div>
              <div
                className="flex gap-x-2  mb-2"
                style={{
                  marginLeft: "23%",
                  width: "93%",
                  fontSize: "110%",
                  fontFamily: "Poppins",
                }}
              >
                <Button
                  style={{ marginRight: "10%" }}
                  variant="warning badge-xl light"
                  value="1"
                  onClick={() => {
                    setTestCase1Disabled(true);
                    handleCompileTestCase(1);
                  }}
                  disabled={testCase1Disabled || runClicked}
                >
                  {lang.menu.Case} 1
                </Button>
                <Button
                  style={{ marginRight: "10%" }}
                  variant="warning badge-xl light"
                  value="2"
                  onClick={() => {
                    setTestCase2Disabled(true);
                    handleCompileTestCase(2);
                  }}
                  disabled={testCase2Disabled || runClicked}
                >
                  {lang.menu.Case} 2
                </Button>
                <Button
                  variant="warning badge-xl light"
                  value="3"
                  onClick={() => {
                    setTestCase3Disabled(true);
                    handleCompileTestCase(3);
                  }}
                  disabled={testCase3Disabled || runClicked}
                >
                  {lang.menu.Case} 3
                </Button>
              </div>
              <InputGroup
                className="mb-2"
                style={{
                  marginLeft: "3%",
                  width: "93%",
                  fontFamily: "Poppins",
                }}
              >
                <InputGroup.Text
                  id="input-field-text"
                  className="w-40"
                  style={{ backgroundColor: "#e9f9fd" }}
                >
                  &nbsp;&nbsp;{lang.menu.inputValue}
                </InputGroup.Text>
                <Form.Control
                  id="input-field"
                  aria-describedby="basic-addon3"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                />
              </InputGroup>

              <InputGroup
                className="mb-2"
                style={{
                  marginLeft: "3%",
                  width: "93%",
                  fontFamily: "Poppins",
                }}
              >
                <InputGroup.Text
                  id="expected-output-field-text"
                  className="w-40"
                  style={{ backgroundColor: "#e9f9fd" }}
                >
                  &nbsp;&nbsp;{lang.menu.ExpectedValue}
                </InputGroup.Text>
                <Form.Control
                  id="expected-output-field"
                  aria-describedby="basic-addon3"
                  value={expectedOutputValue}
                  onChange={(e) => setExpectedOutputValue(e.target.value)}
                />
              </InputGroup>
            </div>
          </Col>
        </Row>
        <Row style={{ fontFamily: "Poppins" }}>
          <Button
            className="me-2"
            variant="primary light"
            style={{
              marginTop: "5%",
              marginLeft: "20%",
              width: "60%",
              marginBottom: "2%",
            }}
            onClick={() => {
              setFinTest(1);
              setTestCasesDisabled(true);
              handleCompileFin();
            }}
            disabled={testCasesDisabled || runClicked || !code ||finTest===1}
          >
            <FaRunning size={16} /> {processing ? lang.menu.processing : lang.menu.run}
          </Button>
        </Row>
      </div>

      <Row>
      {outputDetails && <OutputDetails outputDetails={outputDetails} />}
      </Row>

    </Card>
  );
};

export default LandingRecruiter;
