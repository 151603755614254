import React, { useEffect, useState } from "react";

import Select from "react-select";
import data from "../data";
import { Button,Form } from "react-bootstrap";
import swal from "sweetalert";
import axios from "axios";
import { useHistory , useParams} from "react-router-dom";
import translations from "../../components/AppsMenu/Candidate/mulitlingue";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function UpdateAssignment() {
    //assignment id
    const { id } = useParams();
    const token=localStorage.getItem("token");

    const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });

  
  useEffect(() => {
    const selectedLang = JSON.parse(localStorage.getItem("selectedLang"));
    if (selectedLang) {
      setLang(selectedLang);
    }
  }, []);
  const history = useHistory();

  const [height, setHeight] = useState("150px");
  function handleChangeStyle(e) {
    e.preventDefault();
    setHeight("300px");
    let newHeight = e.target.scrollHeight + "px";
    setHeight(newHeight);
  }

  //Style Select
  const selectStyles = {
    control: (base) => ({
      ...base,
      borderRadius: "15px",
      boxShadow: "none",
      border: "none",
      "&:hover": {
        border: "none",
      },
    }),
    option: (base) => ({
      ...base,
      backgroundColor: "#fff",
      color: "#333",
      "&:hover": {},
    }),
    menu: (base) => ({
      ...base,
      borderRadius: "15px",
      marginTop: "-1px",
      boxShadow: "none",
      border: "none",
      "&:before": {
        display: "none",
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#555",
    }),
  };
 
  const [assignment, setAssignment] = useState({
    name: "", 
    description: "",
    difficulty: "",
    duration: "",
    repository_recruiter: ""
  });
 
 /* useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem('userDetails'));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
          const data = await response.json();
            console.log("data.companyID : ",data[0].companyID)
            comp=data[0].companyID;
            console.log("comp: ",comp)
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };
  
    fetchUserPermissions();
  }, [comp]);
*/

  useEffect(()=>{
    const getAssignment = async ()=>{
        try {
            console.log("id", id);
            const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/assignment/${id}`, 
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
            console.log("assignment", response.data.assignment);
        if(response.data.assignment){
            setAssignment(response.data.assignment);
        }
        } catch (error) {
            console.log(error);
        }
        
    }

    getAssignment();
  }, [id]);


  const validateURL = (str) => {
    const pattern = new RegExp(
        '^https://(?:[a-zA-Z]|[0-9]|[$-_@.&+]|[!*\\(\\),]|' +
        '(?:%[0-9a-fA-F][0-9a-fA-F]))+$'
    );
    return pattern.test(str);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if(parseInt(assignment.duration) > process.env.REACT_APP_TEMPORARY_USER_DURATION|| parseInt(assignment.duration) <1){
      swal('Please reduce the duartion.', {
        icon: "error",
      });
        return;
      
    }
    if(assignment.repository_recruiter && !validateURL(assignment.repository_recruiter)){
      swal('Please enter a correct URL', {
        icon: "error",
      });
      return;
    }
    const currentUser = JSON.parse(localStorage.getItem('userDetails'));
    const userId = currentUser.localId;
    axios
      .get(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`, 
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      .then((response) => {
        if (response.data && response.data[0] && response.data[0].companyID) {
          const currentUser = JSON.parse(localStorage.getItem('userDetails'));
          const userId = currentUser.localId;
          axios
            .put(`${process.env.REACT_APP_APP_DOMAIN}/api/assignment/${userId}/${id}`, assignment, 
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
            .then((res) => {
              swal(lang.menu.assiUpdatea, {
                icon: "success",
              });
              history.push(`/assignment-list`);
            })
            .catch((err) => {
              swal(lang.menu.deleteWrong, "error");
            });
        }
      })
      .catch((error) => {
        console.error("Error fetching company ID:", error);
      });
  };
  
  

  
  
  return (
    <>
      <h1>{lang.menu.UpdateAssignment} </h1>
      <br />
      <div className="max-widht">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header d-block">
                  <h4 className="left-align-container smallscreen">
                    {lang.menu.AssignmentName}
                  </h4>

                  <div className="input-group ">
                    <input
                      required
                      type="text"
                      className={`form-control `}
                      name="name"
                      value={assignment.name}
                      placeholder=" Title"
                      onChange={(e) =>
                        setAssignment({ ...assignment, [e.target.name]: e.target.value })
                      }
                    />
                  </div>
                  <br />
                  <h4 className="left-align-container smallscreen">
                    {lang.menu.description}
                  </h4>
                  <div>
                    <textarea
                      required
                      style={{ height: height }}
                      name="description"
                      value={assignment.description}
                      onChange={(e) =>
                        setAssignment({ ...assignment, [e.target.name]: e.target.value })
                      }
                      type="text"
                      className={`form-control`}
                      placeholder=" Assignment Description"
                    />
                  </div>
                  <br />

                  <h4 className="left-align-container smallscreen">
                    {lang.menu.Repository}
                  </h4>

                  <div className="input-group ">
                    <input
                      type="text"
                      className={`form-control `}
                      name="repository_recruiter"
                      value={assignment.repository_recruiter}
                      placeholder={lang.menu.Repository}
                      onChange={(e) =>
                        setAssignment({ ...assignment, [e.target.name]: e.target.value })
                      }
                    />
                  </div>
                  <br />
                  <div className="select-container" >
                  <div className="select-wrapper-right">
                  <h4 className="left-align-container smallscreen">
                    {lang.menu.assignmentDuration}
                  </h4>

                  <Form.Control
                        type="number"
                        name="duration"
                        value={assignment.duration}
                        placeholder={lang.menu.assignmentDuration}
                        required
                        onChange={(e) =>
                            setAssignment({ ...assignment, [e.target.name]: e.target.value })}
                        />
                    </div>


                    <div className="select-wrapper-left" >
                      <h4 className="smallscreen">
                        {lang.menu.Difficulty}
                      </h4>
                      <Form.Control
                        as="select"
                        name="difficulty"
                        value={assignment.difficulty}
                        onChange={(e) =>
                            setAssignment({ ...assignment, [e.target.name]: e.target.value })}
                        >
                            <option value="">{lang.menu.Difficulty}</option>
                            <option value="Beginner">Beginner</option>
                            <option value="Intermediate">Intermediate</option>
                            <option value="Advanced">Advanced</option>
                        </Form.Control>

                    </div>
                  </div>

                  <br />
            
                <div className="card-footer border-0">
                  <div className="d-flex justify-content-end">
                    <Button
                      type="submit"
                      className="btn btn-primary btn sweet-confirm"
                    >
                      {lang.menu.PostNow}
                    </Button>
                  </div>
                </div>

              </div>
            </div>
          </div>
          </div>
        </form>
      </div>
    </>
  );
}
export default UpdateAssignment;