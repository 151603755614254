import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { Col, Row, Modal, Badge,Form ,Pagination} from 'react-bootstrap';
import { RiVerifiedBadgeLine } from 'react-icons/ri';
import { AiFillCloseCircle } from 'react-icons/ai';
import translations from"../AppsMenu/Candidate/mulitlingue";
import PageTitle from "../../layouts/PageTitle";
import { Link } from "react-router-dom";

const JobAssessment = () => {
  const [candidateAssessment, setCandidateAssessment] = useState([]);
  const token=localStorage.getItem("token");

  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
          );
          const data = await response.json();
          setCandidateAssessment(
            data.some(
              (permission) => permission.title === "candidate-assessment"
            )
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);
  const [showAccessDeniedModal, setShowAccessDeniedModal] = useState(true); 

  useEffect(() => {
    if (!candidateAssessment) {
      setShowAccessDeniedModal(true); 
    }
  }, [candidateAssessment]);
  const [jobs, setJobs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [logsPerPage] = useState(7);
  const [selectedLang, setSelectedLang] = useState(null);
  const [lang, setLang] = useState(() => {
      const storedLang = localStorage.getItem('selectedLang');
      if (storedLang) {
        const parsedLang = JSON.parse(storedLang);

        if (parsedLang.language === 'Français') {
          return translations.fr;
        }
      }
      return translations.en;
    });
  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem('userDetails'));
    const userId = currentUser.localId;

    const getUserCompanyID = async () => {
      const responseC = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
      const data = await responseC.json();
      const compId = data[0].companyID;
      return compId;
    };

    const fetchData = async () => {
        const compId = await getUserCompanyID();
      console.log("compid : ",compId)
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getAll`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
        let filteredJobs = response.data;
      
        if (compId!=1) {

          filteredJobs = response.data.filter((job) => job.companyid === compId);
          console.log("filteredJobs : ",filteredJobs)

        }else{
          console.log("response.data : ",response.data)

          filteredJobs=response.data;
        }
      
        let updatedJobs = [];
        await Promise.all(filteredJobs.map(async (job) => {
          const candidates = await getCandidates(job.id);
          const candLength = candidates.length;
          job.candLength = candLength;
          updatedJobs.push(job);
        }));
      
        setJobs(updatedJobs);
      };
      
      fetchData().catch((error) => {

        console.error('Erreur lors de la récupération des emplois:', error);
      });
   

  }, []);

  const parseSkillsString = (skillsString) => {
    const skillsArray = skillsString.replace(/\(/g, '[').replace(/\)/g, ']').replace(/'/g, '"');

    try {
      return JSON.parse(skillsArray);
    } catch (error) {
      console.error('Error parsing skills string:', error);
      return [];
    }
  };

  const getCandidates = async (jobId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getJobRoleCandidates/${jobId}`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
      );
      const candidates = response.data;

      return candidates;
    } catch (error) {
      console.error(`Error fetching job skills for ID ${jobId}:`, error);
      return [];
    }
  };
  const indexOfLastLog = currentPage * logsPerPage;
  const indexOfFirstLog = indexOfLastLog - logsPerPage;
  const currentJobs = jobs
    .filter((log) =>
      Object.values(log)
        .join(' ')
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    )
    .slice(indexOfFirstLog, indexOfLastLog);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(jobs.length / logsPerPage); i++) {
    pageNumbers.push(i);
  }

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <> {candidateAssessment ? (  
    <Fragment>
            <PageTitle
        activeMenu={lang.menu.jobAssessment}
        motherMenu={lang.menu.dashboard}
      />
        <Form.Group controlId="searchForm.Control">
          <Form.Control
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Form.Group>
      <Row>
        <Col>
        <div className="table-responsive">
            <table className="table display mb-4 dataTablesCard job-table table-responsive-xl card-table dataTable no-footer">
             
            <thead>
              <tr>
                <th>{lang.menu.name}</th>
                <th>{lang.menu.candidates}</th>
                <th>Nature</th>
                <th>{lang.menu.postDate}</th>
                <th>{lang.menu.closeDate}</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {currentJobs.map((job) => (
                <tr key={job.id}>
                  <td><Link to={`/Job-Details/${job.id}`}>{job.JobTitle}</Link></td>
                  <td>{job.candLength}</td>
                 
                  <td>{job.TypeOfQualification}</td>
                  <td>{new Date(job.PostedDate).toLocaleDateString()}</td>
                  <td>{new Date(job.CloseDate).toLocaleDateString()}</td>
                  <td>
                    {job.Status === 'Active' ? (
                      <Badge  variant="primary-Sshade">
                        <RiVerifiedBadgeLine size={20} color='#219e8ae6'/>
                      </Badge>
                    ) : (
                      <Badge  variant="primary-Sshade">
                        <AiFillCloseCircle size={20} color='red' />
                      </Badge>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table></div>
          <Pagination className="justify-content-end">
          <Pagination.Prev
            onClick={() => setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage))}
          />
          {pageNumbers.map((number) => (
            <Pagination.Item
              key={number}
              active={number === currentPage}
              onClick={() => paginate(number)}
            >
              {number}
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={() =>
              setCurrentPage((prevPage) =>
                prevPage < Math.ceil(jobs.length / logsPerPage) ? prevPage + 1 : prevPage
              )
            }
          />
        </Pagination>
        </Col>
      </Row>
    </Fragment> ) : (
             <Modal show={showAccessDeniedModal} >
             <Modal.Header>
               <Modal.Title>{lang.menu.accessDenied}</Modal.Title>
             </Modal.Header>
             <Modal.Body>
 {lang.menu.YoudontHavePerm}
             </Modal.Body>
             <Modal.Footer>
         
             </Modal.Footer>
           </Modal>
          )}</>
  );
};

export default JobAssessment;