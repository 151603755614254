import React, { useEffect, useState } from "react";

import Select from "react-select";
import data from "../data";
import "./PostJob.css";
import { Button,OverlayTrigger, Tooltip } from "react-bootstrap";
import swal from "sweetalert";
import axios from "axios";
import { useHistory } from "react-router-dom";
import translations from "../../components/AppsMenu/Candidate/mulitlingue";
import Swal from "sweetalert2";
import { border } from "@mui/system";

function PostJob() {
  const [companyID, setCompanyID] = useState("");
  const [isDisable, setIsDisable] = useState("");
  const [quotaMax, setQuotaMax] = useState("");
  const [userConnect, setUserConnect] = useState("");
  const token=localStorage.getItem("token");

var comp=0;
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });

  useEffect(() => {
    fetchProfiles();
  }, [companyID]);
  useEffect(() => {

    const selectedLang = JSON.parse(localStorage.getItem("selectedLang"));
    if (selectedLang) {
      setLang(selectedLang);
    }
  }, []);
  const [selectedOptionOC, setSelectedOptionOC] = useState({ value: lang.menu.toc, label: lang.menu.toc });
	const [maxscore, setMaxscore] = useState(lang.menu.toc);

  const [selectedOption, setSelectedOption] = useState({ value: lang.menu.pt1, label: lang.menu.pt1 });
  const [selectedOption2, setSelectedOption2] = useState({ value: lang.menu.pt2, label: lang.menu.pt2 });
  const optionsPt1 = [];
  for (let i = 0; i <= 100; i++) {
    const value = (i / 100).toFixed(2);
    const label = `${i} %`;
    optionsPt1.push({ value: value, label: label });
  }


  const optionsPt2 = [];
  for (let i = 0; i <= 100; i++) {
    const value = (i / 100).toFixed(2); 
    const label = `${i} %`;
    optionsPt2.push({ value: value, label: label });
  }
  const [pt1Options, setPt1Options] = useState(optionsPt1);
  const [pt2Options, setPt2Options] = useState(optionsPt2);
  const history = useHistory();
  const [height, setHeight] = useState("150px");
  function handleChangeStyle(e) {
    e.preventDefault();
    setHeight("300px");
    let newHeight = e.target.scrollHeight + "px";
    setHeight(newHeight);
  }

  //Style Select
  const selectStyles = {
    control: (base, state) => ({
      ...base,
      borderRadius: "15px",
      boxShadow:  "0 0 0 1px #000000" , // Ajoute une bordure bleue au focus
      border: "1px 0 #000000" , // Bordure au focus
    }),
    option: (base) => ({
      ...base,
      backgroundColor: "#fff",
      color: "#333",
    }),
    menu: (base) => ({
      ...base,
      borderRadius: "15px",
      marginTop: "-5px",
      boxShadow: "none",
      border: "none",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#555",
    }),
  };
  

  
  
  
 
  const [jobData, setJobData] = useState({
    title: "",
    description: "",
    specialisms: [],
    jobType: "",
    offeredSalary: "",
    experience: "",
    typeOfQualification: "",
    country: "",
    closeDate: "",
    companyid:"",
    profiles: [],
    skills: [],
    tests: [],
    testsToDisplay: [],
    personality: [],
    technical: [],
    psycho: [],
    selectedProfiles: [],
    PT1:0.0,
    PT2:0.0,
    maxOccurence:3
  });
  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem('userDetails'));
        setUserConnect(currentUser)
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
          const data = await response.json();
comp=data[0].companyID;
jobData.companyid = comp; 

setCompanyID(comp)
if(comp!==1 ){
  const subscriptionResponse = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/getSubscriptionFeaturesByCompanyId/${comp}`, 
    {
      headers: {
      Authorization: `Bearer ${token}`,
      }});
  const subscriptionData = await subscriptionResponse.json();
  if(subscriptionResponse.status===200){
    setQuotaMax(subscriptionData[3])

    const isDisabled = subscriptionData[3].numberquota_max <=  subscriptionData[3].quotaJobRole;

    setIsDisable(isDisabled)
  }}
  else if(comp===1 && currentUser.userRole===3 ){
    const subscriptionResponse = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/getSubscriptionFeaturesByRecruiterId/${currentUser.localId}`, 
      {
        headers: {
        Authorization: `Bearer ${token}`,
        }});
    const subscriptionData = await subscriptionResponse.json();
    if(subscriptionResponse.status===200){
      setQuotaMax(subscriptionData[3])
  
      const isDisabled = subscriptionData[3].numberquota_max <=  subscriptionData[3].quotaJobRole;
  
      setIsDisable(isDisabled)
    }}
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };
  
    fetchUserPermissions();
  }, [comp]);
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_APP_DOMAIN}/api/post-job/getTestsbyProfile`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
      )
      .then((res) => {
        const filteredTests = res.data.filter((test) =>
          jobData.selectedProfiles.some(
            (profile) => test.job_profile_id === profile.id
          )
        );
        const uniqueTestNames = new Set();
        filteredTests.forEach((test) => {
          if (test.personality_test_name) {
            uniqueTestNames.add(test.personality_test_name);
          }
          if (test.psycho_test_name) {
            uniqueTestNames.add(test.psycho_test_name);
          }
          if (test.tech_test_name) {
            uniqueTestNames.add(test.tech_test_name);
          }
          if(test.assignment_name){
            uniqueTestNames.add(test.assignment_name);
          }
        });
        setJobData((prevJobData) => ({
          ...prevJobData,
          testsToDisplay: uniqueTestNames,
        }));
      })
      .catch((err) => console.log(err));
  }, [jobData.selectedProfiles]);
  const profileOptions = jobData.profiles.map((profile) => ({
    value: profile.id,
    label: profile.title,
  }));

  const handleProfileChange = (selectedOptions) => {
    const selectedProfileIds = selectedOptions.map((option) => option.value);
    const selectedProfiles = jobData.profiles.filter((profile) =>
      selectedProfileIds.includes(profile.id)
    );
    const selectedSkills = selectedProfiles.flatMap((profile) => {
      const qualifications = profile.requiredQualifications
        .replace(/^\[|\]$/g, "")
        .split(", ");
      return qualifications;
    });

    setJobData((prevJobData) => ({
      ...prevJobData,
      selectedProfiles: selectedProfiles,
      skills: `[${selectedSkills.join(", ")}]`,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const currentUser = JSON.parse(localStorage.getItem('userDetails'));
    const userId = currentUser.localId;
    axios
      .get(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`)
      .then(async(response) => {
        if (response.data && response.data[0] && response.data[0].companyID) {
          const comp = response.data[0].companyID;
          const currentUser = JSON.parse(localStorage.getItem('userDetails'));
          const userId = currentUser.localId;
          jobData.companyid = comp;
          jobData.userId = userId;
          let subscriptionResponse="";
          let subscriptionData=""
          if(comp!==1 ){
                subscriptionResponse = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/getSubscriptionFeaturesByCompanyId/${comp}`, 
                  {
                    headers: {
                    Authorization: `Bearer ${token}`,
                    }});
                subscriptionData = await subscriptionResponse.json();
              }
          else if(comp===1&& currentUser.userRole===3 ){
                subscriptionResponse = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/getSubscriptionFeaturesByRecruiterId/${userId}`, 
                  {
                    headers: {
                    Authorization: `Bearer ${token}`,
                    }});
                subscriptionData = await subscriptionResponse.json();
                      }
                if(comp!==1 && subscriptionData[3].numberquota_max>subscriptionData[3].quotaJobRole){
          axios
            .post(`${process.env.REACT_APP_APP_DOMAIN}/api/post-job`, jobData, 
              {
                headers: {
                Authorization: `Bearer ${token}`,
                }})
            .then(async(res) => {
              swal(lang.menu.jobRoleMsgPostSuccess, {
                icon: "success",
              });
            

              await axios.put(
                `${process.env.REACT_APP_APP_DOMAIN}/api/companies/updateCompanyField/${comp}`,
                { fieldName:"quotaJobRole","incrementValue":1 ,quota_max:subscriptionData[3].numberquota_max,quota_used:subscriptionData[3].quotaJobRole}, 
                {
                  headers: {
                  Authorization: `Bearer ${token}`,
                  }}
              );
              history.push(`/job-list`);
            })
            .catch((err) => {
              swal(lang.menu.deleteWrong, "error");
            });}
            else if(comp===1&& currentUser.userRole===3 && subscriptionData[3].numberquota_max>subscriptionData[3].quotaJobRole ){
              axios
                .post(`${process.env.REACT_APP_APP_DOMAIN}/api/post-job`, jobData, 
                  {
                    headers: {
                    Authorization: `Bearer ${token}`,
                    }})
                .then(async(res) => {
                  swal(lang.menu.jobRoleMsgPostSuccess, {
                    icon: "success",
                  });
                
    
                  await axios.put(
                    `${process.env.REACT_APP_APP_DOMAIN}/api/companies/updateRecruiterField/${userId}`,
                    { fieldName:"quotaJobRole","incrementValue":1 ,quota_max:subscriptionData[3].numberquota_max,quota_used:subscriptionData[3].quotaJobRole}, 
                    {
                      headers: {
                      Authorization: `Bearer ${token}`,
                      }}
                  );
                  history.push(`/job-list`);
                })
                .catch((err) => {
                  swal(lang.menu.deleteWrong, "error");
                });}


            else{
              axios
              .post(`${process.env.REACT_APP_APP_DOMAIN}/api/post-job`, jobData, 
                {
                  headers: {
                  Authorization: `Bearer ${token}`,
                  }})
              .then(async(res) => {
                swal(lang.menu.jobRoleMsgPostSuccess, {
                  icon: "success",
                });
              

                history.push(`/job-list`);
              })
              .catch((err) => {
                swal(lang.menu.deleteWrong, "error");
              });
            }
        }
      })
      .catch((error) => {
        console.error("Error fetching company ID:", error);
      });
  };
  
  

  async function fetchProfiles() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_APP_DOMAIN}/api/post-job/getAllProfiles`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
      );
      let filteredJobsProf = response.data;

      if (companyID!=1) {
        filteredJobsProf = response.data.filter((job) => job.companyid === companyID);
     }
     else{
        filteredJobsProf = response.data; 
     }

      setJobData((prevJobData) => ({
        ...prevJobData,
        profiles: filteredJobsProf
        ,
      }))
    } catch (error) {
      console.error("Error fetching candidates:", error);
    }
  }
  const [newSpecialism, setNewSpecialism] = useState("");
  const [options, setOptions] = useState(data.SpecialismOptions);

  const normalizeString = (str) => {
    return str.toLowerCase().replace(/\s+/g, '');
  }
  
  const handleAddSpecialism = () => {
    const normalizedNewSpecialism = normalizeString(newSpecialism);
  
    const similarSpecialism = options.find(
      option => {
        const normalizedOptionValue = normalizeString(option.value);
        return normalizedOptionValue.includes(normalizedNewSpecialism) || normalizedNewSpecialism.includes(normalizedOptionValue);
      }
    );
  
    if (similarSpecialism) {
      Swal.fire({
        title: `${lang.menu.exist} "${similarSpecialism.value}".`,
        text: `${lang.menu.swalAddQualification1} "${newSpecialism}", ${lang.menu.swalAddQualification2}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OK',
        cancelButtonText: `${lang.menu.Discard}`
      }).then((result) => {
        if (result.isConfirmed) {
          addNewSpecialism();
        } else {
          setNewSpecialism(""); 
        }
      });
    } else {
      addNewSpecialism();
    }
  };
  
  const addNewSpecialism = () => {
    const updatedSpecialismOptions = [
      ...options,
      { value: newSpecialism, label: newSpecialism },
    ];
    data.SpecialismOptions = updatedSpecialismOptions;
    setOptions(updatedSpecialismOptions);
    setNewSpecialism("");
  };
  const handleDropdownSelect2 = (value) => {
    if (value !== lang.menu.pt2) {
      // Update PT1 options based on the selected value of PT2
      const filteredOptions = optionsPt1.filter(option => parseFloat(option.value) > parseFloat(value));
      setPt1Options(filteredOptions);
      setJobData({
        ...jobData,
        PT2: parseFloat(value),
      });
    }
  };
  const handleDropdownSelect1 = (value) => {
    if (value !== lang.menu.pt1) {
      // Update PT1 options based on the selected value of PT2
      const filteredOptions = optionsPt2.filter(option => parseFloat(option.value) < parseFloat(value));
      setPt2Options(filteredOptions);
      setJobData({
        ...jobData,
        PT1: parseFloat(value),
      });
    }
  };
  const optionsOc = [
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" }
  ];
  const handleDropdownSelect4 = (value) => {
		setMaxscore(value);
    setJobData({
      ...jobData,
      maxOccurence: value,
    });
	};
  const handleSelectChange2 = (selectedOption2) => {
    setSelectedOption2(selectedOption2);
    handleDropdownSelect2(selectedOption2.value);
  };
  const handleSelectChange1 = (selectedOption1) => {
    setSelectedOption(selectedOption1);
    handleDropdownSelect1(selectedOption1.value);
  };

  const handleSelectChangeOC = (selectedOptionOC) => {
    setSelectedOptionOC(selectedOptionOC);
    handleDropdownSelect4(selectedOptionOC.value);
  };
  return (
    <>
      <h1>{lang.menu.CreateNewJobRole} </h1>
      <br />
      <div className="max-widht">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header d-block">
                <label><strong>{lang.menu.JobRoleName} <span style={{ color: 'red' }}>*</span> :</strong> </label>
              

                  <div className="input-group ">
                    <input
                      required
                      type="text"
                      className={`form-control `}
                      placeholder={lang.menu.JobRoleName}
                      onChange={(e) =>
                        setJobData({ ...jobData, title: e.target.value })
                      }
                    />
                  </div>
                  <br />
                  <label><strong>{lang.menu.Responsibility} <span style={{ color: 'red' }}>*</span> :</strong> </label>
                  
                  <div>
                    <textarea
                      required
                      style={{ height: height }}
                      onChange={(e) =>
                        setJobData({ ...jobData, description: e.target.value })
                      }
                      type="text"
                      className={`form-control`}
                      placeholder={lang.menu.Responsibility}
                    />
                  </div>
                  <br />

                  <div className="select-container" >
                    <div className="select-wrapper-left" >
                    <label><strong>{lang.menu.NeededQualifications} <span style={{ color: 'red' }}>*</span> :</strong> </label>
                     

                  
                     
                      <Select
                        required
                        isMulti
                        options={data.SpecialismOptions}
                        styles={selectStyles}
                        onChange={(selectedOptions) => {
                          const selectedValues = selectedOptions.map(
                            (option) => option.value
                          );

                          setJobData({
                            ...jobData,
                            specialisms: JSON.stringify(selectedValues),
                          });

                        }}
                      />

                  
                      
                    </div>

                    <div className="select-wrapper-left">
                      <div className="buttonAline">
                      <label><strong>{lang.menu.AddNeededQualification} <span style={{ color: 'red' }}>*</span> :</strong> </label>                        
                       
                        <div className="tooltip-container">
                        <button
                          id="btn"
                          type="button"
                          className="informationScore">i
                        </button>
                        <div className="tooltip-message">
                        {lang.menu.infoQualification}                        </div>
                      </div>
                      </div>
                      <div className="input-button-container">
                        <input
                        
                          type="text"
                          className={`form-control`}
                          value={newSpecialism}
                          onChange={(e) => setNewSpecialism(e.target.value)}
                        />
                        <button
                          className="buttonSkills"
                          onClick={handleAddSpecialism}
                        >
                          <i className="fas fa-plus"></i>
                        </button>
                      </div>
                    </div>
                  </div>

                  <br />
                  <div className="select-container">
                    <div className="select-wrapper-right">
                    <label><strong>{lang.menu.jobType} <span style={{ color: 'red' }}>*</span> :</strong> </label>
                     
                      <Select
                        required
                        options={data.JobTypeOptions}
                        styles={selectStyles}
                        onChange={(e) =>
                          setJobData({ ...jobData, jobType: e.value })
                        }
                      />
                    </div>
                    <div className="select-wrapper-right">
                    <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip-pt1">{lang.menu.msgOc}</Tooltip>}
    >
                    <label><strong>{lang.menu.toc} :</strong> </label>
                    </OverlayTrigger>
                    <br/>
                    <Select
      value={selectedOptionOC}
      onChange={handleSelectChangeOC}
      options={optionsOc}
      
      styles={selectStyles}

    />
                    </div>
                  </div>
                  <br />
                  <div className="select-container">
                    <div className="select-wrapper-right">
                   

                    <label><strong>{lang.menu.country} <span style={{ color: 'red' }}>*</span> :</strong> </label>             

<Select
  
  options={data.countries}
  styles={selectStyles}
  required
  onChange={(e) =>
    setJobData({
      ...jobData,
      country: e.value,
    })
  }
/>




                    </div>
                    <div className="select-wrapper-right">
                    <label><strong>{lang.menu.Experience} <span style={{ color: 'red' }}>*</span> :</strong> </label>             

                      <Select
                        required
                        
                        options={data.ExperienceOptions}
                        styles={selectStyles}
                        onChange={(e) =>
                          setJobData({ ...jobData, experience: e.value })
                        }
                      />
                    </div>
                  </div>
                  <br />
                  <div className="select-container">
                    <div className="select-wrapper-right">
                    <label><strong>{lang.menu.closeDate} <span style={{ color: 'red' }}>*</span> :</strong> </label>             

                      <input
                        required
                        type="date"
                        className={`form-control`}
                        onChange={(e) =>
                          setJobData({
                            ...jobData,
                            closeDate: e.target.value,
                          })
                        }
                      />
                    </div>

                    <div className="select-wrapper-right">
                    <label><strong>{lang.menu.OfferedSalary} <span style={{ color: 'red' }}>*</span> :</strong> </label>         
                     <textarea
                        onChange={(e) =>
                          setJobData({
                            ...jobData,
                            offeredSalary: e.target.value,
                          })
                        }
                        required
                        type="text"
                        className={`form-control`}
                        placeholder="Offred Salary"
                      />
                    </div>
                  </div>
                  <br />
                  <div className="select-container">
                  <div className="select-wrapper-right">
                  <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip-pt1">{lang.menu.msgpt1}</Tooltip>}
          >
            <label><strong>{lang.menu.pt1} <span style={{ color: 'red' }}>*</span> :</strong> </label>
          </OverlayTrigger>
      <Select
      required
      value={selectedOption}
      onChange={handleSelectChange1}
      options={pt1Options}
      
      styles={selectStyles}

    />
    </div>
                    <div className="select-wrapper-right">
                    <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip-pt1">{lang.menu.msgpt2}</Tooltip>}
          >
            <label><strong>{lang.menu.pt2} <span style={{ color: 'red' }}>*</span> :</strong> </label>
          </OverlayTrigger>
      <Select
      required
            value={selectedOption2}
            onChange={handleSelectChange2}
            options={pt2Options} 
            
            styles={selectStyles}

          />
    </div>
                  </div>
                  <br/>
                  <div className="select-container">
                    <div className="select-wrapper-right">
                    <label><strong>{lang.menu.AddProfiles} <span style={{ color: 'red' }}>*</span> :</strong> </label>             

                      <Select
                        
                        isMulti
                        options={profileOptions}
                        styles={selectStyles}
                        onChange={handleProfileChange}
                      />
                    </div>
                    <div className="select-wrapper-right">
                    <label><strong>{lang.menu.TypeOfQualification} <span style={{ color: 'red' }}>*</span> :</strong> </label>             

                      <Select
                      required
                        
                        options={data.TypeQualificationOptions}
                        styles={selectStyles}
                        onChange={(e) =>
                          setJobData({
                            ...jobData,
                            typeOfQualification: e.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <br></br>
                  <div className="select-container">
                    <div className="select-wrapper-right">
                      {jobData.selectedProfiles.length > 0 && (
                        <>
                          <h4 className="smallscreen">
                            {lang.menu.RelatedSkills}
                          </h4>
                          {jobData.selectedProfiles.map((profile) => (
                            <div key={profile.id}>
                              {profile.requiredQualifications
                                .replace(/[\[\]']+/g, "")
                                .split(/\), /)
                                .map((qualification, index) => {
                                  const [skill, count] =
                                    qualification.split(", ");

                                  const cleanedSkill = skill
                                    .replace(/[\(\)]/g, "")
                                    .trim();
                                  const cleanCoefficient = count.replace(
                                    /[\(\)]/g,
                                    ""
                                  );

                                  return (
                                    <span
                                      key={index}
                                      className="specialism-tag"
                                    >
                                      {cleanedSkill} ({cleanCoefficient})
                                    </span>
                                  );
                                })}
                            </div>
                          ))}
                        </>
                      )}
                    </div>

                    <div className="select-wrapper-right">
                      {jobData.selectedProfiles.length > 0 && (
                        <>
                          <h4 className="smallscreen">
                            {lang.menu.linkedTests}
                          </h4>
                          {Array.from(jobData.testsToDisplay).map(
                            (testName, index) => (
                              <div key={index} className="specialism-tag">
                                {testName}
                              </div>
                            )
                          )}
                        </>
                      )}
                    </div>

                  </div>
                 
                </div>

                <div className="card-footer border-0">
                  <div className="d-flex justify-content-end">
                  {companyID && companyID!==1 ? (
                      <Button type="submit" disabled={isDisable || jobData.selectedProfiles.length ===0}>
                      {lang.menu.PostNow}
                    </Button>):
                    companyID && companyID===1 && userConnect &&userConnect.userRole===3 ? (
                    
                      <Button type="submit" disabled={isDisable || jobData.selectedProfiles.length ===0}>
                      {lang.menu.PostNow}
                    </Button>
                    ):
                    (<Button
                      type="submit"
                      disabled={jobData.selectedProfiles.length ===0 || jobData.PT1===0 ||jobData.PT2===0  }
                      className="btn btn-primary btn sweet-confirm"
                    >
                      {lang.menu.PostNow}
                    </Button>) }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
export default PostJob;
