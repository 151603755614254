import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Button, Form } from "react-bootstrap";

import axios from "axios";
import "./PostJob.css";
function SendPersonalityTest() {

  const token=localStorage.getItem("token");

  const [jobData, setJobData] = useState({
    testsToDisplay: [],
    personality: [],
    technical: [],
    psycho: [],
  });

  const [selectedTest, setSelectedTest] = useState(null); // Store the selected test, not an array

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_APP_DOMAIN}/api/post-job/getAllTests`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }})
      .then((res) =>
        setJobData((prevJobData) => ({
          ...prevJobData,
          testsToDisplay: res.data,
        }))
      )
      .catch((err) => console.log(err));
  }, []);

  const handleTestChange = (selectedTest) => {
    // Updated to handle a single test
    setSelectedTest(selectedTest); // Store the selected test
  };

  const TestsOptions = jobData.testsToDisplay.map((test) => ({
    value: test.Id,
    label: test.name,
    test_source: test.test_source,
  }));



  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");

  const sendEmail = async () => {
    if (selectedTest) {
      const testId = selectedTest.value;
      let testType = "";

      if (selectedTest.test_source === "PersonalityTest") {
        testType = "personality";
      } else if (selectedTest.test_source === "TechnicalTest") {
        testType = "technical";
      } else if (selectedTest.test_source === "Psychoest") {
        testType = "psychotechnical";
      }

      let testLink = "";

      if (testType === "personality") {
        testLink = `http://${process.env.REACT_APP_APP_Send_Mail}/page-test-candidate/${testId}`;
      } else if (testType === "technical") {
        testLink = `http://${process.env.REACT_APP_APP_Send_Mail}/JobRoleListTech`;
      } else if (testType === "psychotechnical") {
        testLink = `http://${process.env.REACT_APP_APP_Send_Mail}/JobRoleList`;
      }

      let dataSend = {
        email: email,
        subject: subject,
        message: `
        <html>
        <body>
  <div style="font-family: Arial, sans-serif; background-color: #f0f0f0; padding: 20px; border-radius: 5px; text-align: left;">
    <p style="font-size: 18px; color: #333;">Dear Candidate, </p>
    <p style="font-size: 14px; color: #333;">Please Click The button To Pass your Test</p></div><div style="font-family: Arial, sans-serif; background-color: #f0f0f0; padding: 20px; border-radius: 5px; text-align: center;">
    <a href="${testLink}" style="display: inline-block; background-color: #43DAC2; color: #fff; padding: 10px 20px; text-decoration: none; border-radius: 5px; text-align: center;">Start Test</a>
  </div>
</body>
      </html>
        `,
      };

      // Send the email to the server for processing
      const res = await fetch(
        `${process.env.REACT_APP_APP_DOMAIN}/api/send-personality-test`, // Replace with the correct email sending endpoint
        {
          method: "POST",
          body: JSON.stringify(dataSend),
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      if (res.status > 199 && res.status < 300) {
        alert("Send Successfully!");
      }
    }
  };

  return (
    <>
      <div className="max-widht">
        <h1>Send Test</h1>
        <br />
        <form onSubmit={sendEmail}>
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header d-block">
                  <h3>Email Address</h3>
                  <div className="input-group ">
                    <input
                      required
                      type="text"
                      className={`form-control `}
                      placeholder="Email Address"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <br />
                  <h3>Subject</h3>
                  <div>
                    <textarea
                      required
                      onChange={(e) => setSubject(e.target.value)}
                      type="text"
                      className={`form-control`}
                      placeholder="Description"
                    />
                  </div>
                  <br />
                  <div className="select-container">
                    <div className="select-wrapper-left">
                      <h3>Test</h3>

                      <Form.Group controlId="selectedTest">
      <Form.Control
        as="select"
        name="Select Test"
        value={selectedTest}
        onChange={(e) =>handleTestChange(e.target.value)}
        required
      >
        <option value="">selectedTest</option>
        {TestsOptions.map((test, index) => (
          <option key={index} value={test.label}>
            {test.label}
          </option>
        ))}
      </Form.Control>
    </Form.Group>

                    </div>
                  </div>
                  <div className="card-footer border-0">
                    <div className="d-flex justify-content-end">
                      <Button
                        type="submit"
                        className="btn btn-primary btn sweet-confirm"
                      >
                        Send Email
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default SendPersonalityTest;
