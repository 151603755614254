import { React, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Card } from "react-bootstrap";
import { RiArrowGoBackFill } from "react-icons/ri";
import { height } from '@mui/system';
import translations from "../../components/AppsMenu/Candidate/mulitlingue"

const NotFound = () => {
    const [lang] = useState(() => {
		const storedLang = localStorage.getItem('selectedLang');
		if (storedLang) {
			const parsedLang = JSON.parse(storedLang);
			if (parsedLang.language === 'Français') {
				return translations.fr;
			}
		}
		return translations.en;
	});
  return (
    <div style={styles.container}>
    <Card style={{height:"150%"}}>
    
      <h1 style={styles.title}>{lang.menu.notfoundTitle}</h1>
      <p style={styles.message}>
      {lang.menu.notfoundMsg}
      </p>
      
   
    <Card.Footer>
    <Link to="/" style={styles.homeLink}>
      <RiArrowGoBackFill size={25} />  {lang.menu.backNotfound}
      </Link>
    </Card.Footer>
    </Card>
    </div>
  );
};

const styles = {
  container: {
    textAlign: 'center',
    padding: '10%',
  },
  title: {
    fontSize: '48px',
    color: '#74188D',
  },
  message: {
    fontSize: '24px',
    color: '#000',
  },
  homeLink: {
    fontSize: '18px',
    color: '#43DAC2',
    textDecoration: 'none',
  },
};

export default NotFound;
