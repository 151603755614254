import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Radar } from 'react-chartjs-2';
import {
    Col, Row, Dropdown, Card, Table, Button, Link
} from "react-bootstrap";
import translations from "../AppsMenu/Candidate/mulitlingue";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {FaDownload} from "react-icons/fa6";
import {CiImport,  CiLogin} from "react-icons/ci";
import swal from "sweetalert";

const AssignmentResult = ({ candidateId, jobRoleId }) => {
    const [candidates, setCandidates] = useState([]);
    const [selectedCandidate, setSelectedCandidate] = useState('');
    const [selectedJobRole, setSelectedJobRole] = useState('');
    
    const [selectedLang, setSelectedLang] = useState(null);
    const [filteredJobRoles, setFilteredJobRoles] = useState([]);
    const [candSelected, setCandSelected] = useState(false);
    const [selectionMade, setSelectionMade] = useState(false);
    
    const [candAssignment, setCandAssignment]= useState(null);
    const [showCandAssignment, setShowCandAssignment]= useState(false);
    const token=localStorage.getItem("token");

    const [lang, setLang] = useState(() => {
        const storedLang = localStorage.getItem('selectedLang');
        if (storedLang) {
            const parsedLang = JSON.parse(storedLang);
            if (parsedLang.language === 'Français') {
                return translations.fr;
            }
        }
        return translations.en;
    });

    useEffect(() => {
        const fetchCandidates = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getAllCandidate`, 
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  });
                setCandidates(response.data);
            } catch (error) {
                console.log('Erreur lors de la récupération des candidats :', error);
            }
        };

        fetchCandidates();
    }, []);

  
    const getJobsForCandidate = async (candidateId) => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/assignment/jobroleAll/${candidateId}`, 
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
          /*const jobs = response.data.jobroles.map(job =>{ return {
            id: job.id,
            JobTitle: job.JobTitle
          }});*/
          const jobs = response.data.jobroles;
          return jobs;
        } catch (error) {
          console.error(`Error fetching jobs for ID ${candidateId}:`, error);
          return "";
        }
      };
      const handleCandidateSelect = async(eventKey) => {
        setCandSelected(false)
        const selectedCandidateObj = candidates.find(candidate => candidate.email === eventKey);

        setSelectedCandidate(selectedCandidateObj);
        const jobs= await getJobsForCandidate(selectedCandidateObj.uid)
        //setJobsCand(jobs)
        //const filteredRoles = jobRoles.filter((role) => jobs.includes(role.id));
        setFilteredJobRoles(jobs);
        setCandSelected(true);
        setSelectionMade(false); 
      };
      
      const handleJobRoleSelect = async(eventKey) => {
        setSelectedJobRole(filteredJobRoles.find(job => job.id == eventKey));
        setCandAssignment(null);
        setShowCandAssignment(false);
        //const jobTitle=await getJobName(eventKey)
        
       // console.log("with title", filteredJobRoles.find(job => job.id == eventKey).JobTitle );
        //setJobTitle(filteredJobRoles.find(job => job.id == eventKey).JobTitle);
        setSelectionMade(true); 
      };
     /* useEffect(()=>{
        setCandAssignment(null);
        setShowCandAssignment(false);
      },[selectedJobRole]); */
   
    //update assignment score
  const [updateAssignment, setUpdateAssignment]= useState();
  const [assignmentScore, setAssignmentScore]= useState(0);
  const handleChangeAssignmentScore = async (assignmentId)=>{
    try {
 
      const candidateid= selectedCandidate.uid;
      const jobid= selectedJobRole.id;
      if((assignmentScore > 100) || (assignmentScore < 0 )){
        swal('give a correct score', {
          icon: "error",
        });
        return;
      }
      const response = await axios.put(`${process.env.REACT_APP_APP_DOMAIN}/api/assignment/score/${candidateid}/${jobid}/${assignmentId}`, {
        assignmentScore : parseFloat(assignmentScore)
      }, 
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      if(response.data.candidate){
        selectedJobRole.candidate_jobs[0].detailed_assignment_score = response.data.candidate.detailed_assignment_score;
        selectedJobRole.candidate_jobs[0].assignment_score = parseFloat(response.data.candidate.assignment_score);
      }

      setUpdateAssignment(null);
      setAssignmentScore(0);

    } catch (error) {
      console.error(error);
    }
  }

  
  const getCandAssignment= async (assignmentid)=>{
    try {
      const candidateid= selectedCandidate.uid;
      const jobid= selectedJobRole.id;
      const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/assignment/result/${candidateid}/${jobid}/${assignmentid}`, 
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }) ;

      setCandAssignment(response.data);
     setShowCandAssignment(true);


    } catch (error) {
      console.error(error);
    }
  }

  const handleDownload =async () => {
    try {
      const candidateid= selectedCandidate.uid;
      const jobid= selectedJobRole.id;
      
    const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/assignment/download/${candidateid}/${jobid}/${candAssignment.assignment_id}`,
      {
        responseType: "arraybuffer",
      }, 
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    const file = new Blob([response.data], {
      type: "application/octet-stream",
    });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
    } catch (error) {
      console.error(error);
    }
  
  };
    return (
        <div >
            <h2>{lang.menu.selJobCandidat}</h2>
            <Row>
            <Col xl={5}>
                <Dropdown onSelect={handleCandidateSelect}>
  <Dropdown.Toggle variant="primary light" id="dropdown-candidate" style={{ width: '80%' }}>
    <label htmlFor="dropdown-candidate"></label>
    {selectedCandidate ? selectedCandidate.email : lang.menu.selectCandidate}
  </Dropdown.Toggle>
  <Dropdown.Menu style={{ maxWidth: '100%', maxHeight: '400%', overflowY: 'auto', width: '80%' }}>
    {candidates.length > 0 ? (
      candidates.map((candidate) => (
        <Dropdown.Item key={candidate.uid} eventKey={candidate.email}>
          {candidate.email}
        </Dropdown.Item>
      ))
    ) : (
      <Dropdown.Item disabled>
{lang.menu.noCandidate}
      </Dropdown.Item>
    )}
  </Dropdown.Menu>
</Dropdown>
                </Col>
                <Col xl={5}>
                <Dropdown onSelect={handleJobRoleSelect}>
  <Dropdown.Toggle variant="primary light" id="dropdown-jobrole" style={{ width: '80%' }} disabled={!candSelected}>
    <label htmlFor="dropdown-jobrole"></label>
    {selectedJobRole ? selectedJobRole.JobTitle : lang.menu.selectJobRole}
  </Dropdown.Toggle>
  <Dropdown.Menu style={{ maxWidth: '100%', maxHeight: '400%', overflowY: 'auto', width: '80%' }}>
    {filteredJobRoles.length > 0 ? (
      filteredJobRoles.map((jobRole) => (
        <Dropdown.Item key={jobRole.id} eventKey={jobRole.id}>
          {jobRole.JobTitle}
        </Dropdown.Item>
      ))
    ) : (
 
      <Dropdown.Item disabled>
        {lang.menu.noJobRolesForCandidate}
      </Dropdown.Item>
    )}
  </Dropdown.Menu>
</Dropdown>
                </Col>

                {/*<Col xl={2}>
                    <Button className='btn-outline-primary btn-rounded' onClick={handleFetchRadarPsycho}>{lang.menu.submit}</Button>
</Col>*/}
            </Row>

            <Row style={{backgroundColor: "white", paddingTop: "8%"}}>
            {selectedJobRole ? (
              <>
            <Col xl={5}>
    
            {selectedJobRole &&
        <>         
    <Card style={{backgroundColor:"#8cd6c8d0", paddingTop: "8%"}}>
        <Card.Body>
       
         
        

{ selectedJobRole && (selectedJobRole.candidate_jobs[0].assignment_score >= 0) ?(<>
<div style={{ marginBottom: '20px', maxHeight: '100%' }}>
<Button style={{ backgroundColor: "#ffffff", width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' ,color:"black"}}
>
<span style={{ textAlign: "left" }}>{lang.menu.AssignmentResult}</span>
<span style={{ textAlign: "right" }} >{selectedJobRole.candidate_jobs[0].assignment_score ? (Math.round(selectedJobRole.candidate_jobs[0].assignment_score) + "%") : ("---")} </span>

</Button>
<br/>


{
    selectedJobRole.jobprofiles.length > 0 &&  selectedJobRole.jobprofiles.map((jobprofile, index) =>( 
             <Card> 
               <Card.Body> 
                
               <div key={index}>
               <Row >
                  <Col xl={8}><strong>Test :</strong> {jobprofile.Assignments[0].name}</Col>
                  {!(updateAssignment == index) ? <Col><strong>Note :</strong>{(selectedJobRole.candidate_jobs[0].detailed_assignment_score && JSON.parse(selectedJobRole.candidate_jobs[0].detailed_assignment_score)[jobprofile.Assignments[0].id]) ? (Math.round(JSON.parse(selectedJobRole.candidate_jobs[0].detailed_assignment_score)[jobprofile.Assignments[0].id]) + "%") : ("---")}   </Col>
                  : <Col><strong>Note :</strong>
                  <input
                    className="input-search form-control"
                   value={assignmentScore} onChange={(e)=>{
                    console.log ("assignmentScore", assignmentScore);
                    setAssignmentScore(e.target.value)
                  }}/>
                  </Col>}
               </Row>
               <br/>
               <Row style={{textAlign: "center"}}>
               <div className="test-card-icons">
              
            
              { !(updateAssignment === index) ? 
              <button 
                style={{ border: "none" }}
                onClick={()=>{
                  setAssignmentScore((selectedJobRole.candidate_jobs[0].detailed_assignment_score && selectedJobRole.candidate_jobs[0].detailed_assignment_score[jobprofile.Assignments[0].id]) ? JSON.parse(selectedJobRole.candidate_jobs[0].detailed_assignment_score)[jobprofile.Assignments[0].id] : 0);
                setUpdateAssignment(index);
              }}>
                <i className="fas fa-pencil-alt fa-lg" ></i>
              </button>
              
            :  <button 
               style={{ border: "none" }}
                onClick={()=>handleChangeAssignmentScore(jobprofile.Assignments[0].id)}>
              <i className="fas fa-check fa-lg" ></i>
              </button>
            }
            
            <button style={{ border: "none" }} onClick={()=>getCandAssignment(jobprofile.Assignments[0].id)}>
            <i className="fa fa-file-code fa-lg" ></i>
              </button>
           </div>
           

               </Row>
               
               </div>
            </Card.Body> 
        </Card>
      )
     )}
</div>


</> ) : (
<div className='text-center' ><p>{lang.menu.noAssignment}</p>

</div>
)}
      </Card.Body>
    </Card>
    </>
}
  
</Col>

<Col>
{(!showCandAssignment && selectedJobRole) ?
  <div className='text-center' ><p>{lang.menu.selectAssignment} </p>
 
  </div>

: <>
{(showCandAssignment && candAssignment) &&
<>

 {candAssignment.submit ?
 <>
  <Row>
  <Col>
  
  </Col>
  <Col xl={3}>

  {candAssignment.assignment_url ?
<Button style={{ backgroundColor: "#8cd6c8d0", width: '100%' }} onClick={()=>{ window.open(candAssignment.assignment_url, '_blank', 'noopener,noreferrer');}}>
<CiLogin />{"   "}{lang.menu.Assignment}</Button>
    :

<Button style={{ backgroundColor: "#8cd6c8d0", width: '100%' }} onClick={handleDownload}>
<CiImport />{"   "}{lang.menu.Assignment}</Button>
  }
</Col>
</Row>

<Row>
<span style={{ textAlign: "left" }}><strong>{lang.menu.comment}</strong></span>
<div className='text-center' ><p>{candAssignment.candidate_comment ? candAssignment.candidate_comment : "There is  no comment"}</p>
</div>
</Row>
</>
: (
  <>
   <div className='text-center' ><p>{lang.menu.notyet}</p>
  <p>{lang.menu.noTestRes}</p>
  </div>
  
  </>
)}
</>


}
</>
}

</Col>
</>): (
  <div className='text-center' ><p>{lang.menu.selectCandJob}</p>

</div>
)
}
            </Row>
        
  


            </div>
    );
};

export default AssignmentResult;

