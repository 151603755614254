import React, { useState, useEffect, useMemo } from "react";
import { useTable, useSortBy } from "react-table";
import PageTitle from "../../layouts/PageTitle";
import translations from "../AppsMenu/Candidate/mulitlingue";
import axios from "axios";

export const QuestionTable = () => {
  const [questions, setQuestions] = useState([]);
  const [categoryNames, setCategoryNames] = useState({});
  const token=localStorage.getItem("token");

  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Fran�ais") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  useEffect(() => {
    const selectedLang = JSON.parse(localStorage.getItem("selectedLang"));
    if (selectedLang) {
      setLang(selectedLang);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/question1/getAllQuestions`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
        setQuestions(response.data);
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
    };

    fetchData();

    axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/categories/getAllCategories`, 
      {
        headers: {
        Authorization: `Bearer ${token}`,
        }})
      .then(response => {
        const catMap = {};
        response.data.forEach(cat => {
          catMap[cat.id] = cat.name;
        });
        setCategoryNames(catMap);
      })
      .catch(error => console.error("Error fetching categorie names:", error));
  }, []);

  const fetchCatName = (idcat) => {
    return categoryNames[idcat] || "N/A";
  };

  const COLUMNS = useMemo(
    () => [
      {
        Header: "ID",
        Footer: "ID",
        accessor: "id",
      },
      {
        Header: `${lang.menu.Question}`,
        Footer: `${lang.menu.Question}`,
        accessor: "question",
      },
      {
        Header: `${lang.menu.imageRef}`,
        Footer: `${lang.menu.imageRef}`,
        accessor: "imageRef",
      },
      {
        Header: `${lang.menu.choix1}`,
        Footer: `${lang.menu.choix1}`,
        accessor: "choix1",
      },
      {
        Header: `${lang.menu.choix2}`,
        Footer: `${lang.menu.choix2}`,
        accessor: "choix2",
      },
      {
        Header: `${lang.menu.choix3}`,
        Footer: `${lang.menu.choix3}`,
        accessor: "choix3",
      },
      {
        Header: `${lang.menu.choix4}`,
        Footer: `${lang.menu.choix4}`,
        accessor: "choix4",
      },
      {
        Header: `${lang.menu.BonneReponse}`,
        Footer: `${lang.menu.BonneReponse}`,
        accessor: "BonneReponse",
      },
      {
        Header: `${lang.menu.Categorie}`,
        Footer: `${lang.menu.Categorie}`,
        accessor: "Categorie",
        Cell: ({ value }) => fetchCatName(value),
      },
    ],
    [lang.menu,categoryNames]
  );

  const columns = useMemo(() => COLUMNS, [COLUMNS]);
  const data = useMemo(() => questions, [questions]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <>
      <div className="card">
      <div className="card-header">
          <h4 className="card-title">{lang.menu.PsychoTechnicalQuestionTable}</h4>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <div className="dataTables_wrapper">
              <table {...getTableProps()} className="table dataTable display">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("Header")}
                          <span className="ml-1">
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <i className="fa fa-arrow-down" />
                              ) : (
                                <i className="fa fa-arrow-up" />
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {" "}
                              {cell.render("Cell")}{" "}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>

                <tfoot>
                  {footerGroups.map((footerGroup) => (
                    <tr {...footerGroup.getFooterGroupProps()}>
                      {footerGroup.headers.map((column) => (
                        <td {...column.getFooterProps()}>
                          {column.render("Footer")}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionTable;
