import { lazy, Suspense, useEffect } from 'react';

/// Components
import Index from "./jsx";
import { connect, useDispatch } from 'react-redux';
import {  Route, Switch, withRouter,Redirect } from 'react-router-dom';
// action
import { checkAutoLogin } from './services/AuthService';
import { isAuthenticated , hasValidation } from './store/selectors/AuthSelectors';
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import "./css/custom-style.css";


const role = lazy(() => import('./jsx/components/Dashboard/RoleRegistration'));
const role2 = lazy(() => import('./jsx/components/Dashboard/RoleRegistration2'));

const FetchLinkedin = lazy(() => import('./jsx/components/fetchLinkedin'));
const SignUp = lazy(() => import('./jsx/pages/Registration'));
const ForgotPassword = lazy(() => import('./jsx/pages/ForgotPassword'));
const Login = lazy(() => {
    return new Promise(resolve => {
    setTimeout(() => resolve(import('./jsx/pages/Login')), 500);
  });
});

function App (props) {
    const dispatch = useDispatch();
     useEffect(() => {
         checkAutoLogin(dispatch, props.history);
    }, [dispatch, props.history]);
    
    let routes = (  
        <Switch>
            <Route path='/login' component={Login} />
            <Route path='/fetchlinkedin' component={FetchLinkedin} />
            {/* <Route path="/fetchlinkedin" element={<FetchLinkedin />} /> */}

            <Route path='/page-register' component={SignUp} />
            <Route path='/page-forgot-password' component={ForgotPassword} />
        </Switch>
    );

    let roleRoute = (
        <Switch>
            <Route path='/pick-role' component={role} />
            <Route path='/pick-role2' component={role2} />
            <Redirect to='/pick-role' />
        </Switch>
    );
    if (props.isAuthenticated) {
        if (props.hasValidation) { 
            return (
                <>
                    <Suspense fallback={
                        <div id="preloader">
                            <div className="sk-three-bounce">
                                <div className="sk-child sk-bounce1"></div>
                                <div className="sk-child sk-bounce2"></div>
                                <div className="sk-child sk-bounce3"></div>
                            </div>
                        </div>  
                       }
                    >
                        <Index />
                    </Suspense>
                </>
            );
        } else {
            return (
                <div className="vh-100">
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>
                  }
                >
                    {roleRoute}
                </Suspense>
			</div>
            );
        }	
	} else{ 
		return (
			<div className="vh-100">
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>
                  }
                >
                    {routes}
                </Suspense>
			</div>
		);
	}
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: isAuthenticated(state),
        hasValidation: hasValidation(state),
    };
};

export default withRouter(connect(mapStateToProps)(App));