import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card,Dropdown, Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import translations from "../AppsMenu/Candidate/mulitlingue";
import swal from "sweetalert";

const ListCategories = () => {
  const [categoryPer, setCategoryPer] = useState([]);
  const token=localStorage.getItem("token");

  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
          );
          const data = await response.json();
          setCategoryPer(
            data.some((permission) => permission.title === "list-categories")
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);
  const [showAccessDeniedModal, setShowAccessDeniedModal] = useState(true); 

  useEffect(() => {
    if (!categoryPer) {
      setShowAccessDeniedModal(true); 
    }
  }, [categoryPer]);
  const [categories, setCategories] = useState([]);
  const [createCat, setCreateCat] = useState([]);
  const [modalType, setModalType] = useState("create");
  const [nameFolder, setNameFolder] = useState("");

  const [selectedSkill, setSelectedSkill] = useState(null);
  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
          );
          const data = await response.json();
          setCreateCat(
            data.some((permission) => permission.title === "create-category")
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  useEffect(() => {
    const selectedLang = JSON.parse(localStorage.getItem("selectedLang"));
    if (selectedLang) {
      setLang(selectedLang);
    }
  }, []);
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_APP_DOMAIN}/api/categories/getAllCategories`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
      );
      setCategories(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    

    fetchData();
  }, []);

  useEffect(() => {
    const fetchQuestionCount = async () => {
      try {
        const updatedCategories = await Promise.all(
          categories.map(async (category) => {
            const response = await axios.get(
              `${process.env.REACT_APP_APP_DOMAIN}/api/question1/count/${category.id}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Cache-Control": "no-cache",
                  Pragma: "no-cache",
                },
              }
            );
            const questionCount = response.data.count;
            return { ...category, questionCount };
          })
        );
        setCategories(updatedCategories);
      } catch (error) {
        console.log(error);
      }
    };

    fetchQuestionCount();
  }, []);

  const handleModalOpen = () => {
    setShowModal(true);
    setModalType("create")
    setSelectedSkill(null);
    setName(null);
    setDescription(null)

  };
  const handleModalOpenUpdate = (skillSelect) => {
    setSelectedSkill(skillSelect);
    setName(skillSelect.name);
    setNameFolder(skillSelect.name)
    setDescription(skillSelect.description);
    setShowModal(true);
    setModalType("update");
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const createCategory = async (newCategory) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_APP_DOMAIN}/api/categories/createCategorie`,
        newCategory, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
      );
      const createdCategory = response.data;
      setCategories([...categories, createdCategory]);
      setShowModal(false);
    } catch (error) {
      console.log(error);
    }
  };
  const checkCategoryNameExists = (name) => {
    console.log("name :",name.length)

    const cleanedName = name.trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();
console.log("cleanedName :",cleanedName.length)
    return categories.some((category) =>
       category.name.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase() === cleanedName
  );
  };
  
   const handleFormSubmit = (e) => {
    e.preventDefault();
  
    if (modalType === "update" && selectedSkill && selectedSkill.name.toLowerCase() === name.toLowerCase()) {
      updateCategorie({
        name,
        description,
      });
    } else if (checkCategoryNameExists(name)) {
      swal(lang.menu.duplicateNameError, {
        icon: "error",
      });
      return;
    } else {
      const newCategory = {
        name,
        description,
      };
  
      if (modalType === "update") {
        updateCategorie(newCategory);
      } else {
        createCategory(newCategory);
      }
    }
  
    setName("");
    setDescription("");
  };
  
  const [searchTerm, setSearchTerm] = useState('');
  const filterByTitle = () => {
    return categories.filter(cat => cat.name.toLowerCase().includes(searchTerm.toLowerCase()));
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleDelete = (id) => {
    // Show a confirmation dialog using SweetAlert
    swal({
      title:lang.menu.deleteTitle ,
      text: lang.menu.deleteTextCategory,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        // User confirmed, perform the delete action
        axios
          .delete(`${process.env.REACT_APP_APP_DOMAIN}/api/categories/deleteCategorieById/` + id, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }})
          .then((res) => {
            if (res.data.message === "Categorie was deleted successfully!") {
              console.log(res);
              swal(lang.menu.deleteSuccessCategory, {
                icon: "success",
              });
              setCategories((prevCat) =>
                prevCat.filter((cat) => cat.id !== id)
          );
              // Add any additional logic here after successful delete
            } else {
             swal(lang.menu.deleteTitleWrong,lang.menu.deleteWrong, "error");            }
          })
          .catch((err) => {
            swal(lang.menu.deleteTitleWrong, lang.menu.deleteWrong, "error");
            console.log(err);
          });
      } else {
        // User canceled the delete action
        swal(lang.menu.categoryDeleteCancel, {
          icon: "info",
        });
      }
    });
  };
  const updateCategorie = async (updatedCcat) => {
    try {
      const updatedCategory = {
        ...updatedCcat,
        nameFolder: nameFolder
      };
      const response = await axios.put(
        `${process.env.REACT_APP_APP_DOMAIN}/api/categories/updateCategorie/${selectedSkill.id}`,
        updatedCategory, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
      );
      const updatedCcatData = response.data;
      setCategories(categories.map(cat => cat.id === updatedCcatData.id ? updatedCcatData : cat));
      setShowModal(false);
      setSelectedSkill(null);
      swal(lang.menu.updateMsgSucccat, {
        icon: "success",
      });
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <> {categoryPer ? (  
      <div style={{ fontFamily: "Poppins" }}>
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <h4 className="fs-20 font-w600 me-auto">
          {lang.menu.categorieAvailable}{" "}
        </h4>
        <div>
          {createCat ? (
            <Button
              variant="primary"
              className="me-3 btn-sm"
              onClick={handleModalOpen}
            >
              <i className="fas fa-plus me-2"></i>
              {lang.menu.newCategorie}
            </Button>
          ) : (
            <></>
          )}
        </div>
      </div>
      <Form.Group controlId="formBasicSearch">
        <Form.Control
          type="text"
          placeholder={lang.menu.serachJob}
          value={searchTerm}
          onChange={handleSearch}
        />
      </Form.Group>
      <br />
      <div className="row">
        <div className="col-xl-12">
        <Row>
  {filterByTitle().map((item, index) => (
    <Col xl={6} key={index} className="mb-4">
      <Card>
        <Card.Header>
          <Card.Title className="fs-20 mb-1">{item.name}</Card.Title>
        </Card.Header>
        <Card.Body>
          <div>
            <div className="d-flex">
              <Card.Text>{item.description}</Card.Text>
            </div>
          </div>
        </Card.Body>
        <Card.Footer>
          <Row>
            <Col>
              <Link to={`/QuestionOfCategory/${item.id}`} className="btn btn-outline-primary btn-rounded">
                <i className="fas fa-eye"></i>
              </Link>
            </Col>
            <Col>
              <Button variant="danger light" onClick={() => handleModalOpenUpdate(item)}>
                <i className="fas fa-pencil-alt"></i>
              </Button>
            </Col>
            <Col>
              <Button variant="danger light" onClick={() => handleDelete(item.id)}>
                <i className="fas fa-trash-alt fa-lg"></i>
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </Col>
  ))}
</Row>

        </div>
      </div>
    
      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header>
          <Modal.Title> 
            
          {modalType === "update" ? lang.menu.Update : lang.menu.createNewCat}
          </Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setShowModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleFormSubmit}>
            <Form.Group controlId="formCategoryName">
              <Form.Label>{lang.menu.name} </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter category name"
                value={name}
                onChange={handleNameChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formCategoryDescription">
              <Form.Label>{lang.menu.description} </Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter category description"
                value={description}
                onChange={handleDescriptionChange}
                rows={3}
                required
              />
            </Form.Group>
            <br />
            <Button variant="primary" type="submit">
              
      {modalType === "update" ? lang.menu.Update : lang.menu.createCat}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
    ) : (
             <Modal show={showAccessDeniedModal} >
             <Modal.Header>
               <Modal.Title>{lang.menu.accessDenied}</Modal.Title>
             </Modal.Header>
             <Modal.Body>
 {lang.menu.YoudontHavePerm}
             </Modal.Body>
             <Modal.Footer>
         
             </Modal.Footer>
           </Modal>
          )}</>
  );
};

export default ListCategories;
