import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import swal from "sweetalert";
import "./TestCandidate.css";
import translations from "../components/AppsMenu/Candidate/mulitlingue";

function TestCandidate() {
  
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);

      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  useEffect(() => {
    const selectedLang = JSON.parse(localStorage.getItem("selectedLang"));
    if (selectedLang) {
      setLang(selectedLang);
    }
  }, []);
  const { id } = useParams();
  const [userId, setUserId] = useState("");
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [questions, setQuestions] = useState([]);
  const token=localStorage.getItem("token");

  const [testId, setTestId] = useState(id);

  const questionRefs = useRef([]); // Ref to store question elements

  useEffect(() => {
    fetchQuestions();
    fetchUserIdFromLocalStorage();
  }, []);

  async function fetchQuestions() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_APP_DOMAIN}/api/page-test-candidate/` + id, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
      );
      setQuestions(response.data);
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  }

  const history = useHistory();
  async function handleSubmit(event) {
    event.preventDefault();
    
    // Vérifier que chaque question a une réponse sélectionnée
    const unansweredQuestions = questions.filter((question) => {
      return !getSelectedAnswer(question.id);
    });
  
    if (unansweredQuestions.length > 0) {
      swal(
        lang.menu.Incomplete,
        lang.menu.incompleteText,
        "warning"
      );
      return; // Ne pas soumettre le formulaire si des questions sont sans réponse
    }
  
    const jobRoleId = localStorage.getItem("jobRoleId");
    const userDetailsString = localStorage.getItem("userDetails");
    const userDetails = JSON.parse(userDetailsString);
    const idCandidate = userDetails ? userDetails.localId : null;
    const answerData = questions.map((question) => {
      console.log("question :", question);
      const selectedAnswer = getSelectedAnswer(question.id);
      return {
        questionId: question.id,
        answer: selectedAnswer,
        score: selectedAnswer * question.coefficient,
        result: "result",
        jobRoleId: jobRoleId
      };
    });
  
    const formData = {
      testId,
      candidateId: userId,
      answersData: answerData
    };
    console.log("formData:", formData);
  
    try {
      await axios.post(
        `${process.env.REACT_APP_APP_DOMAIN}/api/personality-test/submit-answers`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      swal(
        "Answers Submitted!",
        "Your answers have been submitted successfully.",
        "success"
      ).then(async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_APP_DOMAIN}/api/candidate-scores/${userId}/${testId}/${jobRoleId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              }
            }
          );
          console.log("Candidate Scores:", response.data);
  
          try {
            const notePer = response.data.scores;
            await axios.put(
              `${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/candidatesjobtestPer/${idCandidate}/${jobRoleId}/${testId}`,
              { notePer },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                }
              }
            );
            console.log("PUT Request Successful");
            history.push(`/JobRoleTests/${jobRoleId}`);
          } catch (putError) {
            console.error("Error sending PUT request:", putError);
          }
        } catch (error) {
          console.error("Error fetching candidate scores:", error);
        }
      });
    } catch (error) {
      console.error("Error submitting answers:", error);
      swal(
        "Error",
        "There was an error submitting your answers. Please try again later.",
        "error"
      );
    }
  }
  
  
  function getSelectedAnswer(questionId, index) {
    const radioButtons = document.getElementsByName(questionId);
    for (let i = 0; i < radioButtons.length; i++) {
      if (radioButtons[i].checked) {
        return radioButtons[i].value;
      }
    }
    return null;
  }
  const questionClass = (index) => {
    if (index === currentQuestion) {
      return "question current-question";
    } else {
      return "question other-question";
    }
  };

  const fetchUserIdFromLocalStorage = () => {
    const storedUserData = localStorage.getItem("userDetails");

    if (storedUserData) {
      try {
        const parsedUserData = JSON.parse(storedUserData);

        if (parsedUserData.email) {
          const userEmail = parsedUserData.email;
          setUserId(userEmail);
          console.log("Fetched user email:", userEmail);
        } else {
          console.log("User email not found in local storage.");
        }
      } catch (error) {
        console.error("Error parsing stored user data:", error);
      }
    } else {
      console.log("No stored user data found in local storage.");
    }
  };
  const sendEmail = async () => {
    let testLink = `http://${process.env.REACT_APP_APP_Send_Mail}/peronality-test-result`;

    let dataSend = {
      email: "yassine.ayadi@teligencia.com, yassinayadi44@gmail.com",
      subject: "Personality Test Result",
      message: `
        <html>
        <body>
  <div style="font-family: Arial, sans-serif; background-color: #f0f0f0; padding: 20px; border-radius: 5px; text-align: left;">
    <p style="font-size: 18px; color: #333;">Dear Recruiter, </p>
    <p style="font-size: 14px; color: #333;">Your Candidate with the Email <b>${userId}</b> has completed the test.</p>
    <p style="font-size: 14px; color: #333;">Please Click The button To view the test result</p>
    </div><div style="font-family: Arial, sans-serif; background-color: #f0f0f0; padding: 20px; border-radius: 5px; text-align: center;">
    <a href="${testLink}" style="display: inline-block; background-color: #43DAC2; color: #fff; padding: 10px 20px; text-decoration: none; border-radius: 5px; text-align: center;">View Test Result</a>
  </div>
</body>
      </html>
        `,
    };

    // Send the email to the server for processing
    const res = await fetch(
      `${process.env.REACT_APP_APP_DOMAIN}/api/send-personality-test`,
      {
        method: "POST",
        body: JSON.stringify(dataSend),
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
  };

  return (
    <>
      <h1>Personality Test</h1>
      <br></br> <br></br> <br></br>
      <form onSubmit={handleSubmit}>
        <div className="quiz">
          {questions.map((question, index) => (
            <div
              key={question.id}
              ref={(element) => (questionRefs.current[index] = element)}
              className={questionClass(index)}
            >
              <h3>{question.question}</h3>
              <br />
              <ul>
                {question.answer_value.split(";").map((answer) => {
                  const [value, description] = answer.trim().split("-");
                  const radioClassName =
                    question.answer_value.split(";").length === 3
                      ? `radio-btn-${value + 5}`
                      : `radio-btn-${value}`;
                  return (
                    <li key={value}>
                      <label>
                        <input
                          type="radio"
                          className={radioClassName}
                          name={question.id}
                          value={value}
                          onClick={() => setCurrentQuestion(index + 1)}
                        />
                        <label className="label-font-left">{description}</label>
                      </label>
                    </li>
                  );
                })}
              </ul>
              <br />
              <br />
              <hr />
            </div>
          ))}
        </div>
        <div className="text-center">
          <Button
            type="submit"
            style={{ fontSize: "16px", padding: "12px 24px" }}
          >
            Submit <i className="fa fa-paper-plane me-2"></i>
          </Button>
        </div>
      </form>
    </>
  );
}

export default TestCandidate;
