import React, { Fragment,useState,useEffect } from "react";
import {Link} from 'react-router-dom';
import axios from "axios";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";

//Image 
import profile from '../../../../images/profile1.jpg';
import pic1 from '../../../../images/profile/pic1.jpg';
const ApexPieChart = loadable(() =>
	pMinDelay(import("../../Jobick/Home/ApexPieChart"), 1000)
);

  
const CandidateList = () => {
	const pathname = window.location.pathname
  	var idUser = pathname.split("/").pop();
      const token=localStorage.getItem("token");

    const [candidate, setCandidate] = useState();
	const [candidate1, setCandidate1] = useState();
	const [file, setFile] = useState();
	const [name, setName] = useState();
	const [phone, setPhone] = useState();
	const [email, setEmail] = useState();

	const [skills, setSkills] = useState();
	const [education, setEducation] = useState();
	const [score, setScore] = useState();
	const [experience, setExperience] = useState();
	const [language, setLanguage] = useState();
	const [linkedIn, setLinkedin] = useState();
	const [github, setGithub] = useState();
	const [country, setCountry] = useState();
	const [speciality, setSpeciality] = useState();
	const [certif, setCertif] = useState();
	const [status, setStatus] = useState();
	const [jobrole_id, setJobrole_id] = useState();
    const [cvUrl, setCvUrl] = useState('');
    const [showCV, setShowCV] = useState(false);
	useEffect(() => {
		const fetchData = async () => {
			try {
			  const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/flask/candidateDtails`, {
				params: {
				  user_id: idUser
				},
				headers: {
					Authorization: `Bearer ${token}`
				  }
			  });
			  const userData = response.data;
			  setCandidate(userData)
			  setName(userData && userData.name);
			  setPhone(userData && userData.phone);
			  setEmail(userData && userData.email);
			  setSkills(userData && userData.skills);
			  setEducation(userData && userData.education);
			  setScore(userData && userData.score);
			  setExperience(userData && userData.experience);
			  setLanguage(userData && userData.language);
			  setLinkedin(userData && userData.linkedIn);
			  setGithub(userData && userData.github);
			  setCountry(userData && userData.country);
			  setSpeciality(userData && userData.speciality);

			  setCertif(userData && userData.certif);
			  setStatus(userData && userData.status);
			  setJobrole_id(userData && userData.jobrole_id);
			 
			} catch (error) {
			  console.error(error);
			}
		  };
		  fetchData();
	  }, [candidate1]);
    useEffect(() => {
      axios.get(`${process.env.REACT_APP_APP_DOMAIN}/flask/candidateProfil?idUser=` + idUser,
		{
			headers: {
			  Authorization: `Bearer ${token}`
			}
		}
	  )
        .then(res => {
          setCandidate(res.data)
		  setFile(res.data && res.data[0][0]);
		  
        });
    }, [idUser]);
  console.log(candidate)
    useEffect(() => {
      const fetchCv = async () => {
        try {
          const filename = file;
          const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/flask/candidateProfilCv?filename=${filename}`, {
            responseType: 'blob',
			headers: {
				Authorization: `Bearer ${token}`
			  }
          });
          const fileUrl = URL.createObjectURL(response.data);
          setCvUrl(fileUrl);
        } catch (error) {
          console.error(error);
        }
      };
  
      fetchCv();
    }, []);
  
    const handleShowCV = () => {
      setShowCV(true);
    };
  

    return (<>
    <div className="row">
				<div className="col-xl-12">
					<div className="profile-back">
						<img src={profile} alt="" />
						<div className="social-btn">
				
							<Link to={"#"} className="btn btn-primary">Update Profile</Link>
						</div>
					</div>
					<div className="profile-pic d-flex">
						<img src={pic1} alt="" />
						<div className="profile-info2">
							<h2 className="mb-0">{name}</h2>
							<h4>{speciality}</h4>
							<span className="d-block"><i className="fas fa-map-marker-alt me-2"></i>{country}</span>
						</div>
					</div>
				</div>
				<div className="col-xl-3 col-xxl-4 col-lg-6 mt-5">
					<div className="row">
						<div className="col-xl-12">
							<div className="card">
								<div className="card-header border-0">
									<h4 className="fs-20">Skills</h4>
								</div>
								<div className="card-body">
									<div id="pieChart2" className="mb-4">
										<ApexPieChart /> 
									</div>
									<div className="progress default-progress">
										<div className="progress-bar bg-green progress-animated" style={{width: "90%", height:"13px"}} role="progressbar">
											<span className="sr-only">90% Complete</span>
										</div>
									</div>
									<div className="d-flex align-items-end mt-2 pb-4 justify-content-between">
										<span className="fs-14 font-w500">Figma</span>
										<span className="fs-16"><span className="text-black pe-2"></span>90%</span>
									</div>
									<div className="progress default-progress">
										<div className="progress-bar bg-info progress-animated" style={{width: "68%", height:"13px"}} role="progressbar">
											<span className="sr-only">45% Complete</span>
										</div>
									</div>
									<div className="d-flex align-items-end mt-2 pb-4 justify-content-between">
										<span className="fs-14 font-w500">Adobe XD</span>
										<span className="fs-16"><span className="text-black pe-2"></span>68%</span>
									</div>
									<div className="progress default-progress">
										<div className="progress-bar bg-blue progress-animated" style={{width: "85%", height:"13px"}} role="progressbar">
											<span className="sr-only">85% Complete</span>
										</div>
									</div>
									<div className="d-flex align-items-end mt-2 pb-4 justify-content-between">
										<span className="fs-14 font-w500">Photoshop</span>
										<span className="fs-16"><span className="text-black pe-2"></span>85%</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>	
				<div className="col-xl-9 col-xxl-8 col-lg-6 mt-lg-5 mt-0">
					<div className="row">
						<div className="col-xl-8 col-xxl-7">
							<div className="card">
								<div className="card-header border-0 pb-0">
									<h4 className="fs-20 font-w600">About Me</h4>
								</div>
								<div className="card-body">
									<h4 className="fs-20 font-w600 my-4">Contact</h4>
									<div className="d-flex flex-wrap">	
										<div className="d-flex contacts-social mb-3  me-sm-5 me-0 text-start">
											<span className="me-3">
												<i className="fas fa-phone-alt"></i>
											</span>
											<div>
												<span>Phone</span>
												<h5 className="mb-0 fs-18">{phone}</h5>
											</div>
										</div>
										<div className="d-flex contacts-social mb-3 text-start">
											<span className="me-3">
												<i className="fas fa-envelope-open"></i>
											</span>
											<div>
												<span>Email</span>
												<h5 className="mb-0 fs-18">{email}</h5>
											</div>
											
											
										</div>
										<div className="d-flex contacts-social mb-3 text-start">
											<span className="me-3">
											<i className="fas fa-light fa-language"></i>
											</span>
											<div>
												<span>Language</span>
												<h5 className="mb-0 fs-18">{language}</h5>
											</div>
											
											
										</div>
									</div>	
								</div>
								
							</div>
						</div>
						<div className="col-xl-4 col-xxl-5">
							<div className="card">
								<div className="card-header border-0 pb-0">
									<h4 className="fs-20 font-w600">Socials</h4>
								</div>
								<div className="card-body">
									<div>
									
										<Link to={"#"} className="btn text-start d-block mb-3 bg-linkedin light">
											<i 	className="fab fa-linkedin-in scale5 me-4 text-linkedin"></i>{linkedIn}
										</Link>
										<Link to={"#"} className="btn text-start d-block mb-3 bg-dribble light">
										<i className="fab fa-github scale5 me-4 text-github"></i>{github}
										</Link>
										
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-12">
							<div className="card bg-dark">
								<div className="card-body d-flex align-items-center">
									<div>
                                    <h4 className="fs-20 font-w600 mb-2 text-white">Upload your curriculum vitae</h4>
                <p className="text-white mb-0 op6">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut</p>
              </div>
              <div className="upload">
                <button onClick={handleShowCV}><i className="fas fa-arrow-up"></i> Show CV</button>
              </div>
            </div>
          </div>
        </div>
        </div></div>{showCV && cvUrl && (
          <div className="col-xl-12">
            <embed src={cvUrl} type="application/pdf" width="100%" height="600px" />
          </div>
        )}
			</div>	</>
    )
};

export default CandidateList;
