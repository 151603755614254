import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Col, Row, Container, Button, Modal, ModalTitle, ModalBody,Nav,Tab } from 'react-bootstrap';
import { FiEye } from 'react-icons/fi';
import { languageOptions } from "./constants/languageOptions";
import translations from "../AppsMenu/Candidate/mulitlingue";

const ListTestTechCoding = () => {
  const [testList, setTestList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [missingIds, setMissingIds] = useState([]);
  const [showTestsModal, setShowTestsModal] = useState(false);
  const [selectedJobRole, setSelectedJobRole] = useState(null);
  const [noTestMessage, setNoTestMessage] = useState('');
  const token=localStorage.getItem("token");

  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem('selectedLang');
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === 'Français') {
        return translations.fr;
      }
    }
    return translations.en;
  });
  const [jobroleId, setJobroleId] = useState('');
  const getUserCompanyID = async () => {
    const currentUser = JSON.parse(localStorage.getItem('userDetails'));
  const userId = currentUser.localId;
    const responseC = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
    const data = await responseC.json();
    const compId = data[0].companyID;
    return compId;
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const compId = await getUserCompanyID(); // Wait for the Promise to resolve
  
        const responseBoth = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/findTestListByType/Both`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
        const responseCoding = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/findTestListByType/Coding`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
  
        const combinedList = [...responseBoth.data, ...responseCoding.data];
        console.log("combinedList : ", combinedList);
  
        if (compId!=1) {
          const filteredTests = combinedList.filter(test => test.companyid === compId || test.companyid === null);
          setTestList(filteredTests);
        } else {
          setTestList(combinedList);
        }
  
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);
  
  const getLanguageName = (languageId) => {
    const languageOption = languageOptions.find((option) => option.id === languageId);
    return languageOption ? languageOption.name : '';
  };

  const showJobRoleTests = async (jobRole, jobName) => {
    try {
      const response1 = await axios.get(
        `${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getJobRoleCandidates/${jobRole}`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
      );

      const missingTestIds = response1.data;
      localStorage.setItem('jobRoleId', jobRole);

      setMissingIds(missingTestIds);
      setShowTestsModal(true);
      setSelectedJobRole(jobName);
setJobroleId(jobRole)
      if (missingTestIds.length === 0) {
        setNoTestMessage('Le test n\'a pas encore été passé.');
        
      } else {
        setNoTestMessage('');
      }
    } catch (error) {
      console.error("Error fetching jobRole tests:", error);
    }
  };
  const [imageURL, setImageURL] = useState('');
  const downloadImage = (imageUrl) => {
    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'image.png';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
  };
  const loadImage = async (jobroleId, userId, testId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getScreenCodeForTestId/${jobroleId}/${userId}/${testId}`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
      );

      if (response.data && response.data.screenCode) {
        const imageURL = `${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/images/${response.data.screenCode}`;

        setImageURL(imageURL);
        downloadImage(imageURL);

      }
    } catch (error) {
      console.error("Error loading image:", error);
    }
  };
  return (
    <Container style={{ fontFamily: "Poppins" }}>
      <Card>
        <Card.Header>
          <Card.Title>{lang.menu.codingRes}</Card.Title>
        </Card.Header>
      </Card>
      <Row>
        {loading ? (
          <p>{lang.menu.loading}</p>
        ) : (
          <Row>
            {testList.map(test => (
              <Col key={test.id} xs={12} sm={6} md={4}>
                <Card>
                  <Card.Body style={{ fontFamily: "Poppins" }}>
                    <Card.Title style={{ fontSize: "180%",textAlign:"center" }}><strong>{test.JobTitle}</strong></Card.Title>
                    <Card.Text>{test.JobDescription}</Card.Text>
                    <br/>
                    <Row>
                      <Col><span style={{ fontSize: "120%" }}><strong>{lang.menu.experience} :</strong></span> {test.Experience}</Col>
                      <Col><span style={{ fontSize: "120%" }}><strong>{lang.menu.OfferedSalary} :</strong></span> {test.OfferedSalary}</Col>
                    </Row>
                    <Row>
                      <Col><span style={{ fontSize: "120%" }}><strong>{lang.menu.JobType}:</strong></span> {test.JobType}</Col>
                      <Col><span style={{ fontSize: "120%" }}><strong>{lang.menu.country} :</strong></span> {test.Country}</Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer>
                    <Button variant="primary" onClick={() => showJobRoleTests(test.id, test.JobTitle)}>
                      <FiEye />{lang.menu.viewCoding}
                    </Button>
                  </Card.Footer>
                </Card>
              </Col>
            ))}

            <Modal show={showTestsModal} onHide={() => setShowTestsModal(false)} style={{ fontFamily: "Poppins" }}>
              <Modal.Header closeButton><h3>{selectedJobRole}</h3></Modal.Header>
              {noTestMessage && <p style={{ padding: "20px" }}>{noTestMessage}</p>}
              {missingIds.length > 0 && missingIds[0].userId && (

                <ModalBody>

{missingIds.map((missingId, index) => (
  <Card className="text-center">
    <Card.Body className="custom-tab-1">
      <ul className="nav nav-tabs card-body-tabs mb-3">
        <Nav.Item as="li">
          <Nav.Link active href="#">
            {missingId.email}
          </Nav.Link>
        </Nav.Item>
      </ul>

      {missingId.listeTest === null ? (
        <Card.Text>
          <p>{lang.menu.testNoPassed}</p>
        </Card.Text>
      ) : (
        <>
          {missingId.listeTest.map((test, testIndex) => (
            <div key={testIndex}>
              <Card.Text>
                <p><strong>{lang.menu.TestName}: </strong>{test.name}</p>
                <p><strong >{lang.menu.description} : </strong>{test.description}</p>
                <p><strong>{lang.menu.language} : </strong>{getLanguageName(test.LanguageId)}</p>
                <p><strong>{lang.menu.note} : </strong>{test.note[0]}/10</p>
                <Button
                  className="me-2"
                  variant="primary light"
                  onClick={() => {
                    setShowTestsModal(false);

                    if (missingId.listeTest && missingId.listeTest.length > 0) {
                      localStorage.setItem('selectedCandidate', missingId.userId);
                      window.location.href = `/technicalRes/${test.id}`;
                    }
                  }}
                >
                  {lang.menu.viewTestResult}
                </Button>
                <Button
                  className="me-2"
                  variant="success light"
                  onClick={() => loadImage(jobroleId, missingId.userId, test.id)}
                >
                  {lang.menu.viewScreenCode}
                </Button>
              </Card.Text>
              <br />
            </div>
          ))}
      
        </>
      )}
    </Card.Body>
  </Card>
))}


                </ModalBody>
              )}

            </Modal>
          </Row>
        )}
      </Row>
    </Container>
  );
};

export default ListTestTechCoding;

