import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { logout } from '../../../store/actions/AuthActions';
import translations from "../AppsMenu/Candidate/mulitlingue";
import {
    Row,
    Col,
    Card,
    Table,
    Button,
    Dropdown,
    ProgressBar,
    Modal,
} from "react-bootstrap";
import { useDispatch } from 'react-redux';
import { CiLogout } from "react-icons/ci";

const SubscriptionAndInvoices = () => {
    const [subscriptions, setSubscriptions] = useState([]);
    const token=localStorage.getItem("token");

    const [lang, setLang] = useState(() => {
        const storedLang = localStorage.getItem("selectedLang");
        if (storedLang) {
          const parsedLang = JSON.parse(storedLang);
          if (parsedLang.language === "Français") {
            return translations.fr;
          }
        }
        return translations.en;
      });

    useEffect(() => {
        const currentUser = JSON.parse(localStorage.getItem('userDetails'));
        const userId = currentUser.localId;

        const fetchInvoiceData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/Subscription/getUserSubscriptionsWithInvoices/${userId}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
                setSubscriptions(response.data);
                console.log(response.data)
            } catch (error) {
                console.error('Failed to fetch invoice data', error);
            }
        };

        fetchInvoiceData();
    }, []);

    const history = useHistory();
    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(logout());
        history.push('/');
    };

    return (
        <div className="container">
            <Row className="align-items-center mb-4">
                <Col>
                    <h2>{lang.menu.subscriptions}</h2>
                </Col>
                <Col className="text-right">
                    <Button variant="danger" onClick={handleLogout}>
                        <CiLogout size={20} /> {lang.menu.logout}
                    </Button>
                </Col>
            </Row>
            {subscriptions.map((sub, index) => (
                <Card key={index} className="mb-4">
                    <Card.Header>
                        <h5>{lang.menu.subscription} {index + 1}</h5>
                    </Card.Header>
                    <Card.Body>
                        <p><strong>{lang.menu.type}:</strong> {sub.subscription.Type}</p>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>{lang.menu.invoiceId}</th>
                                    <th>{lang.menu.amountDue}</th>
                                    <th>{lang.menu.status}</th>
                                    <th>{lang.menu.periodEnd}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sub.invoices.map((invoice, idx) => (
                                    <tr key={idx}>
                                        <td>{invoice.invoice_id}</td>
                                        <td>{invoice.amount_due}</td>
                                        <td>{invoice.status}</td>
                                        <td>{new Date(invoice.period_end).toLocaleDateString()}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            ))}
        </div>
    );
};

export default SubscriptionAndInvoices;
