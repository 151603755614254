import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import translations from "../../components/AppsMenu/Candidate/mulitlingue";
import "./uploadTest.css";
function UploadTest() {
  const downloadPers=localStorage.getItem('downloadPers');
  const token=localStorage.getItem("token");

  const [showModal, setShowModal] = useState(false);
  const [showInput, setShowInput] = useState(false);

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  
  
  useEffect(() => {
    if (downloadPers === "true") {
      setShowInput(true);
    } else {
      setShowInput(false);
    }
  }, [downloadPers]);
  const [isExcelDownloaded, setIsExcelDownloaded] = useState(false);

  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  useEffect(() => {
    const selectedLang = JSON.parse(localStorage.getItem("selectedLang"));
    if (selectedLang) {
      setLang(selectedLang);
    }
  }, []);
  const location = useLocation();
  let testId = null;

  if (testId === null) {
    testId = new URLSearchParams(location.search).get("testId");
  }
  const [questionsData, setquestionsData] = useState([]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_APP_DOMAIN}/api/upload-test/${testId}`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }})
      .then((res) => {setquestionsData(res.data);
          setTotalPages(Math.floor( res.data.length  / process.env.REACT_APP_PAGINATION) + 1);

      })
      .catch((err) => console.log(err));
  }, [questionsData]);

  const handleDelete = (id) => {
    console.log("id question= " + id);
    axios
      .delete(
        `${process.env.REACT_APP_APP_DOMAIN}/api/upload-test/delete/` + id, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
      )
      .then((res) => {
        console.log(res);
        console.log(id);
      })

      .catch((err) => console.log(err));
  };

  //delete all
  const handleDeleteAll = () => {
    axios
      .delete(
        `${process.env.REACT_APP_APP_DOMAIN}/api/upload-test/deleteAll/${testId}`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
  };

  const [file, setFile] = useState(null);

  const handleChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("question", file);
    formData.append("testId", testId);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_APP_DOMAIN}/api/upload-test/${testId}`,
        formData, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
      );
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  const handleFetchExcelFile = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_APP_DOMAIN}/api/upload-test/${testId}/download-excel-template`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }},
        {
          responseType: "arraybuffer",
        }
      );

      const file = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      localStorage.setItem("downloadPers", true);

      setShowInput(!showInput);
      setIsExcelDownloaded(true);
      setShowModal(true);
    } catch (error) {
      console.log("Error fetching the Excel file:", error);
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  

  return (
    <>
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <form onSubmit={handleSubmit}>
         
            <div>
            

              <input
                className="form-file-input form-control align-items-center mb-4 flex-wrap"
                type="file"
                accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={handleChange}
              />

              <Button type="submit" className="btn btn-primary me-3 btn-sm">
                <i className="fas fa-plus me-2"></i>
                {lang.menu.upload}
                
              </Button>

              <Button
                className="btn btn-danger me-3 btn-sm"
                onClick={handleFetchExcelFile}
            
        >
            {lang.menu.clickdownloadTemplate}
        </Button>
              <Button
                variant="danger"
                className="btn btn-danger me-3 btn-sm"
                onClick={handleDeleteAll}
              >
                <i className="fas fa-trash me-2"></i>
                {lang.menu.DeleteAll}
              </Button>
            </div>
         
            
        
          
        </form>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="table-responsive">
            <table className="table display mb-4 dataTablesCard job-table table-responsive-xl card-table dataTable no-footer">
              <thead>
                <tr>
                  <th>Test</th>

                  <th>{lang.menu.questions}</th>
                  <th>{lang.menu.Feature}</th>
                  <th>{lang.menu.Evaluation}</th>
                  <th>{lang.menu.Coefficient}</th>

                  <th>{lang.menu.actions}</th>
                </tr>
              </thead>
              <tbody>
                {questionsData.length > 0 && questionsData.slice((currentPage - 1)* process.env.REACT_APP_PAGINATION, currentPage * process.env.REACT_APP_PAGINATION).map((tableData, index) => {
                  return (
                    <tr key={index}>
                      <td>{tableData.name}</td>

                      <td>{tableData.question}</td>

                      <th>{tableData.trait}</th>
                      <th>{tableData.answer_value}</th>
                      <td>{tableData.coefficient}</td>

                      <td>
                        <div className="action-buttons ">
                          <Link
                            to={`/upload-test/update/${tableData.Id}`}
                            className="btn btn-secondary light mr-2"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                              className="svg-main-icon"
                            >
                              <g
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                              >
                                <rect x="0" y="0" width="24" height="24"></rect>
                                <path
                                  d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z"
                                  fill="#000000"
                                  fillRule="nonzero"
                                  transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "
                                ></path>
                                <rect
                                  fill="#000000"
                                  opacity="0.3"
                                  x="5"
                                  y="20"
                                  width="15"
                                  height="2"
                                  rx="1"
                                ></rect>
                              </g>
                            </svg>
                          </Link>

                          <Button
                            className="btn btn-danger light"
                            onClick={() => handleDelete(tableData.Id)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                              className="svg-main-icon"
                            >
                              <g
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                              >
                                <rect x="0" y="0" width="24" height="24"></rect>
                                <path
                                  d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                  fill="#000000"
                                  fillRule="nonzero"
                                ></path>
                                <path
                                  d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                  fill="#000000"
                                  opacity="0.3"
                                ></path>
                              </g>
                            </svg>
                          </Button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <div className="sm-mb-0 mb-3">
          <h5 className="mb-0">{currentPage + "/" + totalPages}</h5>
          </div>
          <nav>
          <ul className="pagination pagination-circle">
              <li className="page-item page-indicator">
                <Button
                 style={{width:"100%"}}
                  onClick={()=> {
                    handlePageChange((currentPage === 1) ? currentPage : currentPage - 1);
                  }}
                 className="page-link">
                  {lang.menu.previous}
                </Button>
              </li>
              
              {currentPage > 1 && 
              <li className="page-item">
              <Button 
              className="page-link"
              
                key={currentPage - 1} 
                value={currentPage - 1} 
                onClick={()=>handlePageChange(currentPage - 1)} >
                  {currentPage - 1}
              </Button>
              </li>}

              <li className="page-item active">
          <Button
            className="page-link"
             key={currentPage} 
             value={currentPage} 
              active>
                {currentPage}
                </Button>
                </li>

          {currentPage < totalPages && 
            <li className="page-item ">
            <Button 
              className="page-link"
              key={currentPage + 1} 
              value={currentPage + 1} 
              onClick={()=>handlePageChange(currentPage + 1)}>
                {currentPage + 1}
            </Button>
            </li>}
          
           
              <li className="page-item page-indicator">
                <Button
                className="page-link"
                style={{width:"110%"}}
                onClick={()=> {
                  handlePageChange((currentPage === totalPages) ? currentPage : currentPage + 1);
                }}>
                  {lang.menu.next}
                </Button>
                  
              
              </li>
            </ul>
          </nav>
        </div>
        <Modal show={showModal} onHide={handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Message</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{lang.menu.PersonalitymsgImport}</p>
            <br></br>
            <p style={{ color: "red" }}>{lang.menu.closeExcelFile}</p>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default UploadTest;