import React, { useState, useEffect } from "react";
import Editor from "@monaco-editor/react";

import "./Editor.css";
import { BsWindowSplit } from "react-icons/bs";

const CodeEditorWindow = ({ onChange, language, code, theme, squelette, allowCopyPaste }) => {
  const [value, setValue] = useState(code || "");

  const handleEditorChange = (value) => {
    setValue(value);
    onChange("code", value);
  };

  
  useEffect(() => {
    if(!allowCopyPaste){
      const handlePaste = (event) => {
      event.stopPropagation();
      event.preventDefault();
    };
    
    const handleCopy = (event) => {
      event.stopPropagation();
      event.preventDefault();
    };

    const handleCut = (event) => {
      event.stopPropagation();
      event.preventDefault();
    };

    window.addEventListener('paste', handlePaste, true);
 
    window.addEventListener('copy', handleCopy, true);
    window.addEventListener('cut', handleCut, true);
    return () => {
      
    window.removeEventListener('paste', handlePaste, true);
    window.removeEventListener('copy', handleCopy, true);
    window.removeEventListener('cut', handleCut, true);

    };
  }
  }, [allowCopyPaste]);
  
  
  const editorOptions = {
    mouseWheelZoom: true,
    contextmenu: false,
    pasteAs: {
      enabled : false,
    }, 
    selectionHighlight: false,

  };

 const pasteEv ={
  enabled : false,
 }

  return (
  /*{<div className="overlay rounded-md overflow-hidden w-full h-full shadow-4xl">}*/
  <div>
      <Editor
        height="85vh"
        width={`100%`}
        language={language || "javascript"}
        value={value}
        theme={theme}
        defaultValue={squelette}
        onChange={handleEditorChange}
        options={editorOptions}
        editorDidMount={(editor) => {
          window.editor = editor;
        }} 
        onPaste={pasteEv}
       
      />
    </div>
  );
};

export default CodeEditorWindow;