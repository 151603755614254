import React, { useState, useEffect ,useCallback } from "react";
import axios from "axios";
import { Card, Table, Modal, Button, Form,Dropdown } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";

import { Link } from "react-router-dom";
import { VscRunAll } from "react-icons/vsc";
import { languageOptions } from "./constants/languageOptions";
import { languageOptions1 } from '../Jobs/OneCompiler/constants/languageOptions1';

import translations from "../../components/AppsMenu/Candidate/mulitlingue";
import swal from "sweetalert";

const TopicsByCandidate = () => {
  const [topicsList, setTopicsList] = useState([]);
  const token=localStorage.getItem("token");

  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
          );
          const data = await response.json();
          setTopicsList(
            data.some((permission) => permission.title === "topics")
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);
  const [showAccessDeniedModal, setShowAccessDeniedModal] = useState(true); 

  useEffect(() => {
    if (!topicsList) {
      setShowAccessDeniedModal(true); 
    }
  }, [topicsList]);
  const [selectedLang, setSelectedLang] = useState(null);
  const currentUser = JSON.parse(localStorage.getItem('userDetails'));
    const userId = currentUser.localId;
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  useEffect(() => {
    const selectedLang = JSON.parse(localStorage.getItem("selectedLang"));
    if (selectedLang) {
      setLang(selectedLang);
    }
  }, []);

  const [topics, setTopics] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [input1, setInput1] = useState("");
  const [expectedOutput1, setExpectedOutput1] = useState("");
  const [input2, setInput2] = useState("");
  const [expectedOutput2, setExpectedOutput2] = useState("");
  const [input3, setInput3] = useState("");
  const [expectedOutput3, setExpectedOutput3] = useState("");
  const [LanguageId, setLanguageId] = useState("");
  const [Squelette, setSquelette] = useState("");
  const [ExtentionCode, setExtentionCode] = useState("");
  const [Timer, setTimer] = useState("");
  const [compiler, setCompiler] = useState("");

  const [selectedTopic, setSelectedTopic] = useState(null);
  const [mode, setMode] = useState("create");
  const [durationCoding, setDurationCoding] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem('userDetails'));
        const userId = currentUser.localId;
        const response = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/local/${userId}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
        const data = await response.json();
        setDurationCoding(data[0].DurationCoding);
        console.log("data[0].DurationCoding : ", data[0].DurationCoding)
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
  const getUserCompanyID = async () => {
    const currentUser = JSON.parse(localStorage.getItem('userDetails'));
    const userId = currentUser.localId;
    const responseC = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
    const data = await responseC.json();
    const compId = data[0].companyID;
    return compId;
  }; 
  const handleModalOpen = () => {
    setShowModal(true);
    setSelectedTopic(null);
    setMode("create");
  };
  const fetchTopic = async () => {
    try {
      const compId = await getUserCompanyID();

      const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/topic/getAllTopics`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }});
      if (compId!=1) {
        const filteredTests = response.data.filter(test => test.companyID === compId || test.companyID === null);
        setTopics(filteredTests);
      } else {
        setTopics(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleEditTopic = (topic) => {
    setSelectedTopic(topic);
    setMode("update");

    setShowModal(true);
    setName(topic.name);
    setDescription(topic.description);
    setInput1(topic.input1);
    setExpectedOutput1(topic.expectedOutput1);
    setInput2(topic.input2);
    setExpectedOutput2(topic.expectedOutput2);
    setInput3(topic.input3);
    setExpectedOutput3(topic.expectedOutput3);
    setLanguageId(topic.LanguageId);
    setSquelette(topic.Squelette);
    setExtentionCode(topic.ExtentionCode);
    setTimer(topic.Timer);
    setCompiler(topic.compiler);
  };
  const handleModalClose = () => {
    setShowModal(false);
    setName("");
    setDescription("");
    setInput1("");
    setExpectedOutput1("");
    setInput2("");
    setExpectedOutput2("");
    setInput3("");
    setExpectedOutput3("");
    setLanguageId("");
    setSquelette("");
    setExtentionCode("");
    setTimer("");
    setCompiler("");
  };

  const createOrUpdateTopic = async (newTopic) => {
    try {
      const compId = await getUserCompanyID();
      if (mode === "create") {
        // Create a new topic
        const currentUser = JSON.parse(localStorage.getItem('userDetails'));
        const userId = currentUser.localId;
        newTopic.companyID = compId;
        newTopic.userId = userId;
        console.log("newTopic :", newTopic)
        const response = await axios.post(
          `${process.env.REACT_APP_APP_DOMAIN}/api/topic/createTopic`,
          newTopic, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }}
        );
        const createdTopic = response.data;

        setTopics([...topics, createdTopic]);
        fetchTopic();
      } else if (mode === "update") {
        // Update an existing topic
        const currentUser = JSON.parse(localStorage.getItem('userDetails'));
        const userId = currentUser.localId;
        const response = await axios.put(
          `${process.env.REACT_APP_APP_DOMAIN}/api/topic/updateTopicById/${selectedTopic.id}/${userId}`,
          newTopic, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }}
        );
        const updatedTopic = response.data;
        setTopics((prevTopics) =>
          prevTopics.map((topic) =>
            topic.id === updatedTopic.id ? updatedTopic : topic
          )
        );
        fetchTopic();
      }

      setShowModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const op = languageOptions.find(lang => lang.id === LanguageId);

    if (op) {
      localStorage.setItem("squeletteEx", op.functionSkeleton);
      localStorage.setItem("ExtentionCodeEx", op.output);
      if (mode === "create") {
      setSquelette(op.functionSkeleton);
      setExtentionCode(op.output)
      }
    }
  }, [LanguageId]);



  const handleFormSubmit = (e) => {
    e.preventDefault();

    const newTopic = {
      name,
      description,
      input1,
      expectedOutput1,
      input2,
      expectedOutput2,
      input3,
      expectedOutput3,
      LanguageId,
      Squelette,
      ExtentionCode,
      Timer: Timer || durationCoding,
      compiler,
    };

    createOrUpdateTopic(newTopic);
    setName("");
    setDescription("");
    setInput1("");
    setExpectedOutput1("");
    setInput2("");
    setExpectedOutput2("");
    setInput3("");
    setExpectedOutput3("");
    setLanguageId("");
    setSquelette("");
    setExtentionCode("");
    setTimer("");
    setCompiler("");

    setShowModal(false);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };
  const handleInput1Change = (e) => {
    setInput1(e.target.value);
  };

  const handleExpectedOutput1Change = (e) => {
    setExpectedOutput1(e.target.value);
  };
  const handleInput2Change = (e) => {
    setInput2(e.target.value);
  };

  const handleExpectedOutput2Change = (e) => {
    setExpectedOutput2(e.target.value);
  };
  const handleInput3Change = (e) => {
    setInput3(e.target.value);
  };

  const handleExpectedOutput3Change = (e) => {
    setExpectedOutput3(e.target.value);
  };
  const handleLanguageIdChange = (e) => {
    const languageId = parseInt(e.target.value, 10); 
    setLanguageId(languageId);
    
  
    const selectedLang = languageOptions.find((option) => option.id === languageId);
    
  
    if (selectedLang) {
      localStorage.setItem("squeletteEx", selectedLang.functionSkeleton);
      localStorage.setItem("ExtentionCodeEx", selectedLang.output);

      setSquelette(selectedLang.functionSkeleton);
      setExtentionCode(selectedLang.output)
    }
  };
  
  

  const handleSqueletteChange = (e) => {
    setSquelette(e.target.value);
  };
  const handleExtentionCodeChange = (e) => {
    setExtentionCode(e.target.value);
  };
  const handleTimerChange = (e) => {
    setTimer(e.target.value);
  };

  useEffect(() => {
    fetchTopic();
  }, []);
  useEffect(() => {
    if (!selectedTopic) {
      setName("");
      setDescription("");
      setInput1("");
      setExpectedOutput1("");
      setInput2("");
      setExpectedOutput2("");
      setInput3("");
      setExpectedOutput3("");
      setLanguageId("");
      setSquelette("");
      setExtentionCode("");
      setTimer("");
      setCompiler("");
    }
  }, [selectedTopic]);
  const [loading, setLoading] = useState(false);

  const handleDelete = (topicId) => {
    if (topicId && !loading) {
      setLoading(true);
      const currentUser = JSON.parse(localStorage.getItem('userDetails'));
      const userId = currentUser.localId;
      axios
        .delete(`${process.env.REACT_APP_APP_DOMAIN}/api/topic/deleteTopicById/${topicId}/${userId}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }})
        .then((response) => {
          console.log(response.data);

          if (response.data.message !== "Topic was deleted successfully!") {
            swal(lang.menu.suppImpo, { icon: "error" });
          } else {
            setTopics((prevTests) => prevTests.filter((topic) => topic.id !== topicId));
          }
          setLoading(false);
        })
        .catch((error) => {
          swal(lang.menu.supprimerError, { icon: "error" });
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    const deleteButtons = document.querySelectorAll(".deleteButton");

    deleteButtons.forEach((deleteButton) => {
      const handleClick = () => handleDelete(deleteButton.dataset.id);
      deleteButton.addEventListener("click", handleClick);

      // Clean up the event listener
      return () => {
        deleteButton.removeEventListener("click", handleClick);
      };
    });
  }, []);
  // Function to get language name by languageId
  const getLanguageName = (languageId) => {

    const languageOption = languageOptions.find(
      (option) => option.id === languageId
    );


    return languageOption ? languageOption.name : "";
  };
  // Function to get language name by languageId
  const getLanguageName1 = (languageId) => {
    const languageOption = languageOptions1.find(
      (option) => option.id === languageId
    );
    return languageOption ? languageOption.label : "";
  };
  const handlePassTest = (topicId, TimerNum) => {
    localStorage.setItem("topicId", topicId);
    localStorage.setItem("questionsNbrCoding", TimerNum);
  };





  const [userRole, setuserRole] = useState([]);

  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        const userId = currentUser.localId;
        const response = await fetch(
          `${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`
        );
        const data = await response.json();
        setuserRole(data);
        console.log("user data " + data[0].adminComplier); // Log the correct value here
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);
  useEffect(() => {
    userRole[0]?.recruiterComplier !== null
      ? userRole[0]?.recruiterComplier === "1"
        ? setCompiler("oneCompiler")
        : setCompiler("judge0")
      : userRole[0]?.companyComplier !== null
        ? userRole[0]?.companyComplier === "1"
          ? setCompiler("oneCompiler")
          : setCompiler("judge0")
        : userRole[0]?.adminComplier === "1"
          ? setCompiler("oneCompiler")
          : setCompiler("judge0");
  }, [userRole]);
  useEffect(() => {
    if (compiler) {
      localStorage.setItem("compiler", compiler);
      const compilerStore = localStorage.getItem("compiler");

    }
  }, [compiler]);

  const handleRunTopic = (topic) => {
    // Your redirection logic here
    const redirectionPath =
      topic.compiler === 'oneCompiler'
        ? `topic-for-recruiter2/${topic.id}`
        : `topic-for-recruiter/${topic.id}`;

        

    window.location.href = redirectionPath;
    handlePassTest(topic.id, topic.Timer)

  };
  const getTextAreaValue = () => {
    if (mode === "update") {
      console.log("userRole[0]:",userRole[0])
     
      return compiler === "oneCompiler" ? "oneCompiler" : "judge0";
    }
    return localStorage.getItem("compiler");
  };
  return (
    
    <> {topicsList ? (  
    <>
      <Card>
        <Card.Header>
          <Card.Title>{lang.menu.listTopic}</Card.Title>
          <Button
            variant="primary"
            className="me-3 btn-sm"
            onClick={handleModalOpen}
          >
            <i className="fas fa-plus me-2"></i>{lang.menu.NewTopic}
          </Button>
        </Card.Header>
        <Card.Body>
          <div className="row">
            <div className="col-xl-12">
              <div className="table-responsive">
                <table className="table display mb-4 dataTablesCard job-table table-responsive-xl card-table dataTable no-footer">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>{lang.menu.name}</th>
                      <th>{lang.menu.description}</th>
                      <th>{lang.menu.input}</th>
                      <th>{lang.menu.expectedOutPut}</th>
                      <th>{lang.menu.language}</th>
                      <th>{lang.menu.squelette}</th>
                      <th>{lang.menu.codeExten}</th>
                      <th>{lang.menu.timer}</th>

                      <th>{lang.menu.actions}</th>
                    </tr>
                  </thead>
                  <tbody >
                    {topics.map((topic) => (
                      <tr  key={topic.id}>
                        <td style={{ textAlign: "center" }}>{topic.id}</td>
                        <td style={{ textAlign: "center" }}>{topic.name}</td>
                        <td style={{ textAlign: "center" }}>{topic.description}</td>
                        <td style={{ textAlign: "center" }}>{topic.input1}</td>
                        <td style={{ textAlign: "center" }}>{topic.expectedOutput1}</td>
                        <td style={{ textAlign: "center" }}>
                          {topic.compiler === "oneCompiler"
                            ? getLanguageName1(topic.LanguageId)
                            : getLanguageName(topic.LanguageId)}
                        </td>
                        <td style={{ textAlign: "center" }}>{topic.Squelette}</td>
                        <td style={{ textAlign: "center" }}>{topic.ExtentionCode}</td>
                        <td style={{ textAlign: "center" }}>{topic.Timer} s</td>
                        <td>
                          <div className="action-buttons ">
                            <Dropdown>
                              <Dropdown.Toggle
                                className="nav-link i-false c-pointer"
                                as="a"
                                data-toggle="dropdown"
                                aria-expanded="false"
                                style={{ textAlign: "center" }}
                              ><i className="flaticon-032-ellipsis"></i></Dropdown.Toggle>
                              <Dropdown.Menu className="profile-dropdown">
                                <DropdownItem onClick={() => handleEditTopic(topic)}>
                                  <Button
                                    className="btn btn-secondary light mr-2" title="Edit Topic"
                                    
                                  >
                                    <i className="fas fa-pencil-alt"></i>
                                  </Button>                
                                   <span title="Edit" > {lang.menu.edit}</span>

                                </DropdownItem>
                                <DropdownItem onClick={() => handleDelete(topic.id)}>
                                  <Button

                          className="btn btn-danger light shadow btn-xs sharp me-1"
                          title="Delete Topic"
                                                            
                                  >
                                    <i className="fa fa-trash"></i>
                                  </Button>  
                                   <span title="Delete" > {lang.menu.delete}</span>

                                </DropdownItem>
                                <DropdownItem onClick={() => handleRunTopic(topic)}>
                                  <Button
                                    
                                    className="btn btn-warning light mr-2"
                                    title="Run Topic"
                                   
                                  >
                                    <VscRunAll size={16} /> 
                                  </Button> 
                                  <span title="Run Topic"  > {lang.menu.runTopic}</span>
                                </DropdownItem>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>

      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header>
          <Modal.Title>{mode === "update" ? lang.menu.UpdateTopic :lang.menu.CreateNewTopic }</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setShowModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleFormSubmit}>
            <Form.Group controlId="formCategoryName">
              <Form.Label>{lang.menu.name}</Form.Label>
           
                    
              <Form.Control
                type="text"
                placeholder={`${lang.menu.enterM} ${lang.menu.name}`}
                value={name}
                onChange={handleNameChange}
                required
              />
            </Form.Group>
            <br/>
            <Form.Group controlId="formCategoryDescription">
              <Form.Label> {lang.menu.description}</Form.Label>
              <Form.Control
                as="textarea"
                placeholder={`${lang.menu.enterF} ${lang.menu.description}`}
                value={description}
                onChange={handleDescriptionChange}
                rows={3}
                required
              />
            </Form.Group>
            <br/>
            <Form.Group controlId="formCategoryDescription">
              <Form.Label> {lang.menu.input} 1 </Form.Label>
              <div className="tooltip-container">
                        <button
                          id="btn"
                          type="button"
                          className="informationScore">i
                        </button>
                        <div className="tooltip-message">
                        <p>Varchar : "texte",</p>
                        <p> Int : 123, </p>
                        <p> Float : 1.23, </p>
                        <p>Booléen : true/false,</p>
                        <p> Date : YYYY-MM-DD, </p>
                        <p> Liste : ["item1", "item2"]</p> 
              </div>
                      </div>
              <Form.Control
                as="textarea"
                placeholder={`${lang.menu.enterF} ${lang.menu.input} 1`}
                value={input1}
                onChange={handleInput1Change}
                rows={3}
                required
              />
            </Form.Group>
            <br/>
            <Form.Group controlId="formCategoryDescription">
              <Form.Label> {lang.menu.expectedOutPut} 1 </Form.Label>
              <div className="tooltip-container">
                        <button
                          id="btn"
                          type="button"
                          className="informationScore">i
                        </button>
                        <div className="tooltip-message">
                        <p>Varchar : texte,</p>
                        <p> Int : 123, </p>
                        <p> Float : 1.23, </p>
                        <p>Booléen : true/false,</p>
                        <p> Date : YYYY-MM-DD, </p>
                        <p> Liste : ["item1", "item2"]</p> 
              </div>
                      </div>
              <Form.Control
                as="textarea"
                placeholder={`${lang.menu.enterM} ${lang.menu.expectedOutPut} 1`}
                value={expectedOutput1}
                onChange={handleExpectedOutput1Change}
                rows={3}
                required
              />
            </Form.Group>
            <br/>
            <Form.Group controlId="formCategoryDescription">
              <Form.Label> {lang.menu.input} 2 </Form.Label>
              <div className="tooltip-container">
                        <button
                          id="btn"
                          type="button"
                          className="informationScore">i
                        </button>
                        <div className="tooltip-message">
                        <p>Varchar : "texte",</p>
                        <p> Int : 123, </p>
                        <p> Float : 1.23, </p>
                        <p>Booléen : true/false,</p>
                        <p> Date : YYYY-MM-DD, </p>
                        <p> Liste : ["item1", "item2"]</p> 
              </div>
                      </div>
              <Form.Control
                as="textarea"
                placeholder={`${lang.menu.enterF} ${lang.menu.input} 2`}
                value={input2}
                onChange={handleInput2Change}
                rows={3}
                required
              />
            </Form.Group>
            <br/>
            <Form.Group controlId="formCategoryDescription">
              <Form.Label> {lang.menu.expectedOutPut} 2 </Form.Label>
              <div className="tooltip-container">
                        <button
                          id="btn"
                          type="button"
                          className="informationScore">i
                        </button>
                        <div className="tooltip-message">
                        <p>Varchar : texte,</p>
                        <p> Int : 123, </p>
                        <p> Float : 1.23, </p>
                        <p>Booléen : true/false,</p>
                        <p> Date : YYYY-MM-DD, </p>
                        <p> Liste : ["item1", "item2"]</p> 
              </div>
                      </div>
              <Form.Control
                as="textarea"
                placeholder={`${lang.menu.enterM} ${lang.menu.expectedOutPut} 2`}
                value={expectedOutput2}
                onChange={handleExpectedOutput2Change}
                rows={3}
                required
              />
            </Form.Group>
            <br/>
            <Form.Group controlId="formCategoryDescription">
              <Form.Label> {lang.menu.input} 3 </Form.Label>
              <div className="tooltip-container">
                        <button
                          id="btn"
                          type="button"
                          className="informationScore">i
                        </button>
                        <div className="tooltip-message">
                        <p>Varchar : "texte",</p>
                        <p> Int : 123, </p>
                        <p> Float : 1.23, </p>
                        <p>Booléen : true/false,</p>
                        <p> Date : YYYY-MM-DD, </p>
                        <p> Liste : ["item1", "item2"]</p>
 
              </div>
                      </div>
              <Form.Control
                as="textarea"
                placeholder={`${lang.menu.enterF} ${lang.menu.input} 3`}
                value={input3}
                onChange={handleInput3Change}
                rows={3}
                required
              />
            </Form.Group>
            <br/>
            <Form.Group controlId="formCategoryDescription">
              <Form.Label> {lang.menu.expectedOutPut} 3 </Form.Label>
              <div className="tooltip-container">
                        <button
                          id="btn"
                          type="button"
                          className="informationScore">i
                        </button>
                        <div className="tooltip-message">
                        <p>Varchar : texte,</p>
                        <p> Int : 123, </p>
                        <p> Float : 1.23, </p>
                        <p>Booléen : true/false,</p>
                        <p> Date : YYYY-MM-DD, </p>
                        <p> Liste : ["item1", "item2"]</p> 
              </div>
                      </div>
              <Form.Control
                as="textarea"
                placeholder={`${lang.menu.enterM} ${lang.menu.expectedOutPut} 3`}
                value={expectedOutput3}
                onChange={handleExpectedOutput3Change}
                rows={3}
                required
              />
            </Form.Group>
            <br/>
            <Form.Group controlId="formCategoryDescription">
              <Form.Label> compiler </Form.Label>
              <Form.Control as="textarea" 
      value={getTextAreaValue()}
      rows={3} readOnly />
            </Form.Group>
            {mode === 'update' ? (
  <Form.Group controlId="formCategoryDescription">
    <Form.Label>{lang.menu.language}</Form.Label>
    <Form.Control
      as="select"
      value={LanguageId}
      onChange={handleLanguageIdChange}
      required
    >
      <option value="">Select Language</option>
      {console.log("userRole[0]?.compiler :",compiler )}
      {compiler === "oneCompiler"
        ? languageOptions1.map((option) => (
          <option key={option.id} value={option.id}>
            {option.label}
          </option>
        ))
        : languageOptions.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
    </Form.Control>
  </Form.Group>
) : (
  <Form.Group controlId="formCategoryDescription">
    <Form.Label>{lang.menu.language}</Form.Label>
    <Form.Control
      as="select"
      value={LanguageId}
      onChange={handleLanguageIdChange}
      required
    >
      <option value="">Select Language</option>
      {localStorage.getItem("compiler") === "oneCompiler"
        ? languageOptions1.map((option) => (
          <option key={option.id} value={option.id}>
            {option.label}
          </option>
        ))
        : languageOptions.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
    </Form.Control>
  </Form.Group>
)}

            <br/>
            <Form.Group controlId="formCategoryDescription">
              <Form.Label> {lang.menu.squelette} </Form.Label>
              <Form.Control
                as="textarea"
                placeholder={`${lang.menu.enterM} ${lang.menu.squelette}`}
                value={Squelette}
                onChange={handleSqueletteChange}
                rows={3}
                required
              />
            </Form.Group>
            <br/>
            <Form.Group controlId="formCategoryDescription">
              <Form.Label> {lang.menu.codeExten} </Form.Label>
              <Form.Control
                as="textarea"
                placeholder={`${lang.menu.enterM} ${lang.menu.codeExten}`}
                value={ExtentionCode}
                onChange={handleExtentionCodeChange}
                rows={3}
                required
              />
            </Form.Group>
            <br/>
            <Form.Group controlId="formCategoryDescription">
              <Form.Label>{lang.menu.timer}</Form.Label>
              <Form.Control
                type="number"
                value={Timer}
                onChange={handleTimerChange}
                rows={3}
                placeholder={`${lang.menu.tempPerDefault} ${durationCoding} ${lang.menu.seconds}`}
              />
            </Form.Group>

            <br />
            <Button variant="primary" type="submit">
              {selectedTopic ? lang.menu.UpdateTopic : lang.menu.CreateNewTopic}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>) : (
             <Modal show={showAccessDeniedModal} >
             <Modal.Header>
               <Modal.Title>{lang.menu.accessDenied}</Modal.Title>
             </Modal.Header>
             <Modal.Body>
 {lang.menu.YoudontHavePerm}
             </Modal.Body>
             <Modal.Footer>
         
             </Modal.Footer>
           </Modal>
          )}</>
  );
};

export default TopicsByCandidate;