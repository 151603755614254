
import React, { Fragment, useState, useEffect } from "react";
import axios from 'axios';
import translations from "../AppsMenu/Candidate/mulitlingue";
import PageTitle from "../../layouts/PageTitle";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import { useHistory } from "react-router-dom";
import swal from "sweetalert";

function UpdateTestPsychotechnique() {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [durationQuestionQCM, setDurationQuestionQCM] = useState("");
  const [test, setTest] = useState("");
  const token=localStorage.getItem("token");

  const pathname = window.location.pathname;
  const idtest = pathname.split("/").pop();
  const history = useHistory();
  const [lang, setLang] = useState(() => {
		const storedLang = localStorage.getItem('selectedLang');
		if (storedLang) {
		  const parsedLang = JSON.parse(storedLang);
		  if (parsedLang.language === 'Français') {
			return translations.fr;
		  }
		}
		return translations.en;
	  });
    useEffect(() => {
  
      const selectedLang = JSON.parse(localStorage.getItem('selectedLang'));
      if (selectedLang) {
        setLang(selectedLang);
      }
    }, []);
    useEffect(() => {
      const fetchData = async () => {
        try {
        
          const response = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/testPsycho/getTestById/${idtest}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }});
          const data = await response.json();
          console.log("data :",data)

          setTest(data);
          const selectedCategoriesOptions = data.categories.map(category => ({
            value: category.id,
            label: category.name,
            questionPerCat: category.questionPerCat
          }));
          setSelectedCategories(selectedCategoriesOptions);
        } catch (error) {
          console.error(error);
        }
      };
    
      fetchData();
    }, []);
    useEffect(() => {
      const fetchData = async () => {
        try {
          const currentUser = JSON.parse(localStorage.getItem('userDetails'));
          const userId = currentUser.localId;
          const response = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/local/${userId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }});
          const data = await response.json();
          setDurationQuestionQCM(data[0].DurationQuestionQCM);
        } catch (error) {
          console.error(error);
        }
      };
    
      fetchData();
    }, []);
    

  const [minQuestionsPerCategory, setMinQuestionsPerCategory] = useState(0); // Initialize with 0
 /* useEffect(() => {
    const fetchMinQuestionsPerCategory = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_APP_DOMAIN}/api/question1/minQuestionsPerCategory`
        );
        setMinQuestionsPerCategory(response.data.minQuestions);
      } catch (error) {
        console.log(error);
      }
    };

    fetchMinQuestionsPerCategory();
  }, []);
  */
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/categories/getAllCategoriesQuest`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
        setCategories(response.data);
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchCategories();
  }, []);
  const handleCategoryChange = (selectedOptions) => {
    setSelectedCategories(selectedOptions);
  };
  console.log(selectedCategories)
  
  
  const getUserCompanyID = async () => {
    const currentUser = JSON.parse(localStorage.getItem('userDetails'));
  const userId = currentUser.localId;
    const responseC = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
    const data = await responseC.json();
    const compId = data[0].companyID;
    return compId;
  };
  const handleSubmit = async () => {
    try {
      const testName = document.querySelector("#name").value; 
      const testDescription = document.querySelector("#description").value; 
      const questionNbre = document.querySelector("#questionNbre").value;
      let timePerQuestion = document.querySelector("#timePerQuestion").value; 
      const categoryIds = selectedCategories.map(category => category.value); 
      if (!testName.trim() || !testDescription.trim() || !questionNbre.trim()) {
        swal(lang.menu.requiredFields, {
          icon: "error",
        });
        return;
      }
      if (categoryIds.length === 0) {
        console.error("Please select at least one category.");
        return;
      }
        if (!timePerQuestion) {
        timePerQuestion = durationQuestionQCM;
      }
      if(questionNbre > minQuestionsPerCategory){
        swal(lang.menu.questionNbreCategoryExceeded, {
          icon: "error",
        });
        return;
      }
	      if(questionNbre <1){
        swal(`The nbre of questions must be positive`, {
          icon: "error",
        });
        return;
      }   
	      if(timePerQuestion <1){
        swal(`The time questions must be positive`, {
          icon: "error",
        });
        return;
      }  
      const currentUser = JSON.parse(localStorage.getItem('userDetails'));
      const userId = currentUser.localId;
  
      const response = await axios.put(`${process.env.REACT_APP_APP_DOMAIN}/api/testPsycho/updateTestPsycho/${idtest}/${userId}`, {
        name: testName,
        description: testDescription,
        questionNbre: questionNbre,
        nbre_question_globale:questionNbre*categoryIds.length,
        timePerQuestion: timePerQuestion,
        categoryId: categoryIds,
        userId:userId
      }, 
      {
        headers: {
        Authorization: `Bearer ${token}`,
        }});  
      if (response.data==="Le test de psycho est déjà lié à un candidat."){
        swal(lang.menu.updateError, {
          icon: "error",
        });

      }else{
        swal("Test updated successfully", {
          icon: "success",
        });
  
        history.push("/TestPsychotechniqueList");
      }
    
    } catch (error) {
      console.error(error);
      swal('Failed to submit the form', {
        icon: "error",
      });
    }
  };
  
  // find the min
  useEffect(()=>{
    const findMin = ()=>{
      var min = 0;
      selectedCategories.forEach((selectedCategory)=>{
        if(selectedCategory.questionPerCat && (min === 0 || selectedCategory.questionPerCat <  min)){
          min = selectedCategory.questionPerCat;
        }
      })
      return min;
    }

    setMinQuestionsPerCategory(findMin());
  },[selectedCategories])

  return (
    <>
     {test && (
    <div >
      <div className="mb-3">
        <label htmlFor="name" className="form-label">{lang.menu.name}</label>
        <input type="text" className="form-control" placeholder="Name" id="name" defaultValue={test.test_name} />
      </div>
      <div className="mb-3">
        <label htmlFor="description" className="form-label">{lang.menu.description}</label>
        <textarea className="form-control" rows="3" placeholder="Description" id="description" defaultValue={test.description}></textarea>
      </div>
      <div className="mb-3">
        <label htmlFor="questionNbre" className="form-label">{lang.menu.nbrQuestion}</label>
        <input type="number" className="form-control" placeholder="Question Nbre" id="questionNbre" defaultValue={test.test_questionNbre}/>
        <p className="form-text" style={{color:"red"}}>{lang.menu.nbCat} {minQuestionsPerCategory}</p>
     </div>
     <div className="mb-3">
  <label htmlFor="timePerQuestion" className="form-label">{lang.menu.timePerQuestion}</label>
  <input type="number" className="form-control" placeholder={`Default time per question is ${durationQuestionQCM} Seconds`} id="timePerQuestion" defaultValue={test.timePerQuestion} />
</div>

      <div className="mb-3">
  <label htmlFor="categories" className="form-label">Categories</label>
  <Select
    options={categories.map(category => ({ value: category.id, label: category.name, questionPerCat: category.questionPerCat }))}
    isMulti
    onChange={handleCategoryChange}
    value={selectedCategories}
  />
</div>

<div className="col-12">
        <button onClick={handleSubmit} className="btn btn-outline-primary btn-rounded">{lang.menu.submit} </button>
      </div>
  <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
    </div>
     )}</>
  );
};

export default UpdateTestPsychotechnique;

