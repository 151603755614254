import React, { useState, useEffect } from "react";

function Volunteerings({ lang, toggleCategory, expandedCategories }) {
  const [volunteerings, setVolunteerings] = useState([
    { organization: "", taskDescription: "" },
  ]);

  useEffect(() => {
    const storedVolunteerings = localStorage.getItem("Volunteerings");
    if (storedVolunteerings !== "undefined" && storedVolunteerings != null) {
      setVolunteerings(JSON.parse(storedVolunteerings));
      console.log(storedVolunteerings);
    }
  }, []);

  useEffect(() => {
    if (expandedCategories.volunteerings) {
      localStorage.setItem("Volunteerings", JSON.stringify(volunteerings));
    }
  }, [expandedCategories.volunteerings, volunteerings]);

  const handleAddVolunteering = () => {
    setVolunteerings([
      ...volunteerings,
      { organization: "", taskDescription: "" },
    ]);
  };

  const handleRemoveVolunteering = (index) => {
    const newVolunteerings = [...volunteerings];
    newVolunteerings.splice(index, 1);
    setVolunteerings(newVolunteerings);
  };

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const maxCharacterLimits = {
      organization: 100,
      taskDescription: 1000,
    };

    if (name in maxCharacterLimits && value.length > maxCharacterLimits[name]) {
      return; // Don't update state if character limit exceeded
    }
    const newVolunteerings = [...volunteerings];
    newVolunteerings[index][name] = value;
    setVolunteerings(newVolunteerings);
  };
  const handleAutoResize = (event) => {
    const textarea = event.target;
    const maxHeight = 200; // Maximum height in pixels

    textarea.style.height = "auto"; // Reset the height
    if (textarea.scrollHeight > maxHeight) {
      textarea.style.height = `${maxHeight}px`;
      textarea.style.overflowY = "auto"; // Add scrollbar if content exceeds max height
    } else {
      textarea.style.height = `${textarea.scrollHeight}px`; // Set the height to the scrollHeight
      textarea.style.overflowY = "hidden"; // Hide scrollbar if content is within max height
    }
  };


  return (
    <div className="category">
      <h2
        className="single-line"
        onClick={() => toggleCategory("volunteerings")}
      >
        {lang.menu.Volunteerings}
      </h2>
      {expandedCategories.volunteerings && (
        <div className="category-content">
          {volunteerings?.map((volunteering, index) => (
            <center key={index} className="experience-block">
              <svg
                onClick={() => handleRemoveVolunteering(index)}
                height="30px"
                width="30px"
                xmlns="http://www.w3.org/2000/svg"
                fill="#74188D"
                viewBox="0 0 24 24"
                stroke="#74188D"
                className="remove-icon"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
              <input
                style={{ marginTop: "30px", marginLeft: "45px" }}
                className="inputOnboard"
                type="text"
                name="organization"
                value={volunteering.organization}
                onChange={(e) => handleChange(index, e)}
                placeholder={lang.menu.Organization}
              />
              <textarea
                style={{ marginTop: "3px", marginLeft: "45px" }}
                className="inputOnboard"
                name="taskDescription"
                value={volunteering.taskDescription}
                onChange={(e) => {
                  handleChange(index, e);
                  handleAutoResize(e);
                }}
                placeholder={lang.menu.td}
              />
            </center>
          ))}
          <center>
            <svg
              cursor="pointer"
              width="40px"
              height="40px"
              onClick={handleAddVolunteering}
              stroke="#74188D"
              fill="#74188D"
              strokeWidth="0"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              className="svg-add-circle-outline"
            >
              <path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
            </svg>
          </center>
        </div>
      )}
    </div>
  );
}

export default Volunteerings;
