import React, { useState, useEffect } from "react";
import { countries } from "countries-list";
import translations from "../../../components/AppsMenu/Candidate/mulitlingue";

const countryNames = Object.values(countries).map((country) => country.name);

function Educ({user,updateUser}) {
  const [educations, setEducations] = useState([
    { degree: "", institution: "", location: "", startDate: "", endDate: "" },
  ]);

  useEffect(() => {

    
    let storedData 
    if (user.education) {

      storedData = user.education;
      while (typeof(storedData) === "string") {
      storedData = JSON.parse(storedData);

    }
    }
      if (storedData) {
    
        setEducations(storedData);
      }
    
   
  }, []);
  useEffect(() => {
    updateUser(educations);
  }, [educations]);

  const [lang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });

  const handleAddEducation = () => {
    setEducations([
      ...educations,
      { degree: "", institution: "", location: "", startDate: "", endDate: "" },
    ]);
  };

  const handleRemoveEducation = (index) => {
    const newEducations = [...educations];
    newEducations.splice(index, 1);
    setEducations(newEducations);
  };

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const newEducations = [...educations];
    newEducations[index][name] = value;
    setEducations(newEducations);
  };

  return (
    <div>
        <div className="basic-form">
        <form onSubmit={(e) => e.preventDefault()} className="p-3">
          {educations.map((education, index) => (
            <div key={index} style={{border:"2px solid #86E6D7" ,marginBottom:"30px", boxShadow:"rgba(0, 0, 0, 0.35) 0px 1px 5px"}}>
              <svg
                onClick={() => handleRemoveEducation(index)}
                height="30px"
                width="30px"
                xmlns="http://www.w3.org/2000/svg"
                fill="#74188D"
                viewBox="0 0 24 24"
                stroke="#74188D"
                style={{
                    position: "relative",
    top: 0,
    right: -1000,
    cursor: "pointer",
    margin: "10px",
                }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
                </svg>
                <div style={{display: "flex", "flex-direction": "row"}}>
              <div className="form-group mb-3 col-md-6 my-form-padding">
                   <label className="my-label">{lang.menu.Degree}</label>
                   <input
                                    className="form-control"

                style={{ marginTop: "3px" }}
                type="text"
                name="degree"
                value={education.degree}
                onChange={(e) => handleChange(index, e)}
                placeholder={lang.menu.Degree}
              />
                 </div>
                 <div className="form-group mb-3 col-md-6 my-form-padding">
                   <label className="my-label">{lang.menu.EdIn}</label>
                   <input
                      className="form-control"
                      style={{ marginTop: "3px" }}
                type="text"
                name="institution"
                value={education.institution}
                onChange={(e) => handleChange(index, e)}
                placeholder={lang.menu.EdIn}
              />
                 </div>
                </div>
                <div style={{display: "flex", "flex-direction": "row"}}>
                <div className="form-group mb-3 col-md-6 my-form-padding">
                   <label className="my-label">{lang.menu.location}</label>
                   <select
                style={{ marginTop: "3px" }}
                className="form-control"
                name="location"
                value={education.location}
                onChange={(e) => handleChange(index, e)}
              >
                <option value="">{lang.menu.sac}</option>
                {countryNames.map((country, i) => (
                  <option key={i} value={country}>
                    {country}
                  </option>
                ))}
              </select>
                 </div>


                 <div style={{display: "flex", "flex-direction": "row"}}>
                 <div className="form-group mb-3 col-md-6 my-form-padding">
                   <label className="my-label">{lang.menu.Started}</label>
                   <input
                      className="form-control"
                      type="month"
                name="startDate"
                value={education.startDate}
                onChange={(e) => handleChange(index, e)}
              />
                 </div>
                
            

                 <div className="form-group mb-3 col-md-6 my-form-padding">
                   <label className="my-label">{lang.menu.Ended}</label>
                   <input
                      className="form-control"
                      type="month"
                name="endDate"
                value={education.endDate}
                onChange={(e) => handleChange(index, e)}
              />
                 </div>
                 </div>
             


                
                 </div>
              
            </div>
          ))}
          <center>
            <svg
              cursor="pointer"
              width="40px"
              height="40px"
              onClick={handleAddEducation}
              stroke="#74188D"
              fill="#74188D"
              strokeWidth="0"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              className="svg-add-circle-outline"
            >
              <path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
            </svg>
          </center>
          </form>
        </div>
      
    </div>
  );
}

export default Educ;
