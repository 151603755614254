import React, { useState, useEffect } from "react";
import { countries } from "countries-list";

const countryNames = Object.values(countries).map((country) => country.name);

function Education({ lang, refreshData, toggleCategory, expandedCategories }) {
  const [educations, setEducations] = useState([
    { degree: "", institution: "", location: "", startDate: "", endDate: "" },
  ]);

  useEffect(() => {
    const storedEducations = localStorage.getItem("Education");
    if (storedEducations) {
      setEducations(JSON.parse(storedEducations));
    }
  }, [refreshData]);

  useEffect(() => {
    if (expandedCategories.education) {
      localStorage.setItem("Education", JSON.stringify(educations));
    }
  }, [expandedCategories.education, educations]);

  const handleAddEducation = () => {
    setEducations([
      ...educations,
      { degree: "", institution: "", location: "", startDate: "", endDate: "" },
    ]);
  };

  const handleRemoveEducation = (index) => {
    const newEducations = [...educations];
    newEducations.splice(index, 1);
    setEducations(newEducations);
  };

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const maxCharacterLimits = {
      degree: 100,
      institution: 100,
    };
  
    if (name in maxCharacterLimits && value.length > maxCharacterLimits[name]) {
      return; // Don't update state if character limit exceeded
    }
    const newEducations = [...educations];
    newEducations[index][name] = value;
    setEducations(newEducations);
  };

  return (
    <div className="category">
      <h2 className='single-line' onClick={() => toggleCategory("education")}>{lang.menu.Education}</h2>
      {expandedCategories.education && (
        <div className="category-content">
          {educations.map((education, index) => (

<center key={index} className="experience-block">
              <svg
                onClick={() => handleRemoveEducation(index)}
                height="30px"
                width="30px"
                xmlns="http://www.w3.org/2000/svg"
                fill="#74188D"
                viewBox="0 0 24 24"
                stroke="#74188D"
                className="remove-icon"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>

<div style={{ display: "flex","flex-direction": "row"}}>
<div style={{ width:"50%"}}>

              <input
                className="inputOnboard"
                style={{ marginTop: "30px" }}
                type="text"
                name="institution"
                value={education.institution}
                onChange={(e) => handleChange(index, e)}
                placeholder={lang.menu.EdIn}
              />
</div>
<div style={{ width:"50%"}}>
              <select
                style={{ marginTop: "30px" }}
                className="inputOnboard"
                name="location"
                value={education.location}
                onChange={(e) => handleChange(index, e)}
              >
                <option value="">{lang.menu.sac}</option>
                {countryNames.map((country, i) => (
                  <option key={i} value={country}
                  disabled={country === lang.menu.sac}
>
                    {country}
                  </option>
                ))}
              </select>
</div>
</div>


<div style={{ display: "flex","flex-direction": "row" ,paddingLeft: '10px',paddingRight: '10px'}}>
<div style={{ width:"50%"}}>
<input
                className="inputOnboard"
                style={{ marginTop: "3px" }}
                type="text"
                name="degree"
                value={education.degree}
                onChange={(e) => handleChange(index, e)}
                placeholder={lang.menu.Degree}
              />
</div>
<h4 style={{marginTop:"10px"}}>{lang.menu.Started}</h4>
<div style={{ width:"50%"}}>
              <input
                className='inputOnboard'
                type="month"
                name="startDate"
                value={education.startDate}
                onChange={(e) => handleChange(index, e)}
              />
</div >
<h4 style={{marginTop:"10px"}}>{lang.menu.Ended}</h4>
<div style={{ width:"50%"}}>
              <input
                className='inputOnboard'
                type="month"
                name="endDate"
                value={education.endDate}
                onChange={(e) => handleChange(index, e)}
              />
            </div>
</div>

</center>




            
          ))}
          <center>
            <svg
              cursor="pointer"
              width="40px"
              height="40px"
              onClick={handleAddEducation}
              stroke="#74188D"
              fill="#74188D"
              strokeWidth="0"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              className="svg-add-circle-outline"
            >
              <path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
            </svg>
          </center>
        </div>
      )}
    </div>
  );
}

export default Education;
