import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Button, Form ,Badge,Modal,Card} from "react-bootstrap";
import translations from"../AppsMenu/Candidate/mulitlingue"
import Swal from "sweetalert2"; 
import axios from "axios";
import "./PostJob.css";
import { AiOutlinePieChart } from "react-icons/ai";

function SendJobEmail() {
  const [sendPersonalityTest, setSendPersonalityTest] = useState([]);
  const [quotaMax, setQuotaMax] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [userConnect, setUserConnect] = useState("");
  const token=localStorage.getItem("token");

  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        setUserConnect(currentUser)
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
          );
          const data = await response.json();
          setSendPersonalityTest(
            data.some(
              (permission) =>
                permission.title === "send-personality-test-to-candidate"
            )
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);
  const [showAccessDeniedModal, setShowAccessDeniedModal] = useState(true); 

  useEffect(() => {
    if (!sendPersonalityTest) {
      setShowAccessDeniedModal(true); 
    }
  }, [sendPersonalityTest]);
  const [selectedLang, setSelectedLang] = useState(null);
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem('selectedLang');
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === 'Français') {
        return translations.fr;
      }
    }
    return translations.en;
  });
  const [jobRoleName, setJobRoleName] = useState(lang.menu.jobRole);
  const [skillsForJob, setSkillsForJob] = useState("");
  const [companyID, setCompanyID] = useState("");
  const [isDisable, setIsDisable] = useState("");
  const [sendingEmails, setSendingEmails] = useState(false);

  const [jobRoles, setJobRoles] = useState([]);
  const [jobRolesList, setJobRolesList] = useState([]);
  const [jobRolesListName, setJobRolesListName] = useState([]);
  const [jobData, setJobData] = useState({
    testsToDisplay: [],
    personality: [],
    technical: [],
    psycho: [],
  });
  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem('userDetails'));
    const userId = currentUser.localId;
    
    const getUserCompanyID = async () => {
      const responseC = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
      const data = await responseC.json();
      const compId = data[0].companyID;
      return compId;
    };

    getUserCompanyID().then((compId) => {
      axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getAll`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }})
        .then(async(response) => {
          setCompanyID(compId)
          if (compId!=1) {
            const filteredJobs = response.data.filter(job => job.companyid === compId);
            setJobRolesList(filteredJobs);
            const names = filteredJobs.map(job => job.JobTitle);
            console.log("names : ",names)
            setJobRolesListName(names)
            const subscriptionResponse = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/getSubscriptionFeaturesByCompanyId/${compId}`, 
              {
                headers: {
                Authorization: `Bearer ${token}`,
                }});
            const subscriptionData = await subscriptionResponse.json();
            console.log("comp id sub:", subscriptionData);
            if (subscriptionResponse.status === 200) {
              setQuotaMax(subscriptionData[4]); 
              const isDisabled = subscriptionData[4].numberquota_max <=  subscriptionData[4].quotaParcours;
            setIsDisable(isDisabled)
            }

          } else {
            setJobRolesList(response.data);
            const names = response.data.map(job => job.JobTitle);
console.log("names : ",names)
            setJobRolesListName(names)

          }
        })
        .catch(error => {
          console.error('Erreur lors de la récupération des emplois:', error);
        });
    });
  }, []);
  useEffect(() => {
		axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getAll`, 
      {
        headers: {
        Authorization: `Bearer ${token}`,
        }})
		  .then(response => {
            console.log("res: ", response);
			setJobRoles(response.data);
		  })
		  .catch(error => {
			console.error('Erreur lors de la récupération des rôles d\'emploi:', error);
		  });
	  }, []);
    const parseSkillsString = (skillsString) => {
      const skillsArray = skillsString
        .replace(/\(/g, '[')
        .replace(/\)/g, ']')
        .replace(/'/g, '"');
    
      try {
        return JSON.parse(skillsArray);
      } catch (error) {
        console.error("Error parsing skills string:", error);
        return [];
      }
    };
  const [selectedTest, setSelectedTest] = useState(null); // Store the selected test, not an array
  const getJobSkills = async (jobId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/GetSkillsByJobRoleTitle/${jobId}`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }});
      const jobskills = response.data;
      return jobskills;
    } catch (error) {
      console.error(`Error fetching job skills for ID ${jobId}:`, error);
      return "";
    }
  };



	const handleDropdownSelect3 =async (value) => {
		setJobRoleName(value);
		console.log("Selected jb:", value);
    const selectedJob = jobRoles.find((job) => job.JobTitle === value);
    console.log("Selected jb:", selectedJob);

      if (selectedJob) {
          try {
              const jobSkills = await getJobSkills(selectedJob.JobTitle);
              
              setSkillsForJob(jobSkills)
          } catch (error) {
              console.error("Error fetching job skills:", error);
          }
      }
	};

    const sendEmail = async(e) => {
        e.preventDefault();
         // Step 1: Clean the email string
         console.log("email :",email.length)
        setSendingEmails(true)
    const cleanedEmail = email.trim();
console.log("cleanedEmail :",cleanedEmail.length)
    // Step 2: Validate email format using a regular expression
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(cleanedEmail)) {
        Swal.fire({
            icon: 'error',
            title: 'Invalid Email',
            text: 'Please enter a valid email address.'  
        });
        setIsButtonDisabled(false);
        setSendingEmails(false)

        return;
    }
    if (email !== "admin@admin.admin") {
      const verifyEmailUrl = `${process.env.REACT_APP_APP_DOMAIN}/api/SendEmail/verify-email/${email}`;
      
      try {
        const isEmailValid = await axios.post(verifyEmailUrl, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
        console.log("isEmailValid :", isEmailValid);

        if (isEmailValid.data.valid === false) {
          Swal.fire({
            icon: 'error',
            title: 'Invalid Email',
            text: 'The provided email address is not valid.'
          });
          setIsButtonDisabled(false);
          setSendingEmails(false)

          return;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          Swal.fire({
            icon: 'error',
            title: 'Unauthorized',
            text: 'Your session may have expired. Please log in again.',
          });
          setSendingEmails(false)

        } else {
          console.error("Error creating candidate:", error);
       
          setSendingEmails(false)

        }
        console.error("Error verifying email:", error);
      
      }
    }
        setIsButtonDisabled(true);
        let subscriptionResponse="";
          let subscriptionData=""
          const currentUser = JSON.parse(localStorage.getItem('userDetails'));
          const userId = currentUser.localId;
        try {
          
          const baseUrl = `${process.env.REACT_APP_APP_DOMAIN}/api`;
          
          if(companyID!==1){
                subscriptionResponse = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/getSubscriptionFeaturesByCompanyId/${companyID}`, 
                  {
                    headers: {
                    Authorization: `Bearer ${token}`,
                    }});
                subscriptionData = await subscriptionResponse.json();
              }
          else if(companyID===1&& currentUser.userRole===3 ){
                subscriptionResponse = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/getSubscriptionFeaturesByRecruiterId/${userId}`, 
                  {
                    headers: {
                    Authorization: `Bearer ${token}`,
                    }});
                subscriptionData = await subscriptionResponse.json();
                      }
          // Step 1: Check if the user with the provided email exists
          const getUserByEmailUrl = `${baseUrl}/users/local/getUserByEmail/${email}`;
      
          axios
            .get(getUserByEmailUrl, 
              {
                headers: {
                Authorization: `Bearer ${token}`,
                }})
            .then((userExistsResponse) => {
              console.log("userExistsResponse:", userExistsResponse);
      
              if (userExistsResponse.status === 200) {
                const selectedJob = jobRoles.find((job) => job.JobTitle === jobRoleName);
      
                if (selectedJob) {
                  axios
                    .get(`${baseUrl}/jobrole/GetSkillsByJobRoleTitle/${selectedJob.JobTitle}`, 
                      {
                        headers: {
                        Authorization: `Bearer ${token}`,
                        }})
                    .then(async(jobSkillsResponse) => {
                      const jobSkills = jobSkillsResponse.data;
                      // Proceed with creating a candidate using jobSkills
                      const createCandidateUrl = `${baseUrl}/jobrole/create-candidate/${email}/${selectedJob.id}`;
                      
                      if(companyID!==1&& currentUser.userRole===3 && subscriptionData[4].numberquota_max>subscriptionData[4].quotaParcours){
                      axios
                        .post(`${baseUrl}/jobrole/create-candidate/${email}/${selectedJob.id}`, {},
                          {
                            headers: {
                            Authorization: `Bearer ${token}`,
                            }})
                        .then(async(createCandidateResponse) => {
                          console.log("createCandidateResponse:", createCandidateResponse);
      
                          if (createCandidateResponse.status === 200) {
                            createCandidateAndSendEmail(email,selectedJob.id,subject);
                            Swal.fire({
                              icon: 'success',
                              title: lang.menu.inviteSuccess,
                              showConfirmButton: false,
                              timer: 1500
                            });
                            await axios.put(
                              `${process.env.REACT_APP_APP_DOMAIN}/api/companies/updateCompanyField/${companyID}`,
                              { fieldName:"quotaParcours","incrementValue":1, quota_max:subscriptionData[4].numberquota_max,quota_used:subscriptionData[4].quotaParcours }, 
                              {
                                headers: {
                                Authorization: `Bearer ${token}`,
                                }}
                            );
                            //window.location.reload();
                            setSendingEmails(false)

                          } else {
                            Swal.fire({
                              icon: 'error',
                              title: lang.menu.error,
                              text: lang.menu.errorEmailText
                            });  
                            setSendingEmails(false)
                          }
                        })
                        .catch((error) => {
                          console.error("Error creating candidate:", error);
                          Swal.fire({
                            icon: 'error',
                            title: lang.menu.error,
                            text: lang.menu.errorCreatCanForEmail
                          });   
                          setSendingEmails(false)
                
                             });
                            }
                            else  if(companyID===1 &&currentUser.userRole===3 && subscriptionData[4].numberquota_max>subscriptionData[4].quotaParcours){
                              axios
                                .post(`${baseUrl}/jobrole/create-candidate/${email}/${selectedJob.id}`, {},
                                  {
                                    headers: {
                                    Authorization: `Bearer ${token}`,
                                    }})
                                .then(async(createCandidateResponse) => {
                                  console.log("createCandidateResponse:", createCandidateResponse);
              
                                  if (createCandidateResponse.status === 200) {
                                    createCandidateAndSendEmail(email,selectedJob.id,subject);
                                    Swal.fire({
                                      icon: 'success',
                                      title: lang.menu.inviteSuccess,
                                      showConfirmButton: false,
                                      timer: 1500
                                    });
                                    await axios.put(
                                      `${process.env.REACT_APP_APP_DOMAIN}/api/companies/updateRecruiterField/${userId}`,
                                      { fieldName:"quotaParcours","incrementValue":1, quota_max:subscriptionData[4].numberquota_max,quota_used:subscriptionData[4].quotaParcours }, 
                                      {
                                        headers: {
                                        Authorization: `Bearer ${token}`,
                                        }}
                                    );
                                    window.location.reload();
                                    setSendingEmails(false)

        
                                  } else {
                                    Swal.fire({
                                      icon: 'error',
                                      title: lang.menu.error,
                                      text: lang.menu.errorEmailText
                                    });                        
                                    setSendingEmails(false)
                                  }
                                })
                                .catch((error) => {
                                  if (error.response && error.response.status === 401) {
                                    Swal.fire({
                                      icon: 'error',
                                      title: 'Unauthorized',
                                      text: 'Your session may have expired. Please log in again.',
                                    });
                                  } else {
                                    console.error("Error creating candidate:", error);
                                    Swal.fire({
                                      icon: 'error',
                                      title: lang.menu.error,
                                      text: lang.menu.errorCreatCanForEmail,
                                    });
                                  }
                                  console.error("Error creating candidate:", error);
                                  setSendingEmails(false)
      
                                     });
                                    }



                            else{
                              axios
                              .post(`${baseUrl}/jobrole/create-candidate/${email}/${selectedJob.id}`, {},
                                {
                                  headers: {
                                  Authorization: `Bearer ${token}`,
                                  }})
                              .then((createCandidateResponse) => {
                                console.log("createCandidateResponse:", createCandidateResponse);
            
                                if (createCandidateResponse.status === 200) {
                                  createCandidateAndSendEmail(email,selectedJob.id,subject);
                                  Swal.fire({
                                    icon: 'success',
                                    title: lang.menu.inviteSuccess,
                                    showConfirmButton: false,
                                    timer: 1500
                                  });
                                  //window.location.reload();

                                  setSendingEmails(false)

                                } else {
                                  Swal.fire({
                                    icon: 'error',
                                    title: lang.menu.error,
                                    text: lang.menu.errorEmailText
                                  });   
                                  setSendingEmails(false)
                                }
                              })
                              .catch((error) => {
                                console.error("Error creating candidate:", error);
                              
                                if (error.response && error.response.status === 401) {
                                  Swal.fire({
                                    icon: 'error',
                                    title: 'Unauthorized',
                                    text: 'Your session may have expired. Please log in again.',
                                  });
                                } else {
                                  console.error("Error creating candidate:", error);
                                  Swal.fire({
                                    icon: 'error',
                                    title: lang.menu.error,
                                    text: lang.menu.errorCreatCanForEmail
                                  }); 
                                }   
                                setSendingEmails(false)
             
                                   });
                            }
                    })
                    .catch((error) => {
                      console.error("Error fetching job skills:", error);

                      if (error.response && error.response.status === 401) {
                        Swal.fire({
                          icon: 'error',
                          title: 'Unauthorized',
                          text: 'Your session may have expired. Please log in again.',
                        });
                      } else {
                        console.error("Error creating candidate:", error);
                        Swal.fire({
                          icon: 'error',
                          title: lang.menu.error,
                          text: lang.menu.jobSkillERROR
                        });     
                      }
                      setSendingEmails(false)

                                    });
                }
              } else {
                console.error("Error checking selectedJob:");
                setSendingEmails(false)

              }
            })
            .catch((error) => {
              console.error("Error checking user existence:", error.response || error);
      
              if (error.response && error.response.status === 500) {
                const createTemporaryUserUrl = `${baseUrl}/users/local/createTemporaryUser/${email}`;
                if(companyID!==1 && subscriptionData[4].numberquota_max>subscriptionData[4].quotaParcours){

                axios
                  .post(createTemporaryUserUrl, 
                    {
                      headers: {
                      Authorization: `Bearer ${token}`,
                      }})
                  .then((createTemporaryUserResponse) => {
                    console.log("createTemporaryUserResponse:", createTemporaryUserResponse.data.password);
                    const passwordTempo=createTemporaryUserResponse.data.password;
                    if (createTemporaryUserResponse.status===201) {
                        const selectedJob = jobRoles.find((job) => job.JobTitle === jobRoleName);
      
                        if (selectedJob) {
                          axios
                            .get(`${baseUrl}/jobrole/GetSkillsByJobRoleTitle/${selectedJob.JobTitle}`)
                            .then((jobSkillsResponse) => {
                              const jobSkills = jobSkillsResponse.data;
                              const createCandidateUrl = `${baseUrl}/jobrole/create-candidate/${email}/${selectedJob.id}`;
                              axios
                                .post(`${baseUrl}/jobrole/create-candidate/${email}/${selectedJob.id}`, {},
                                  {
                                    headers: {
                                    Authorization: `Bearer ${token}`,
                                    }})
                                .then(async(createCandidateResponse) => {
                                  console.log("createCandidateResponse:", createCandidateResponse);
              
                                  if (createCandidateResponse.status === 200) {
                                    createTempoUserAndSendEmail(email,selectedJob.id,passwordTempo,subject);
                                    Swal.fire({
                                      icon: 'success',
                                      title: lang.menu.inviteSuccess,
                                      showConfirmButton: false,
                                      timer: 1500
                                    });     
                                    await axios.put(
                                      `${process.env.REACT_APP_APP_DOMAIN}/api/companies/updateCompanyField/${companyID}`,
                                      { fieldName:"quotaParcours","incrementValue":1 }, 
                                      {
                                        headers: {
                                        Authorization: `Bearer ${token}`,
                                        }}
                                    );    
                                    window.location.reload();
                                    setSendingEmails(false)

                                  } else {
                                    Swal.fire({
                                      icon: 'error',
                                      title: lang.menu.error,
                                      text: lang.menu.errorEmailText
                                    });
                                    setSendingEmails(false)

                                  }
                                })
                                .catch((error) => {
                                  console.error("Error creating candidate:", error);
                               
                                  setSendingEmails(false)

                                  if (error.response && error.response.status === 401) {
                                    Swal.fire({
                                      icon: 'error',
                                      title: 'Unauthorized',
                                      text: 'Your session may have expired. Please log in again.',
                                    });
                                  } else {
                                    console.error("Error creating candidate:", error);
                                    Swal.fire({
                                      icon: 'error',
                                      title: lang.menu.error,
                                      text: lang.menu.errorCreatCanForEmail
                                    });
                                  }
                                });
                            })
                            .catch((error) => {
                              console.error("Error fetching job skills:", error);
                              setSendingEmails(false)

                              if (error.response && error.response.status === 401) {
                                Swal.fire({
                                  icon: 'error',
                                  title: 'Unauthorized',
                                  text: 'Your session may have expired. Please log in again.',
                                });
                              } else {
                                console.error("Error creating candidate:", error);
                                Swal.fire({
                                  icon: 'error',
                                  title: lang.menu.error,
                                  text: lang.menu.jobSkillERROR
                                });
                              }
                            });
                        }
                    } else {
                      setSendingEmails(false)

                      Swal.fire({
                        icon: 'error',
                        title: lang.menu.error,
                        text: lang.menu.errorTempoUser
                      });
                    }
                  })
                  .catch((error) => {
                    console.error("Error creating temporary user:", error);
                    setSendingEmails(false)

                    
                    if (error.response && error.response.status === 401) {
                      Swal.fire({
                        icon: 'error',
                        title: 'Unauthorized',
                        text: 'Your session may have expired. Please log in again.',
                      });
                    } else {
                      console.error("Error creating candidate:", error);
                      Swal.fire({
                      icon: 'error',
                      title: lang.menu.error,
                      text: lang.menu.errorTempoUserText
                    });   
                    }
                       });
                      }



else if(companyID===1 && currentUser.userRole===3 && subscriptionData[4].numberquota_max>subscriptionData[4].quotaParcours){

  axios
    .post(createTemporaryUserUrl, 
      {
        headers: {
        Authorization: `Bearer ${token}`,
        }})
    .then((createTemporaryUserResponse) => {
      console.log("createTemporaryUserResponse:", createTemporaryUserResponse.data.password);
      const passwordTempo=createTemporaryUserResponse.data.password;
      if (createTemporaryUserResponse.status===201) {
          const selectedJob = jobRoles.find((job) => job.JobTitle === jobRoleName);

          if (selectedJob) {
            axios
              .get(`${baseUrl}/jobrole/GetSkillsByJobRoleTitle/${selectedJob.JobTitle}`, 
                {
                  headers: {
                  Authorization: `Bearer ${token}`,
                  }})
              .then((jobSkillsResponse) => {
                const jobSkills = jobSkillsResponse.data;
                const createCandidateUrl = `${baseUrl}/jobrole/create-candidate/${email}/${selectedJob.id}`;
                axios
                  .post(`${baseUrl}/jobrole/create-candidate/${email}/${selectedJob.id}`, {},
                    {
                      headers: {
                      Authorization: `Bearer ${token}`,
                      }})
                  .then(async(createCandidateResponse) => {
                    console.log("createCandidateResponse:", createCandidateResponse);

                    if (createCandidateResponse.status === 200) {
                      createTempoUserAndSendEmail(email,selectedJob.id,passwordTempo,subject);
                      Swal.fire({
                        icon: 'success',
                        title: lang.menu.inviteSuccess,
                        showConfirmButton: false,
                        timer: 1500
                      });     
                      await axios.put(
                        `${process.env.REACT_APP_APP_DOMAIN}/api/companies/updateRecruiterField/${userId}`,
                        { fieldName:"quotaParcours","incrementValue":1 }, 
                        {
                          headers: {
                          Authorization: `Bearer ${token}`,
                          }}
                      );    
                      window.location.reload();
                      setSendingEmails(false)

                    } else {
                      Swal.fire({
                        icon: 'error',
                        title: lang.menu.error,
                        text: lang.menu.errorEmailText
                      });
                      setSendingEmails(false)

                    }
                  })
                  .catch((error) => {
                    console.error("Error creating candidate:", error);
                    setSendingEmails(false)

                    Swal.fire({
                      icon: 'error',
                      title: lang.menu.error,
                      text: lang.menu.errorCreatCanForEmail
                    });
                  });
              })
              .catch((error) => {
                setSendingEmails(false)

                console.error("Error fetching job skills:", error);
                Swal.fire({
                  icon: 'error',
                  title: lang.menu.error,
                  text: lang.menu.jobSkillERROR
                });
              });
          }
      } else {
        setSendingEmails(false)

        Swal.fire({
          icon: 'error',
          title: lang.menu.error,
          text: lang.menu.errorTempoUser
        });
      }
    })
    .catch((error) => {
      setSendingEmails(false)

      console.error("Error creating temporary user:", error);
      Swal.fire({
        icon: 'error',
        title: lang.menu.error,
        text: lang.menu.errorTempoUserText
      });             
         });
        }



                      else{
                        axios
                        .post(createTemporaryUserUrl, 
                          {
                            headers: {
                            Authorization: `Bearer ${token}`,
                            }})
                        .then((createTemporaryUserResponse) => {
                          console.log("createTemporaryUserResponse:", createTemporaryUserResponse.data.password);
                          const passwordTempo=createTemporaryUserResponse.data.password;
                          if (createTemporaryUserResponse.status===201) {
                              const selectedJob = jobRoles.find((job) => job.JobTitle === jobRoleName);
            
                              if (selectedJob) {
                                axios
                                  .get(`${baseUrl}/jobrole/GetSkillsByJobRoleTitle/${selectedJob.JobTitle}`, 
                                    {
                                      headers: {
                                      Authorization: `Bearer ${token}`,
                                      }})
                                  .then((jobSkillsResponse) => {
                                    const jobSkills = jobSkillsResponse.data;
                                    const createCandidateUrl = `${baseUrl}/jobrole/create-candidate/${email}/${selectedJob.id}`;
                                    axios
                                      .post(`${baseUrl}/jobrole/create-candidate/${email}/${selectedJob.id}`, {},
                                        {
                                          headers: {
                                          Authorization: `Bearer ${token}`,
                                          }})
                                      .then((createCandidateResponse) => {
                                        console.log("createCandidateResponse:", createCandidateResponse);
                    
                                        if (createCandidateResponse.status === 200) {
                                          createTempoUserAndSendEmail(email,selectedJob.id,passwordTempo,subject);
                                          Swal.fire({
                                            icon: 'success',
                                            title: lang.menu.inviteSuccess,
                                            showConfirmButton: false,
                                            timer: 1500
                                          });   
                                          window.location.reload();
                                          setSendingEmails(false)

                                        } else {
                                          Swal.fire({
                                            icon: 'error',
                                            title: lang.menu.error,
                                            text: lang.menu.errorEmailText
                                          });
                                          setSendingEmails(false)

                                        }
                                      })
                                      .catch((error) => {
                                        console.error("Error creating candidate:", error);
                                        setSendingEmails(false)

                                        Swal.fire({
                                          icon: 'error',
                                          title: lang.menu.error,
                                          text: lang.menu.errorCreatCanForEmail
                                        });
                                      });
                                  })
                                  .catch((error) => {
                                    console.error("Error fetching job skills:", error);
                                    setSendingEmails(false)

                                    Swal.fire({
                                      icon: 'error',
                                      title: lang.menu.error,
                                      text: lang.menu.jobSkillERROR
                                    });
                                  });
                              }
                          } else {
                            setSendingEmails(false)

                            Swal.fire({
                              icon: 'error',
                              title: lang.menu.error,
                              text: lang.menu.errorTempoUser
                            });
                          }
                        })
                        .catch((error) => {
                          console.error("Error creating temporary user:", error);
                          setSendingEmails(false)

                          Swal.fire({
                            icon: 'error',
                            title: lang.menu.error,
                            text: lang.menu.errorTempoUserText
                          });             
                             });
                      }
              } else {
                setSendingEmails(false)

                Swal.fire({
                  icon: 'error',
                  title: lang.menu.error,
                  text: lang.menu.errorCheckingUserExisting
                });              }
            });
        } catch (error) {
          console.error("Error sending email:", error);
          setSendingEmails(false)

          Swal.fire({
            icon: 'error',
            title: lang.menu.error,
            text: lang.menu.errorSendingEmail
          });        }
          finally {
            setIsButtonDisabled(false); 
          }
      };
      
      
      const sendEmailToCandidate = async (email, jobId,subject) => {
        try {
          const response = await axios.post(`${process.env.REACT_APP_APP_DOMAIN}/api/candidateExt/sendEmail`, {
            email: email,
            jobId: jobId,
            subject: subject,
          }, 
          {
            headers: {
            Authorization: `Bearer ${token}`,
            }});
      
          if (response.status === 200) {
            console.log('Email sent successfully');
          } else {
            console.error('Failed to send email');
          }
        } catch (error) {
          console.error('Error sending email:', error);
        }
      };
      const createCandidateAndSendEmail = async (email, jobId,subject) => {
   
        try {
          await sendEmailToCandidate(email, jobId,subject);
      

        } catch (error) {
          console.error('Error creating invitation:', error);
        }

      };    
      //Tempo User Email
      const sendEmailToTempoUser = async (email, jobId,password,subject) => {
        try {
          const response = await axios.post(`${process.env.REACT_APP_APP_DOMAIN}/api/candidateExt/sendEmailTempo`, {
            email: email,
            jobId: jobId,
            password:password,
            subject:subject,
          }, 
          {
            headers: {
            Authorization: `Bearer ${token}`,
            }});
      
          if (response.status === 200) {
            console.log('Email sent successfully');
          } else {
            console.error('Failed to send email');
          }
        } catch (error) {
          console.error('Error sending email:', error);
        }
      };
      const createTempoUserAndSendEmail = async (email, jobId,password,subject) => {
   
        try {
          await sendEmailToTempoUser(email, jobId,password,subject);
      
        } catch (error) {
          console.error('Error creating candidate:', error);
        }
      };   
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");

  const jobRoleOptions = jobRolesList.map((jobRole) => ({
    value: jobRole.JobTitle,
    label: jobRole.JobTitle,
  }));
  return (
    <>
      {sendPersonalityTest ? (
        <Card>
          <Card.Header className="d-flex justify-content-between align-items-center">
          <Card.Title>{lang.menu.sendPersonalityTest}</Card.Title>
          <div className="d-flex align-items-center">
            {quotaMax ? (
              <Badge className="d-flex align-items-center justify-content-center badge-rounded me-2" 
              variant="outline-danger"      style={{ color: quotaMax.quotaParcours >=  quotaMax.numberquota_max ? 'red' : '#74188D' }}>

                <AiOutlinePieChart size={20} />{' '}
                {lang.menu.quotaUsed} : {quotaMax.quotaParcours}/{quotaMax.numberquota_max}
              </Badge>
            ) : null}
         
          </div>
        </Card.Header>
    
        <form onSubmit={sendEmail}>
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header d-block">
                <Form.Label><strong>{lang.menu.email} <span style={{ color: 'red' }}>*</span>  :</strong></Form.Label>
                  <div className="input-group ">
                    <input
                      required
                      type="text"
                      className={`form-control `}
                      placeholder={lang.menu.email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <br />
                  <Form.Label><strong>{lang.menu.Subject} <span style={{ color: 'red' }}>*</span>  :</strong></Form.Label>                  <div>
                    <textarea
                      required
                      onChange={(e) => setSubject(e.target.value)}
                      type="text"
                      className={`form-control`}
                      placeholder={lang.menu.Subject}
                    />
                  </div>  
                  <br />
                  <div className="select-container">
                    <div className="select-wrapper-left">
             
                    <Form.Group controlId="jobRoleName">
  <Form.Label>
    <strong>{lang.menu.jobRole}<span style={{ color: 'red' }}>*</span> :</strong>
  </Form.Label>
  
  <Select
    name="jobRoleName"
    value={jobRoleOptions.find(option => option.value === jobRoleName)} 
    onChange={(selectedOption) => handleDropdownSelect3(selectedOption.value)} 
    options={jobRoleOptions} 
    placeholder={lang.menu.jobRole} 
    isClearable
    required
  />
</Form.Group>


    <br/>
    
    {skillsForJob && skillsForJob.length > 0 && (
     <div className="form-group">
     <label>
       <strong>{lang.menu.SkillSelected}:</strong>
     </label>
    <ul>
      {parseSkillsString(skillsForJob).map((skill, index) => (
        <Badge key={index} style={{ margin: "1%", backgroundColor: "#43DAC2" }}>
          <span style={{ textAlign: "center" }}>{`${skill[0]} : ${skill[1]}`}</span>
        </Badge>
      ))}
    </ul></div>
  )}
  
                    </div>
                  </div>
                  <div className="card-footer border-0">
                    <div className="d-flex justify-content-end">
                    {companyID && companyID!==1 ? (

                      <Button
                        type="submit"
                        className={`btn btn-primary me-3 btn-sm ${isDisable ? "disabled" : ""}`}

                      >
                        {lang.menu.SendEmail}
                      </Button>):
                      companyID && companyID===1 && userConnect &&userConnect.userRole===3 ? (

                        <Button
                          type="submit"
                          className={`btn btn-primary me-3 btn-sm ${isDisable ? "disabled" : ""}`}
  
                        >
                          {lang.menu.SendEmail}
                        </Button>)
                      
                     :(
                          <Button
                          type="submit"
                          disabled={isButtonDisabled || sendingEmails}
                          className="btn btn-primary btn sweet-confirm"
                        >
                        {lang.menu.SendEmail}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
   
      </Card>
         ) : (
          <Modal show={showAccessDeniedModal} >
          <Modal.Header>
            <Modal.Title>                        {lang.menu.accessDenied}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          {lang.menu.YoudontHavePerm}

          </Modal.Body>
          <Modal.Footer>
      
          </Modal.Footer>
        </Modal>
       )}
    </>
  );
}

export default SendJobEmail;
