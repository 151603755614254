import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Card } from "react-bootstrap";
import axios from "axios";
import { RiArrowGoBackFill } from "react-icons/ri";
import translations from "../../AppsMenu/Candidate/mulitlingue";

const QuestionOfSkill = () => {
  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [Name, setSkillName] = useState("");
  const history = useHistory();
  const token=localStorage.getItem("token");

  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  useEffect(() => {
    const selectedLang = JSON.parse(localStorage.getItem("selectedLang"));
    if (selectedLang) {
      setLang(selectedLang);
    }
  }, []);
  const pathname = window.location.pathname;
  const idCat = pathname.split("/").pop();
  const getImageUrlFromServer = (imageName, Name, id) => {
    return `${process.env.REACT_APP_APP_DOMAIN}/api/techniqueQCM/images/${Name}/${id}/${imageName}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_APP_DOMAIN}/api/skill/getSkillNameById/${idCat}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }}
        );
        setSkillName(response.data.name);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [idCat]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_APP_DOMAIN}/api/techniqueQCM/FindBySkill/${idCat}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }}
        );
        setQuestions(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [idCat]);

  const isImage = (url) => {
    const imageExtensions = [".jpg", ".jpeg", ".png", ".gif"];
    const extension = url.substring(url.lastIndexOf(".")).toLowerCase();
    return imageExtensions.includes(extension);
  };

  const getImageUrl = (imageRef, id) => {
    if (isImage(imageRef)) {
      const imageUrl = getImageUrlFromServer(imageRef, Name, id);
      return imageUrl;
    }
    return null;
  };

  const renderContent = (content, id) => {
    const imageUrl = getImageUrl(content, id);
    if (imageUrl) {
      return (
        <div className="frame">
          <img src={imageUrl} alt=" " width={150} height={100}/>
        </div>
      );
    } else {
      return <strong style={{ fontSize: "14px" }}>{content}</strong>;
    }
  };
  const renderContentRef = (content, id) => {
    const imageUrl = getImageUrl(content, id);
    if (imageUrl) {
      return (
        <div className="frame center">
          <img src={imageUrl} alt=" " width={300} height={200}/>
        </div>
      );
    } else {
      return <strong style={{ fontSize: "14px" }}>{content}</strong>;
    }
  };
  const handleBonneReponseClick = (question) => {
    setSelectedQuestion(question);
  };

  return (
    <div style={{ fontFamily: "Poppins" }}>
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <h2 className="fs-20 font-w800  me-auto">
          {lang.menu.typeQuestion}: {Name}
        </h2>
        <div>
          <button
            className="back-button"
            onClick={() => history.goBack()}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px 20px",
              border: "none",
              backgroundColor: "#e6e6e6",
              color: "#333",
              fontSize: "16px",
              fontWeight: "bold",
              textTransform: "uppercase",
              borderRadius: "4px",
              transition: "background-color 0.3s",
            }}
          >
            <span style={{ marginRight: "5px" }}>
              <RiArrowGoBackFill />
            </span>
            {lang.menu.retour}
          </button>
        </div>
      </div>

      <div>
        {questions.map((question) => (
          <Card key={question.id} className="mb-4">
            <Card.Body>
              <Card.Title
                style={{
                  color: "black",
                  textAlign: "left",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                {question.question}
              </Card.Title>
              {question.imageRef &&
                renderContentRef(question.imageRef, question.id)}
              <ul
                style={{
                  display: "flex",
                  flexDirection: "row",
                  color: "black",
                  textAlign: "left",
                }}
              >
                <li style={{ marginRight: "60px", fontSize: "14px" }}>
                  1. {renderContent(question.choix1, question.id)}
                </li>
                <li style={{ marginRight: "60px", fontSize: "14px" }}>
                  2. {renderContent(question.choix2, question.id)}
                </li>
                <li style={{ marginRight: "60px", fontSize: "14px" }}>
                  3. {renderContent(question.choix3, question.id)}
                </li>
                {question.choix4 && (
                  <li style={{ marginRight: "60px", fontSize: "14px" }}>
                    4. {renderContent(question.choix4, question.id)}
                  </li>
                )}
              </ul>
              <p>
                <span
                  onClick={() => handleBonneReponseClick(question)}
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    border: "1px solid blue",
                    padding: "5px",
                    borderRadius: "5px",
                    background: "transparent",
                    textAlign: "left",
                    color: "black",
                  }}
                >
                  {lang.menu.BonneReponse}
                </span>
                <strong style={{ color: "black" }}>
                  {" "}
                  {selectedQuestion === question ? question.BonneReponse : ""}
                </strong>
              </p>
            </Card.Body>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default QuestionOfSkill;
