
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  Link,
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import swal from "sweetalert";
import translations from "../AppsMenu/Candidate/mulitlingue";

function UpdateCoefficient() {
  const token=localStorage.getItem("token");

  const history = useHistory();
  //Modal box
  const [addCard, setAddCard] = useState(true);

  const { id } = useParams();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_APP_DOMAIN}/api/upload-test/read/` + id, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }})
      .then((res) => {
        console.log(res);
        setUpdatedQuestionData({
          ...updatedQuestionData,
          question: res.data[0].question,
          coefficient: res.data[0].coefficient,
          answer_value: res.data[0].answer_value,
          trait: res.data[0].trait,
        });
      })

      .catch((err) => console.log(err));
  }, []);
  const [updatedQuestionData, setUpdatedQuestionData] = useState({
    question: "",
    coefficient: "",
    answer_value: "",
    trait: "",
  });

  const handleUpdate = (event) => {
    event.preventDefault();
       if( updatedQuestionData.coefficient<1){
      swal(`The coefficient must be positive`, {
        icon: "error",
      });
        return;
      
    }
    axios
      .put(
        `${process.env.REACT_APP_APP_DOMAIN}/api/upload-test/update/` + id,
        updatedQuestionData, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
      )
      .then((res) => {
        console.log(res);
        swal(lang.menu.questionPerUpdated, {
          icon: "success",
        });
        history.push(`/upload-test?testId=${res.data.testId}`);
      })
      .catch((err) => {
        swal(lang.menu.deleteTitleWrong , lang.menu.deleteWrong);
        console.log(err);
      });
  };
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  useEffect(() => {
    const selectedLang = JSON.parse(localStorage.getItem("selectedLang"));
    if (selectedLang) {
      setLang(selectedLang);
    }
  }, []);
  return (
    <>
      <div className="col-12">
        <Modal className="modal fade" show={addCard} onHide={setAddCard}>
          <div className="" role="document">
            <div className="">
              <form onSubmit={handleUpdate}>
                <div className="modal-header">
                  <h4 className="modal-title fs-20">
                    {lang.menu.UpdateQuestion}
                  </h4>
                  <Link
                    to="/upload-test"
                    className="btn-close"
                    data-dismiss="modal"
                  >
                    <span></span>
                  </Link>
                </div>
                <div className="modal-body">
                  <i
                    className="flaticon-cancel-12 close"
                    data-dismiss="modal"
                  ></i>
                  <div className="add-contact-box">
                    <div className="add-contact-content">
                      <div className="form-group mb-3">
                        <label className="text-black font-w500">{lang.menu.Question}</label>
                        <div className="contact-name">
                          <input
                            className="form-control"
                            required="required"
                            value={updatedQuestionData.question}
                            onChange={(e) =>
                              setUpdatedQuestionData({
                                ...updatedQuestionData,
                                question: e.target.value,
                              })
                            }
                            placeholder="question"
                          />
                        </div>
                      </div>

                      <div className="form-group mb-3">
                        <label className="text-black font-w500">{lang.menu.Feature}</label>
                        <div className="contact-name">
                          <input
                            className="form-control"
                            required="required"
                            value={updatedQuestionData.trait}
                            onChange={(e) =>
                              setUpdatedQuestionData({
                                ...updatedQuestionData,
                                trait: e.target.value,
                              })
                            }
                            placeholder="trait"
                          />
                        </div>
                      </div>

                      <div className="form-group mb-3">
                        <label className="text-black font-w500">
                          {lang.menu.assessment}
                        </label>
                        <div className="contact-name">
                          <input
                            className="form-control"
                            required="required"
                            value={updatedQuestionData.answer_value}
                            onChange={(e) =>
                              setUpdatedQuestionData({
                                ...updatedQuestionData,
                                answer_value: e.target.value,
                              })
                            }
                            placeholder="Evaluation"
                          />
                        </div>
                      </div>

                      <div className="form-group mb-3">
                        <label className="text-black font-w500">
                          {lang.menu.Coefficient}
                        </label>
                        <div className="contact-name">
                          <input
                            className="form-control"
                            required="required"
                            value={updatedQuestionData.coefficient}
                            onChange={(e) =>
                              setUpdatedQuestionData({
                                ...updatedQuestionData,
                                coefficient: e.target.value,
                              })
                            }
                             min="1"
                            placeholder="coefficient"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary">
                    {lang.menu.Add}
                  </button>

                  <button
                    type="button"
                    onClick={() => {setAddCard(false); 
                      history.goBack();}}
                    className="btn btn-danger"
                  >
                    <i className="flaticon-delete-1"></i> {lang.menu.Discard}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default UpdateCoefficient;

