import React, { useState, useEffect } from "react";
import axios from "axios";
import { Card, Table, ProgressBar, Badge, Pagination, Form,Modal } from "react-bootstrap";
import translations from "../../components/AppsMenu/Candidate/mulitlingue"

function UserLogs() {
  const [userLog, setUserLog] = useState([]);
  const token=localStorage.getItem("token");

  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
          );
          const data = await response.json();
          setUserLog(
            data.some(
              (permission) =>
                permission.title === "view-admin-dashoard"
            )
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);
  const [showAccessDeniedModal, setShowAccessDeniedModal] = useState(true); 

  useEffect(() => {
    if (!userLog) {
      setShowAccessDeniedModal(true); 
    }
  }, [userLog]);
  const [lang] = useState(() => {
		const storedLang = localStorage.getItem('selectedLang');
		if (storedLang) {
			const parsedLang = JSON.parse(storedLang);
			if (parsedLang.language === 'Français') {
				return translations.fr;
			}
		}
		return translations.en;
	});
  const [userLogs, setUserLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [logsPerPage] = useState(7);
  const [searchTerm, setSearchTerm] = useState("");

  const getUserEmail = async (userId) => {
    try {
      const responseC = await fetch(
        `${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`
      );
      const data = await responseC.json();
      
  
      if (data && data[0] && data[0].email) {
        const email = data[0].email;
        return email;
      } else {
        console.error(
          "Erreur lors de la récupération de l'email pour l'utilisateur :",
          userId
        );
        return "";
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
      return "";
    }
  };
  

  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem("userDetails"));
    const userId = currentUser.localId;

    const getUserCompanyID = async () => {
      const responseC = await fetch(
        `${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`
      );
      const data = await responseC.json();
      const compId = data[0].companyID;
      return compId;
    };

    axios
      .get(`${process.env.REACT_APP_APP_DOMAIN}/api/userLogs/getAllUserLogs`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }})
      .then(async (response) => {
        console.log("response hiba :",response)
        const logsWithEmails = await Promise.all(
          response.data.map(async (log) => {
            const email = await getUserEmail(log.userId);
            return { ...log, userEmail: email };
          })
        );

        setUserLogs(logsWithEmails);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des logs:", error);
        setLoading(false);
      });
  }, []);

  const indexOfLastLog = currentPage * logsPerPage;
  const indexOfFirstLog = indexOfLastLog - logsPerPage;
  const currentLogs = userLogs
    .filter((log) =>
      Object.values(log)
        .join(" ")
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    )
    .slice(indexOfFirstLog, indexOfLastLog);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  function formatCalendarDate(date) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return new Date(date).toLocaleDateString("en-US", options);
  }

  const mapEntityToLabel = (entity) => {
    switch (entity) {
      case 'jobRole':
        return 'Job Role';
      case 'jobProfile':
        return 'Job Profile';
      case 'testPersonality':
        return 'Test Personality';
      case 'testPsychoTechnique':
        return 'Test PsychoTechnique';
      case 'testTech':
        return 'Test Tech';
      case 'testQCMTech':
        return 'Test QCM Tech';
      case 'Test':
        return 'Test';
      case 'testCodingTech':
        return 'Test Coding Tech';
      case 'Candidate':
        return 'Candidate';
      default:
        return entity;
    }
  };
  return (
    <> {userLog ? (  
    <Card>
      <Card.Header>
        <Card.Title>User Logs</Card.Title>
      </Card.Header>
      <Card.Body>
      <Form.Group controlId="searchForm.Control">
          <Form.Control
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Form.Group>
        <br/>
        {loading && <ProgressBar animated now={100} label={`Loading...`} />}
        {!loading && (
          <>
            <Table responsive className="header-border">
              <thead>
                <tr>
                  <th>
                    <strong>User Email</strong>
                  </th>
                  <th>
                    <strong>Action Id</strong>
                  </th>
                  <th>
                    <strong>Action Name</strong>
                  </th>
                  <th>
                    <strong>Date</strong>
                  </th>
                  <th>
                    <strong>Action</strong>
                  </th>
                  <th>
                    <strong>Entity</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentLogs.map((log) => (
                  <tr key={log.id}>
                    <td>{log.userEmail}</td>
                    <td>{log.actionId}</td>
                    <td>{log.actionName}</td>
                    <td>{formatCalendarDate(log.date)}</td>
                    <td>
                      {log.action === "add" && (
                        <Badge variant="primary">Add</Badge>
                      )}
                      {log.action === "delete" && (
                        <Badge variant="danger">Delete</Badge>
                      )}
                      {log.action === "update" && (
                        <Badge variant="warning">Update</Badge>
                      )}
                        {log.action === "Accept" && (
                        <Badge variant="success">Accept</Badge>
                      )}
                        {log.action === "reject" && (
                        <Badge variant="dark">Reject</Badge>
                      )}
                    </td>
                    <td> {mapEntityToLabel(log.entity)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Pagination className="justify-content-end">
              <Pagination.Prev
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage > 1 ? prevPage - 1 : prevPage
                  )
                }
              />
              {Array.from({ length: Math.ceil(userLogs.length / logsPerPage) }).map(
                (item, index) => (
                  <Pagination.Item
                    key={index}
                    active={index + 1 === currentPage}
                    onClick={() => paginate(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                )
              )}
              <Pagination.Next
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage < Math.ceil(userLogs.length / logsPerPage)
                      ? prevPage + 1
                      : prevPage
                  )
                }
              />
            </Pagination>
          </>
        )}
      </Card.Body>
    </Card>) : (
			<Modal show={showAccessDeniedModal} >
			<Modal.Header>
			  <Modal.Title>{lang.menu.accessDenied}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
			{lang.menu.YoudontHavePerm}
			</Modal.Body>
			<Modal.Footer>
		
			</Modal.Footer>
		  </Modal>
		 )}</>
  );
}

export default UserLogs;
