import React, { useEffect, useState,useCallback,Fragment } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { logout } from '../../../store/actions/AuthActions';
import translations from "../AppsMenu/Candidate/mulitlingue";
import {
    Row,
    Col,
    Card,
    Button,
    Form,
    ProgressBar,
    Modal,
} from "react-bootstrap";
import { useDispatch } from 'react-redux';
import { Icon } from "@iconify/react";
import { FaArrowRight } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system'
import { CiLogout } from "react-icons/ci";

const EXCHANGE_RATES = {
    EUR: 1.00,
    USD: 1.09,
    TND: 2.00,
    GBP: 0.85
};

const PayeYourInvoice = () => {
  const token=localStorage.getItem("token");

    const [invoiceData, setInvoiceData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const history = useHistory();
    const [showLogoutPopup, setShowLogoutPopup] = useState(false);
    const dispatch = useDispatch();
    const [features, setFeatures] = useState([]);
    const [subscriptionTypes, setSubscriptionTypes] = useState([]);
    const [enterpriseSubscriptions, setEnterpriseSubscriptions] = useState([]);
    const [soloSubscriptions, setSoloSubscriptions] = useState([]);
    const [allSubscriptions, setAllSubscriptions] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState("EUR");
    const [selectedBilling, setSelectedBilling] = useState("monthly");
    const [closestSubscription, setClosestSubscription] = useState(null);

    const calculateConvertedPrice = (price, currency) => {
      const rate = selectedBilling === "monthly" ? 1 : 12;
      return (parseFloat(price.slice(1)) * EXCHANGE_RATES[currency] * rate).toFixed(2);
    };

    useEffect(() => {
        const currentUser = JSON.parse(localStorage.getItem('userDetails'));
        const userId = currentUser.localId;
        localStorage.setItem("NotPayed", true);

        const fetchInvoiceData = async () => {
            try {
              const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/Subscription/getInvoiceByUserId/${userId}`, 
                {
                  headers: {
                  Authorization: `Bearer ${token}`,
                  },
                });
                setInvoiceData(response.data);
                setLoading(false);
            } catch (error) {
                setError('Failed to fetch invoice data');
                setLoading(false);
            }
        };

        fetchInvoiceData();
    }, []);

    useEffect(() => {
        const showFeatures = async () => {
            try {
              const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/subscriptionFeature/getAll`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
                const featureData = response.data;

                setFeatures(featureData);

                const response2 = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/subscriptionfeaturespertypes/findAllWithTypeSub`, 
                  {
                    headers: {
                    Authorization: `Bearer ${token}`,
                    }});
                const sub = response2.data;
                setSubscriptionTypes(sub);

                const featureOrder = [
                    'Candidate Profiles',
                    'Candidate extraction',
                    'Create job profile',
                    'Create job role',
                    'Parcours'
                ];

                const sortFeatures = (features) => {
                    return features.sort((a, b) => {
                        return featureOrder.indexOf(a.feature_name) - featureOrder.indexOf(b.feature_name);
                    });
                };

                const soloSubs = {};
                const enterpriseSubs = {};

                for (const [key, value] of Object.entries(sub)) {
                    const sortedValue = [...value].sort((a, b) => {
                        return featureOrder.indexOf(a.feature_name) - featureOrder.indexOf(b.feature_name);
                    });

                    if (key.startsWith("Solo") || key === "Free" || key === "Pay as you go") {
                        soloSubs[key] = sortedValue;
                    }
                    if (key.startsWith("Entreprise") || key === "Free" || key === "Pay as you go") {
                        enterpriseSubs[key] = sortedValue;
                    }
                }

                setSoloSubscriptions(soloSubs);
                setEnterpriseSubscriptions(enterpriseSubs);
                console.log("enterpriseSubs:",enterpriseSubs)

                const currentUser = JSON.parse(localStorage.getItem('userDetails'));
             const role = currentUser.userRole;
                let allSubscriptions = [];
                let SUB = null;

                if(role===3){
                  SUB = soloSubs;


                }else if(role===2){
                  SUB = enterpriseSubs;

                }

                Object.entries(SUB).forEach(([typeName, features]) => {
                  let priceType="";
                  features.forEach((feature) => {
                    priceType=feature.type_monthly_price;
                 
                  });
              
                  
              
                  allSubscriptions.push({ typeName, features,priceType });
                });
            
                setAllSubscriptions(allSubscriptions);

            } catch (error) {
                console.error("Error fetching features:", error);
            }
        };

        showFeatures();
    }, []);

    const [lang, setLang] = useState(() => {
        const storedLang = localStorage.getItem("selectedLang");
        if (storedLang) {
            const parsedLang = JSON.parse(storedLang);
            if (parsedLang.language === "Français") {
                return translations.fr;
            }
        }
        return translations.en;
    });
    const [companyID, setCompanyID] = useState("");

    const fetchCompany = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem('userDetails'));
        const userId = currentUser.localId;
    
        const getUserCompanyID = async () => {
          const responseC = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
          const data = await responseC.json();
          return data[0].companyID;
        };
    
        const compId = await getUserCompanyID();
        setCompanyID(compId)
      } catch (error) {
        console.error('Erreur lors de la récupération des emplois:', error);
      }
    };
    useEffect(()=>{
      fetchCompany()
    }, []);
    const handleLogoutConfirm = () => {
        setShowModal(true);
        dispatch(logout(history));
    };

    const handleLogout = () => {
        setShowLogoutPopup(true);
    };

    const handleCloseModal = () => {
      setClosestSubscription(null);
    };
    const handleClose = () => {
        setShowLogoutPopup(false);
    };
    const ModalPayme = ({ subscriptionType,userEmail }) => {
      const [showModal, setShowModal] = useState(false);
      const [token, setToken] = useState("");
    console.log("subscriptionType   :",subscriptionType)
      const amountPayme= calculateConvertedPrice(subscriptionType.priceType, selectedCurrency)
      const [formData, setFormData] = useState({
        amount: amountPayme,
        note: `${subscriptionType.features[0].type_id}`,
        first_name: '',
        last_name: '',
        email: userEmail,
        phone: '50719929',
        order_id: `${subscriptionType.features[0].type_id}`
      });
      
    
      
      const [gateway, setGatway] = useState(false)
        const [formError, setFormError] = useState(null)
        const [error, setError] = useState(null)
        const [iframeURL, setIframeURL] = useState('')
        const { t } = useTranslation()
        const [phone, setPhone] = useState('50719929')
        const [amount, setAmount] = useState(amountPayme)
        const [email, setEmail] = useState(userEmail)
        const [firstName, setFirstName] = useState("")
        const [lastName, setLastName] = useState("")
    
        const handelClick = async () => {
          try {
            console.log("phone:",phone)
              const res = await axios.get(
                  `https://sandbox.paymee.tn/api/v1/accounts/${phone}/account-number`,
                  {
                      headers: {
                          Authorization: `Token ${process.env.REACT_APP_PAYME_API_KEY}`,
                      },
                  }
              )
              console.log("res 14:",res.data)
    
              if (!res.data.status) {
                  setFormError(res.data.message)
                  setGatway(false)
              } else {
                const formDataPayme = new FormData();
                formDataPayme.append("email", formData.email);
                formDataPayme.append("first_name", formData.first_name);
                formDataPayme.append("last_name", formData.last_name);
    
     console.log("formDataPayme:",formDataPayme)
                  const res2 = await axios.post(
                      `https://sandbox.paymee.tn/api/v2/payments/create`,
                      {
                        order_id:subscriptionType.features[0].type_id,
                          phone: phone,
                          amount,
                          note: subscriptionType.features[0].type_id,
                          first_name:firstName,
                          last_name:lastName,
                          email:email,
                          return_url: "https://app.hirecue.com",
                          cancel_url: "https://app.hirecue.com",
                          webhook_url: "https://www.webhook_url.tn"
                        
                      },
                      {
                          headers: {
                              Authorization: `Token ${process.env.REACT_APP_PAYME_API_KEY}`,
                          },
                      }
                  )
                  console.log("res 15:",res2.data)
    
                  if (res2.data.status) {
                      setToken(res2.data.data.token)
                      setIframeURL(
                          `https://sandbox.paymee.tn/gateway/${res2.data.data.token}`
                      )
    
                      setGatway(true)
                      setFormError(null)
    
                  } else {
    
                      setGatway(false)
                      setFormError(res2.data.message)
                  }
              }
          } catch (error) {
              console.log(error)
              setError(error)
              setGatway(false)
          }
      }
      const [paymentStatus, setPaymentStatus] = useState(null);
    
      const checkToken = useCallback(async () => {
        if (!token) {
          return;
        }
    
        console.log("Checking token:", token);
    
        try {
          const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/payMe/payments/${token}/check/v2`, {
            headers: {
              "Authorization-Bearer": `Bearer ${token}`,
              "Authorization": `Token ${process.env.REACT_APP_PAYME_API_KEY}`
            },
          });
    
          const { data } = response.data;
          const currentUser = JSON.parse(localStorage.getItem('userDetails'));
          const userId = currentUser.localId;
          if (data && data.payment_status) {
            console.log("Payment completed successfully",data);
            setPaymentStatus('completed');
            setShowModal(false);
            handleCloseModal()
            const subscriptionData = {
              userId: userId,
              amount: data.amount,
              note: formData.order_id,
            };
          
            try {
              console.log("subscriptionData :",subscriptionData)
              const subscriptionDone = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/Subscription/createSubscription`, {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(subscriptionData),
              });
          
              if (!subscriptionDone.ok) {
                throw new Error('Failed to create subscription');
              }
          
              const dataSub = await subscriptionDone.json();
          
              const invoiceData = {
                sub_id: dataSub.sub_id,
                customer_id: dataSub.customer_id,
                email: data.email,
                received_amount: data.received_amount,
                amount: data.amount, 
                payment_status: data.payment_status,
                firstname: data.firstname
              };
          
              const invoiceDone = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/invoice/createinvoice`, {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(invoiceData),
              });
          
              if (!invoiceDone.ok) {
                throw new Error('Failed to create invoice');
              }
    
              console.log("Subscription and invoice creation completed successfully");
       
          
            } catch (error) {
              console.error("Error:", error);
            }
          }
          else {
            console.log("Payment not completed or unsuccessful");
            setPaymentStatus('incomplete');
          }
        } catch (error) {
          console.error("Error checking payment status:", error);
          setPaymentStatus('error');
        }
      }, [token]);
    
      const handlePaymeeComplete = useCallback((event) => {
        console.log('Paymee payment completed:', event.detail);
        setPaymentStatus('completed');
        setShowModal(false);
        // Perform further actions like updating the UI or notifying the user
      }, []);
    
      useEffect(async() => {
        if (paymentStatus === 'completed') {
          console.log('Payment already completed, skipping check.');
          const response = await axios.put(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/updateAfterSubscription/${companyID}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }});
          handleLogout()

          if("choice"===3){
            handleLogout()
      
          }
          return;
        }
    
        const intervalId = setInterval(checkToken, 1000);
    
        window.addEventListener('paymee.complete', handlePaymeeComplete);
        
        return () => {
         
          clearInterval(intervalId);
          window.removeEventListener('paymee.complete', handlePaymeeComplete);
          
        };
      }, [checkToken, handlePaymeeComplete, paymentStatus]);
      
      useEffect(() => {
        console.log("Hello",token)
          window.addEventListener(
              'message',
              function (event) {
                  if (event.data.event_id === 'paymee.complete') {
                      //Execute Step 3
                      // window.location.replace()
                      // https://sandbox.paymee.tn/api/v1/payments/{token}/check
                      axios
                          .get(
                              `https://sandbox.paymee.tn/api/v2/payments/${token}/check`,
    
                              {
                                  headers: {
                                      Authorization: `Token ${process.env.REACT_APP_PAYME_API_KEY}`,
                                  },
                              }
                          )
                          .then((res) => {
                            console.log("res.data:",res.data)
                              if (res.data.data && res.data.data.payment_status) {
                                  setIframeURL(
                                      'https://sandbox.paymee.tn/paylink/success'
                                  )
                              } else {
                                  setIframeURL(
                                      'https://sandbox.paymee.tn/paylink/failure'
                                  )
                              }
                          })
                          .catch((err) => {
                              console.log(err)
                              setError(err)
                          })
                  }
              },
              false
          )
      }, [token])
      return (
        <>
          <Button color="primary" onClick={() => setShowModal(true)} className="btn-soft-primary rounded-pill">
            {lang.menu.purchaseNow}<FaArrowRight />
          </Button>
          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header >
              <Modal.Title>{lang.menu.purchaseNow}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Card>
                        <Card.Header>
                            <h3>{t('Online payment')}</h3>
                        </Card.Header>
                        <Box overflow="auto">
                           
                        <Form>
        {!gateway ? (
            <Fragment>
               <Form.Group className="mb-3" controlId="formAmount">
                  <Form.Label>{lang.menu.amount}</Form.Label>
                  <Form.Control type="text" placeholder="Enter amount" name="amount" value={amountPayme}     onChange={(event) => {
                        setAmount(event.target.value.toString());
                    }} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formAmount">
                  <Form.Label>{lang.menu.email}</Form.Label>
                  <Form.Control type="email" placeholder="Enter email" name="email" value={userEmail}     onChange={(event) => {
                        setEmail(event.target.value.toString());
                    }}  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formAmount">
                  <Form.Label>{lang.menu.firstName}</Form.Label>
                  <Form.Control type="text" placeholder="Enter first_name" name="first_name" value={firstName}     onChange={(event) => {
                        setFirstName(event.target.value.toString());
                    }}  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formAmount">
                  <Form.Label>{lang.menu.lastName}</Form.Label>
                  <Form.Control type="text" placeholder="Enter last_name" name="last_name" value={lastName}     onChange={(event) => {
                        setLastName(event.target.value.toString());
                    }}  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formAmount">
                  <Form.Label>{lang.menu.phone}</Form.Label>
                  <Form.Control type="text" placeholder="Enter phone" name="phone" value={formData.phone}  onChange={(event) => {
                        setPhone(event.target.value.toString());
                    }} />
                </Form.Group>
    
    
    
    
              
               
                <br />
                {formError && (
                    <p sx={{ color: 'red', my: 1 }}>
                        {formError}
                    </p>
                )}
                <Button onClick={handelClick}>
                    {t('Submit')}
                </Button>
            </Fragment>
        ) : (
            <iframe
                title="gateway payment"
                width="500px"
                height="400px"
                src={iframeURL}
            />
        )}
    </Form>
    
                          
                        </Box>
                    </Card>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowModal(false)}>{lang.menu.retour }</Button>
            </Modal.Footer>
          </Modal>
        </>
      );
    };
    const subscriptionLinks = {
      Free: "https://buy.stripe.com/test_4gw5nZ5cH9mJ9P2cMZ",
      "Solo Starter": "https://buy.stripe.com/test_6oE6s3gVp7eB1iw4gy",
      "Solo Pro": "https://buy.stripe.com/test_6oE03F20v8iFe5iaEX",
      "Solo Premium": "https://buy.stripe.com/test_4gwaIjax11Uh0eseVe",
      "Entreprise Starter": "https://buy.stripe.com/test_dR69Ef0Wr9mJ4uIcN0",
      "Entreprise Pro": "https://buy.stripe.com/test_6oEdUvbB5eH31iw00f",
      "Entreprise Premium": "https://buy.stripe.com/test_4gwaIj6gL2Yl0esfZe",
      "Pay as you go": "https://buy.stripe.com/test_eVa5nZax11UhbXadR7"
    };
    const PurchaseLink = ({ subscriptionType }) => {
      const currentUser = JSON.parse(localStorage.getItem('userDetails'));
      const userEmail = currentUser.email;
      const link = `${subscriptionLinks[subscriptionType.typeName]}?prefilled_email=${userEmail}`;
    
      const handlePurchaseClick = (link) => {
        if (selectedCurrency !== "TND") {
         // setModalLink(link);
         // setShowModal(true);
          window.location.href = link; 
          handleLogout()
        } else {
          //window.open(link, "_blank");
         
        }
      };
    
  
    
      return selectedCurrency === "TND" ? (
        <ModalPayme subscriptionType={subscriptionType} userEmail={userEmail} />
      ) : (
        <>
          <Button
            onClick={() => handlePurchaseClick(link)}
            className="btn-soft-primary rounded-pill"
          >
            {lang.menu.purchaseNow} <FaArrowRight />
          </Button>
        </>
      );
    };
    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <Card style={{ backgroundColor: "#8cd6c8d0", marginTop: "8%", marginLeft: "8%", marginRight: "8%" }}>
            <Card.Header>
                <h1 className='text-center'>Your Subscription Has Expired</h1>
                <h4 className='single-line' onClick={handleLogout} style={{ color: 'red', cursor: "pointer" }}>
                    <CiLogout />{'  '}{lang.menu.Logout}
                </h4>
            </Card.Header>
            <Card.Body>
                <p>Your subscription ended on: {new Date(invoiceData.period_end).toLocaleDateString()}</p>
                <p>To continue using our services, please pay your outstanding invoice.</p>
                <Button onClick={() => setShowModal(true)}>
                    {lang.menu.purchaseNow}
                </Button>
                {showLogoutPopup && (
                    <>
                        <div className="overlay" onClick={handleClose}></div>
                        <div
                            style={{
                                position: "fixed",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                backgroundColor: "white",
                                padding: "20px",
                                width: "40%",
                                minWidth: "180px",
                                maxWidth: "100%",
                                textAlign: "center",
                                border: "1px solid rgba(0, 0, 0, 0.2)",
                                borderRadius: "8px",
                                boxShadow: "0 0 200px rgba(0, 0, 0, 0.3)",
                                zIndex: 999
                            }}
                        >
                            <h3>{lang.menu.aysywtl}</h3>
                            <center>
                                <Row>
                                    <Col>
                                        <Button style={{ width: "50%", marginInline: "20%" }} onClick={handleLogoutConfirm}>
                                            {lang.menu.Confirm}
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button style={{ width: "50%", marginInline: "20%", backgroundColor: "#bcebe4", color: "#74188D" }} onClick={handleClose}>
                                            {lang.menu.Cancel}
                                        </Button>
                                    </Col>
                                </Row>
                            </center>
                        </div>
                       
                    </>
                )} <Modal show={showModal} onHide={() => setShowModal(false)} dialogClassName="modal-100w">
                <Modal.Header>
                    <Modal.Title>{lang.menu.OffreClosest}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center mt-3">
                        <button
                            className={`btn btn-outline-secondary me-2 ${selectedCurrency === "EUR" ? "active" : ""}`}
                            onClick={() => setSelectedCurrency("EUR")}
                        >
                            EUR
                        </button>
                        <button
                            className={`btn btn-outline-secondary me-2 ${selectedCurrency === "USD" ? "active" : ""}`}
                            onClick={() => setSelectedCurrency("USD")}
                        >
                            USD
                        </button>
                        <button
                            className={`btn btn-outline-secondary me-2 ${selectedCurrency === "TND" ? "active" : ""}`}
                            onClick={() => setSelectedCurrency("TND")}
                        >
                            TND
                        </button>
                        <button
                            className={`btn btn-outline-secondary ${selectedCurrency === "GBP" ? "active" : ""}`}
                            onClick={() => setSelectedCurrency("GBP")}
                        >
                            GBP
                        </button>
                    </div>
                    <Row>
                    {allSubscriptions.map((subscription) => {
            console.log("allSubscriptions :",allSubscriptions)
            const cardClass = 'pricing-box h-100 shadow-sm';
            return (
              <Col key={subscription.typeName} md={4}>
                <Card className={cardClass}>
                  <Card.Body className="p-4 px-lg-5">
                    <div className="pricing-name mt-4 pt-2 text-center">
                      <h4 className="fs-18 text-primary">
                        <div className="pricing-icon rounded-circle icons-md mx-auto mb-4">
                          <Icon icon="uim-telegram-alt" className="text-primary" />
                          {subscription.typeName}
                        </div>
                      </h4>
                      <h2 className="fw-semibold">
                      {calculateConvertedPrice(subscription.priceType, selectedCurrency)}
                        <small className="fs-16">{selectedCurrency} {selectedBilling === "monthly" ? "mo" : "yr"}</small>
                      </h2>
                    </div>
                 
                    <ul className="list-unstyled mt-3 mb-4">
                      {subscription.features.map((feature) => (
                        <li key={feature.feature_id} className="my-2">
                          <strong>{feature.feature_name}:</strong> {feature.feature_quota_max}
                        </li>
                      ))}
                    </ul>
                    <PurchaseLink subscriptionType={subscription} className="btn btn-primary btn-block" />

                  </Card.Body>
                </Card>
              </Col>
            );
          })}
                    </Row>
                </Modal.Body>
            </Modal>
            </Card.Body>
        </Card>
    );
};

export default PayeYourInvoice;
