import React, { useState, useEffect } from 'react';

function AreaOfExpertise({ lang, expandedCategories, toggleCategory}) {
  const [selectedExpertise, setSelectedExpertise] = useState(null);
  const [selectedPosts, setSelectedPosts] = useState([]);
  var expertiseOptions=[
    "Software Engineering",
    "Marketing",
    "Data Analysis",
    "Product Management"];
  var preferredPosts={
    "Software Engineering": [
      "Software Developer",
      "Frontend Developer",
      "Backend Developer",
      "Full Stack Developer",
      "Mobile App Developer",
      "Game Developer",
      "Web Developer",
      "DevOps Engineer",
      "Cloud Engineer",
      "Embedded Systems Engineer",
      "QA Engineer",
      "UI/UX Designer",
      "Database Administrator",
      "Network Engineer",
      "Security Engineer",
      "Machine Learning Engineer",
      "Data Engineer",
      "Blockchain Developer",
      "AR/VR Developer",
      "IoT Developer"
    ],
    "Marketing": [
      "Digital Marketing Specialist",
      "Social Media Manager",
      "SEO Specialist",
      "Content Marketing Manager",
      "Email Marketing Specialist",
      "PPC Specialist",
      "Brand Manager"
    ],
    "Data Analysis": [
      "Data Analyst",
      "Business Analyst",
      "Data Scientist",
      "Quantitative Analyst",
      "Market Research Analyst"
    ],
    "Product Management": [
      "Product Manager",
      "Product Owner",
      "Technical Product Manager",
      "Product Marketing Manager"
    ]
  }
  useEffect(() => {
    const storedData = localStorage.getItem('AreaOfExpertise');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setSelectedExpertise(parsedData.Expertise);
      setSelectedPosts(parsedData.Posts);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('AreaOfExpertise', JSON.stringify({Expertise: selectedExpertise,Posts: selectedPosts }));
  }, [selectedExpertise, selectedPosts]);

  const handleExpertiseSelect = (option) => {
    setSelectedExpertise(option);
    setSelectedPosts([]); // Reset selectedPosts when expertise changes
  };

  const handlePostCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedPosts((prevSelectedPosts) => [...prevSelectedPosts, value]);
    } else {
      setSelectedPosts((prevSelectedPosts) => prevSelectedPosts.filter((post) => post !== value));
    }
  };

  return (
    <div className="category">
      <h2 className='single-line' onClick={() => toggleCategory('areaOfExpertise')}>{lang.menu.aoe}</h2>
      {expandedCategories.areaOfExpertise && (
        <div className="category-content">
          <div>
            <h3>{lang.menu.pp}</h3>
            <h5>{lang.menu.wkoodyltsotp}</h5>

            {expertiseOptions.map((option, index) => (
              <button

                key={index}
                type="button"
                style={{ height: "40px",paddingLeft: "3px", paddingRight: "3px",marginLeft: "3px" }}
                onClick={() => handleExpertiseSelect(option)}
                className={`btnOnboard ${selectedExpertise === option ? 'selected' : ''}`}
              >
                <b>{option}</b>
              </button>
            ))}
          </div>
          {selectedExpertise && (
            <div className="checkbox-wrapper">
              <h5>{lang.menu.stpyp}</h5>
              {preferredPosts[selectedExpertise].map((post, index) => (
                <div key={index} style={{ marginBottom: "10px" }}>
                  <input 
                  className='inputOnboard'
                    type="checkbox"
                    id={`checkbox-${index}`}
                    name="preferredPosts"
                    value={post}
                    checked={selectedPosts.includes(post)}
                    onChange={handlePostCheckboxChange}
                  />
                  <label className="terms-label" htmlFor={`checkbox-${index}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 200 200" className="checkbox-svg">
                      <mask fill="white" id={`path-1-inside-1_476_5-${index}`}>
                        <rect height="200" width="200"></rect>
                      </mask>
                      <rect mask={`url(#path-1-inside-1_476_5-${index})`} strokeWidth="40" className="checkbox-box" height="200" width="200"></rect>
                      <path strokeWidth="15" d="M52 111.018L76.9867 136L149 64" className="checkbox-tick"></path>
                    </svg>
                    <span className="label-text"><b>{post}</b></span>
                  </label>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default AreaOfExpertise;
