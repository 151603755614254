export const LanguageInfo = (id) => {
  const languages = [
      {
        "id": 63,
        "name": "JavaScript (Node.js 12.14.0)",
        "value": "javascript",
        "variable_declaration": "const ",
        "output":"console.log()"
      },
      {
        "id": 45,
        "name": "Assembly (NASM 2.14.02)",
        "value": "assembly",
        "variable_declaration": "section .data\n"
      },
      {
        "id": 46,
        "name": "Bash (5.0.0)",
        "value": "bash",
        "variable_declaration": ""
      },
      {
        "id": 48,
        "name": "C (GCC 7.4.0)",
        "value": "c",
        "variable_declaration": "type "
      },
      {
        "id": 52,
        "name": "C++ (GCC 7.4.0)",
        "value": "cpp",
        "variable_declaration": "type "
      },
      {
        "id": 49,
        "name": "C (GCC 8.3.0)",
        "value": "c",
        "variable_declaration": "type "
      },
      {
        "id": 53,
        "name": "C++ (GCC 8.3.0)",
        "value": "cpp",
        "variable_declaration": "type "
      },
      {
        "id": 50,
        "name": "C (GCC 9.2.0)",
        "value": "c",
        "variable_declaration": "type "
      },
      {
        "id": 54,
        "name": "C++ (GCC 9.2.0)",
        "value": "cpp",
        "variable_declaration": "type "
      },
      {
        "id": 51,
        "name": "C# (Mono 6.6.0.161)",
        "value": "csharp",
        "variable_declaration": "type "
      },
      {
        "id": 44,
        "name": "Executable",
        "value": "exe",
        "variable_declaration": ""
      },
      {
        "id": 87,
        "name": "F# (.NET Core SDK 3.1.202)",
        "value": "fsharp",
        "variable_declaration": "let mutable "
      },
      {
        "id": 62,
        "name": "Java (OpenJDK 13.0.1)",
        "value": "java",
        "variable_declaration": "public static  "

      },
      {
        "id": 67,
        "name": "Pascal (FPC 3.0.4)",
        "value": "pascal",
        "variable_declaration": "var variableName: type = value;"
      },
      {
        "id": 85,
        "name": "Perl (5.28.1)",
        "value": "perl",
        "variable_declaration": "my "
      },
      {
        "id": 68,
        "name": "PHP (7.4.1)",
        "value": "php",
        "variable_declaration": "$"
      },
      {
        "id": 70,
        "name": "Python (2.7.17)",
        "value": "python",
        "variable_declaration": ""
      },
      {
        "id": 71,
        "name": "Python (3.8.1)",
        "value": "python",
        "variable_declaration": ""
      },
      {
        "id": 80,
        "name": "R (4.0.0)",
        "value": "r",
        "variable_declaration": "variableName <- value"
      },
      {
        "id": 72,
        "name": "Ruby (2.7.0)",
        "value": "ruby",
        "variable_declaration": ""
      },
      {
        "id": 82,
        "name": "SQL (SQLite 3.27.2)",
        "value": "sql",
        "variable_declaration": ""
      },
      {
        "id": 74,
        "name": "TypeScript (3.7.4)",
        "value": "typescript",
        "variable_declaration": "let variableName: type = value;"
      },
      {
        "id": 84,
        "name": "Visual Basic.Net (vbnc 0.0.0.5943)",
        "value": "vbnet",
        "variable_declaration": "Dim variableName As type = value"
      },
      {
        "id": 71,
        "name": "MongoDB Shell (4.2.3)",
        "value": "mongodb",
        "variable_declaration": ""
      }
    ];

    const language = languages.find((lang) => lang.id === id);
    return language || null;
  };
 
