import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
  Modal,
  Button,
  Card,
  Table,
  Badge,
  Row,
  Col

} from "react-bootstrap";
import translations from"../AppsMenu/Candidate/mulitlingue";

const JobRoleList = () => {
  const token=localStorage.getItem("token");

  const [jobRoles, setJobRoles] = useState([]);
  const [showTestsModal, setShowTestsModal] = useState(false);
  const [selectedJobRoleTests, setSelectedJobRoleTests] = useState([]);  
  const [selectedCandidate, setSelectedCandidate] = useState('');
  const [selectedJobRole, setSelectedJobRole] = useState(null);
  const [candidates, setCandidates] = useState([]);
  const [testDetails, setTestDetails] = useState([]);
  const [missingIds, setMissingIds] = useState([]);
  const [passTest,setPassTest] = useState([]);
  const [userRole, setuserRole] = useState([]);
  const getUserCompanyID = async () => {
    const currentUser = JSON.parse(localStorage.getItem('userDetails'));
    const userId = currentUser.localId;
    const responseC = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
    const data = await responseC.json();
    const compId = data[0].companyID;
    return compId;
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userRole.length > 0) {
          const userDetailsString = localStorage.getItem("userDetails")
          const userDetails = JSON.parse(userDetailsString);
          const localId = userDetails ? userDetails.localId : null;
          const compId =await getUserCompanyID()
          const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/candidatesjobTech/${localId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }});
          if (compId!=1) {
            
            const filteredJobs = response.data.filter(job => job.companyid === compId);
            setJobRoles(filteredJobs);
          } else {
            setJobRoles(response.data);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchData();
  }, [userRole]);
  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
          );
          const data = await response.json();
          setPassTest(
            data.some((permission) => permission.title === "pass-psycho-test")
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);
  const [lang, setLang] = useState(() => {
		const storedLang = localStorage.getItem('selectedLang');
		if (storedLang) {
		  const parsedLang = JSON.parse(storedLang);
		  if (parsedLang.language === 'Français') {
			return translations.fr;
		  }
		}
		return translations.en;
	  });
    useEffect(() => {
  
      const selectedLang = JSON.parse(localStorage.getItem('selectedLang'));
      if (selectedLang) {
        setLang(selectedLang);
      }
    }, []);

    useEffect(() => {
      const fetchUserPermissions = async () => {
        try {
          const currentUser = JSON.parse(localStorage.getItem('userDetails'));
          if (currentUser && currentUser.localId) {
            const userId = currentUser.localId;
            const response = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
            const data = await response.json();
            setuserRole(data);
  
          }
        } catch (error) {
          console.error("Error fetching permissions details:", error);
        }
      };
    
      fetchUserPermissions();
    }, []);
console.log("tesst user: ",userRole)

  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/users`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
        setCandidates(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);
  const handlePassTest = (jobRoleId) => {
    const userId = userRole[0].uid;
    localStorage.setItem('jobRoleId', jobRoleId);
    localStorage.setItem('selectedCandidate', userId);
  };

  const showJobRoleTests = async (jobRole) => {
    try {
      const userId = userRole[0].uid;

      const response1 = await axios.get(
        `${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/findMissingTestPsychoIds/${userId}/${jobRole.id}`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
      );

      const missingTestIds = response1.data.missingTestIds;
      setMissingIds(missingTestIds);
      const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getJobroleById/${jobRole.id}`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }});
      const tests = response.data.testPsychoIds;
      setSelectedJobRoleTests(tests);
      
      localStorage.setItem('jobRoleId', jobRole.id); 
      
      setShowTestsModal(true);
    } catch (error) {
      console.error("Error fetching jobRole tests:", error);
    }
  };



  const fetchTestDetails = async (testId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/testPsycho/getTestWithQuestionsById/${testId}`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }});
      console.log("res : ",response.data)
      localStorage.setItem('questionsNbrPsycho', response.data.question_globale);

      return response.data;
    } catch (error) {
      console.error("Error fetching test details:", error);
      return null;
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const details = [];
        for (const testId of selectedJobRoleTests) {
          const testDetail = await fetchTestDetails(testId);
          if (testDetail) {
            details.push(testDetail);
          }
        }
        setTestDetails(details);
      } catch (error) {
        console.error("Error fetching test details:", error);
      }
    };

    fetchData();
  }, [selectedJobRoleTests]);

  return (
    <Card >
      <Card.Header>
        <Card.Title>{lang.menu.jobRole}</Card.Title>
      </Card.Header>
      <Card.Body>
      <div className="d-block">
    
        </div>
        <Table responsive>
        <thead>
          <tr>
            <th>ID</th>
            <th>Job Title</th>
            <th>Skills</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {jobRoles.map((jobRole) => (
            <tr key={jobRole.id}>
              <td>{jobRole.id}</td>
              <td>{jobRole.JobTitle}</td>
              <td>          {jobRole.skills
    .replace("[", "")
    .replace("]", "")
    .split("),")
    .map((skill, index) => {
      const skillParts = skill.replace("('", "").replace("')", "").split("',");
      return    <Badge variant="primary light"><span key={index}>{skillParts[0]}</span> {"    "}</Badge>;
    })}</td>
              <td>
                {jobRole && (
                  <>
                    <button
                      className="btn btn-secondary"
                      onClick={() => showJobRoleTests(jobRole)}
                    >
                      Show Tests
                    </button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal show={showTestsModal} onHide={() => setShowTestsModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Tests du JobRole</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <ul>
        {testDetails.map((testDetail, index) => {
  const testId = selectedJobRoleTests[index];
  const isMissing = missingIds.includes(testId);

  return (
    <li key={index}>
      <h4>{testDetail.test_name}</h4>
      <p><strong>Description:</strong>{testDetail.description}</p>
      <Row><Col><strong>Question per Category:</strong> {testDetail.test_questionNbre}</Col>
      <Col><strong>Time per Qustion:</strong> {testDetail.timePerQuestion} Seconds<br /></Col></Row>
      <Row><Col><strong>Nbre Question Globale: </strong>{testDetail.question_globale}</Col><Col>
      <strong>Time of Test :</strong> {testDetail.question_globale*30} Seconds<br /></Col></Row>

      { passTest ? ( <Button
        variant="primary"
        onClick={() => {
          setShowTestsModal(false);

          if (isMissing) {
            window.location.href = `/TestPsychotechniqueCandidat/${testId}`;
          }
        }}
        disabled={!isMissing} 
      >
        {isMissing ? 'Pass Test' : 'Test Passed'}
      </Button>) : <></>}
    </li>
  );
})}
            </ul>
</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowTestsModal(false)}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
      </Card.Body>
    </Card>
  );
};

export default JobRoleList;

