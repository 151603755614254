import React,{useState,useContext, useEffect} from 'react';
import {Link} from 'react-router-dom';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import {Dropdown,Badge,Row,Col} from 'react-bootstrap';
import { MdMarkEmailRead } from "react-icons/md";
import axios from 'axios';
import translations from"../AppsMenu/Candidate/mulitlingue";
import { LiaMoneyCheckAltSolid } from "react-icons/lia";

//Import Components
import { ThemeContext } from "../../../context/ThemeContext";
import VacanyTab from '../Jobick/Home/VacanyTab';
import CanvasChartTab from '../Jobick/Home/CanvasChartTab';
import FeaturedCompanies from '../Jobick/Home/FeaturedCompanies';
import RecentActivity from '../Jobick/Home/RecentActivity';
import HomeSlider from '../Jobick/Home/HomeSlider';
import { BsCalendarDate } from "react-icons/bs";

//Images
import pic1 from './../../../images/profile/pic1.jpg';

//Components
const NewCustomers1 = loadable(() =>
	pMinDelay(import("../Jobick/Home/NewCustomers1"), 1000)
);
const NewCustomers2 = loadable(() =>
	pMinDelay(import("../Jobick/Home/NewCustomers2"), 1000)
);
const NewCustomers3 = loadable(() =>
	pMinDelay(import("../Jobick/Home/NewCustomers3"), 1000)
);
const NewCustomers4 = loadable(() =>
	pMinDelay(import("../Jobick/Home/NewCustomers4"), 1000)
);
const ApexPieChart = loadable(() =>
	pMinDelay(import("../Jobick/Home/ApexPieChart"), 1000)
);


const DashboardDark = () => {	
	const [UserLogPer, setUserLogPer] = useState([]);
	const [candidates, setCandidates] = useState([]);
	const token=localStorage.getItem("token");

useEffect(() => {
    const fetchUserPermissions = async () => {
        try {
            const currentUser = JSON.parse(localStorage.getItem("userDetails"));
            if (currentUser && currentUser.localId) {
                const userId = currentUser.localId;
                const response = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
                const data = await response.json();
                const hasPermission = data.some((permission) => permission.title === "view-user-logs");
				setUserLogPer(hasPermission)
                if (hasPermission) {
                    //loadUserLogs();
					setLoading(false);

                } else {
                    setLoading(false);
                }
            }
        } catch (error) {
            console.error("Error fetching permissions details:", error);
            setLoading(false);
        }
    };

    fetchUserPermissions();
}, []);

const [subscription, setSubscription] = useState([]);
const [user, setUser] = useState("");

const [quota, setQuota] = useState({});  
useEffect(() => {
    const fetchQuota = async () => {
        try {
			const currentUser = JSON.parse(localStorage.getItem("userDetails"));
		const userId = currentUser.localId;
			const responseC = await fetch(
				`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`
			  );
			  const data = await responseC.json();
			
			  if( data[0].PaymentControl ===0){
				if(user.compId!=1){
					const responsecomp = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/companieByid/${user.compId}`, 
						{
						  headers: {
							Authorization: `Bearer ${token}`,
						  }});
					setQuota(responsecomp.data || {});  
				}
				else if(user.compId===1 && user.role===3){
					const responseRec = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/users/getRecruiterSub/${user.userId}`, 
						{
						  headers: {
							Authorization: `Bearer ${token}`,
						  }});
					setQuota(responseRec.data || {});  
			}
			  }
		
        } catch (error) {
            console.error("Error fetching quota data:", error);
            setQuota({});  // Set quota to an empty object on error
        }
    };

    if (user.compId) {  
        fetchQuota();
    }
}, [user]);
  useEffect(() => {
	const fetchSubscription = async () => {
	  try {
		const currentUser = JSON.parse(localStorage.getItem("userDetails"));
		const userId = currentUser.localId;
			const responseC = await fetch(
				`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`
			  );
			  const data = await responseC.json();
		
		  if( data[0].PaymentControl===0){
			const responseSub = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/Subscription/getInvoiceByUserId/${userId}`, 
				{
				  headers: {
					Authorization: `Bearer ${token}`,
				  }});
			setSubscription(responseSub.data);
		  }

	  } catch (error) {
		console.error("Error fetching subscription data:", error);
	  }
	};
  
	fetchSubscription();
  }, [user]);
	const { changeBackground, background } = useContext(ThemeContext);
	const [compId, setCompId] = useState("");
	const [jobs, setJobs] = useState([]);
	const [jobsApp, setJobsApp] = useState([]);
	const [allApplicantNb, setAllApplicantNb] = useState("");
	const [userLogs, setUserLogs] = useState([]);
	const [loading, setLoading] = useState(true);
	const [profileViewed, setProfileViewed] = useState("");
	const [candidateAccepted, setCandidateAccepted] = useState("");
	const [skillsTop3, setSkillsTop3] = useState([]);
	const [skillsJobTop4, setSkillsJobTop4] = useState([]);
	const [lang] = useState(() => {
		const storedLang = localStorage.getItem("selectedLang");
		if (storedLang) {
		  const parsedLang = JSON.parse(storedLang);
		  if (parsedLang.language === "Fran�ais") {
			return translations.fr;
		  }
		}
		return translations.en;
	  });
	const avatarClasses = [
		"empty-avatar-red",
		"empty-avatar-green",
		"empty-avatar-pimary",
		"empty-avatar-yellow",
		"empty-avatar-purple",
	  ];
	const randomAvatarClass =
    avatarClasses[Math.floor(Math.random() * avatarClasses.length)];
	  useEffect(() => {
		changeBackground({ value: "dark", label: "Dark" });
		

	}, []); 
	const getUserEmail = async (userId) => {
		const responseC = await fetch(
		  `${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`
		);
		const data = await responseC.json();
	
		if (data && data.length > 0 && data[0].email) {
		  const email = data[0].email;
		  return email;
		} else {
		  console.error(
			"Erreur lors de la r�cup�ration de l'email pour l'utilisateur :",
			userId
		  );
		  return "";
		}
	  };
	useEffect(() => {
		const currentUser = JSON.parse(localStorage.getItem("userDetails"));
		const userId = currentUser.localId;
	
		const getUserCompanyID = async () => {
		  const responseC = await fetch(
			`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`
		  );
		  const data = await responseC.json();
		  const compId = data[0].companyID;
		  return compId;
		};
	
		axios
		  .get(`${process.env.REACT_APP_APP_DOMAIN}/api/userLogs/getAllUserLogs`, 
			{
			  headers: {
				Authorization: `Bearer ${token}`,
			  }})
		  .then(async (response) => {
			const logsWithEmails = await Promise.all(
			  response.data.map(async (log) => {
				const email = await getUserEmail(log.userId);
				return { ...log, userEmail: email };
			  })
			);
	
			setUserLogs(logsWithEmails);
			setLoading(false);
		  })
		  .catch((error) => {
			console.error("Erreur lors de la r�cup�ration des logs:", error);
			setLoading(false);
		  });
	  }, []);



	  const TableSub = ({ sub }) => {
		return (
			<>
			<div className="card-header pb-0 border-0 flex-wrap">
		
			<h4 className="fs-20 mb-3">{lang.menu.subDetails}</h4>
			<div className="d-flex">
				<Badge  variant="success "><LiaMoneyCheckAltSolid  size={25}/>{" "}{sub.status}</Badge>
			</div>
			</div>
			<div className="card-body"> 
			  <Row >
				<Col>
				  <strong><BsCalendarDate size={20}/>{lang.menu.closeDate} </strong>
				  <span>{new Date(sub.period_end).toLocaleDateString()}</span>
				</Col>
			
			  </Row>
			  <table className="table display mb-3 dataTablesCard job-table table-responsive-s card-table dataTable no-footer">
				<thead>
				  <tr>
					<th></th>
					<th>{lang.menu.quotaMax}</th>
					<th>{lang.menu.quotaUsed}</th>
				  </tr>
				</thead>
				<tbody>
				  <tr>
					<td>{lang.menu.quotaExtraction}</td>
					<td>{sub.extractionNumber}</td>
					<td>{quota[0].quotaExtraction}</td>
				  </tr>
				  <tr>
					<td>{lang.menu.quotaJobRole}</td>
					<td>{sub.quotaJobRole}</td>
					<td>{quota[0].quotaJobRole}</td>
	
				  </tr>
				  <tr>
					<td>{lang.menu.quotaJobProfile}</td>
					<td>{sub.quotaJobprofile}</td>
					<td>{quota[0].quotaJobProfile}</td>
	
				  </tr>
				  <tr>
					<td>{lang.menu.quotaParcoursJobTest}</td>
					<td>{sub.quotaParcours}</td>
					<td>{quota[0].quotaParcours}</td>
	
				  </tr>
				  <tr>
					<td>{lang.menu.quotaProfiles}</td>
					<td>{sub.user_profiles}</td>
					<td>{quota[0].quotaProfiles}</td>
	
				  </tr>
				</tbody>
			  </table>
		 </div>
		  </>
		);
	};


	const [control3, setControl3] = useState('Newest');
	useEffect(() => {
		const fetchData = async () => {
		  try {
			const currentUser = JSON.parse(localStorage.getItem('userDetails'));
			const userId = currentUser.localId;
	  
			// Requ�te pour r�cup�rer les d�tails de l'utilisateur
			const userResponse = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
			if (!userResponse.ok) {
			  throw new Error(`Error fetching user details: ${userResponse.status}`);
			}
			const userData = await userResponse.json();
	  
			// Mise � jour de l'�tat de l'utilisateur
			setUser({
			  userId: userId,
			  firstName: userData[0].FirstName || "",
			  lastName: userData[0].LastName || "",
			  email: userData[0].email || "",
			  password: userData[0].Password || "",
			  country: userData[0].Country || "",
			  jobTitle: userData[0].JobTitle || "",
			  phoneNumber: userData[0].PhoneNumber || "",
			  language: userData[0].Language || "",
			  avatar: userData[0].Avatar || "",
			  cover: userData[0].Cover || "",
			  signature: userData[0].EmailSignature || "",
			  role: userData[0].userRole || "",
			  disabled: userData[0].disabled,
			  roleName: userData[0].roleName || "",
			  compId: userData[0].companyID || "",
			  PaymentControl: userData[0].PaymentControl !== undefined ? userData[0].PaymentControl : ""
			});
	  
			setCompId(userData[0].companyID)
			const compId=userData[0].companyID
			const userResponseComp = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getTotalCvInfoByCompanyId/${compId}`, 
				{
				  headers: {
					Authorization: `Bearer ${token}`,
				  }});
				if (!userResponseComp.ok) {
					throw new Error(`Error fetching CV info: ${userResponseComp.status}`);
				}
				const cvPerComp = await userResponseComp.json();
				setProfileViewed(cvPerComp.totalCvInfo)
				const userResponseCandAccept = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getAcceptedCandidates/${compId}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
				if (!userResponseCandAccept.ok) {
					throw new Error(`Error fetching CV info: ${userResponseCandAccept.status}`);
				}
				const candAccept = await userResponseCandAccept.json();
				setCandidateAccepted(candAccept.candidateAccepted)
				const userResponseSkillsTop = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getSkillsTopCandidate/${compId}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
				if (!userResponseSkillsTop.ok) {
					throw new Error(`Error fetching CV info: ${userResponseSkillsTop.status}`);
				}
				const skillsTopCand = await userResponseSkillsTop.json();
				if (Object.keys(skillsTopCand.resultat).length !== 0) {
					const skillsData = skillsTopCand.resultat;
					const totalSkillsCount = Object.values(skillsData).reduce((acc, curr) => acc + curr, 0);
				  
					const skillsWithPercentage = Object.fromEntries(
					  Object.entries(skillsData).map(([skill, count]) => {
						const percentage = (count * 100) / totalSkillsCount;
						return [skill, percentage];
					  })
					);
				  
					setSkillsTop3(skillsWithPercentage);
					}
						  //	jobs Top 4
						  const userResponseSkillsTopJob = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getSkillsTopJob/${compId}`, 
							{
							  headers: {
								Authorization: `Bearer ${token}`,
							  }});
						  if (!userResponseSkillsTopJob.ok) {
							  throw new Error(`Error fetching CV info: ${userResponseSkillsTopJob.status}`);
						  }
						  const skillsTopJobs = await userResponseSkillsTopJob.json();
						  if (Object.keys(skillsTopJobs.resultat).length !== 0) {
							  const skillsData = skillsTopJobs.resultat;
							  const totalSkillsCount = Object.values(skillsData).reduce((acc, curr) => acc + curr, 0);
							
							  const skillsWithPercentage = Object.fromEntries(
								Object.entries(skillsData).map(([skill, count]) => {
								  const percentage = (count * 100) / totalSkillsCount;
								  return [skill, percentage];
								})
							  );
							
							  setSkillsJobTop4(skillsWithPercentage); 
		  
		  
		  
							}
			

		  } catch (error) {
			console.error(error);
		  }
		};
	
		fetchData();
	  }, []);

	  useEffect(() => {
		if (compId === "") {
			return;
		  }
		
		const fetchData = async () => {
		try {
			const jobsResponse = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/JobRole/getLatestJobsByCompanyId/${compId}`, 
				{
				  headers: {
					Authorization: `Bearer ${token}`,
				  }});
			if (!jobsResponse.ok) {
				if (jobsResponse.status === 404) {
				  throw new Error("No jobs found for the specified company ID");
				} else {
				  throw new Error(`Error fetching jobs: ${jobsResponse.status}`);
				}
			  }
			const jobsData = await jobsResponse.json();
			const jobsWithCandidates = await Promise.all(jobsData.map(async (job) => {
			  const candidates = await getCandidates(job.id);
			  return { ...job, candidateLength: candidates.length };
			}));
	  
			setJobs(jobsWithCandidates);





		} catch (error) {
			console.error(error);
		  }
		};
	
		fetchData();
	  }, [compId]);
	  useEffect(() => {
		const fetchData = async () => {
		try {

			const response4 = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/findAllApplicant`, 
				{
				  headers: {
					Authorization: `Bearer ${token}`,
				  }});
			if (response4.status !== 200) {
			  throw new Error(`Error fetching all Applicant: ${response4.status}`);
			}
			let AllApplicant = response4.data.length;
			setAllApplicantNb(AllApplicant);

		} catch (error) {
			console.error(error);
		  }
		};
	
		fetchData();
	  }, []);
	  useEffect(() => {
		if (compId === "") {
			return;
		  }
		const fetchData = async () => {
		try {
			// Requ�te pour r�cup�rer tous les emplois
			const response3 = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getAll`, 
				{
				  headers: {
					Authorization: `Bearer ${token}`,
				  }});
			if (response3.status !== 200) {
			  throw new Error(`Error fetching all jobs: ${response3.status}`);
			}
			let filteredJobs = response3.data;
	  
			if (compId!=1) {
			  filteredJobs = response3.data.filter((job) => job.companyid === compId);
			}
			else{
				filteredJobs = response3.data;
			}
	  
			let updatedJobs = [];
			await Promise.all(filteredJobs.map(async (job) => {
			  const candidates = await getCandidates(job.id);
			  const JobDetails = await getJobDetails(job.id);
			  const candidatesList = JobDetails.map(candidate => ({
				candidat: candidate.id_candidate,
				status: candidate.candAccept,
				lastActivity: candidate.last_activity || null,
			  }));
			  const candLength = candidates.length;
			  job.candLength = candLength;
			  job.candidatesList = candidatesList;
			  updatedJobs.push(job);
			}));
	  
			setJobsApp(updatedJobs);
		} catch (error) {
			console.error(error);
		  }
		};
	
		fetchData();
	  }, [compId]);

	  
	  const getCandidates = async (jobId) => {
		try {
		  const response = await axios.get(
			`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/findCandidatesByJobRoleIdApp/${jobId}`, 
			{
			  headers: {
				Authorization: `Bearer ${token}`,
			  }}
		  );
		  const candidates = response.data;
	
		  return candidates;
		} catch (error) {
		  console.error(`Error fetching job skills for ID ${jobId}:`, error);
		  return [];
		}
	  };
	  const getJobDetails = async (jobId) => {
		try {
			const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getJobRoleDetailsForCandidates/${jobId}`, 
				{
				  headers: {
					Authorization: `Bearer ${token}`,
				  }});
		  const candidates = response.data;
		  return candidates;
		} catch (error) {
		  console.error(`Error fetching job skills for ID ${jobId}:`, error);
		  return [];
		}
	  };
	return(    
		<>
			<div className="row">
				<div className="col-xl-6">
					<div className="row">
						<div className="col-xl-12">
							<div className="card">
								<div className="card-body">
									<div className="row separate-row">
										<div className="col-sm-6">
											<div className="job-icon pb-4 d-flex justify-content-between">
												<div>
													<div className="d-flex align-items-center mb-1">
														<h2 className="mb-0">{allApplicantNb}</h2>
													</div>	
													<span className="fs-14 d-block mb-2">{lang.menu.assessmentsSchedules}</span>
												</div>	
												<div id="NewCustomers">
													<NewCustomers1 />
												</div>
											</div>
										</div>
										<div className="col-sm-6">
											<div className="job-icon pb-4 pt-4 pt-sm-0 d-flex justify-content-between">
												<div>
													<div className="d-flex align-items-center mb-1">
														<h2 className="mb-0">{allApplicantNb}</h2>
													</div>	
													<span className="fs-14 d-block mb-2">{lang.menu.applicationSent}</span>
												</div>	
												<div id="NewCustomers1">
													<NewCustomers2 />
												</div>
											</div>
										</div>
										<div className="col-sm-6">
											<div className="job-icon pt-4 pb-sm-0 pb-4 pe-3 d-flex justify-content-between">
												<div>
													<div className="d-flex align-items-center mb-1">
														<h2 className="mb-0">{profileViewed}</h2>
													</div>	
													<span className="fs-14 d-block mb-2">{lang.menu.profileViewed}</span>
												</div>	
												<div id="NewCustomers2">
													<NewCustomers3 />
												</div>
											</div>
										</div>
										<div className="col-sm-6">
											<div className="job-icon pt-4  d-flex justify-content-between">
												<div>
													<div className="d-flex align-items-center mb-1">
														<h2 className="mb-0">{candidateAccepted}</h2>
													</div>	
													<span className="fs-14 d-block mb-2">{lang.menu.acceptedCandidates}</span>
												</div>	
												<div id="NewCustomers3">
													<NewCustomers4 />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						{skillsJobTop4 && skillsJobTop4.length!=0 ? (
						<div className="col-xl-12">
  <div className="card">
    <div className="card-body">
      <h4 className="fs-20 mb-3">{lang.menu.skillsForJobs}</h4>
      <div className="row">
        {Object.entries(skillsJobTop4)
          .slice(0, 4)
          .map(([skill, value]) => (
            <div className="col-xl-6 col-sm-6" key={skill}>
              <div className="progress default-progress">
                <div className="progress-bar bg-green progress-animated" style={{ width: `${value}%`, height: "13px" }} role="progressbar">
                  <span className="sr-only">{value.toFixed(2)}% Complete</span>
                </div>
              </div>
              <div className="d-flex align-items-end mt-2 pb-4 justify-content-between">
                <span className="fs-14 font-w500">{skill}</span>
                <span className="fs-16"><span className="text-black pe-2"></span>{value.toFixed(2)}%</span>
              </div>
            </div>
          ))}
      </div>
    </div>
  </div>
</div>
):(<></> )}
					{/* 	<div className="col-xl-12">
							<VacanyTab jobsApp={jobsApp}/>
						</div>
						<div className="col-xl-12">
							<CanvasChartTab  />
						</div>
						<div className="col-xl-12">
							<FeaturedCompanies />
						</div> */}
						{jobs.length > 0 ? (
						<div className="card">
								<div className="card-header pb-0 border-0 flex-wrap">
									<h4 className="fs-20 mb-3">{lang.menu.availableJobsForYou}</h4>
									<div className="d-flex">	
										<Dropdown className="default-select bgl-primary rounded" >
											<Dropdown.Toggle  as="div" className="btn btn-sm  text-primary d-flex align-items-center i-false" >
												{control3}
												<i className="fas fa-angle-down text-primary scale5 ms-3"></i>
												
											</Dropdown.Toggle>
											<Dropdown.Menu className="dropdown-menu dropdown-menu-end mt-1">
												<Dropdown.Item className=" text-primary"  onClick={()=>setControl3("Newest")}>
													Newest
												</Dropdown.Item>
												<Dropdown.Item className=" text-primary" onClick={()=>setControl3("Oldest")}>
													Oldest
												</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
										<Dropdown className="custom-dropdown mb-0">
											<Dropdown.Toggle as="button" className="btn sharp tp-btn dark-btn i-false">
												<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#342E59" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
													<path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="#342E59" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
													<path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="#342E59" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
												</svg>
											</Dropdown.Toggle>
											<Dropdown.Menu className="dropdown-menu-right">
												<Dropdown.Item>{lang.menu.details}</Dropdown.Item>
												<Dropdown.Item className="text-danger">{lang.menu.cancel}</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
									</div>	
								</div>
								<div className="card-body">
								<div className="owl-carousel owl-carousel owl-loaded front-view-slider">
										{jobs.length > 0 ? (
										<HomeSlider jobs={jobs} />
										) : (
										<p>{lang.menu.noJobs}</p>
										)}
									</div>
								</div>
							</div>
					):(<></> )}
					</div>
				</div>
				<div className="col-xl-6">
					<div className="row">
						<div className="col-xl-12">
							<div className="card">
								<div className="card-body">
									<div className="row ">
										<div className="col-xl-8 col-xxl-7 col-sm-7">
											<div className="update-profile d-flex">
											{user.avatar ? (
              <img
                className="rounded-circle"
                src={`${process.env.REACT_APP_APP_DOMAIN}/api/images/avatars/${user.avatar}`}
                alt=""
				style={{ width: '50%', height: '50%' }}
              />
            ) : (
              <div
                className={`profile-pic-empty empty-avatar rounded-circle ${randomAvatarClass}`}
				style={{ width: '300%', height: '300%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '300%' }} 
              >
                {user.firstName}
				
              </div>
            )}
												<div className="ms-4">
													<h3 className="fs-24 font-w400 mb-0">{user.firstName}{" "} {user.lastName}</h3>
													<span className="text-primary d-block mb-4">{user.roleName}</span>
													
												</div>
												
											</div>
										

										</div>
										
										<div className="col-xl-4 col-xxl-5 col-sm-5 sm-mt-auto mt-3">
											<Link to={"/profile"} className="btn btn-primary btn-rounded">{lang.menu.updateProfile}</Link>
										</div>
										
									</div>
									<div className="col-xl-8 ">
											<span
								style={{ padding:"5%",display: 'flex', alignItems: 'left', justifyContent: 'left', fontSize: '150%' }} 

											><MdMarkEmailRead size={30} /> {' '}{user.email}</span>
										</div>

								</div>
							</div>
						</div>
						
{skillsTop3 && skillsTop3.length!=0 ? (
						<div className="col-xl-12">
						<div className="card">
								<div className="card-body">
						<div className="row mt-4 align-items-center">
  <h4 className="fs-20 mb-3">{lang.menu.skillsForCandidates}</h4>
  <div className="col-xl-6 col-sm-6">
  {Object.entries(skillsTop3)
  .slice(0, 3)
  .map(([skill, value]) => (
    <React.Fragment key={skill}>
      <div className="progress default-progress">
        <div className="progress-bar bg-green progress-animated" style={{ width: `${value}%`, height: "13px" }} role="progressbar">
          <span className="sr-only">{value.toFixed(2)}% Complete</span>
        </div>
      </div>
      <div className="d-flex align-items-end mt-2 pb-4 justify-content-between">
        <span className="fs-14 font-w500">{skill}</span>
        <span className="fs-16"><span className="text-black pe-2"></span>{value.toFixed(2)}%</span>
      </div>
    </React.Fragment>
  ))}

  </div>
  <div className="col-xl-6 col-sm-6">
    <div id="pieChart1">
      <ApexPieChart skillsTop3={skillsTop3} />
    </div>
  </div>
</div>
</div>
							</div>
						</div>	


):(<></> )}
						<div className="col-xl-12">
						<div className="card">

						{subscription && quota && quota.length > 0 && user && user.compId === 1 && user.role === 3 && user.PaymentControl === 0 ? (
    <TableSub sub={subscription} />
  ) : subscription && quota && quota.length > 0 && user && user.compId !== 1&& (user.role === 2 || user.role === 3)&& user.PaymentControl === 0 ? (
    <TableSub sub={subscription} />
  ) : (
    <>
    </>
  )}
</div>
						</div>
				


					</div>
				</div>
		
			</div>	
		</>
	)
}
export default DashboardDark;