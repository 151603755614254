import React, { useEffect, useState } from "react";
import { Button, ListGroup, Card, Col } from 'react-bootstrap';
import translations from "../../AppsMenu/Candidate/mulitlingue";

const calculateComplexity = (time) => {

  if (time <= 1) {
    return "O(1)";
  } else if (time <= 10) {
    return "O(log n)";
  } else if (time <= 100) {
    return "O(n)";
  } else {
    return "O(n^2)";
  }
};

const OutputDetails = ({ outputDetails }) => {
  const complexity = calculateComplexity(outputDetails?.time);
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  useEffect(() => {
    const selectedLang = JSON.parse(localStorage.getItem("selectedLang"));
    if (selectedLang) {
      setLang(selectedLang);
    }
  }, []);
  return (
    <Card style={{ backgroundColor: "#e9f9fd", color: "black", width: "90%", height: "85%", textAlign: "left", fontSize: "13px", marginLeft: "5%", marginTop: "2%" }}>
      <Card.Title style={{ fontFamily: "Poppins" }}>{lang.menu.details}</Card.Title>
      <Card.Body style={{ fontFamily: "Poppins" }}>
        <div className="basic-list-group">
          <ListGroup>
            <ListGroup.Item className="d-flex justify-content-between align-items-center">
            {lang.menu.status}:{" "}
              <span className="font-semibold px-2 py-1 rounded-md bg-gray-100">
                {outputDetails?.status?.description}
              </span>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex justify-content-between align-items-center">
            {lang.menu.memory}:{" "}
              <span className="font-semibold px-2 py-1 rounded-md bg-gray-100">
                {outputDetails?.memory}
              </span>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex justify-content-between align-items-center">
            {lang.menu.time}:{" "}
              <span className="font-semibold px-2 py-1 rounded-md bg-gray-100" style={{ marginLeft: "10px" }}>
              {lang.menu.estimatedComp}: {complexity}
              </span>
              <span className="font-semibold px-2 py-1 rounded-md bg-gray-100">
                {outputDetails?.time}
              </span>
            
            </ListGroup.Item>
          </ListGroup>
        </div>
      </Card.Body>
    </Card>
  );
};

export default OutputDetails;
