import React, { useState, useEffect, useRef } from "react";
import "./technical.css";
import LandingResult from "./Components/LandingResult";
import {
  Col,
  Row,
  Accordion,
  Card,
  Badge,
  Table,
  Button,
} from "react-bootstrap";
import translations from "../AppsMenu/Candidate/mulitlingue";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { BsArrowsFullscreen } from "react-icons/bs";

import axios from "axios";

function TechRes() {
  const [topicDetails, setTopicDetails] = useState();
  const handle = useFullScreenHandle();
  const [isFullScreen, setIsFullScreen] = useState(true);
  const [response2, setResponse2] = useState(null);
  const token=localStorage.getItem("token");

  const toggleFullScreen = () => {
    if (isFullScreen) {
      handle.exit();
    } else {
      handle.enter();
    }
    setIsFullScreen(!isFullScreen);
  };

  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });

  useEffect(() => {
    const selectedLang = JSON.parse(localStorage.getItem("selectedLang"));
    if (selectedLang) {
      setLang(selectedLang);
    }
  }, []);

  const pathname = window.location.pathname;
  const idtopic = pathname.split("/").pop();
  const jobroleId = localStorage.getItem("jobRoleId");

  useEffect(() => {
    const fetchTopicDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_APP_DOMAIN}/api/topic/getTopicById/${idtopic}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }}
        );
        setTopicDetails(response.data);
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    if (!topicDetails) {
      fetchTopicDetails();
    }
  }, [topicDetails, idtopic]);

  useEffect(() => {
    const fetchCodeForTest = async () => {
      try {
        const userId = localStorage.getItem("selectedCandidate");
        const response2 = await axios.get(
          `${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getcodeFForTestId/${jobroleId}/${userId}/${idtopic}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }}
        );
        setResponse2(response2.data);
        console.log("response2.data :", response2.data);

        if (topicDetails) {
          setTopicDetails({
            ...topicDetails,
            codeF: response2.data.codeF,
          });
          console.log("topicDetails :", topicDetails);
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (!response2 && topicDetails) {
      fetchCodeForTest();
    }
  }, [response2, topicDetails, jobroleId, idtopic]);

  return (
    <FullScreen handle={handle}>
      <Row>
        <Col>
          {" "}
          <span style={{ fontFamily: "Poppins", fontSize: "230%" }}>
          {lang.menu.codTest}{" "}
          </span>
        </Col>
        <Col>
          <Button
            onClick={toggleFullScreen}
            variant="primary badge-xl light"
            style={{ marginLeft: "90%", marginBottom: "2%" }}
          >
            <BsArrowsFullscreen />
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Card style={{ fontFamily: "Poppins" }}>
            <Card.Header className="d-block">
              <Card.Title>{topicDetails ? topicDetails.name : ""}</Card.Title>
            </Card.Header>
            <Card.Body>
              <Accordion
                className="accordion accordion-danger-solid"
                defaultActiveKey="0"
              >
                <div className="accordion-item">
                  <span className="accordion-header-text">
                    <Badge
                      variant="primary badge-xl light"
                      style={{ width: "100%", textAlign: "left" }}
                    >
                      {lang.menu.description} :
                    </Badge>
                    <p style={{ color: "black", textAlign: "left" }}>
                      {topicDetails ? topicDetails.description : ""}
                    </p>
                  </span>
                  <span className="accordion-header-text">
                    <Badge
                      variant="primary badge-xl light"
                      style={{ width: "100%", textAlign: "left" }}
                    >
                      {lang.menu.exemple}:
                    </Badge>
                  </span>
                </div>
              </Accordion>
              <Table
                responsive
                hover
                className="header-border verticle-middle"
                style={{ backgroundColor: "#e9f9fd" }}
              >
                <thead>
                  <th scope="col">{lang.menu.input}</th>
                  <th scope="col"> {lang.menu.expectedOutPut}</th>
                </thead>
                <tbody className="pt-4">
                  <tr>
                    <td>{topicDetails ? topicDetails.input1 : ""}</td>
                    <td>{topicDetails ? topicDetails.expectedOutput1 : ""}</td>
                  </tr>
                  <tr>
                    <td>{topicDetails ? topicDetails.input2 : ""}</td>
                    <td>{topicDetails ? topicDetails.expectedOutput2 : ""}</td>
                  </tr>
                  <tr>
                    <td>{topicDetails ? topicDetails.input3 : ""}</td>
                    <td>{topicDetails ? topicDetails.expectedOutput3 : ""}</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col md={8}>
        {topicDetails && topicDetails.codeF!=""? (

          <LandingResult
            idTopic={topicDetails ? topicDetails.id : ""}
            input1={topicDetails ? topicDetails.input1 : ""}
            expectedOutput1={topicDetails ? topicDetails.expectedOutput1 : ""}
            input2={topicDetails ? topicDetails.input2 : ""}
            expectedOutput2={topicDetails ? topicDetails.expectedOutput2 : ""}
            input3={topicDetails ? topicDetails.input3 : ""}
            expectedOutput3={topicDetails ? topicDetails.expectedOutput3 : ""}
            LanguageId={topicDetails ? topicDetails.LanguageId : ""}
            Squelette={topicDetails ? topicDetails.codeF : ""}
            ExtentionCode={topicDetails ? topicDetails.ExtentionCode : ""}
          />) : (
            <></>
            )}
        </Col>
      </Row>
    </FullScreen>
  );
}

export default TechRes;
