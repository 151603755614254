import axios from "axios";
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import swal from "sweetalert";
import translations from "../AppsMenu/Candidate/mulitlingue";

function NewTest() {
  const token=localStorage.getItem("token");

  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  useEffect(() => {
    const selectedLang = JSON.parse(localStorage.getItem("selectedLang"));
    if (selectedLang) {
      setLang(selectedLang);
    }
  }, []);
  const history = useHistory();
  const [height, setHeight] = useState("150px");
  const [testData, setTestData] = useState({
    testTitle: "",
    testDescription: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_APP_DOMAIN}/api/post-test`, testData, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }})
      .then((res) => {
        swal("Poof! Your Test has been created! Now pls add your questions!", {
          icon: "success",
        });

        const testId = res.data.insertId;
        history.push({
          pathname: "/upload-test",
          search: `?testId=${testId}`,
        });

        // history.push(`/upload-test/${testId}`);
      })
      .catch((err) => {
        swal("Oops!", "Something went wrong!", "error");
        console.log(err);
      });
  };
  return (
    <>
      <h1>{lang.menu.CreatePersonalityTest} </h1>
      <br />
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header d-block">
                <h3>{lang.menu.TestName}</h3>

                <div className="input-group ">
                  <input
                    required
                    type="text"
                    className={`form-control `}
                    placeholder="Test Title"
                    onChange={(e) =>
                      setTestData({ ...testData, testTitle: e.target.value })
                    }
                  />
                </div>
                <br />
                <h3>{lang.menu.TestDescription}</h3>
                <div className="input-group">
                  <textarea
                    required
                    style={{ height: height }}
                    onChange={(e) =>
                      setTestData({
                        ...testData,
                        testDescription: e.target.value,
                      })
                    }
                    type="text"
                    className={`form-control`}
                    placeholder=" Test Description"
                  />
                </div>
                <br />

                <div className="card-footer border-0">
                  <div className="d-flex justify-content-end">
                    <Button
                      type="submit"
                      className="btn btn-primary btn sweet-confirm"
                    >
                      {lang.menu.PostNow}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default NewTest;