import React, { useState, useEffect, useRef } from "react";
import "./technical.css";
import {
  Col,
  Row,
  Accordion,
  Card,
  Badge,
  Table,
  Button,
} from "react-bootstrap";
import { BsArrowsFullscreen } from "react-icons/bs";
import axios from "axios";
import translations from "../AppsMenu/Candidate/mulitlingue";
import { useHistory  } from "react-router-dom/cjs/react-router-dom.min";
import LandingRecruiter from "./Components/LandingRecruiter";

function TechRecruiter() {
    const history = useHistory();
  // get user id
  const userDetailsString = localStorage.getItem("userDetails");
  const userDetails = JSON.parse(userDetailsString);
  const selectedCandidate = userDetails ? userDetails.localId : null;

  const token=localStorage.getItem("token");

  
  const [topicDetails, setTopicDetails] = useState();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const pathname = window.location.pathname;
  const idtopic = pathname.split("/").pop();

  const [showTestPolicy, setShowTestPolicy] = useState(true);

  






  const [elementRef, setElementRef] = useState(null);
  const [ideRef, setIdeRef]= useState(null);

  // language
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  useEffect(() => {
    const selectedLang = JSON.parse(localStorage.getItem("selectedLang"));
    if (selectedLang) {
      setLang(selectedLang);
    }
  }, []);

  useEffect(() => {
    const fetchTopicDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_APP_DOMAIN}/api/topic/getTopicById/${idtopic}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }}
        );
        setTopicDetails(response.data);

        localStorage.setItem('questionsNbrCoding', response.data.Timer);
      } catch (error) {
        console.error(error);
      }
    };
 
    fetchTopicDetails();
  }, []);




  //timer
  const testTime  = localStorage.getItem("questionsNbrCoding");



  useEffect(() => {
    const fullscreenChangeHandler = () => {
      setIsFullScreen(document.fullscreenElement !== null);
    };

    document.addEventListener("fullscreenchange", fullscreenChangeHandler);

    return () => {
      document.removeEventListener("fullscreenchange", fullscreenChangeHandler);
    };
  }, []);


  const enterFullScreen = (ref) => {
    const element = ref;
    
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  };

  const exitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };

  

  const handleFullScreenButtonClick = (ref) => {
    setIsFullScreen(true);
    enterFullScreen(ref);
  };



 const handleExit = () => {
    if(isFullScreen){
    exitFullScreen();
    setIsFullScreen(false);
    }
    
    
    //window.history.go(-1);
    history.goBack();

  }; 



  const handleCancel2 = () => {
    setShowTestPolicy(false);
    handleExit();
  };

 
  const [confirmTestPolicy, setConfirmTestPolicy]= useState(false);
 

  return (
    <div>
    
          {confirmTestPolicy &&
          <Card ref={(ref) => setElementRef(ref)}>
            <Row>
              <Col>
                {" "}
                <span style={{ fontFamily: "Poppins", fontSize: "230%" }}>
                  {lang.menu.testTechnique}{" "}
                </span>
              </Col>
              <Col>
                <Button
                  onClick={()=>handleFullScreenButtonClick(ideRef)}
                  variant="primary badge-xl light"
                  style={{ marginLeft: "90%", marginBottom: "2%" }}
                >
                  <BsArrowsFullscreen />
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Card style={{ fontFamily: "Poppins" }}>
                  <Card.Header className="d-block">
                    <Card.Title>
                      {topicDetails ? topicDetails.name : ""}
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Accordion
                      className="accordion accordion-danger-solid"
                      defaultActiveKey="0"
                    >
                      <div className="accordion-item">
                        <span className="accordion-header-text">
                          <Badge
                            variant="primary badge-xl light"
                            style={{ width: "100%", textAlign: "left" }}
                          >
                            {lang.menu.description} :
                          </Badge>
                          <p style={{ color: "black", textAlign: "left" }}>
                            {topicDetails ? topicDetails.description : ""}
                          </p>
                        </span>
                        <span className="accordion-header-text">
                          <Badge
                            variant="primary badge-xl light"
                            style={{ width: "100%", textAlign: "left" }}
                          >
                            {lang.menu.exemple} :
                          </Badge>
                        </span>
                      </div>
                    </Accordion>
                    <Table
                      responsive
                      hover
                      className="header-border verticle-middle"
                      style={{ backgroundColor: "#e9f9fd" }}
                    >
                      <thead>
                        <th scope="col">{lang.menu.input}</th>
                        <th scope="col"> {lang.menu.expectedOutPut}</th>
                      </thead>
                      <tbody className="pt-4">
                        <tr>
                          <td>{topicDetails ? topicDetails.input1 : ""}</td>
                          <td>
                            {topicDetails ? topicDetails.expectedOutput1 : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>{topicDetails ? topicDetails.input2 : ""}</td>
                          <td>
                            {topicDetails ? topicDetails.expectedOutput2 : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>{topicDetails ? topicDetails.input3 : ""}</td>
                          <td>
                            {topicDetails ? topicDetails.expectedOutput3 : ""}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={8} ref={(ref) => setIdeRef(ref)}>
              
                <LandingRecruiter
                  idTopic={topicDetails ? topicDetails.id : ""}
                  input1={topicDetails ? topicDetails.input1 : ""}
                  expectedOutput1={
                    topicDetails ? topicDetails.expectedOutput1 : ""
                  }
                  input2={topicDetails ? topicDetails.input2 : ""}
                  expectedOutput2={
                    topicDetails ? topicDetails.expectedOutput2 : ""
                  }
                  input3={topicDetails ? topicDetails.input3 : ""}
                  expectedOutput3={
                    topicDetails ? topicDetails.expectedOutput3 : ""
                  }
                  LanguageId={topicDetails ? topicDetails.LanguageId : ""}
                  Squelette={topicDetails ? topicDetails.Squelette : ""}
                  ExtentionCode={topicDetails ? topicDetails.ExtentionCode : ""}
                  ExitTest={handleExit}
                  allowCopyPaste={true}
                />
           
              </Col>
            </Row>
          </Card >

}
       
     

      
{showTestPolicy && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0 0 200px rgba(0, 0, 0, 0.3)",
          }}
        >
          <h1>{lang.menu.TestPolicy}</h1>
          <ul>
            <li>
              {lang.menu.TestPolicyComment}
            </li>
          </ul>
          <Button variant="success" onClick={()=>{setShowTestPolicy(false);
        setConfirmTestPolicy(true);}}>{lang.menu.Confirm}</Button>
          <Button variant="danger" onClick={handleCancel2}>{lang.menu.Cancel}</Button>
        </div>
      )}

      
     
    </div>
  );
}

export default TechRecruiter;