import { useState } from "react";
import { FiUpload } from "react-icons/fi";
import "./style/uploadFilePopup.css";
import { Button } from "react-bootstrap";
import axios from "axios";
import oops from './oops.png';
import thankYou from './thankYou .png';
import ISO6391 from "iso-639-1";

export default function FirstTab({ lang,  handleClose , upRefreshData }) {
  const token=localStorage.getItem("token");

  const [file, setFile] = useState({
    selectedFile: null,
  });
  const [extractionMode, setExtractionMode] = useState(false);
  const [dragActive, setDragActive] = useState(false);
  const [msg, setMsg] = useState("");
  const [errorExtract, setErrorExtract] = useState(false);
  const [fileUploadedError, setFileUploadedError] = useState(false);
  const [dataExtracted, setDataExtracted] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const checkFileType = (e, eventType) => {
    let extension;

    if (eventType === "drop") {
      extension = e.dataTransfer.files[0].name.match(/\.([^.]+)$/)[1];
    } else {
      extension = e.target.value.match(/\.([^.]+)$/)[1];
    }

    switch (extension) {
      case "pdf":
        eventType !== "drop"
          ? setFile({ selectedFile: e.target.files[0] })
          : setFile({ selectedFile: e.dataTransfer.files[0] });
        setMsg("");
        break;
      default:
        setFile({ selectedFile: null });
        setMsg(`.${extension} ${lang.menu.fins}`);
    }
  };

  const checkSize = (e, eventType) => {
    let size;
    if (eventType === "drop") {
      // console.log("size", e.dataTransfer.files[0]);
      size = e.dataTransfer.files[0].size / 8;
    } else {
      // console.log("size", e.target.files[0].size);
      size = e.target.files[0].size / 8;
    }
    // console.log("converted size", size);

    if (size <= 512000) {
      checkFileType(e, eventType);
    } else {
      setMsg(`${lang.menu.ssblt}`);
    }
  };

  const chooseFile = (e) => {
    if (e.target.files && e.target.files[0]) {
      checkSize(e);
      // checkFileType(e);
    }
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      checkSize(e, "drop");
      // checkFileType(e, "drop");
    }
  };
  const handleCancel = () => {
    handleClose();
  };
const handleRemoveDuplicates = (originalList) => {
    const lowerCaseList = originalList.map(item => item.toLowerCase());
    const uniqueLowerCaseList = [...new Set(lowerCaseList)];

    // Map the unique lower case list back to their original case based on the first occurrence
    const uniqueList = [];
    uniqueLowerCaseList.forEach(lowerItem => {
      const originalItem = originalList.find(item => item.toLowerCase() === lowerItem);
      uniqueList.push(originalItem);
    });

    return(uniqueList);
  };

  const monthTranslation = {
    "janvier": "01", "février": "02", "mars": "03", "avril": "04", "mai": "05", "juin": "06",
    "juillet": "07", "août": "08", "aout": "08", "septembre": "09", "octobre": "10", "novembre": "11", "décembre": "12",
    "january": "01", "february": "02", "march": "03", "april": "04", "may": "05", "june": "06",
    "july": "07", "august": "08", "september": "09", "october": "10", "november": "11", "december": "12"
  };
  
  const translateMonth = (month) => {
    const monthLower = month.toLowerCase();
    return monthTranslation[monthLower] || null;
  };
  
  const convertToStandardDate = (dateStr) => {
    let match;
  
    // Format: "DD/MM/YYYY - DD/MM/YYYY" or "DD-MM-YYYY - DD-MM-YYYY" or "DD.MM.YYYY - DD.MM.YYYY"
    match = dateStr.match(/(\d{2}[\/\-\.]\d{2}[\/\-\.]\d{4}) - (\d{2}[\/\-\.]\d{2}[\/\-\.]\d{4})/);
    if (match) {
      const startDate = match[1].replace(/[\/\-\.]/g, '-').split('-').reverse().join('-');
      const endDate = match[2].replace(/[\/\-\.]/g, '-').split('-').reverse().join('-');
      return [startDate.slice(0, 7), endDate.slice(0, 7)];
    }
  
    // Format: "MM/YYYY - MM/YYYY" or "MM-YYYY - MM-YYYY" or "MM.YYYY - MM.YYYY"
    match = dateStr.match(/(\d{2}[\/\-\.]\d{4}) - (\d{2}[\/\-\.]\d{4})/);
    if (match) {
      const startDate = match[1].replace(/[\/\-\.]/g, '-');
      const endDate = match[2].replace(/[\/\-\.]/g, '-');
      return [startDate.slice(3) + '-' + startDate.slice(0, 2), endDate.slice(3) + '-' + endDate.slice(0, 2)];
    }
  
    // Format: "Month YYYY - Month YYYY"
    match = dateStr.match(/([a-zA-Zéû]+) (\d{4})-([a-zA-Zéû]+) (\d{4})/);
    if (match) {
      const startMonth = translateMonth(match[1]);
      const startYear = match[2];
      const endMonth = translateMonth(match[3]);
      const endYear = match[4];
      if (startMonth && endMonth) {
        const startDate = `${startYear}-${startMonth}`;
        const endDate = `${endYear}-${endMonth}`;
        return [startDate, endDate];
      }
    }
  
    // Format: "Month YYYY"
    match = dateStr.match(/([a-zA-Zéû]+) (\d{4})/);
    if (match) {
      const month = translateMonth(match[1]);
      const year = match[2];
      if (month) {
        const date = `${year}-${month}`;
        return [date, date];
      }
    }
  
    // Format: "YYYY"
    match = dateStr.match(/(\d{4})/);
    if (match) {
      const year = match[1];
      const date = `${year}-01`;
      return [date, date];
    }
  
    // Handle unknown format
    throw new Error(`Unknown date format: ${dateStr}`);
  };
  
  const standardizeDates = (datesList) => {
    return datesList.map(dateStr => {
      try {
        const [startDate, endDate] = convertToStandardDate(dateStr);
        return { startDate, endDate };
      } catch (e) {
        console.error(e.message);
        // Handle the error or log it
        return { startDate: null, endDate: null };
      }
    });
  };
  
  const handleFileExtract = async () => {
    const formData = new FormData();
    console.log("file : ", file.selectedFile.name);
  
    formData.append("file", file.selectedFile.name);
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_APP_DOMAIN}/flask/candidates/extraction2`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
  
      console.log("response 2", response);
      if (response.status > 499) {
        setErrorExtract(true);
        setErrorMsg(`${lang.menu.wadwalotrnptal}`);
        throw new Error("Failed to extract data");
      }
      if (response.status > 399) {
        setErrorExtract(true);
        setErrorMsg(`${lang.menu.suibtrptal}`);
        throw new Error("Failed to extract data");
      }
  
      const data = response.data;
      console.log("File uploaded successfully:", data);
  
      // Function to split a string into the first part and last word
      const splitString = (inputString) => {
        const words = inputString.split(' ');
        const lastWord = words.pop();
        const firstString = words.join(' ');
        return { firstString, lastWord };
      };
  
      // Function to check and merge strings
      const stringChecker = (string1, string2) => {
        if (!string1 && !string2) return "";
        if (!string1) return string2;
        if (!string2) return string1;
        return string1.includes(string2) ? string1 : string1 + string2;
      };
  
      let l = "ar";
      if (data.language.length > 0) {
        l = ISO6391.getCode(data.language[0]);
      }
  
      const GeneralInfo = {
        FirstName: splitString(data.name).firstString,
        LastName: splitString(data.name).lastWord,
        email: data.email,
        country: data.country || "Tunisia",
        phoneNumber: stringChecker(data.phone2, data.phone),
        language: l,
        linkedIn: data.linkdin,
        github: data.github,
      };
  
      const TechnicalSkills = {
        selectedSkills: handleRemoveDuplicates(data.skill),
        proficiencyLevels: {}
      };
  
      const allSkills = data.skill;
  
      for (let skill of data.skill) {
        TechnicalSkills.proficiencyLevels[skill] = "Junior";
      }
  
      let Education = [];
      for (let i in data.degree) {
        Education.push({
          degree: data.degree[i],
          endDate: "",
          institution: data.university[i],
          location: "",
          startDate: ""
        });
      }
      if (Education.length === 0) {
        Education.push({
          degree: "",
          endDate: "",
          institution: "",
          location: "",
          startDate: ""
        });
      }
  
      let Experiences = [];
  
      const exps = splitByMultipleDelimiters(data.exp, data.dates);
      const standardizedDates = standardizeDates(data.dates);
  
      for (let i in exps) {
        Experiences.push({
          company: "",
          post: exps[i],
          location: "",
          startDate: standardizedDates[i] ? standardizedDates[i].startDate : "",
          endDate: standardizedDates[i] ? standardizedDates[i].endDate : ""
        });
      }
  
      if (Experiences.length === 0) {
        Experiences.push({
          company: "",
          post: "",
          location: "",
          startDate: "",
          endDate: ""
        });
      }
  
      const SoftSkills = {
        selectedLanguages: handleRemoveDuplicates(data.language),
        proficiencyLevels: {}
      };
  
      for (let lang of data.language) {
        SoftSkills.proficiencyLevels[lang] = "Beginner";
      }
  
      localStorage.setItem('GeneralInfo', JSON.stringify(GeneralInfo));
      localStorage.setItem('Education', JSON.stringify(Education));
      localStorage.setItem('Experiences', JSON.stringify(Experiences));
      localStorage.setItem('allSkills', JSON.stringify(allSkills));
      localStorage.setItem('TechnicalSkills', JSON.stringify(TechnicalSkills));
      localStorage.setItem('SoftSkills', JSON.stringify(SoftSkills));
      setDataExtracted(true);
      upRefreshData();
  
    } catch (error) {
      setExtractionMode(true);
      setFileUploadedError(true);
      console.log("Error extracting data. Please try again later.");
    }
  };
  
  
  const splitByMultipleDelimiters=(mainString, delimiters)=> {
    let parts = [mainString];
    delimiters.forEach(delimiter => {
        let tempParts = [];
        parts.forEach(part => {
            let index = part.indexOf(delimiter);
            while (index !== -1) {
                tempParts.push(part.substring(0, index));
                part = part.substring(index + delimiter.length);
                index = part.indexOf(delimiter);
            }
            tempParts.push(part);
        });
        parts = tempParts;
    });
    return parts.map(part => part.trim());
}
  const handleCV = async () => {
    if (file.selectedFile !== null && file.selectedFile !== undefined) {
      const formData = new FormData();

      formData.append("files", file.selectedFile);

      console.log("file : ", file.selectedFile.name);
try {
      const response = await axios
        .post(
          `${process.env.REACT_APP_APP_DOMAIN}/flask/candidates/uploadSingleFileOnboard`,
          //`http://212.132.77.152/flask/candidates/uploadSingleFileOnboard`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
        .then((response) => {
          console.log("response", response);
          if (response.data.message !== "Files uploaded successfully!") {
            setMsg(`${lang.menu.ftufpta}`);
            throw new Error("Failed to upload file");

          }
          setExtractionMode(true);
          handleFileExtract();
          return response.data;
        })
      
      }catch(error)  {
        setExtractionMode(true);

                setFileUploadedError(true)

        console.error("Error uploading file:", error.message);
      };
    } else {
      console.warn("No file selected");
      setMsg(`${lang.menu.nfs}`); // Set error message in the state
    }
  };

  return (
    <center>
      {!extractionMode ? ( // Render the form if not in extraction mode
        <div style={{ justifyContent: "left" }}>
          <form
            style={{ marginLeft: "0" }}
            className="uploadBox"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
            onSubmit={(e) => e.preventDefault()}
          >
            {file.selectedFile !== null ? (
              <p className="filename">{file.selectedFile.name}</p>
            ) : msg !== "" ? (
              msg
            ) : (
              <FiUpload className="upload-icon" />
            )}

            <div>
              <div className="drag">
              {lang.menu.dyfho}{" "}
                <div className="browse">
                  <label
                    htmlFor="img"
                    className="file-label"
                    onClick={() => document.getElementById("getFile").click()}
                  >
                    {lang.menu.Browse}
                    <input
                      type="file"
                      data-max-size="2048"
                      id="getFile"
                      className="fileIcon"
                      onChange={chooseFile}
                    />
                  </label>
                </div>
              </div>
            </div>

            <p className="info">{lang.menu.opfas}</p>
          </form>
          <h3></h3>
          <center>
            <Button
              style={{
                width: "120px",
                marginInline: "20px",
                backgroundColor: "#bcebe4",
                color: "#74188D",
              }}
              onClick={handleCV}
            >
              {lang.menu.Confirm}
            </Button>
            <Button
              style={{ width: "120px", marginInline: "20px" }}
              onClick={handleCancel}
            >
              {lang.menu.Cancel}
            </Button>
          </center>
        </div>
      ) : (
        // Render extraction button if in extraction mode
        <div style={{ height: "fit-content",width: "fit-content" }}>
          <center>
            {fileUploadedError ? (
              <div>
                <img
                  src={oops}
                  style={{ maxWidth: "120px", maxHeight: "120px", margin: "15px" }}
                />
                <h3>{lang.menu.eedptal}</h3>
              </div>
            ) : !errorExtract ? (
              <div>
              {dataExtracted ? (
                <div>


  <img src={thankYou} style={{width:"120px",height:"120px", margin:"15px"}}  />

  <h2 >{lang.menu.ydhbes}</h2>          
  <h2 ><b>{lang.menu.pribys}</b></h2>
                </div>

              ) :(

                <center>

              <div className="loading-wave">
                <div className="loading-bar"></div>
                <div className="loading-bar"></div>
                <div className="loading-bar"></div>
                <div className="loading-bar"></div>
              </div>
              </center>

              
              )}
              </div>
            ) : (
              <div>
                <img
                  src={oops}
                  style={{ width: "120px", height: "120px", margin: "15px" }}
                />
                <h3>{errorMsg}</h3>
              </div>
            )}
          </center>
        </div>
      )}
    </center>
  );
}
