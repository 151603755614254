import React from 'react'

function TestTechCompleted() {
  return (
    <div className="test-completion">
      <div className="test-completion-content">
        <h2 className='titre2-completion-test'>Congratulations!</h2>
        <p className='text-completion-test'>You have successfully completed the test.</p>
        <p className='text-completion-test'>Your results will be reviewed shortly.</p>
      </div>
    </div>
  )
}

export default TestTechCompleted;