import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import translations from "../AppsMenu/Candidate/mulitlingue";

function TestList() {
  const token=localStorage.getItem("token");

  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  useEffect(() => {
    const selectedLang = JSON.parse(localStorage.getItem("selectedLang"));
    if (selectedLang) {
      setLang(selectedLang);
    }
  }, []);
  const [tests, setTests] = useState([]);

  useEffect(() => {
    fetchTests();
  }, []);

  const fetchTests = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_APP_DOMAIN}/api/test-list`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
      );
      setTests(response.data);
    } catch (error) {
      console.error("Error fetching tests:", error);
    }
  };

  return (
    <>
      <h1>{lang.menu.TestList}</h1>
      <br></br> <br></br>
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <Link to={"/new-test"} className="btn btn-primary me-3 btn-sm">
          <i className="fas fa-plus me-2"></i>
          {lang.menu.AddNewTest}
        </Link>
      </div>
      <div className="row">
        {tests.map((test) => (
          <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6" key={test.Id}>
            <div className="card">
              <div className="new-arrival-product">
                <div className="new-arrival-content text-center mt-3">
                  <h2>{test.name}</h2>
                  <div className="card-body">
                    <p>{test.description}</p>
                    <div className="action-buttons ">
                      <Link
                        to={`/page-test-candidate/${test.Id}`}
                        className="btn btn-success light mr-2"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="svg-main-icon"
                          width="24px"
                          height="24px"
                          viewBox="0 0 32 32"
                          x="0px"
                          y="0px"
                        >
                          <g data-name="Layer 21">
                            <path
                              d="M29,14.47A15,15,0,0,0,3,14.47a3.07,3.07,0,0,0,0,3.06,15,15,0,0,0,26,0A3.07,3.07,0,0,0,29,14.47ZM16,21a5,5,0,1,1,5-5A5,5,0,0,1,16,21Z"
                              fill="#000000"
                              fillRule="nonzero"
                            ></path>
                            <circle
                              cx="16"
                              cy="16"
                              r="3"
                              fill="#000000"
                              fillRule="nonzero"
                            ></circle>
                          </g>
                        </svg>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default TestList;
