import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button, Badge, Form } from 'react-bootstrap';
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import translations from "../AppsMenu/Candidate/mulitlingue";
import swal from "sweetalert";
import PageTitle from "../../layouts/PageTitle"

function AssignmentList() {
  const token=localStorage.getItem("token");

  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  useEffect(() => {
    const selectedLang = JSON.parse(localStorage.getItem("selectedLang"));
    if (selectedLang) {
      setLang(selectedLang);
    }
  }, []);
 /* useEffect(() => {
		const fetchData = async () => {
		  try {
			const currentUser = JSON.parse(localStorage.getItem('userDetails'));
			const userId = currentUser.localId;
	  
			const userResponse = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
			if (!userResponse.ok) {
			  throw new Error(`Error fetching user details: ${userResponse.status}`);
			}
			const userData = await userResponse.json();
	 
		  } catch (error) {
			console.error(error);
		  }
		};
	
		fetchData();
	  }, []);

    */
  const [assignments, setAssignments] = useState([]);

  async function fetchAssignments() {
    try {

      const currentUser = JSON.parse(localStorage.getItem('userDetails'));
			const userId = currentUser.localId;

      const response = await axios.get(
        `${process.env.REACT_APP_APP_DOMAIN}/api/assignment/getAll/${userId}`, 
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setAssignments(response.data.assignments);
    } catch (error) {
      console.error("Error fetching profiles:", error);
    }
  }

  useEffect(() => {
    fetchAssignments();
  }, []);
  const handleDelete = (id) => {
    // Show a confirmation dialog using SweetAlert
    swal({
      title: lang.menu.deleteTitle,
      text: lang.menu.deleteTextAssignment,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        // User confirmed, perform the delete action
        const currentUser = JSON.parse(localStorage.getItem('userDetails'));
			const userId = currentUser.localId;
        await axios
          .delete(
            `${process.env.REACT_APP_APP_DOMAIN}/api/assignment/${userId}/${id}`, 
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(async (res) => {
            console.log(res);
            swal(lang.menu.deleteSuccessAssignment, {
              icon: "success",
            });
            //setAssignments(assignments.filter(assignment => assignment.id !== id));
            await fetchAssignments();
          })
          .catch((err) => {
            swal(lang.menu.deleteTitleWrong, lang.menu.deleteWrong, "error");
            console.log(err);
          });
      } else {
        // User canceled the delete action
        swal(lang.menu.deletedCnacledAssignment, {
          icon: "info",
        });
      }
    });


    
  };

  

  //search for skill
  const [searchWord, setSearchWord] = useState("");

    const searchForAssignment = (assignment)=>{
      return assignment.name.toLowerCase().includes(searchWord.toLowerCase());
    }

  const onChangeSearchWord = (e) =>{
    setSearchWord(e.target.value);
  }

  return (
    <div className="job-profile-container">
       <PageTitle
        activeMenu={lang.menu.AssignmentList}
        motherMenu={lang.menu.Assignments}
      />
      <br />

      {/*<div className="profile-list-header">
        <div>
        <Form.Control 
          type="search"
          className="left-align-container smallscreen"
          placeholder="Search"
          onChange={onChangeSearchWord} />
        </div>
        <div>
        <Link to="/create-assignment" className="btn btn-primary test-list-button">
          <i className="fas fa-plus"></i>
          {lang.menu.AddAssignments}
        </Link>
        </div>
      </div>*/}

      <Row className="mb-3 ">
  <Col xs={7}>
    <Form.Group controlId="formBasicSearch">
      <Form.Control
        type="search"
        placeholder={lang.menu.serachJob}
        onChange={onChangeSearchWord}
      />
    </Form.Group>
  </Col>
  <Col xs={1} className="d-flex offset-md-1">
    
  </Col>
  <Col xs={2} className="d-flex align-items-end justify-content-end offset-md-1">
  <Link to="/create-assignment" className="btn btn-primary test-list-button">
          <i className="fas fa-plus"></i>
          {lang.menu.AddAssignments}
        </Link>
  </Col>
</Row>

      
        
      <Container>
      <Row>
      {assignments.filter(assignment => searchForAssignment(assignment)).map((assignment) => (
          <Col key={assignment.id} xs={12} sm={6} md={4}>
            <Card>
              <Card.Body style={{ fontFamily: "Poppins" }}>
                <Card.Title style={{ fontSize: "180%",fontWeight:"700", color: "#000",textAlign:"center" }}>{assignment.name}</Card.Title>
                <Card.Text>{assignment.description}</Card.Text>
                <br/>
                <Row style={{textAlign:"center"}}>
                  <Col> 
                  <Badge variant="success">{assignment.difficulty}</Badge>
                  </Col>
                </Row>
                <br/>
                <Row style={{textAlign:"center"}}>
                  <Col> <button
                  style={{ border: "none" }}
                  onClick={() => handleDelete(assignment.id)}
                >
                  <i className="fas fa-trash-alt fa-lg"></i>
                </button></Col>
                <Col>
                <Link
                  to={`/update-assignment/${assignment.id}`}
                  className="test-details-link"
                >
                  <i className="fas fa-pencil-alt fa-lg"></i>
                </Link></Col>
                
                </Row>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
     
    </div>
  );
}

export default AssignmentList;