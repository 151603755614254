import React, { useState, useEffect, Fragment } from 'react';
import {Link} from 'react-router-dom';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BsCalendar2DateFill } from "react-icons/bs";
import translations from "../../AppsMenu/Candidate/mulitlingue";

const HomeSlider = ({ jobs }) => {
  const [selectedLang, setSelectedLang] = useState(null);

  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem('selectedLang');
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === 'Français') {
        return translations.fr;
      }
    }
    return translations.en;
  });


  if (!jobs) {
    return <div>Erreur : Aucun emploi disponible</div>;
  }

	
	const settings = {
		dots: false,
		infinite: true,
		arrows: false,
		variableWidth: true,
		speed: 500,
		slidesToScroll: 1,
		responsive: [
			{
			  breakpoint: 575,
				settings: {
					slidesToScroll: 1,
					variableWidth: false,
				}
			},
		]	
	};

	const calculateDaysPosted = (postDate) => {
		const currentDate = new Date();
		const postedDate = new Date(postDate);
		const timeDiff = currentDate - postedDate;
		const daysPosted = Math.floor(timeDiff / (1000 * 60 * 60 * 24)); 
	  
		if (daysPosted >= 6) {
		  const weeksPosted = Math.floor(daysPosted / 7); 
		  return `${weeksPosted} ${lang.menu.semaine}${weeksPosted !== 1 ? 's' : ''}`;
		} else {
		  return `${daysPosted} ${lang.menu.jour}${daysPosted !== 1 ? 's' : ''}`;
		}
	  };
	  
	  
	return (
		<Slider  className="card-slide owl-right-nav " {...settings}>
			 {jobs.map((item, index) => (
				
			<div className="items p-2">
				<div className="jobs text-center">
					<div className="">
						<span>
							<svg className="mb-2" xmlns="http://www.w3.org/2000/svg" width="71" height="71" viewBox="0 0 71 71">
								<g  transform="translate(-457 -443)">
									<rect  width="71" height="71" rx="12" transform="translate(457 443)" fill="#c5c5c5"/>
									<g  transform="translate(457 443)">
									  <rect  data-name="placeholder" width="71" height="71" rx="12" fill="#2769ee"/>
									  <circle  data-name="Ellipse 12" cx="18" cy="18" r="18" transform="translate(15 20)" fill="#fff"/>
									  <circle  data-name="Ellipse 11" cx="11" cy="11" r="11" transform="translate(36 15)" fill="#ffe70c" />
									</g>
								</g>
							</svg>
						</span>
						<h4 className="mb-0"><Link to={`/details/${item.id}`}>{item.JobTitle}</Link></h4>
						<span className="text-primary mb-3 d-block">{item.JobType}</span>{" "}
						<span className="ms-2">  {calculateDaysPosted(item.PostedDate)}</span>{" . "} <span>{item.candidateLength}  {lang.menu.candidates}</span>
					</div>
					<div>
					<span className="d-block mb-1"><i className="fas fa-map-marker-alt me-2"></i>{item.Country}</span>
						<span><i className="fas fa-comments-dollar me-2"></i>$ {item.OfferedSalary}</span>
						<br/>	
						<span>
  <BsCalendar2DateFill />
  {" "}
  {new Date(item.CloseDate).toLocaleDateString()}
</span>

					</div>
				</div>	
			</div>
	))}
				
		</Slider>
	);
};

export default HomeSlider;