import { React, useState, useEffect } from "react";
import translations from "../../../components/AppsMenu/Candidate/mulitlingue";

const TechSkill = ({ user, updateUser }) => {
  const [lang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });

  const [selectedSkill, setSelectedSkill] = useState("");
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [proficiencyLevels, setProficiencyLevels] = useState({});
  const proficiencyLevelsOptions = ["Junior", "Mid", "Senior"];
  const availableSkills = [
    { name: "Angular", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    { name: "AR Development", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    { name: "AWS", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    { name: "Astronomy", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    {
      name: "Augmented Reality (AR)",
      proficiencyLevels: ["Junior", "Mid", "Senior"],
    },
    {
      name: "Automotive Engineering",
      proficiencyLevels: ["Junior", "Mid", "Senior"],
    },
    { name: "Azure", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    { name: "Backbone.js", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    { name: "Bioengineering", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    { name: "Bioinformatics", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    { name: "Biotechnology", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    { name: "Blender", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    { name: "Blockchain", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    {
      name: "Blockchain Development",
      proficiencyLevels: ["Junior", "Mid", "Senior"],
    },
    { name: "Bootstrap", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    { name: "C#", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    { name: "C++", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    { name: "CSS3", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    { name: "CV Development", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    { name: "Cybersecurity", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    { name: "Dart", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    {
      name: "Database Administration",
      proficiencyLevels: ["Junior", "Mid", "Senior"],
    },
    { name: "Data Analytics", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    {
      name: "Data Engineering",
      proficiencyLevels: ["Junior", "Mid", "Senior"],
    },
    { name: "Data Science", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    { name: "DevOps", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    {
      name: "Digital Marketing",
      proficiencyLevels: ["Junior", "Mid", "Senior"],
    },
    { name: "Docker", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    { name: "Eclipse", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    {
      name: "Electrical Engineering",
      proficiencyLevels: ["Junior", "Mid", "Senior"],
    },
    { name: "Ember.js", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    {
      name: "Environmental Engineering",
      proficiencyLevels: ["Junior", "Mid", "Senior"],
    },
    { name: "ExpressJs", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    { name: "Figma", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    { name: "Firebase", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    {
      name: "Frontend Development",
      proficiencyLevels: ["Junior", "Mid", "Senior"],
    },
    {
      name: "Full Stack Development",
      proficiencyLevels: ["Junior", "Mid", "Senior"],
    },
    {
      name: "Game Development",
      proficiencyLevels: ["Junior", "Mid", "Senior"],
    },
    { name: "GameMaker", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    { name: "Git", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    { name: "Go", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    { name: "Google Cloud", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    { name: "GraphQL", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    { name: "HTML5", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    { name: "Illustrator", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    { name: "InVision", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    { name: "IntelliJ IDEA", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    {
      name: "IoT (Internet of Things)",
      proficiencyLevels: ["Junior", "Mid", "Senior"],
    },
    { name: "Java", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    { name: "JavaScript", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    { name: "Jenkins", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    { name: "JIRA", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    { name: "jQuery", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    { name: "Kotlin", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    { name: "Kubernetes", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    {
      name: "Machine Learning",
      proficiencyLevels: ["Junior", "Mid", "Senior"],
    },
    {
      name: "Material Science",
      proficiencyLevels: ["Junior", "Mid", "Senior"],
    },
    { name: "Maya", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    {
      name: "Mechanical Engineering",
      proficiencyLevels: ["Junior", "Mid", "Senior"],
    },
    { name: "MongoDB", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    { name: "MySQL", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    { name: "Nanotechnology", proficiencyLevels: ["Junior", "Mid", "Senior"] },
    {
      name: "Natural Language Processing",
      proficiencyLevels: ["Junior", "Mid", "Senior"],
    },
    {
      name: "Network Engineering",
      proficiencyLevels: ["Junior", "Mid", "Senior"],
    },
    { name: "NodeJs", proficiencyLevels: ["Junior", "Mid", "Senior"] },
  ];

  useEffect(() => {
    let storedData = null;
    if (user.skills) {

      storedData = user.skills;
      while (typeof(storedData) === "string") {
      storedData = JSON.parse(storedData);

    }
    storedData=storedData.TechnicalSkills
  }
    if (storedData) {
      const {
        selectedSkills: storedSelectedSkills,
        proficiencyLevels: storedProficiencyLevels,
      } = storedData;
      setSelectedSkills(storedSelectedSkills);
      setProficiencyLevels(storedProficiencyLevels);
    }
  }, []);

  useEffect(() => {
    updateUser({ selectedSkills, proficiencyLevels });
  }, [selectedSkills, proficiencyLevels]);

  const handleSkillChange = (e) => {
    const skill = e.target.value;
    setSelectedSkill(skill);
    if (skill && !selectedSkills.includes(skill)) {
      setSelectedSkills([...selectedSkills, skill]);
      setProficiencyLevels({ ...proficiencyLevels, [skill]: "Junior" });
      setSelectedSkill(""); // Reset the dropdown value
    }
  };

  const handleProficiencyLevelClick = (skill, level) => {
    setProficiencyLevels({ ...proficiencyLevels, [skill]: level });
  };

  const handleRemoveSkill = (skillToRemove) => {
    setSelectedSkills(
      selectedSkills.filter((skill) => skill !== skillToRemove)
    );
    setProficiencyLevels((prevLevels) => {
      const { [skillToRemove]: removedSkill, ...remainingSkills } = prevLevels;
      return remainingSkills;
    });
  };

  const isSelectedProficiency = (skill, level) => {
    return proficiencyLevels[skill] === level ? "selected" : "notselected";
  };

  return (
    <>
      <div className="basic-form">
        <form onSubmit={(e) => e.preventDefault()} className="p-3">
          <div>
            <label className="my-label">{lang.menu.ts}</label>

            <select
              className="form-control"
              value={selectedSkill}
              onChange={handleSkillChange}
            >
              <option value="">{lang.menu.sas}</option>
              {availableSkills.map((skill, index) => (
                <option key={index} value={skill.name}>
                  {skill.name}
                </option>
              ))}
            </select>
          </div>
          {selectedSkills.length > 0 && (
            <div>
              {selectedSkills.map((skill, index) => (
                <div
                  style={{
                    display: "flex",
                    "flex-direction": "row",
                    marginTop: "6px",
                  }}
                  key={index}
                >
                  <h4 style={{ margin: 0, width: "20%" }}>{skill}</h4>
                  {proficiencyLevelsOptions.map((level, index) => (
                    <button
                      key={index}
                      style={{
                        paddingLeft: "3px",
                        paddingRight: "3px",
                        marginLeft: "3px",
                      }}
                      className={isSelectedProficiency(skill, level)}
                      onClick={(e) => {
                        e.preventDefault(); // Prevent default form submission
                        handleProficiencyLevelClick(skill, level);
                      }}
                    >
                      {level}
                    </button>
                  ))}
                  <button
                    style={{
                      paddingLeft: "5px",
                      paddingRight: "5px",
                      marginLeft: "2%",
                      color: "white",
                      background: "#74188D",
                      borderRadius: "10px",
                    }}
                    onClick={() => handleRemoveSkill(skill)}
                  >
                    <b>X</b>
                  </button>
                </div>
              ))}
            </div>
          )}
        </form>
      </div>
    </>
  );
};
export default TechSkill;
