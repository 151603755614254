import React, { Fragment,useState,useEffect } from "react";
import {Link} from 'react-router-dom';
import axios from "axios";
import "./candidateCSS.css"
import ProgressBar from "./CandidateProgress";
import FileUploader from "./FileUploader";
import {
    Dropdown,
	Card,
    
  } from "react-bootstrap";
  import translations from"./mulitlingue"
import { Modal } from 'react-bootstrap';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CVsDataExtraction = () => {
	const [selectedLang, setSelectedLang] = useState(null);
	const [jobRoles, setJobRoles] = useState([]);
	const [requiredFieldsSelected, setRequiredFieldsSelected] = useState(false);

	const [lang, setLang] = useState(() => {
		const storedLang = localStorage.getItem('selectedLang');
		if (storedLang) {
		  const parsedLang = JSON.parse(storedLang);
		  if (parsedLang.language === 'Français') {
			return translations.fr;
		  }
		}
		return translations.en;
	  });
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [pt1, setpt1] = useState(lang.menu.pt1);
	const [pt2, setpt2] = useState(lang.menu.pt2);
	const [maxscore, setMaxscore] = useState(lang.menu.toc);
	const [guideModalOpen, setGuideModalOpen] = useState(false);

	const [jobRoleName, setJobRoleName] = useState(lang.menu.jobRole);
	const [numSaved, setNumSaved] = useState(0);
	const [numNotSaved, setNumNotSaved] = useState(0);
	const [totalCandidatesEn, setTotalCandidatesEn] = useState(0);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	useEffect(() => {
		const storedLang = localStorage.getItem('selectedLang');
		if (storedLang) {
		  setSelectedLang(JSON.parse(storedLang));
		}
	  }, []);
	  
	  
	  const handleDropdownSelect1 = (value) => {
        if (value !== lang.menu.pt1) {
            setpt1(value);
			checkRequiredFields();
        }
    };

    const handleDropdownSelect2 = (value) => {
        if (value !== lang.menu.pt2) {
            setpt2(value);
			checkRequiredFields();
        }
    };
	const handleDropdownSelect3 = (value) => {
		setJobRoleName(value);
		checkRequiredFields();
	};
	const handleDropdownSelect4 = (value) => {
		setMaxscore(value);
	};
	const checkRequiredFields = () => {
		if (pt1 !== lang.menu.pt1 && pt2 !== lang.menu.pt2 && jobRoleName !== lang.menu.jobRole) {
			setRequiredFieldsSelected(true);
		} else {
			setRequiredFieldsSelected(false);
		}
	};
	useEffect(() => {
		axios.get(`${process.env.REACT_APP_APP_DOMAIN}/flask/allJob`)
		  .then(response => {
			setJobRoles(response.data);
		  })
		  .catch(error => {
			console.error('Erreur lors de la récupération des rôles d\'emploi:', error);
		  });
	  }, []);
	const postCandidates = () => {
		setModalIsOpen(true); 
		
		const data = new URLSearchParams();
		if (pt1 !== lang.menu.pt1) {
            data.append('PT1', pt1);
        }
        if (pt2 !== lang.menu.pt2) {
            data.append('PT2', pt2);
        }
		data.append('jobRoleName', jobRoleName);
		if (maxscore === lang.menu.toc) {
			data.append("maxscore", 4);
		  } else {
			data.append("maxscore", maxscore);
		  }
		setLoading(true);
		const currentUser = JSON.parse(localStorage.getItem('userDetails'));
      	const userId = currentUser.localId;
		data.append("userId", userId);

		axios({
			method: 'post',
			url: `${process.env.REACT_APP_APP_DOMAIN}/flask/candidates/addCandidates100`,
			data: data,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			}
		})
			.then(response => {
				
				setNumSaved(response.data.num_saved); 
				setNumNotSaved(response.data.num_not_saved);
				setTotalCandidatesEn(response.data.num_files_to_extract);
				
				setLoading(false);
				setModalIsOpen(false);
				toast.success("Candidates added successfully!", {
					position: toast.POSITION.BOTTOM_CENTER,
					limit: 1,
				  });
				
			})
			.catch(error => {
				setError(error.message);
				
				setLoading(false);
				setModalIsOpen(false);
				toast.error("Error adding candidates!", {
					position: toast.POSITION.BOTTOM_CENTER,
					limit: 1,
				  });
			});
	};
	  
	const uploadCV = () => {
		try {
			postCandidates();
		} catch (error) {
			console.error(error);
		}
	};


    return (
        <div >
 <Card>
                <Card.Header>
                  <Card.Title>{lang.menu.uploadCv}</Card.Title><button className="btn btn-info" onClick={() => setGuideModalOpen(true)}>
  Guide
</button>
                </Card.Header>
              </Card>
			
              <div className="d-flex align-items-center flex-wrap search-job bg-white px-0 mb-4">
			  <div className="row align-items-center">
     
			  <div className="col-xl-2 col-xxl-3 col-lg-3 col-sm-6 col-12 d-flex align-items-center">
					<Dropdown className="form-control border-0  style-1 h-auto" >
						<Dropdown.Toggle  as="div" className="fs-16 font-w500 text-primary justify-content-between d-flex align-items-center i-false dropdown-toggle-custom" >
						{pt1}
							<i className="fas fa-angle-down text-primary scale5 ms-3"></i>
							
						</Dropdown.Toggle>
						<Dropdown.Menu className="dropdown-menu dropdown-menu-end mt-1" >
						<Dropdown.Item className="text-primary" onClick={() => handleDropdownSelect1(lang.menu.pt1)} >
						{lang.menu.pt1}
						</Dropdown.Item>
						<Dropdown.Item className="text-primary" onClick={() => handleDropdownSelect1("1")}>
						100 %
						</Dropdown.Item>
						<Dropdown.Item className="text-primary" onClick={() => handleDropdownSelect1("0.9")}>
						90 %
						</Dropdown.Item>
						<Dropdown.Item className="text-primary" onClick={() => handleDropdownSelect1("0.8")}>
						80 %
						</Dropdown.Item>
						<Dropdown.Item className="text-primary" onClick={() => handleDropdownSelect1("0.7")}>
						70 %
						</Dropdown.Item>
						<Dropdown.Item className="text-primary" onClick={() => handleDropdownSelect1("0.6")}>
						60 %
						</Dropdown.Item>
						<Dropdown.Item className="text-primary" onClick={() => handleDropdownSelect1("0.5")}>
						50 %
						</Dropdown.Item>
						<Dropdown.Item className="text-primary" onClick={() => handleDropdownSelect1("0.4")}>
						40 %
						</Dropdown.Item>

						</Dropdown.Menu>
					</Dropdown>
				</div>
				<div className="col-xl-2 col-xxl-3 col-lg-3 col-sm-6 col-12 d-flex align-items-center">
					<Dropdown className="form-control border-0  style-1 h-auto" >
						<Dropdown.Toggle  as="div" className="fs-16 font-w500 text-primary justify-content-between d-flex align-items-center i-false dropdown-toggle-custom" >
						{pt2}
							<i className="fas fa-angle-down text-primary scale5 ms-3"></i>
						</Dropdown.Toggle>
						<Dropdown.Menu className="dropdown-menu dropdown-menu-end mt-1">
						<Dropdown.Item className="text-primary" onClick={() => handleDropdownSelect2(lang.menu.pt2)}>
						{lang.menu.pt2}
						</Dropdown.Item>
						<Dropdown.Item className="text-primary" onClick={() => handleDropdownSelect2("1")}>
						100 %
						</Dropdown.Item>
						<Dropdown.Item className="text-primary" onClick={() => handleDropdownSelect2("0.9")}>
						90 %
						</Dropdown.Item>
						<Dropdown.Item className="text-primary" onClick={() => handleDropdownSelect2("0.8")}>
						80 %
						</Dropdown.Item>
						<Dropdown.Item className="text-primary" onClick={() => handleDropdownSelect2("0.7")}>
						70 %
						</Dropdown.Item>
						<Dropdown.Item className="text-primary" onClick={() => handleDropdownSelect2("0.6")}>
						60 %
						</Dropdown.Item>
						<Dropdown.Item className="text-primary" onClick={() => handleDropdownSelect2("0.5")}>
						50 %
						</Dropdown.Item>
						<Dropdown.Item className="text-primary" onClick={() => handleDropdownSelect2("0.4")}>
						40 %
						</Dropdown.Item>

						</Dropdown.Menu>
					</Dropdown>
				</div>
				<div className="col-xl-2 col-xxl-3 col-lg-3 col-sm-6 col-12 d-flex align-items-center">
					<Dropdown className="form-control border-0  style-1 h-auto" >
						<Dropdown.Toggle  as="div" className="fs-16 font-w500 text-primary justify-content-between d-flex align-items-center i-false dropdown-toggle-custom" >
						{maxscore}
							<i className="fas fa-angle-down text-primary scale5 ms-3"></i>
						</Dropdown.Toggle>
						<Dropdown.Menu className="dropdown-menu dropdown-menu-end mt-1">
						<Dropdown.Item className="text-primary" onClick={() => handleDropdownSelect4(lang.menu.toc)}>
						{lang.menu.toc}
						</Dropdown.Item>
						<Dropdown.Item className="text-primary" onClick={() => handleDropdownSelect4("3")}>
						3
						</Dropdown.Item>
						<Dropdown.Item className="text-primary" onClick={() => handleDropdownSelect4("4")}>
						4
						</Dropdown.Item>
						<Dropdown.Item className="text-primary" onClick={() => handleDropdownSelect4("5")}>
						5
						</Dropdown.Item>
						<Dropdown.Item className="text-primary" onClick={() => handleDropdownSelect4("6")}>
						6
						</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</div>
				<div className="col-xl-2 col-xxl-3 col-lg-3 col-sm-6 col-12 d-flex align-items-center">
					<Dropdown className="form-control border-0  style-1 h-auto" >
						<Dropdown.Toggle  as="div" className="fs-16 font-w500 text-primary justify-content-between d-flex align-items-center i-false dropdown-toggle-custom" >
						{jobRoleName}
							<i className="fas fa-angle-down text-primary scale5 ms-3"></i>
							
						</Dropdown.Toggle>
						<Dropdown.Menu className="dropdown-menu dropdown-menu-end mt-1">
						<Dropdown.Item className="text-primary" onClick={() => handleDropdownSelect3(lang.menu.jobRole)}>
						{lang.menu.jobRole}
						</Dropdown.Item>
						{jobRoles.map((jobRole, index) => (
            <Dropdown.Item className="text-primary" key={index} onClick={() => handleDropdownSelect3(jobRole)}>
              {jobRole}
            </Dropdown.Item>
          ))}

						</Dropdown.Menu>
					</Dropdown>
				</div>
				</div>
				</div>
                
				<Card c>
  <Card.Body>  
	<FileUploader jobRoleName={jobRoleName} />
	<br/>	<br/>

	<div className="d-flex justify-content-center align-items-center flex-column">
    <button className="btn btn-primary" onClick={uploadCV} >
	Extract
    </button>
	</div>
    <Modal show={modalIsOpen} onHide={() => setModalIsOpen(false)}>
      <Modal.Header>
        <Modal.Title>{lang.menu.cvEx}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{lang.menu.plz}</p>
      </Modal.Body>
    </Modal>

  </Card.Body>
</Card>
<Modal show={guideModalOpen} onHide={() => setGuideModalOpen(false)}>
  <Modal.Header closeButton>
    <Modal.Title>Guide d'utilisation</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <p>Avant de commencer :</p>
    <ol>
      <li>Sélectionnez les options Preselection Thresholds 1 et Preselection Thresholds 2 (PT1 > PT2).</li>
      <li>Sélectionnez le rôle d'emploi (Jobrole) correspondant.</li>
      <li>Cliquez sur "Select File" pour choisir les fichiers à extraire.</li>
      <li>Une fois les fichiers sélectionnés, cliquez sur "Upload files".</li>
    </ol>
    <p>Après avoir suivi ces étapes, vous pourrez cliquer sur "Extract" pour lancer l'extraction des CVs selon vos critères.</p>
  </Modal.Body>
</Modal>
 

		
				{totalCandidatesEn > 0 && <ProgressBar numNotSaved={numNotSaved}
											numSaved={numSaved}
											totalCandidatesEn={totalCandidatesEn}
											lang={lang}
											/>}
											<ToastContainer/>
             </div>
        );
    };
    
    export default CVsDataExtraction;
  