import React, { useState, useEffect, useMemo } from "react";
import { useTable, useSortBy } from "react-table";
import PageTitle from "../../../layouts/PageTitle";
import translations from "../../AppsMenu/Candidate/mulitlingue";
import axios from "axios";

import { Modal, Dropdown, Button, Form , Pagination} from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";


export const QuestionTableTech = () => {
  const [questions, setQuestions] = useState([]);
  const [skillNames, setSkillNames] = useState({});
  const token=localStorage.getItem("token");

  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  useEffect(() => {
    const selectedLang = JSON.parse(localStorage.getItem("selectedLang"));
    if (selectedLang) {
      setLang(selectedLang);
    }
  }, []);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_APP_DOMAIN}/api/techniqueQCM/getAllQuestions`, 
      {
        headers: {
        Authorization: `Bearer ${token}`,
        }}
    )
      .then((response) => response.json())
      .then((data) => setQuestions(data))
      .catch((error) => console.error("Error fetching data:", error));

    // Fetch all Skill names
    
  }, []);

  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/techniqueQCM/getAllQuestions`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
        setQuestions(response.data);
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
    };
 
    fetchData();
 
    axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/skill/getAllSkills`, 
      {
        headers: {
        Authorization: `Bearer ${token}`,
        }})
      .then(response => {
        const skillMap = {};
        response.data.forEach(skill => {
          skillMap[skill.id] = skill.name;
        });
        setSkillNames(skillMap);
      })
      .catch(error => console.error("Error fetching skill names:", error));
  }, []);
 
  const fetchSkillName = (idSkill) => {
    return skillNames[idSkill] || "N/A";
  };

  const COLUMNS = useMemo(
    () => [
      {
        Header: "ID",
        Footer: "ID",
        accessor: "id",
      },
      {
        Header: `${lang.menu.Question}`,
        Footer: `${lang.menu.Question}`,
        accessor: "question",
      },
      {
        Header: `${lang.menu.imageRef}`,
        Footer: `${lang.menu.imageRef}`,
        accessor: "imageRef",
      },
      {
        Header: `${lang.menu.choix1}`,
        Footer: `${lang.menu.choix1}`,
        accessor: "choix1",
      },
      {
        Header: `${lang.menu.choix2}`,
        Footer: `${lang.menu.choix2}`,
        accessor: "choix2",
      },
      {
        Header: `${lang.menu.choix3}`,
        Footer: `${lang.menu.choix3}`,
        accessor: "choix3",
      },
      {
        Header: `${lang.menu.choix4}`,
        Footer: `${lang.menu.choix4}`,
        accessor: "choix4",
      },
      {
        Header: `${lang.menu.BonneReponse}`,
        Footer: `${lang.menu.BonneReponse}`,
        accessor: "BonneReponse",
      },
      {
        Header: `${lang.menu.skill}`,
        Footer: `${lang.menu.skill}`,
        accessor: "Skill",
        Cell: ({ value }) => fetchSkillName(value),  
      },
      {
        Header: `${lang.menu.level}`,
        Footer: `${lang.menu.level}`,
        accessor: "level",
      },
    ],
    [skillNames]
  );

  const columns = useMemo(() => COLUMNS, [COLUMNS]);
  const data = useMemo(() => questions, [questions]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  var {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  const handleDelete= async (id) =>{
    try {
      const currentUser = JSON.parse(localStorage.getItem('userDetails'));
    const userId = currentUser.localId;
      const response = await axios.delete(`${process.env.REACT_APP_APP_DOMAIN}/api/techniqueQCM/questionTech/${userId}/${id}`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }});
      
     if(response.status == 200){

      setQuestions(questions.filter(question => question.id !== id));
      rows.filter(row => row.values.id !== id);
        
      }
    } catch (error) {
      console.error(error);
    }
  }

  const [rowToUpdate, setRowToUpdate] = useState({
    imageRef: "",
      choix1: "",
      choix2: "",
      choix3: "",
      choix4: "",
      BonneReponse: "",
      Skill: "",
      level: ""
  });
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = (row)=> {
    setImportImg(null);
    setRowToUpdate(row);
    setShowModal(true);
  }
  const handleCloseModal = ()=> {
    setShowModal(false);
  }

  const [importImg, setImportImg] = useState(null);

  const handleFileSelection = (e) => {

    setImportImg({...importImg, [e.target.name] : e.target.files[0]});
  };

  const handleupdate= async () =>{
    try {

      const currentUser = JSON.parse(localStorage.getItem('userDetails'));
     const userId = currentUser.localId;

      const formData = new FormData();
      
        //formData.append('NewImgRef', importImg);

        for (const key in importImg) {
          formData.append(key, importImg[key]);
        }
      
      for (const key in rowToUpdate) {
      formData.append(key, rowToUpdate[key]);
    }
      const response = await axios.put(`${process.env.REACT_APP_APP_DOMAIN}/api/techniqueQCM/questionTech/${userId}/${rowToUpdate.id}`,
        formData, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
      );

      
      
      if(response.status == 200){
        if(importImg){
         // setRowToUpdate({...rowToUpdate, ["imageRef"]: importImg.imageRef.name});
          rowToUpdate.imageRef = importImg.imageRef.name;
          console.log("rowToUpdate", rowToUpdate);
        }

        console.log("rowToUpdate", rowToUpdate);
        setQuestions( questions.map(question => {
          if (question.id === rowToUpdate.id) {
              return  rowToUpdate ;
          } else {
              return question;
          }
      }));

      rows = rows.map(row => {
        
        if (row.values.id === rowToUpdate.id) {
            return rowToUpdate ;
        } else {
            return row;
        }
      });

    }

    handleCloseModal();

    } catch (error) {
      console.error(error);
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRowToUpdate({ ...rowToUpdate, [name]: value });
  };

  const checkImage = (str)=>{

      if (str && (str.endsWith(".png") || str.endsWith(".jpg") || str.endsWith(".jpeg"))) {
        return true;
      } else {
        return false;
      }

  }

  const [filteredRows, setFilteredRows]= useState([]);
  useEffect(()=>{
    setFilteredRows(rows);
    setFilterText("");
  },[rows]);
  //search
  const [filterText, setFilterText] = useState("");

  const [selectedFilterField, setSelectedFilterField] =
    useState("All");
  
    const fieldsToCheck = {
      All: lang.menu.All,
      question: lang.menu.Question,
      skill: lang.menu.skill,
      level: lang.menu.level
    };

    const filterQuestionMethod = (row)=>{
      
     
      if(filterText.length > 0 ){
        if(selectedFilterField === "All"){
        return (((row.question && row.question.toString().toLowerCase().includes(filterText.toLowerCase())) ||
        (skillNames[row.Skill] && skillNames[row.Skill].toLowerCase().includes(filterText.toLowerCase())) ||
        (row.level && row.level.toLowerCase().includes(filterText.toLowerCase()))));

      }else if(selectedFilterField === "question"){
        return (row.question && row.question.toLowerCase().includes(filterText.toLowerCase()));
      }else if(selectedFilterField === "skill"){
        return (skillNames[row.Skill] && skillNames[row.Skill].toLowerCase().includes(filterText.toLowerCase()));
      }else if(selectedFilterField === "level"){
        return (row.level && row.level.toLowerCase().includes(filterText.toLowerCase()));

      }
    }
    return true;
    }

   
    useEffect(()=>{
      setFilteredRows(rows.filter(row => filterQuestionMethod(row.values)));
      setCurrentPage(1);
    }, [filterText]);


    //pagination
    
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const handlePageChange = (page) => {
      setCurrentPage(page);
    };


    useEffect(()=>{
      setTotalPages(Math.floor((filteredRows.length > 0 ? filteredRows.length : rows.length) / process.env.REACT_APP_PAGINATION) + 1);
    }, [filteredRows, rows]);


    //handle edit and delate for filterdrows and rows
  return (
    <div style={{ fontFamily: "Poppins" }}>
     {/* <PageTitle activeMenu="Sorting" motherMenu="Table" />*/}
      <div className="card">
        <div className="card-header" style={{position : "relative"}}>
          <h4 className="card-title">{lang.menu.QCMTechnical}</h4>
          <div className="d-flex align-items-center" style={{position: "absolute", right : "0", marginRight: "0"}}>
                        <label>
                          <h4 className="me-2 mt-3">{lang.menu.SearchT}</h4>
                        </label>
                        <input
                          className="input-search form-control"
                          value={filterText || ""}
                          onChange={(e) => setFilterText(e.target.value)}
                          style={{ width: "30%" }}
                        />
                        <select
                          className="ml-5 form-select input-search form-control"
                          value={selectedFilterField}
                          onChange={(e) =>
                            setSelectedFilterField(e.target.value)
                          }
                          style={{ width: "25%"}}
                        >
                          {Object.keys(fieldsToCheck).map((key) => (
                            <option key={key} value={key}>
                              {fieldsToCheck[key]}
                            </option>
                          ))}
                        </select>
                      </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <div className="dataTables_wrapper">
              <table {...getTableProps()} className="table dataTable display">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("Header")}
                          <span className="ml-1">
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <i className="fa fa-arrow-down" />
                              ) : (
                                <i className="fa fa-arrow-up" />
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </th>
                      ))}
                      <th>
                        {lang.menu.actions}
                      </th>
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {filteredRows.length > 0 && filteredRows.slice((currentPage - 1)* process.env.REACT_APP_PAGINATION, currentPage * process.env.REACT_APP_PAGINATION).map((row) => {
                    
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {" "}
                              {cell.render("Cell")}{" "}
                            </td>
                          );
                        })}
                        <td>
                        <Dropdown>
                  <Dropdown.Toggle
                    className="nav-link i-false c-pointer"
                    as="a"
                    data-toggle="dropdown"
                    aria-expanded="false"
                    style={{textAlign:"center"}}
                  ><i  className="flaticon-032-ellipsis"></i></Dropdown.Toggle>
                  <Dropdown.Menu className="profile-dropdown">
                 
    <DropdownItem onClick={() => handleShowModal(row.values)}>
    <Button
                            className="btn btn-secondary light mr-2"
                          >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
        className="svg-main-icon"
      >
        <g
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <rect x="0" y="0" width="24" height="24"></rect>
          <path
            d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z"
            fill="#000000"
            fillRule="nonzero"
            transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "
          ></path>
          <rect
            fill="#000000"
            opacity="0.3"
            x="5"
            y="20"
            width="15"
            height="2"
            rx="1"
          ></rect>
        </g> 
      </svg>
      </Button>
      <span title="Edit" >{lang.menu.edit}</span> </DropdownItem>
       <DropdownItem onClick={() => handleDelete(row.values.id)}>
      
                  <Button
                            className="btn btn-danger light"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                              className="svg-main-icon"
                            >
                              <g
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                              >
                                <rect x="0" y="0" width="24" height="24"></rect>
                                <path
                                  d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                  fill="#000000"
                                  fillRule="nonzero"
                                ></path>
                                <path
                                  d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                  fill="#000000"
                                  opacity="0.3"
                                ></path>
                              </g>
                            </svg>
                          </Button>  <span title="Remove"  > {lang.menu.Remove}</span>
       </DropdownItem>
       
       



                  
                  </Dropdown.Menu>
                  </Dropdown>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                {/* This is only for footer if u require */}
                <tfoot>
                  {footerGroups.map((footerGroup) => (
                    <tr {...footerGroup.getFooterGroupProps()}>
                      {footerGroup.headers.map((column) => (
                        <td {...column.getFooterProps()}>
                          {column.render("Footer")}
                        </td>
                      ))}
                      <td>
                        {lang.menu.actions}
                      </td>
                    </tr>
                  ))}
                </tfoot>
              </table>
            </div>
          </div>
        </div>
        <div >

        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <div className="sm-mb-0 mb-3">
            <h5 className="mb-0">{currentPage + "/" + totalPages}</h5>
          </div>
          <nav>
            <ul className="pagination pagination-circle">
              <li className="page-item page-indicator">
                <Button
                 style={{width:"100%"}}
                  onClick={()=> {
                    handlePageChange((currentPage === 1) ? currentPage : currentPage - 1);
                  }}
                 className="page-link">
                  {lang.menu.previous}
                </Button>
              </li>
              
              {currentPage > 1 && 
              <li className="page-item">
              <Button 
              className="page-link"
              
                key={currentPage - 1} 
                value={currentPage - 1} 
                onClick={()=>handlePageChange(currentPage - 1)} >
                  {currentPage - 1}
              </Button>
              </li>}

              <li className="page-item active">
          <Button
            className="page-link"
             key={currentPage} 
             value={currentPage} 
              active>
                {currentPage}
                </Button>
                </li>

          {currentPage < totalPages && 
            <li className="page-item ">
            <Button 
              className="page-link"
              key={currentPage + 1} 
              value={currentPage + 1} 
              onClick={()=>handlePageChange(currentPage + 1)}>
                {currentPage + 1}
            </Button>
            </li>}
          
           
              <li className="page-item page-indicator">
                <Button
                className="page-link"
                style={{width:"110%"}}
                onClick={()=> {
                  handlePageChange((currentPage === totalPages) ? currentPage : currentPage + 1);
                }}>
                  {lang.menu.next}
                </Button>
                  
              
              </li>
            </ul>
          </nav>
        </div>

       {/* <Pagination
          
          style={{textAlign: "center"}}
      >
          <Pagination.Prev 
              style={{border: "1px solid #8CD6C8" }}
            onClick={()=> {
              handlePageChange((currentPage === 1) ? currentPage : currentPage - 1);
            }}
          > &lt;</Pagination.Prev>
          <Pagination.Ellipsis 
            style={{border: "1px solid #8CD6C8" }}
            >...</Pagination.Ellipsis>

          {currentPage > 1 && 
              <Pagination.Item 
              style={{border: "1px solid #8CD6C8" }}
                key={currentPage - 1} 
                value={currentPage - 1} 
                onClick={()=>handlePageChange(currentPage - 1)} >
                  {currentPage - 1}
              </Pagination.Item>}
          <Pagination.Item
            style={{border: "1px solid #8CD6C8" }}
             key={currentPage} 
             value={currentPage} 
              active>
                {currentPage}
                </Pagination.Item>
          {currentPage < totalPages && 
            <Pagination.Item 
              style={{border: "1px solid #8CD6C8" }}
              key={currentPage + 1} 
              value={currentPage + 1} 
              onClick={()=>handlePageChange(currentPage + 1)}>
                {currentPage + 1}
            </Pagination.Item>}
          

          <Pagination.Ellipsis 
          style={{border: "1px solid #8CD6C8" }}
          >...</Pagination.Ellipsis>
          <Pagination.Next
            style={{border: "1px solid #8CD6C8" }} 
            onClick={()=> {
              handlePageChange((currentPage === totalPages) ? currentPage : currentPage + 1);
            }}
          >&gt; </Pagination.Next>
          </Pagination>*/}
        

        </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{lang.menu.UpdateQuestion}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form.Group controlId="question">
        <Form.Label>{lang.menu.Question}</Form.Label>
        <Form.Control type="text" name="question" value={rowToUpdate.question} onChange={handleChange} />
        </Form.Group>

        <Form.Group controlId="imageRef">
        <Form.Label>{lang.menu.imageRef}</Form.Label>
        <Form.Control type="file" name="imageRef" onChange={handleFileSelection} />
        </Form.Group>

        <Form.Group controlId="choix1">
        <Form.Label>{lang.menu.choix1}</Form.Label>
        {checkImage(rowToUpdate.choix1) ? 
            <Form.Control type="file" name="choix1" onChange={handleFileSelection} />
            :
            <Form.Control type="text" name="choix1" value={rowToUpdate.choix1} onChange={handleChange}  />
        }
        </Form.Group>

        <Form.Group controlId="choix2">
        <Form.Label>{lang.menu.choix2}</Form.Label>
        {checkImage(rowToUpdate.choix2) ? 
            <Form.Control type="file" name="choix2" onChange={handleFileSelection} />
            :
            <Form.Control type="text" name="choix2" value={rowToUpdate.choix2} onChange={handleChange}  />
        }        </Form.Group>

        <Form.Group controlId="choix3">
        <Form.Label>{lang.menu.choix3}</Form.Label>
        {checkImage(rowToUpdate.choix3) ? 
            <Form.Control type="file" name="choix3" onChange={handleFileSelection} />
            :
            <Form.Control type="text" name="choix3" value={rowToUpdate.choix3} onChange={handleChange}  />
        }        </Form.Group>

        <Form.Group controlId="choix4">
        <Form.Label>{lang.menu.choix4}</Form.Label>
        {checkImage(rowToUpdate.choix4) ? 
            <Form.Control type="file" name="choix4" onChange={handleFileSelection} />
            :
            <Form.Control type="text" name="choix4" value={rowToUpdate.choix4} onChange={handleChange}  />
        }
                </Form.Group>

        <Form.Group controlId="BonneReponse">
        <Form.Label>{lang.menu.BonneReponse}</Form.Label>
        <Form.Control type="text" name="BonneReponse" value={rowToUpdate.BonneReponse} onChange={handleChange}  />
        </Form.Group>

        {/*<Form.Group controlId="Skill">
        <Form.Label>{lang.menu.skill}</Form.Label>
        <Form.Control type="text" name="Skill" value={rowToUpdate.Skill} onChange={handleChange}  />
      </Form.Group>*/}

        <Form.Group controlId="Level">
        <Form.Label>{lang.menu.level}</Form.Label>
        <Form.Control
            as="select"
            name="level"
            value={rowToUpdate.level}
            onChange={handleChange}
          >
            
            <option value="">{lang.menu.selectLevel}</option>
            <option value="Beginner">{lang.menu.Beginner}</option>
            <option value="Intermediate">{lang.menu.Intermediate}</option>
            <option value="Advanced">{lang.menu.Advanced}</option>
          </Form.Control>
        </Form.Group>
        
      
   
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleupdate}>
            {lang.menu.SaveChanges}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default QuestionTableTech;