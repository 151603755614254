import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import translations from "../../AppsMenu/Candidate/mulitlingue";
import { AiOutlineSend } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { Row, Col, Card, Table, Button, Modal, Badge, Dropdown } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import swal from "sweetalert";


function ExcelTable() {
  const [data, setData] = useState([]);
  const [selectedLang, setSelectedLang] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [deleteAll, setDeleteAll] = useState([]);
  const downloadTech=localStorage.getItem('downloadTech');
  const token=localStorage.getItem("token");

  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
          );
          const data = await response.json();
          setDeleteAll(
            data.some(
              (permission) =>
                permission.title === "delete-all-questions-testTech"
            )
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);
  const [isExcelDownloaded, setIsExcelDownloaded] = useState(false);
  const [importFileName, setImportFileName] = useState(null);
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  const [skillsData, setSkillsData] = useState([]);

  useEffect(() => {
    fetchData();
    fetchSkills();
  }, []);

  const fetchData = async () => {
    try {
      const importedFileName = localStorage.getItem("importedFileName");
      const response = await axios.get(
        `${process.env.REACT_APP_APP_DOMAIN}/api/techniqueQCM/getAllQuestionsImg`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
      );
      setData(response.data);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const fetchSkills = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_APP_DOMAIN}/api/skill/getAllSkills`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
      );
      setSkillsData(response.data);
    } catch (error) {
      console.log("Error fetching Skills:", error);
    }
  };

  const getSkillName = (skillId) => {
    const skill = skillsData.find((cat) => cat.id === skillId);
    return skill ? skill.name : "N/A";
  };
  const handleFileUpload = (e, index, column) => {
    const file = e.target.files[0];
    console.log(file);
  };

  const sendData = async (index) => {
    const row = data[index];
    const formData = new FormData();

    formData.append("question", row.question);
    formData.append("BonneReponse", row.BonneReponse);
    formData.append("Skill", getSkillName(row.Skill));
    formData.append("choix1", row.choix1);
    formData.append("choix2", row.choix2);
    formData.append("choix3", row.choix3);
    formData.append("choix4", row.choix4);
    formData.append("level", row.level);

    if (row.imageRef === "img") {
      const fileInput = document.getElementById(`imageRef-${index}`);
      if (fileInput.files[0]) {
        formData.append("imageRef", fileInput.files[0]);
      }
    }
    if (row.choix1 === "img") {
      const fileInput = document.getElementById(`choix1-${index}`);
      if (fileInput.files[0]) {
        formData.append("choix1", fileInput.files[0]);
      }
    }

    if (row.choix2 === "img") {
      const fileInput = document.getElementById(`choix2-${index}`);
      if (fileInput.files[0]) {
        formData.append("choix2", fileInput.files[0]);
      }
    }

    if (row.choix3 === "img") {
      const fileInput = document.getElementById(`choix3-${index}`);
      if (fileInput.files[0]) {
        formData.append("choix3", fileInput.files[0]);
      }
    }
    if (row.choix4 === "img") {
      const fileInput = document.getElementById(`choix4-${index}`);
      if (fileInput.files[0]) {
        formData.append("choix4", fileInput.files[0]);
      }
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_APP_DOMAIN}/api/techniqueQCM/createQuestion/${row.id}`,
        formData, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
      );
      window.location.reload();
    } catch (error) {
      console.log("Error sending data:", error);
    }
  };

  const sendData2 = async (index) => {
    const row = data[index];
    const questionID = row.id;
    const formData = new FormData();
    formData.append("question", row.question);
    formData.append("BonneReponse", row.BonneReponse);
    formData.append("Skill", getSkillName(row.Skill));
    formData.append("choix1", row.choix1);
    formData.append("choix2", row.choix2);
    formData.append("choix3", row.choix3);
    formData.append("choix4", row.choix4);
    formData.append("level", row.level);

    if (row.imageRef === "img") {
      const fileInput = document.getElementById(`imageRef-${index}`);
      if (fileInput.files[0]) {
        formData.append("imageRef", fileInput.files[0]);
      }
    }
    try {
      await axios.post(
        `${process.env.REACT_APP_APP_DOMAIN}/api/techniqueQCM/createQuestion2/${questionID}`,
        formData, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
      );
      window.location.reload();
    } catch (error) {
      console.log("Error sending data:", error);
    }
  };

  const truncateTable = async () => {
    try {
      await axios.get(
        `${process.env.REACT_APP_APP_DOMAIN}/api/techniqueQCM/truncateTable`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
      );
      fetchData();
      swal("Table questionCat truncated successfully", {
        icon: "success",
      });
    } catch (error) {
      swal("Error truncating table:", error, {
        icon: "error",
      });
    }
  };
  const handleDelete = () => {
    truncateTable();
  };
  const [importFile, setImportFile] = useState(null);

  const handleFileSelection = (e) => {
    setImportFile(e.target.files[0]);
    setImportFileName(e.target.files[0].name);
  };

  const handleImport = async () => {
    if (importFile) {
      try {
        const formData = new FormData();
        formData.append("filePath", importFile);
        const response = await axios.post(
          `${process.env.REACT_APP_APP_DOMAIN}/api/techniqueQCM/questions/import-excel`,
          formData, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }}
        );
        const responseMessage = response.data;
        fetchData();
        //fetch skill
        fetchSkills();
        if (
          responseMessage.includes(
            "Import des questions à partir d'un fichier Excel n'a pas été effectué."
          )
        ) {
          swal(responseMessage, {
            icon: "error",
          });
        } else {
          swal(responseMessage, {
            icon: "success",
          });

          localStorage.setItem("importedFileName", importFile.name);
          try {
            const response = await axios.get(
              `${process.env.REACT_APP_APP_DOMAIN}/api/techniqueQCM/getAllQuestionsImg`,
              {}, 
              {
                headers: {
                Authorization: `Bearer ${token}`,
                }}
            );
            setData(response.data);
          } catch (error) {
            console.log("Error fetching data:", error);
          }
        }
      } catch (error) {
        swal("Error Import table:", error, {
          icon: "error",
        });
      }
    } else {
      swal("Please select a file to import.", {
        icon: "error",
      });
    }
  };
  const handleFetchExcelFile = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_APP_DOMAIN}/api/techniqueQCM/download-excel-file`,
        {
          responseType: "arraybuffer",
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );

      const file = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      localStorage.setItem("downloadTech", true);

      setIsExcelDownloaded(true);
      setShowModal(true);
    } catch (error) {
      console.log("Error fetching the Excel file:", error);
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleDeleteQuestion= async (id) =>{
    try {
      const currentUser = JSON.parse(localStorage.getItem('userDetails'));
    const userId = currentUser.localId;
      const response = await axios.delete(`${process.env.REACT_APP_APP_DOMAIN}/api/techniqueQCM/questionTechImg/${userId}/${id}`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }});
      if(response.status == 200){
        setData(data.filter(row => row.id !== id));
      }
    } catch (error) {
      console.error(error);
    }
  }

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };


  useEffect(()=>{
    setTotalPages(Math.floor( data.length  / process.env.REACT_APP_PAGINATION) + 1);
  }, [data]);

  return (
    <Fragment>
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <h4 className="fs-20 font-w600 me-auto">{lang.menu.technicalQCM} </h4>
        <div>
          <Link to={`/QuestionTableTech`} className="btn btn-primary">
            {lang.menu.listQuestion}
          </Link>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <Row>
                <Col lg="12">
                  <Button
                    variant="primary"
                    onClick={handleFetchExcelFile}
                    style={{ marginLeft: "15%" }}
                  >
                    {lang.menu.downloadTemplate}
                  </Button>
                  {"   "}
                  <input
                    type="file"
                    onChange={handleFileSelection}
                    disabled={downloadTech!="true"}
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"


                  />
                  <Button
                    className="me-2"
                    variant="success light"
                    onClick={handleImport}
                  >
                    {lang.menu.importQuestion}
                  </Button>
                  {deleteAll ? (
                    <Button
                      className="me-2"
                      variant="danger"
                      onClick={handleDelete}
                    >
                      {lang.menu.deleteQuestion}
                    </Button>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
              <div className="">
              
                <Card>
                  <Card.Header>
                    <Card.Title>{lang.menu.questions}</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Table responsive>
                      <thead style={{ color: "#74188D" }}>
                        <tr>
                          <th style={{ fontSize: "100%" }}>
                            <strong>{lang.menu.Question}</strong>
                          </th>
                          <th style={{ fontSize: "100%" }}>
                            <strong>{lang.menu.BonneReponse}</strong>
                          </th>
                          <th style={{ fontSize: "100%" }}>
                            <strong>{lang.menu.skill}</strong>
                          </th>
                          <th style={{ fontSize: "100%" }}>
                            <strong>{lang.menu.level}</strong>
                          </th>
                          <th style={{ fontSize: "100%" }}>
                            <strong>{lang.menu.imageRef}</strong>
                          </th>
                          <th style={{ fontSize: "100%" }}>
                            <strong>{lang.menu.choix1}</strong>
                          </th>
                          <th style={{ fontSize: "100%" }}>
                            <strong>{lang.menu.choix2}</strong>
                          </th>
                          <th style={{ fontSize: "100%" }}>
                            <strong>{lang.menu.choix3}</strong>
                          </th>
                          <th style={{ fontSize: "100%" }}>
                            <strong>{lang.menu.choix4}</strong>
                          </th>
                          <th style={{ fontSize: "100%" }}>
                            <strong>{lang.menu.actions}</strong>
                          </th>
                          
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.length > 0 && data.slice((currentPage - 1)* process.env.REACT_APP_PAGINATION, currentPage * process.env.REACT_APP_PAGINATION).map((row, index) => (
                          <tr key={row.id}>
                            <td>{row.question}</td>
                            <td>{row.BonneReponse}</td>
                            <td>{getSkillName(row.Skill)}</td>
                            <td>
                              <Badge variant="success">{row.level}</Badge>
                            </td>
                            <td>
                              {row.imageRef === "img" ? (
                                <input
                                  type="file"
                                  id={`imageRef-${index}`}
                                  onChange={(e) =>
                                    handleFileUpload(e, index, "imageRef")
                                  }
                                />
                              ) : (
                                row.imageRef
                              )}
                            </td>
                            <td>
                              {row.choix1 === "img" ? (
                                <input
                                  type="file"
                                  id={`choix1-${index}`}
                                  onChange={(e) =>
                                    handleFileUpload(e, index, "choix1")
                                  }
                                />
                              ) : (
                                row.choix1
                              )}
                            </td>
                            <td>
                              {row.choix2 === "img" ? (
                                <input
                                  type="file"
                                  id={`choix2-${index}`}
                                  onChange={(e) =>
                                    handleFileUpload(e, index, "choix2")
                                  }
                                />
                              ) : (
                                row.choix2
                              )}
                            </td>
                            <td>
                              {row.choix3 === "img" ? (
                                <input
                                  type="file"
                                  id={`choix3-${index}`}
                                  onChange={(e) =>
                                    handleFileUpload(e, index, "choix3")
                                  }
                                />
                              ) : (
                                row.choix3
                              )}
                            </td>
                            <td
                              style={{
                                backgroundColor: row.choix4 ? "" : "#B0C4DE",
                              }}
                            >
                              {row.choix4 === "img" ? (
                                <input
                                  type="file"
                                  id={`choix4-${index}`}
                                  onChange={(e) =>
                                    handleFileUpload(e, index, "choix4")
                                  }
                                />
                              ) : (
                                row.choix4
                              )}
                            </td>
                            <td>

                            <Dropdown>
                  <Dropdown.Toggle
                    className="nav-link i-false c-pointer"
                    as="a"
                    data-toggle="dropdown"
                    aria-expanded="false"
                    style={{textAlign:"center"}}
                  ><i  className="flaticon-032-ellipsis"></i></Dropdown.Toggle>
                  <Dropdown.Menu className="profile-dropdown">

       <DropdownItem onClick={() => handleDeleteQuestion(row.id)}>
      
                  <Button
                            className="btn btn-danger light"
                           // onClick={() => handleDeleteQuestion(row.id)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                              className="svg-main-icon"
                            >
                              <g
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                              >
                                <rect x="0" y="0" width="24" height="24"></rect>
                                <path
                                  d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                  fill="#000000"
                                  fillRule="nonzero"
                                ></path>
                                <path
                                  d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                  fill="#000000"
                                  opacity="0.3"
                                ></path>
                              </g>
                            </svg>
                          </Button>  <span title="Remove" 
                         // onClick={() => handleDeleteQuestion(row.id)}
                           > {lang.menu.Remove}</span>
       </DropdownItem>
      
          </Dropdown.Menu>
                  </Dropdown>                 
                             
       
      
                            </td>
                            
                            <td>
                              {row.imageRef === "img" &&
                              row.choix1 === "img" &&
                              row.choix2 === "img" &&
                              row.choix3 === "img" &&
                              row.choix4 === "img" ? (
                                <button
                                  type="button"
                                  className="btn btn-submit"
                                  title="Send"
                                  onClick={() => sendData(index)}
                                >
                                  <AiOutlineSend size={16} />{" "}
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-submit"
                                  title="Send"
                                  onClick={() => sendData2(index)}
                                >
                                  <AiOutlineSend size={16} />
                                </button>
                              )}
                            </td>
                            
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
                <div className="d-flex align-items-center justify-content-between flex-wrap">
          <div className="sm-mb-0 mb-3">
            <h5 className="mb-0">{currentPage + "/" + totalPages}</h5>
          </div>
          <nav>
            <ul className="pagination pagination-circle">
              <li className="page-item page-indicator">
                <Button
                 style={{width:"100%"}}
                  onClick={()=> {
                    handlePageChange((currentPage === 1) ? currentPage : currentPage - 1);
                  }}
                 className="page-link">
                  {lang.menu.previous}
                </Button>
              </li>
              
              {currentPage > 1 && 
              <li className="page-item">
              <Button 
              className="page-link"
              
                key={currentPage - 1} 
                value={currentPage - 1} 
                onClick={()=>handlePageChange(currentPage - 1)} >
                  {currentPage - 1}
              </Button>
              </li>}

              <li className="page-item active">
          <Button
            className="page-link"
             key={currentPage} 
             value={currentPage} 
              active>
                {currentPage}
                </Button>
                </li>

          {currentPage < totalPages && 
            <li className="page-item ">
            <Button 
              className="page-link"
              key={currentPage + 1} 
              value={currentPage + 1} 
              onClick={()=>handlePageChange(currentPage + 1)}>
                {currentPage + 1}
            </Button>
            </li>}
          
           
              <li className="page-item page-indicator">
                <Button
                className="page-link"
                style={{width:"110%"}}
                onClick={()=> {
                  handlePageChange((currentPage === totalPages) ? currentPage : currentPage + 1);
                }}>
                  {lang.menu.next}
                </Button>
                  
              
              </li>
            </ul>
          </nav>
        </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={showModal} onHide={handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Message</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{lang.menu.msgImport}</p>
          </Modal.Body>
        </Modal>
      </div>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
    </Fragment>
  );
}

export default ExcelTable;
