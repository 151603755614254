import React, { useState,useEffect,useCallback } from 'react';
import axios from 'axios';
import {
  Badge,
Card,
Form,
Modal
  
} from "react-bootstrap";
import translations from"./mulitlingue"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IoMdCheckboxOutline } from 'react-icons/io';
import swal from "sweetalert";
import Select from 'react-select';

const CvUpload = () => {
  const [candidatePerSend, setCandidatePerSend] = useState([]);
  const token=localStorage.getItem("token");

  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const data = await response.json();
          setCandidatePerSend(
            data.some((permission) => permission.title === "send-cv")
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);
  const [showAccessDeniedModal, setShowAccessDeniedModal] = useState(true); 

  useEffect(() => {
    if (!candidatePerSend) {
      setShowAccessDeniedModal(true); 
    }
  }, [candidatePerSend]);
  const [selectedLang, setSelectedLang] = useState(null);
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem('selectedLang');
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === 'Français') {
        return translations.fr;
      }
    }
    return translations.en;
  });
  const appliedJobId = localStorage.getItem("appliedJobId");
  const currentUser = JSON.parse(localStorage.getItem('userDetails'));
  const useremail= currentUser.email;
  const [skillsForJob, setSkillsForJob] = useState("");
  	const [jobRoleName, setJobRoleName] = useState(lang.menu.jobRole);
    const [jobRoles, setJobRoles] = useState([]);
    const [jobRolesList, setJobRolesList] = useState([]);
    const [jobRolesListName, setJobRolesListName] = useState([]);

    const [buttonDisabled, setButtonDisabled] = useState(true);
  const [file, setFile] = useState(null);
  const [currentLang, setCurrentLang] = useState(lang);
  const [filename, setFilename] = useState("");
  const [emailRef, setEmailRef] = useState("");
  const [fullname, setFullname] = useState("");
  const [nameRef, setNameRef] = useState("");
  const [email, setEmail] = useState(useremail);
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [uploadSuccess, setUploadSuccess] = useState(false);
  useEffect(() => {
    const appliedJob = localStorage.getItem("appliedJob");

    if (appliedJob && jobRoles.includes(appliedJob)) {
      setJobRoleName(appliedJob);
    }

    //localStorage.removeItem("appliedJob");
  }, [jobRoles]);
  useEffect(() => {
    const storedLang = localStorage.getItem('selectedLang');
    if (storedLang) {
      setSelectedLang(JSON.parse(storedLang));
    }
  }, []);

  useEffect(() => {
    setCurrentLang(selectedLang);
  }, [selectedLang]);

	const handleDropdownSelect3 = async (value) => {
    setJobRoleName(value);
    const selectedJob = jobRoles.find((job) => job === value);

    if (selectedJob) {
      try {
        const jobSkills = await getJobSkills(selectedJob);

        setSkillsForJob(jobSkills);
      } catch (error) {
        console.error("Error fetching job skills:", error);
      }
    }
  };
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFilename(selectedFile.name);
    setFile(selectedFile);
    setButtonDisabled(false);
  };
  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem('userDetails'));
    const userId = currentUser.localId;
    
    const getUserCompanyID = async () => {
      const responseC = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
      const data = await responseC.json();
      const compId = data[0].companyID;
      return compId;
    };

    getUserCompanyID().then((compId) => {
      axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getAll`, 
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(response => {
          if (compId !== 1) {
            const filteredJobs = response.data.filter(job => job.companyid === compId && job.Status==="Active" && job.archived===0);
            const jobObjects = filteredJobs.map(job => ({ id: job.id, jobTitle: job.JobTitle }));
            setJobRolesList(jobObjects);
            const names = filteredJobs.map(job => job.JobTitle);
            setJobRolesListName(names);
          } else {
            const filteredJobs = response.data.filter(job =>  job.Status==="Active" && job.archived===0);

            const jobObjects = filteredJobs.map(job => ({ id: job.id, jobTitle: job.JobTitle }));
            setJobRolesList(jobObjects);
            const names = filteredJobs.map(job => job.JobTitle);
            setJobRolesListName(names);
          }
        })
        .catch(error => {
          console.error('Erreur lors de la récupération des emplois :', error);
        });
    });
    
  }, []);
  useEffect(() => {
		axios.get(`${process.env.REACT_APP_APP_DOMAIN}/flask/allJob`,{
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
		  .then(response => {
			setJobRoles(response.data);
		  })
		  .catch(error => {
			console.error('Erreur lors de la récupération des rôles d\'emploi:', error);
		  });
	  }, []);
    const MAX_FILE_SIZE = 2.5 * 1024 * 1024;
    const handleFileUpload = async () => {
      const formData = new FormData();
      
        formData.append('files', file);
  const largeFiles =  file.size > MAX_FILE_SIZE;

  if (largeFiles) {
    swal(lang.menu.filesize, {
      icon: "error",
    });
    return;
  }

      try {
        const response = await axios.post(`${process.env.REACT_APP_APP_DOMAIN}/flask/candidates/uploadSingleFile`, formData,{
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        swal(lang.menu.filesuccess, {
          icon: "success",
        });
        setUploadSuccess(true)
      } catch (error) {
        console.log('Error uploading files. Please try again later.');
      }
    };
   

    const handleUploadAndAdd = async (event) => {
      event.preventDefault();
      const formData = new FormData();
      formData.append('files', file);
      formData.append('filename', filename);
      formData.append('emailRef', emailRef);
      formData.append('fullname', fullname);
      formData.append('nameRef', nameRef);
      formData.append('email', email);
      const formData2 = new FormData();
      formData2.append('jobRoleName', jobRoleName);
      formData2.append('files', file.name);
      const currentUser = JSON.parse(localStorage.getItem('userDetails'));
      const userId = currentUser.localId;
    
      try {
        // Adding candidate first
        const addResponse = await axios.post(`${process.env.REACT_APP_APP_DOMAIN}/flask/candidates/addCandidates1`, formData2, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });
        let finalAppliedJobId = appliedJobId;
        if (!finalAppliedJobId) {
          finalAppliedJobId = jobRolesList.find(job => job.jobTitle === jobRoleName)?.id;
        }
        // Uploading file after candidate is added
        formData.append('jobId', finalAppliedJobId);

        const uploadResponse = await axios.post(
          `${process.env.REACT_APP_APP_DOMAIN}/flask/upload`, 
          formData, // Le corps de la requête, ici un objet FormData
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
              'filename': filename,
              'emailRef': emailRef,
              'fullname': fullname,
              'nameRef': nameRef,
              'email': email,
              'jobId': finalAppliedJobId
            }
          }
        );
        
        
        // Creating candidate in job role after candidate is added
        const createCandidateResponse = await axios.post(
          `${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/create-candidate2/${email}/${finalAppliedJobId}`,{}, {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          }
        );
    
        localStorage.removeItem("appliedJob");
        swal(lang.menu.successMes, {
          icon: "success",
        });
        //window.location.reload();
      } catch (error) {
        if (error.response) {
          swal(error.response.data.error|| 'An error occurred', {
            icon: "error",
          });
        } else if (error.request) {
          swal('No response received from the server', {
            icon: "error",
          });
        } else {
          swal('An error occurred', {
            icon: "error",
          });
        }
      }
    };
    
    
    const getJobSkills = async (jobId) => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/GetSkillsByJobRoleTitle/${jobId}`, 
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        const jobskills = response.data;
        return jobskills;
      } catch (error) {
        console.error(`Error fetching job skills for ID ${jobId}:`, error);
        return "";
      }
    };
    
  const handleReferencesChange = (event) => {
    setEmailRef(event.target.value);
  };
  const handlefullname = (event) => {
    setFullname(event.target.value);
  };
  const handleEmail = (event) => {
    setEmail(event.target.value);
  };
  const handlenameRef = (event) => {
    setNameRef(event.target.value);
  };
  const parseSkillsString = (skillsString) => {
    const skillsArray = skillsString
      .replace(/\(/g, '[')
      .replace(/\)/g, ']')
      .replace(/'/g, '"');
  
    try {
      return JSON.parse(skillsArray);
    } catch (error) {
      console.error("Error parsing skills string:", error);
      return [];
    }
  };
  const jobRoleOptions = jobRolesListName.map((jobRole) => ({
    value: jobRole,
    label: jobRole
  }));
  
  return (
    <> {candidatePerSend ? (
    <div >

    <div className="container">
      
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card">
            
          <Card>
                <Card.Header>
                  <Card.Title>{lang.menu.uploadCv}</Card.Title>
                  
                </Card.Header>
              </Card>
          

            <div className="card-body">
      
              <div style={{ backgroundColor: "red", color: "white", padding: "10px", marginBottom: "10px" , borderRadius: "10px"}}>
          Note: {lang.menu.NB}
                  </div>
               <form onSubmit={handleUploadAndAdd}>
                 
                 
                  <div class="input-group">
            <input type="file" className="form-control" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04"
             aria-label="Upload" 
             accept=".pdf" 
             onChange={handleFileChange} required />
            <button className={`btn btn-${buttonDisabled ? 'secondary' : 'success'} btn-sm`} 
            type="button" id="inputGroupFileAddon04" 
            title="Check your selection"
            onClick={handleFileUpload}
            disabled={buttonDisabled} ><IoMdCheckboxOutline /></button>
</div>
                
            
                  <div className="form-group">
                    <br/>
                    <label><strong>{lang.menu.fullname} <span style={{ color: 'red' }}>*</span> : </strong></label>
                    <br/>
                    <input type="text" name="fullname" className="form-control" value={fullname} onChange={handlefullname} required/>
                  </div>
                  <div className="form-group">
                    <br/>
                    <label><strong>{lang.menu.email} <span style={{ color: 'red' }}>*</span> : </strong></label>
                    <br/>
                    <input type="text" name="email" className="form-control" value={email} onChange={handleEmail} required/>
                  </div>
                  <div className="form-group">
                    <br/>
                    <label><strong>{lang.menu.ref} : </strong></label>
                    <br/>
                    <input type="text" name="nameRef" className="form-control" value={nameRef} onChange={handlenameRef} />
                  </div>
                  <div className="form-group">
                    <br/>
                    <label><strong>{lang.menu.mailref} :</strong> </label>
                    <br/>
                    <input type="text" name="references" className="form-control" value={emailRef} onChange={handleReferencesChange} />
                    </div>
                    <br/>
                    <Form.Group controlId="jobRoleName">
  <Form.Label>
    <strong>Job Role <span style={{ color: 'red' }}>*</span>:</strong>
  </Form.Label>
  <Select
  name="jobRoleName"
  value={jobRoleOptions.find(option => option.value === jobRoleName)} // Pour maintenir la sélection actuelle
  onChange={(selectedOption) => handleDropdownSelect3(selectedOption.value)}
  options={[
    { value: '', label: lang.menu.jobRole, isDisabled: true },  // Option non sélectionnable
    ...jobRoleOptions
  ]}
  placeholder={lang.menu.jobRole}
  isClearable
  required
/>


</Form.Group>



    <br/>
    <div>
  {skillsForJob && skillsForJob.length > 0 && (
     <div className="form-group">
     <label>
       <strong>Skills for Selected Job:</strong>
     </label>
    <ul>
      {parseSkillsString(skillsForJob).map((skill, index) => (
        <Badge key={index} style={{ margin: "1%", backgroundColor: "#43DAC2" }}>
          <span style={{ textAlign: "center" }}>{`${skill[0]} : ${skill[1]}`}</span>
        </Badge>
      ))}
    </ul></div>
  )}
</div>
				
                  
                  <br/>
                  
                  <div className="d-flex justify-content-center align-items-center flex-column">
                  <button type="submit" className="btn btn-primary center" disabled={!uploadSuccess}>{lang.menu.upload}</button>
                  </div>
                </form>
            </div>
          </div>
        </div>
      </div>
    </div><ToastContainer />
    </div>   ) : (
             <Modal show={showAccessDeniedModal} >
             <Modal.Header>
               <Modal.Title>{lang.menu.accessDenied}</Modal.Title>
             </Modal.Header>
             <Modal.Body>
 {lang.menu.YoudontHavePerm}
             </Modal.Body>
             <Modal.Footer>
         
             </Modal.Footer>
           </Modal>
          )}</>
  );
};

export default CvUpload;