export const languageOptions1 = [
  {
    id: 1,
    name: "Main.java",
    label: "Java",
    value: "java",
  },
  {
    id: 2,
    name: "main.py",
    label: "Python",
    value: "python",
  },
  {
    id: 3,
    name: "Main.c",
    label: "C",
    value: "c",
  },
  {
    id: 4,
    name: "Main.cpp",
    label: "C++",
    value: "cpp",
  },
  {
    id: 5,
    name: "index.js",
    label: "NODEJS",
    value: "nodejs",
  },
  {
    id: 63,
    name: "index.js",
    label: "JAVASCRIPT",
    value: "javascript",
  },
  {
    id: 7,
    name: "Main.groovy",
    label: "GROOVY",
    value: "groovy",
  },
  {
    id: 8,
    name: "jscript.jsh",
    label: "JSHELL",
    value: "jshell",
  },
  {
    id: 9,
    name: "Main.hs",
    label: "HASKELL",
    value: "haskell",
  },
  {
    id: 10,
    name: "main.tcl",
    label: "TCL",
    value: "tcl",
  },
  {
    id: 11,
    name: "HelloWorld.adb",
    label: "Ada",
    value: "ada",
  },
  {
    id: 12,
    name: "HelloWorld.lsp",
    label: "COMMONLISP",
    value: "commonlisp",
  },
  {
    id: 13,
    name: "HelloWorld.d",
    label: "D",
    value: "d",
  },
  {
    id: 14,
    name: "HelloWorld.ex",
    label: "ELIXIR",
    value: "elixir",
  },
  {
    id: 15,
    name: "main.erl",
    label: "ERLANG",
    value: "erlang",
  },
  {
    id: 16,
    name: "HelloWorld.fs",
    label: "F#",
    value: "fsharp",
  },
  {
    id: 17,
    name: "Main.lua",
    label: "LUA",
    value: "lua",
  },
  {
    id: 18,
    name: "HelloWorld.ftn",
    label: "FORTAN",
    value: "fortran",
  },

  {
    id: 35,
    name: "HelloWorld.asm",
    label: "ASSEMBLY",
    value: "assembly",
  },
  {
    id: 36,
    name: "HelloWorld.scala",
    label: "SCALA",
    value: "scala",
  },
  {
    id: 37,
    name: "HelloWorld.php",
    label: "PHP",
    value: "php",
  },
  {
    id: 38,
    name: "HelloWorld.cs",
    label: "C#",
    value: "csharp",
  },
  {
    id: 39,
    name: "HelloWorld.py",
    label: "PYTHON2",
    value: "python2",
  },
  {
    id: 40,
    name: "HelloWorld.pl",
    label: "PERL",
    value: "perl",
  },
  {
    id: 41,
    name: "HelloWorld.rb",
    label: "RUBY",
    value: "ruby",
  },
  {
    id: 42,
    name: "HelloWorld.go",
    label: "GO",
    value: "go",
  },

  {
    id: 44,
    name: "HelloWorld.r",
    label: "R",
    value: "r",
  },
  {
    id: 45,
    name: "HelloWorld.rkt",
    label: "RACKET",
    value: "racket",
  },
  {
    id: 46,
    name: "HelloWorld.ml",
    label: "OCAML",
    value: "ocaml",
  },
  {
    id: 47,
    name: "HelloWorld.vb",
    label: "VB(VB.NET)",
    value: "vb",
  },
  {
    id: 48,
    name: "HelloWorld.sh",
    label: "BASH",
    value: "bash",
  },
  {
    id: 49,
    name: "HelloWorld.clj",
    label: "CLOJURE",
    value: "clojure",
  },
  {
    id: 50,
    name: "HelloWorld.ts",
    label: "TYPESCRIPT",
    value: "typescript",
  },
  {
    id: 51,
    name: "HelloWorld.cbl",
    label: "COBOL",
    value: "cobol",
  },
  {
    id: 52,
    name: "HelloWorld.kt",
    label: "KOTLIN",
    value: "kotlin",
  },
  {
    id: 53,
    name: "HelloWorld.pas",
    label: "PASCAL",
    value: "pascal",
  },
  {
    id: 54,
    name: "HelloWorld.pl",
    label: "PROLOG",
    value: "prolog",
  },
  {
    id: 55,
    name: "HelloWorld.rs",
    label: "RUST",
    value: "rust",
  },
  {
    id: 56,
    name: "HelloWorld.swift",
    label: "SWIFT",
    value: "swift",
  },
  {
    id: 57,
    name: "HelloWorld.m",
    label: "OCTAVE",
    value: "octave",
  },
  {
    id: 58,
    name: "HelloWorld.bf",
    label: "BRAINFK",
    value: "brainfk",
  },
  {
    id: 59,
    name: "HelloWorld.coffee",
    label: "COFFEESCRIPT",
    value: "coffeescript",
  },
  {
    id: 60,
    name: "HelloWorld.ejs",
    label: "EJS",
    value: "ejs",
  },
  {
    id: 61,
    name: "queries.sql",
    label: "MYSQL",
    value: "mysql",
  },
  {
    id: 62,
    name: "commands.sql",
    label: "POISTGRES",
    value: "postgresql",
  },
  {
    id: 630,
    name: "commands.sql",
    label: "SQLSERVER",
    value: "sqlserver",
  },
  {
    id: 64,
    name: "queries.sql",
    label: "SQLITE",
    value: "sqlite",
  },
  {
    id: 65,
    name: "script.js",
    label: "MongoDB",
    value: "mongodb",
  },
  {
    id: 66,
    name: "commands.sql",
    label: "MARIADB",
    value: "mariadb",
  },
  {
    id: 67,
    name: "commands.redis",
    label: "REDIS",
    value: "redis",
  },
];
