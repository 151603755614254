import React, { useState, useEffect, useRef } from 'react';
import { Dropdown, Row, Card, Col } from 'react-bootstrap';

import translations from "../../AppsMenu/Candidate/mulitlingue";

const OutputWindow = ({ outputDetails }) => {
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  useEffect(() => {
    const selectedLang = JSON.parse(localStorage.getItem("selectedLang"));
    if (selectedLang) {
      setLang(selectedLang);
    }
  }, []);
  const getOutput = () => {
    let statusId = outputDetails?.status?.id;
 
    if (statusId === 6) {
      // compilation error
      return (
        <pre className="px-2 py-1 font-normal text-xs text-red-500">
          {(outputDetails?.compile_output)}
        </pre>
      );
    } else if (statusId === 3) {
      return (
        <pre className="px-2 py-1 font-normal text-xs text-green-500">
        {outputDetails.stdout !== null
          ? new TextDecoder('utf-8').decode(
              new Uint8Array(atob(outputDetails.stdout).split('').map(c => c.charCodeAt(0)))
            )
          : null}
      </pre>
      
      
      
      );
    } else if (statusId === 5) {
      return (
        <pre className="px-2 py-1 font-normal text-xs text-red-500">
          {`Time Limit Exceeded`}
        </pre>
      );
    } else {
      return (
        <pre className="px-2 py-1 font-normal text-xs text-red-500">
          {(outputDetails?.stderr)}
        </pre>
      );
    }
  };

  return (
    <>
   <Row style={{fontFamily:"Poppins" }}>
    <Col>
      <h1 className="font-bold text-xl bg-clip-text text-transparent bg-gradient-to-r from-slate-900 to-slate-700 mb-2">

      {lang.menu.Output}
      </h1></Col>
   
                  </Row>
      <div className="w-full h-56 bg-[#1e293b] rounded-md text-white font-normal text-sm overflow-y-auto">

  <Card style={{backgroundColor:"#e9f9fd",color:"black",width:"93%",height:"100%",textAlign:"left",fontSize:"170%",marginLeft:"3%",fontFamily:"Poppins"}}>
        {outputDetails ? <>{getOutput()}</> : null}</Card>
      </div>
    </>
  );
};

export default OutputWindow;
