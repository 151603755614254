import { useState, useEffect } from "react";
import translations from"./mulitlingue"

function CandidatesProgress({ totalCandidatesEn, numNotSaved, numSaved, lang }) {
  const [rejectedPercent, setRejectedPercent] = useState(0);
  const [acceptedPercent, setAcceptedPercent] = useState(0);
  useEffect(() => {
    if (totalCandidatesEn > 0) {
      setRejectedPercent((numNotSaved / totalCandidatesEn) * 100);
      setAcceptedPercent((numSaved / totalCandidatesEn) * 100);
    }
  }, [totalCandidatesEn, numNotSaved,numSaved]);


  return (
    <div className="row mt-4 align-items-center" >

                <div className="card mt-4 ">
								<div className="card-body">
    <div className="d-flex flex-column align-items-center mt-4">
      <h4 className="fs-20 mb-3">{lang.menu.candidates}</h4>
      <div className="col-xl-6 col-sm-6">
        <div className="progress default-progress">
          <div
            className="progress-bar progress-bar-striped bg-info progress-animated"
            style={{ width: "100%", height: "13px" }}
            role="progressbar"
          >
            <span className="sr-only">100% Complete</span>
          </div>
        </div>
        <div className="d-flex align-items-end mt-2 pb-4 justify-content-between">
          <span className="fs-14 font-w500">Total Candidates</span>
          <span className="fs-16">
            <span className="text-black pe-2"></span>
            {totalCandidatesEn}
          </span>
        </div>
        <div className="progress default-progress">
          <div
            className="progress-bar progress-bar-striped progress-animated"
            style={{ width: `${rejectedPercent}%`, height: "13px" }}
            role="progressbar"
          >
            <span className="sr-only">{rejectedPercent}% Complete</span>
          </div>
        </div>
        <div className="d-flex align-items-end mt-2 pb-4 justify-content-between">
          <span className="fs-14 font-w500">Rejected Candidates</span>
          <span className="fs-16">
            <span className="text-black pe-2"></span>
            {numNotSaved}
          </span>
        </div>
        <div className="progress default-progress">
        <div
            className="progress-bar progress-bar-striped bg-success progress-animated"
            style={{ width: `${acceptedPercent}%`, height: "13px" }}
            role="progressbar"
          >
            <span className="sr-only">{acceptedPercent}% Complete</span>
          </div>
        </div>
        <div className="d-flex align-items-end mt-2 pb-4 justify-content-between">
          <span className="fs-14 font-w500">Selected Candidates</span>
          <span className="fs-16">
            <span className="text-black pe-2"></span>
            {numSaved}
          </span>
        </div>
      </div>
    </div>
    </div></div></div>
  );
}

export default CandidatesProgress;
