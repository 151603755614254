import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Dropdown, Table, Modal, Button } from 'react-bootstrap';
import translations from "./mulitlingue";
import { Radar } from 'react-chartjs-2';
import {Col,Row} from "react-bootstrap";
import PageTitle from '../../../layouts/PageTitle';
import { ToastContainer, toast } from 'react-toastify';
import swal from "sweetalert";

const CandidateEval = () => {
  const [candidateEval, setCandidateEval] = useState([]);
  const token=localStorage.getItem("token");

  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem(""));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const data = await response.json();
          setCandidateEval(
            data.some((permission) => permission.title === "candidate-eval")
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);
  const [showAccessDeniedModal, setShowAccessDeniedModal] = useState(true); 

  useEffect(() => {
    if (!candidateEval) {
      setShowAccessDeniedModal(true); 
    }
  }, [candidateEval]);
  const [candidates, setCandidates] = useState([]);
    const [resultData, setResultData] = useState(null);
    const [selectedJobRole, setSelectedJobRole] = useState('');
    const [jobRoles, setJobRoles] = useState([]);
    const [selectedLang, setSelectedLang] = useState(null);
    const [jobTitle, setJobTitle] = useState('');

    const getUserCompanyID = async () => {
      const currentUser = JSON.parse(localStorage.getItem('userDetails'));
    const userId = currentUser.localId;
      const responseC = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`, 
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      const data = await responseC.json();
      const compId = data[0].companyID;
      return compId;
    };
    const [lang, setLang] = useState(() => {
        const storedLang = localStorage.getItem('selectedLang');
        if (storedLang) {
          const parsedLang = JSON.parse(storedLang);
          if (parsedLang.language === 'Français') {
            return translations.fr;
          }
        }
        return translations.en;
      });
    useEffect(() => {
        const fetchCandidates = async () => {
          try {
            const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getAllCandidate`, 
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
            setCandidates(response.data);
          } catch (error) {
            console.log('Erreur lors de la récupération des candidats :', error);
          }
        };
    
        fetchCandidates();
      }, []);
      useEffect(() => {
        const fetchJobRoles = async () => {
          try {
            const compId = await getUserCompanyID();

            const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getAll`, 
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
            if (compId!=1) {
              const filteredTests = response.data.filter(job => job.companyid=== compId  || job.companyid === null);
              setJobRoles(filteredTests);
           } else {
              setJobRoles(response.data);
           }   
          } catch (error) {
            console.log('Erreur lors de la récupération des jobroles :', error);
          }
        };
    
        fetchJobRoles();
      }, []);
      const getJobName = async (jobId) => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/findJobTitleById/${jobId}`, 
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
          const jobName = response.data.JobTitle;
          console.log(jobName.JobTitle)
          return jobName;
        } catch (error) {
          console.error(`Error fetching job name for ID ${jobId}:`, error);
          return "Unknown Job";
        }
      };
      const handleJobRoleSelect = async(eventKey) => {
        setSelectedJobRole(eventKey);
        const jobTitle=await getJobName(eventKey)
        setJobTitle(jobTitle);
      };
      const fetchCandidateScores = async (candidate) => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/candidateExt/getScoresAndTestNbre/${selectedJobRole}/${candidate.email}`, 
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
          return response.data;
        } catch (error) {
          const responseError =  {
            "Ptscore": null,
            "testPsychoCount": null,
            "score": candidate.score,
            "OT": candidate.OT,
            "Tscore": null,
            "Cscore": null,
            "overallScore": candidate.score/3
        };
        return responseError;
        }
      };
      const handleFetchRadarPsycho = async () => {
        if (selectedJobRole) {
            try {
                const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/candidateExt/candidateByJobRoleId/${selectedJobRole}`, 
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  });
                console.log(response.data.candidates)
                const candidatesWithScores = await Promise.all(response.data.candidates.map(async (candidate) => {
                  const scores = await fetchCandidateScores(candidate);
                  return {
                    ...candidate,
                    scores,
                  };
                }));
                setResultData(candidatesWithScores);
              
            } catch (error) {
                console.error('Erreur lors de la récupération des données :', error);
                swal('Erreur lors de la récupération des données', {
                  icon: "error",
                });
            }
        } else {
            toast.warn('Veuillez sélectionner un candidat et un jobrole avant de soumettre.');
        }
    };
    const radarOptions = {
      scales: {
        r: {
          ticks: {
            beginAtZero: true,
            stepSize: 1,
          },max: 10,
        },
      },
    };  
    const formatDataForRadar = (data) => {
      return {
        labels: ['Ptscore', 'Tscore', 'score'],
        datasets: [
          {
            label: 'Radar Chart',
            data: [
              data.Ptscore,
              (data.Tscore+data.Cscore)/2,
              (data.score) ,
            ],
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: '#74188D',
            pointBackgroundColor: '#74188D',
            pointBorderColor: '#000',
            pointHoverBackgroundColor: '#000',
            pointHoverBorderColor: '#74188D',
          },
        ],
      };
    };
  return (
    <> {candidateEval ? (
    <div >
      <PageTitle
        activeMenu={lang.menu.JobRolEval}
        motherMenu={lang.menu.dashboard}
        pageContent="Candidate"
      />
     <Row>
        <Col xl={10}>
        <Dropdown onSelect={handleJobRoleSelect}>
  <Dropdown.Toggle variant="primary light" id="dropdown-jobrole" style={{ width: '80%' }} >
    <label htmlFor="dropdown-jobrole"></label>
    {selectedJobRole ? jobTitle : lang.menu.selectJobRole}
  </Dropdown.Toggle>
  <Dropdown.Menu style={{ maxWidth: '100%', maxHeight: '400%', overflowY: 'auto', width: '80%' }}>
    {jobRoles.length > 0 ? (
      jobRoles.map((jobRole) => (
        <Dropdown.Item key={jobRole.id} eventKey={jobRole.id}>
          {jobRole.JobTitle}
        </Dropdown.Item>
      ))
    ) : (
 
      <Dropdown.Item disabled>
        {lang.menu.noJobRolesForCandidate}
      </Dropdown.Item>
    )}
  </Dropdown.Menu>
</Dropdown>
<br/>


              </Col>
              <Col xl={2} className="justify-content-xl-end">
              <Button onClick={handleFetchRadarPsycho}>{lang.menu.submit}</Button>
              </Col>
              </Row>
              {resultData && resultData.length!=0 ? (
              <div className="row">
        <div className="col-xl-12">
          <div className="table-responsive">
            <table className="table display mb-4 dataTablesCard job-table table-responsive-xl card-table dataTable no-footer">
              
      <thead>
          <tr>
            <th>ID</th>
            <th>{lang.menu.name}</th>
            <th>Email</th>
        
            <th>Radar</th>
          </tr>
        </thead>      
        <tbody>
          {resultData &&
            resultData.map((data, index) => {
              //const candidateNumber = index + 1;
              const radarData = formatDataForRadar(data.scores); 
              return (
                <tr key={index}>
                  <td>
                    <strong>{data.id}</strong>
                  </td>
                  <td className={data.name ? '' : 'bg-light'}>{data.name}</td>
                  <td className={data.email ? '' : 'bg-light'}>{data.email}</td>
                  <td style={{ maxWidth: "200px", margin: "0 auto" }}>
                    <Radar data={radarData} options={radarOptions} />
                  </td>
                </tr>
              );
            })}
        </tbody>
        </table>
          </div>
        </div>
        </div>): (
  <div className='text-center' ><p>{lang.menu.selectJob}</p>

</div>
)}






    </div>   ) : (
             <Modal show={showAccessDeniedModal} >
             <Modal.Header>
               <Modal.Title>{lang.menu.accessDenied}</Modal.Title>
             </Modal.Header>
             <Modal.Body>
 {lang.menu.YoudontHavePerm}
             </Modal.Body>
             <Modal.Footer>
         
             </Modal.Footer>
           </Modal>
          )}</>
  );
};

export default CandidateEval;
