import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
  Modal,
  Button,
  Card,
  Table,
  Badge,
  Tabs, Tab,
  Col,
  Row

} from "react-bootstrap";
import translations from"../../AppsMenu/Candidate/mulitlingue";
import { languageOptions } from "../constants/languageOptions";

const JobRoleList = () => {
  const [jobRoles, setJobRoles] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [total, setTotal] = useState('');
  const token=localStorage.getItem("token");

  const [userRole, setuserRole] = useState([]);
  const [showTestsModal, setShowTestsModal] = useState(false);
  const [selectedJobRoleTests, setSelectedJobRoleTests] = useState([]);  
  const [selectedCandidate, setSelectedCandidate] = useState('');
  const [testDetails, setTestDetails] = useState([]);
  const [missingIds, setMissingIds] = useState([]);
  const [missingIdsCoding, setMissingIdsCoding] = useState([]);
  const [passTest,setPassTest] = useState([]);
  const getUserCompanyID = async () => {
    const currentUser = JSON.parse(localStorage.getItem('userDetails'));
    const userId = currentUser.localId;
    const responseC = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
    const data = await responseC.json();
    const compId = data[0].companyID;
    return compId;
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userRole.length > 0) {
          const userDetailsString = localStorage.getItem("userDetails")
          const userDetails = JSON.parse(userDetailsString);
          const localId = userDetails ? userDetails.localId : null;
          const compId =await getUserCompanyID()
          const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/candidatesjobTech/${localId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }});
          if (compId!=1) {
  
            const filteredJobs = response.data.filter(job => job.companyid === compId);
            setJobRoles(filteredJobs);
          } else {
            setJobRoles(response.data);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchData();
  }, [userRole]);
  const getLanguageName = (languageId) => {
    const languageIdInt = parseInt(languageId)
    const languageOption = languageOptions.find((option) => option.id === languageIdInt);
   
    return languageOption ? languageOption.name : '';
  };
  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
          );
          const data = await response.json();
          setPassTest(
            data.some((permission) => permission.title === "pass-tech-test")
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);
  const [lang, setLang] = useState(() => {
		const storedLang = localStorage.getItem('selectedLang');
		if (storedLang) {
		  const parsedLang = JSON.parse(storedLang);
		  if (parsedLang.language === 'Français') {
			return translations.fr;
		  }
		}
		return translations.en;
	  });
    useEffect(() => {
  
      const selectedLang = JSON.parse(localStorage.getItem('selectedLang'));
      if (selectedLang) {
        setLang(selectedLang);
      }
    }, []);

    useEffect(() => {
      const fetchUserPermissions = async () => {
        try {
          const currentUser = JSON.parse(localStorage.getItem('userDetails'));
          if (currentUser && currentUser.localId) {
            const userId = currentUser.localId;
            const response = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
            const data = await response.json();
            setuserRole(data);
  
          }
        } catch (error) {
          console.error("Error fetching permissions details:", error);
        }
      };
    
      fetchUserPermissions();
    }, []);
console.log("tesst user: ",userRole)

  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/users`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
        setCandidates(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);
  const handlePassTest = (nbre_question_globale) => {
    /*     const userId = userRole[0].uid;
        localStorage.setItem('jobRoleId', jobRoleId);
        localStorage.setItem('selectedCandidate', userId); */
        localStorage.setItem('questionsNbr', nbre_question_globale);
    
      };


  const showJobRoleTests = async (jobRole) => {
    try {
      const userId = userRole[0].uid;

      const response1 = await axios.get(
        `${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/findMissingTestIds/${userId}/${jobRole.id}`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
      );
  
      const missingTestIds = response1.data.missingTestIds;
      console.log("missingTestIds : ",missingTestIds)
      setMissingIds(missingTestIds);
      //*********************/
      const response2 = await axios.get(
        `${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/findMissingTestIdsCoding/${userId}/${jobRole.id}`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
      );
  
      const missingTestIdsCoding = response2.data.missingTestIds;
      console.log("missingTestIds : ",missingTestIdsCoding)
      setMissingIdsCoding(missingTestIdsCoding);



      const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getJobroleByIdTech/${jobRole.id}`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }});
      const tests = response.data.testTechIds; 
      setSelectedJobRoleTests(tests);
      localStorage.setItem('jobRoleId', jobRole.id); 
      setShowTestsModal(true);
    } catch (error) {
      console.error("Error fetching jobRole tests:", error);
    }
  };
  const fetchTestDetails = async (testId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/testTech/getTestDetailsById/${testId}`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }});
      console.log("res : ",response.data)
      return response.data;
    } catch (error) {
      console.error("Error fetching test details:", error);
      return null;
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const details = [];
        let totalTechQCM = 0; 
        let totalCoding = 0; 
        for (const testId of selectedJobRoleTests) {
          const testDetail = await fetchTestDetails(testId);
          if (testDetail) {
            details.push(testDetail);
            if (testDetail.techQCM && testDetail.techQCM.length > 0) {
              totalTechQCM += testDetail.techQCM.length;
            }
            if (testDetail.codingTest && testDetail.codingTest.length > 0) {
              totalCoding += testDetail.codingTest.length;
            }
          }
        }
        setTestDetails(details);
        setTotal(totalTechQCM+totalCoding); 
      } catch (error) {
        console.error("Error fetching test details:", error);
      }
    };
  
    fetchData();
  }, [selectedJobRoleTests]);
  
  function formatDuration(totalSeconds) {
    console.log("totalSeconds : ",totalSeconds)
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    if(seconds !==0 && minutes !==0 )
      return `${minutes} minutes ${seconds} seconds`;
    else if(minutes ===0)
      return `${seconds} seconds`;
    else
      return `${minutes} minutes`;
  }
  return (
    <Card >
      <Card.Header>
        <Card.Title>{lang.menu.jobRole}</Card.Title>
      </Card.Header>
      <Card.Body>
      <div className="d-block">
    
        </div>
        <Table responsive>
          <thead>
            <tr>
              <th>ID</th>
              <th>{lang.menu.name}</th>
              <th>{lang.menu.skills}</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {jobRoles.map((jobRole) => (
              <tr key={jobRole.id}>
                <td>{jobRole.id}</td>
                <td>{jobRole.JobTitle}</td>
                <td>{jobRole.skills
    .replace("[", "")
    .replace("]", "")
    .split("),")
    .map((skill, index) => {
      const skillParts = skill.replace("('", "").replace("')", "").split("',");
      return    <Badge variant="primary light"><span key={index}>{skillParts[0]}</span> {"    "}</Badge>;
    })}</td>
              
                <td>
                {jobRole && (
                  <>
                    <button
                      className="btn btn-secondary"
                      onClick={() => showJobRoleTests(jobRole)}
                    >
                      Show Tests
                    </button>
                  </>
                )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Modal show={showTestsModal} onHide={() => setShowTestsModal(false)}>
        <Modal.Header closeButton>
    {testDetails.length > 0 && testDetails[0].name && (
      <Modal.Title>
        <strong>{testDetails[0].name}</strong> <br />
      </Modal.Title>
    )}
  </Modal.Header>
  <Modal.Body>
    {testDetails.length > 0 && testDetails[0].name && (
      <>
        <strong>Description :</strong> {testDetails[0].description}<br />
        <strong>Total number of tests :</strong> {total} tests<br />
        <p><strong>Total test duration :</strong> {formatDuration(testDetails[0].TimeTotal)}<br /></p>
      </>
    )}
  <Tabs defaultActiveKey="techQCM" id="tests-tabs">
  <Tab eventKey="techQCM" title="TechQCM">
  <br/>
  <ul>
    {testDetails.map((testDetail, index) => {
      return testDetail.techQCM.map((techQCM, techIndex) => {
        const testId = selectedJobRoleTests[index];
        const idt=parseInt(techQCM.id)

        const isMissing = missingIds.includes(idt);
        return (
          <li key={techIndex} style={{ marginBottom: '20px' }}>
            <strong>Name:</strong> {techQCM.name}<br />
           <p> <strong>Description:</strong> {techQCM.description}<br /></p>
            <Row> <Col><strong>Question per Skill:</strong> {techQCM.questionNbre}</Col>
           <Col><strong>Time per Qustion:</strong> {techQCM.timePerQuestion} Seconds<br /></Col> </Row>
           <Row> <Col> <strong>Nbre Question Globale:</strong> {techQCM.nbre_question_globale}</Col>
           <Col><strong>Time of Test :</strong> {formatDuration(techQCM.nbre_question_globale*30)} <br /></Col>
           </Row>
            {passTest ? (
              <Button
                variant="primary"
                onClick={() => {
                  setShowTestsModal(false);
                  handlePassTest(techQCM.nbre_question_globale)

                  if (isMissing) {
                    window.location.href = `/TestTechniqueCandidat/${techQCM.id}`;
                  }
                }}
                disabled={!isMissing} 
              >
                {isMissing ? 'Pass Test' : 'Test Passed'}
              </Button>
            ) : <></>}<br/>
          </li>
        );
      });
    })}
  </ul>
</Tab>

<Tab eventKey="codingTest" title="Coding Test">
  <br/>
  <ul>
    {testDetails.map((testDetail, index) => {
      return testDetail.codingTest.map((codingTest, codingIndex) => {
        const testId = selectedJobRoleTests[index];
        const idt=parseInt(codingTest.id)
        const isMissingCoding = missingIdsCoding.includes(idt);
        return (
          <li key={codingIndex} style={{ marginBottom: '20px' }}>
            <strong>Name:</strong> {codingTest.name}<br />
            <strong>Description:</strong> {codingTest.description}<br />
            <strong>Language:</strong> {getLanguageName(codingTest.languageId)}
            <p><strong>Time in Seconds:</strong> {formatDuration(codingTest.Timer)} </p> 
            {passTest ? (
              <Button
                variant="primary"
                onClick={() => {
                  setShowTestsModal(false);

                  if (isMissingCoding) {
                    window.location.href = `/technical/${codingTest.id}`;
                  }
                }}
                disabled={!isMissingCoding} 
              >
                {isMissingCoding ? 'Pass Test' : 'Test Passed'}
              </Button>
            ) : <></>}<br/>
          </li>
        );
      });
    })}
  </ul>
</Tab>

</Tabs>


  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowTestsModal(false)}>
      Fermer
    </Button>
  </Modal.Footer>
</Modal>

      </Card.Body>
    </Card>
  );
};

export default JobRoleList;
