const data = {
  SpecialismOptions: [
    { value: "Reactjs", label: "Reactjs" },
    { value: "Angular", label: "Angular" },
    { value: "Vue.js", label: "Vue.js" },
    { value: "Ember.js", label: "Ember.js" },
    { value: "Backbone.js", label: "Backbone.js" },
    { value: "jQuery", label: "jQuery" },
    { value: "NodeJs", label: "NodeJs" },
    { value: "ExpressJs", label: "ExpressJs" },
    { value: "HTML5", label: "HTML5" },
    { value: "CSS3", label: "CSS3" },
    { value: "TailWind", label: "TailWind" },
    { value: "Bootstrap", label: "Bootstrap" },
    { value: "JavaScript", label: "JavaScript" },
    { value: "TypeScript", label: "TypeScript" },
    { value: "stack overflow", label: "Stack Overflow" },
    { value: "Python", label: "Python" },
    { value: "Java", label: "Java" },
    { value: "C#", label: "C#" },
    { value: "C++", label: "C++" },
    { value: "Ruby", label: "Ruby" },
    { value: "PHP", label: "PHP" },
    { value: "Swift", label: "Swift" },
    { value: "Kotlin", label: "Kotlin" },
    { value: "Go", label: "Go" },
    { value: "Rust", label: "Rust" },
    { value: "Dart", label: "Dart" },
    { value: "Scala", label: "Scala" },
    { value: "Perl", label: "Perl" },
    { value: "SQL", label: "SQL" },
    { value: "NoSQL", label: "NoSQL" },
    { value: "MongoDB", label: "MongoDB" },
    { value: "MySQL", label: "MySQL" },
    { value: "PostgreSQL", label: "PostgreSQL" },
    { value: "Oracle", label: "Oracle" },
    { value: "SQLite", label: "SQLite" },
    { value: "Firebase", label: "Firebase" },
    { value: "GraphQL", label: "GraphQL" },
    { value: "RESTful API", label: "RESTful API" },
    { value: "WebSocket", label: "WebSocket" },
    { value: "AWS", label: "AWS" },
    { value: "Azure", label: "Azure" },
    { value: "Google Cloud", label: "Google Cloud" },
    { value: "Docker", label: "Docker" },
    { value: "Kubernetes", label: "Kubernetes" },
    { value: "Jenkins", label: "Jenkins" },
    { value: "Git", label: "Git" },
    { value: "SVN", label: "SVN" },
    { value: "JIRA", label: "JIRA" },
    { value: "Confluence", label: "Confluence" },
    { value: "Visual Studio Code", label: "Visual Studio Code" },
    { value: "IntelliJ IDEA", label: "IntelliJ IDEA" },
    { value: "Eclipse", label: "Eclipse" },
    { value: "Android Studio", label: "Android Studio" },
    { value: "Xcode", label: "Xcode" },
    { value: "Unity", label: "Unity" },
    { value: "Unreal Engine", label: "Unreal Engine" },
    { value: "Blender", label: "Blender" },
    { value: "Photoshop", label: "Photoshop" },
    { value: "Illustrator", label: "Illustrator" },
    { value: "Figma", label: "Figma" },
    { value: "Sketch", label: "Sketch" },
    { value: "InVision", label: "InVision" },
    { value: "3ds Max", label: "3ds Max" },
    { value: "Maya", label: "Maya" },
    { value: "ZBrush", label: "ZBrush" },
    { value: "Substance Painter", label: "Substance Painter" },
    { value: "GameMaker", label: "GameMaker" },
    { value: "Godot", label: "Godot" },
    { value: "VR Development", label: "VR Development" },
    { value: "AR Development", label: "AR Development" },
    { value: "IoT Development", label: "IoT Development" },
    { value: "Robotics", label: "Robotics" },
    { value: "Quantum Computing", label: "Quantum Computing" },
    { value: "Bioinformatics", label: "Bioinformatics" },
    { value: "Data Science", label: "Data Science" },
    { value: "Machine Learning", label: "Machine Learning" },
    { value: "Artificial Intelligence", label: "Artificial Intelligence" },
    { value: "Cybersecurity", label: "Cybersecurity" },
    { value: "Network Engineering", label: "Network Engineering" },
    { value: "Digital Marketing", label: "Digital Marketing" },
    { value: "Blockchain Development", label: "Blockchain Development" },
    { value: "Embedded Systems", label: "Embedded Systems" },
    { value: "Automotive Engineering", label: "Automotive Engineering" },
    { value: "Aerospace Engineering", label: "Aerospace Engineering" },
    { value: "Bioengineering", label: "Bioengineering" },
    { value: "Civil Engineering", label: "Civil Engineering" },
    { value: "Electrical Engineering", label: "Electrical Engineering" },
    { value: "Mechanical Engineering", label: "Mechanical Engineering" },
    { value: "Chemical Engineering", label: "Chemical Engineering" },
    { value: "Biotechnology", label: "Biotechnology" },
    { value: "Environmental Engineering", label: "Environmental Engineering" },
    { value: "Material Science", label: "Material Science" },
    { value: "Nanotechnology", label: "Nanotechnology" },
    { value: "Quantum Physics", label: "Quantum Physics" },
    { value: "Astronomy", label: "Astronomy" },
    { value: "Robotics Process Automation", label: "Robotics Process Automation" },
    { value: "Game Development", label: "Game Development" },
    { value: "Virtual Reality (VR)", label: "Virtual Reality (VR)" },
    { value: "Augmented Reality (AR)", label: "Augmented Reality (AR)" },
    { value: "Blockchain", label: "Blockchain" },
    { value: "DevOps", label: "DevOps" },
    { value: "Cloud Computing", label: "Cloud Computing" },
    { value: "Web Development", label: "Web Development" },
    { value: "Mobile App Development", label: "Mobile App Development" },
    { value: "Database Administration", label: "Database Administration" },
    { value: "UI/UX Design", label: "UI/UX Design" },
    { value: "Data Analytics", label: "Data Analytics" },
    { value: "Full Stack Development", label: "Full Stack Development" },
    { value: "Frontend Development", label: "Frontend Development" },
    { value: "Backend Development", label: "Backend Development" },
    { value: "IoT (Internet of Things)", label: "IoT (Internet of Things)" },
    { value: "Data Engineering", label: "Data Engineering" },
    { value: "Cloud Architecture", label: "Cloud Architecture" },
    { value: "Natural Language Processing", label: "Natural Language Processing" },
  ],
  TestsTypes: [
    { value: "Technical Test", label: "Technical Test" },
    { value: "Personality Test", label: "Personality Test" },
    { value: "Psychotechnical Test", label: "Psychotechnical Test" },
   
  ],
  TypeQualificationOptions: [
    { value: "Leadership", label: "Leadership" },
    { value: "Management", label: "Management" },
    { value: "Technical", label: "Technical" },
    { value: "Education", label: "Education" },
    { value: "Business management", label: "Business management" },
  ],
  JobTypeOptions: [
    { value: "Full Time", label: "Full Time" },
    { value: "Part Time", label: "Part Time" },
    { value: "Freelance", label: "Freelance" },
  ],

  ExperienceOptions: [
    {value:"intern", label:"intern"},
    { value: "Junior", label: "Junior" },
    { value: "2 Years or more", label: "2 Years or more" },
    { value: "5 Years or more", label: "5 Years or more" },
    { value: "10 Years or more", label: "10 Years or more" },
  ],
  Languages : [
    { label: 'Arabic', value: 'Arabic' },
    { label: 'English', value: 'English' },
    { label: 'Chinese', value: 'Chinese' },
    { label: 'Spanish', value: 'Spanish' },
    { label: 'French', value: 'French' },
    
    { label: 'Russian', value: 'Russian' },
    { label: 'Portuguese', value: 'Portuguese' },
    { label: 'German', value: 'German' },
    { label: 'Japanese', value: 'Japanese' },
    { label: 'Korean', value: 'Korean' },
    { label: 'Italian', value: 'Italian' },
    { label: 'Dutch', value: 'Dutch' },
    { label: 'Swedish', value: 'Swedish' },
    { label: 'Danish', value: 'Danish' },
    { label: 'Norwegian', value: 'Norwegian' },
    { label: 'Finnish', value: 'Finnish' },
    { label: 'Turkish', value: 'Turkish' },
    { label: 'Hindi', value: 'Hindi' },
    { label: 'Bengali', value: 'Bengali' },
    { label: 'Urdu', value: 'Urdu' },
    { label: 'Vietnamese', value: 'Vietnamese' },
    { label: 'Thai', value: 'Thai' },
    { label: 'Greek', value: 'Greek' },
    { label: 'Hebrew', value: 'Hebrew' },
    { label: 'Polish', value: 'Polish' },
    { label: 'Czech', value: 'Czech' },
    { label: 'Hungarian', value: 'Hungarian' },
    { label: 'Romanian', value: 'Romanian' },
    { label: 'Swahili', value: 'Swahili' },
    { label: 'Indonesian', value: 'Indonesian' },
    { label: 'Malay', value: 'Malay' },
    { label: 'Tagalog', value: 'Tagalog' },
    { label: 'Farsi', value: 'Farsi' },
    { label: 'Turkmen', value: 'Turkmen' },
   
  ]
  
  ,
  countries:  [
  
      { label: 'Afghanistan', value: 'Afghanistan' },
      { label: 'Åland Islands', value: 'Åland Islands' },
      { label: 'Albania', value: 'Albania' },
      { label: 'Algeria', value: 'Algeria' },
      { label: 'American Samoa', value: 'American Samoa' },
      { label: 'AndorrA', value: 'AndorrA' },
      { label: 'Angola', value: 'Angola' },
      { label: 'Anguilla', value: 'Anguilla' },
      { label: 'Antarctica', value: 'Antarctica' },
      { label: 'Antigua and Barbuda', value: 'Antigua and Barbuda' },
      { label: 'Argentina', value: 'Argentina' },
      { label: 'Armenia', value: 'Armenia' },
      { label: 'Aruba', value: 'Aruba' },
      { label: 'Australia', value: 'Australia' },
      { label: 'Austria', value: 'Austria' },
      { label: 'Azerbaijan', value: 'Azerbaijan' },
      { label: 'Bahamas', value: 'Bahamas' },
      { label: 'Bahrain', value: 'Bahrain' },
      { label: 'Bangladesh', value: 'Bangladesh' },
      { label: 'Barbados', value: 'Barbados' },
      { label: 'Belarus', value: 'Belarus' },
      { label: 'Belgium', value: 'Belgium' },
      { label: 'Belize', value: 'Belize' },
      { label: 'Benin', value: 'Benin' },
      { label: 'Bermuda', value: 'Bermuda' },
      { label: 'Bhutan', value: 'Bhutan' },
      { label: 'Bolivia', value: 'Bolivia' },
      { label: 'Bosnia and Herzegovina', value: 'Bosnia and Herzegovina' },
      { label: 'Botswana', value: 'Botswana' },
      { label: 'Bouvet Island', value: 'Bouvet Island' },
      { label: 'Brazil', value: 'Brazil' },
      { label: 'British Indian Ocean Territory', value: 'British Indian Ocean Territory' },
      { label: 'Brunei Darussalam', value: 'Brunei Darussalam' },
      { label: 'Bulgaria', value: 'Bulgaria' },
      { label: 'Burkina Faso', value: 'Burkina Faso' },
      { label: 'Burundi', value: 'Burundi' },
      { label: 'Cambodia', value: 'Cambodia' },
      { label: 'Cameroon', value: 'Cameroon' },
      { label: 'Canada', value: 'Canada' },
      { label: 'Cape Verde', value: 'Cape Verde' },
      { label: 'Cayman Islands', value: 'Cayman Islands' },
      { label: 'Central African Republic', value: 'Central African Republic' },
      { label: 'Chad', value: 'Chad' },
      { label: 'Chile', value: 'Chile' },
      { label: 'China', value: 'China' },
      { label: 'Christmas Island', value: 'Christmas Island' },
      { label: 'Cocos (Keeling) Islands', value: 'Cocos (Keeling) Islands' },
      { label: 'Colombia', value: 'Colombia' },
      { label: 'Comoros', value: 'Comoros' },
      { label: 'Congo', value: 'Congo' },
      { label: 'Congo, The Democratic Republic of the', value: 'Congo, The Democratic Republic of the' },
      { label: 'Cook Islands', value: 'Cook Islands' },
      { label: 'Costa Rica', value: 'Costa Rica' },
      { label: 'Cote D\'Ivoire', value: 'Cote D\'Ivoire' },
      { label: 'Croatia', value: 'Croatia' },
      { label: 'Cuba', value: 'Cuba' },
      { label: 'Cyprus', value: 'Cyprus' },
      { label: 'Czech Republic', value: 'Czech Republic' },
      { label: 'Denmark', value: 'Denmark' },
      { label: 'Djibouti', value: 'Djibouti' },
      { label: 'Dominica', value: 'Dominica' },
      { label: 'Dominican Republic', value: 'Dominican Republic' },
      { label: 'Ecuador', value: 'Ecuador' },
      { label: 'Egypt', value: 'Egypt' },
      { label: 'El Salvador', value: 'El Salvador' },
      { label: 'Equatorial Guinea', value: 'Equatorial Guinea' },
      { label: 'Eritrea', value: 'Eritrea' },
      { label: 'Estonia', value: 'Estonia' },
      { label: 'Ethiopia', value: 'Ethiopia' },
      { label: 'Falkland Islands (Malvinas)', value: 'Falkland Islands (Malvinas)' },
      { label: 'Faroe Islands', value: 'Faroe Islands' },
      { label: 'Fiji', value: 'Fiji' },
      { label: 'Finland', value: 'Finland' },
      { label: 'France', value: 'France' },
      { label: 'French Guiana', value: 'French Guiana' },
      { label: 'French Polynesia', value: 'French Polynesia' },
      { label: 'French Southern Territories', value: 'French Southern Territories' },
      { label: 'Gabon', value: 'Gabon' },
      { label: 'Gambia', value: 'Gambia' },
      { label: 'Georgia', value: 'Georgia' },
      { label: 'Germany', value: 'Germany' },
      { label: 'Ghana', value: 'Ghana' },
      { label: 'Gibraltar', value: 'Gibraltar' },
      { label: 'Greece', value: 'Greece' },
      { label: 'Greenland', value: 'Greenland' },
      { label: 'Grenada', value: 'Grenada' },
      { label: 'Guadeloupe', value: 'Guadeloupe' },
      { label: 'Guam', value: 'Guam' },
      { label: 'Guatemala', value: 'Guatemala' },
      { label: 'Guernsey', value: 'Guernsey' },
      { label: 'Guinea', value: 'Guinea' },
      { label: 'Guinea-Bissau', value: 'Guinea-Bissau' },
      { label: 'Guyana', value: 'Guyana' },
      { label: 'Haiti', value: 'Haiti' },
      { label: 'Heard Island and Mcdonald Islands', value: 'Heard Island and Mcdonald Islands' },
      { label: 'Holy See (Vatican City State)', value: 'Holy See (Vatican City State)' },
      { label: 'Honduras', value: 'Honduras' },
      { label: 'Hong Kong', value: 'Hong Kong' },
      { label: 'Hungary', value: 'Hungary' },
      { label: 'Iceland', value: 'Iceland' },
      { label: 'India', value: 'India' },
      { label: 'Indonesia', value: 'Indonesia' },
      { label: 'Iran, Islamic Republic Of', value: 'Iran, Islamic Republic Of' },
      { label: 'Iraq', value: 'Iraq' },
      { label: 'Ireland', value: 'Ireland' },
      { label: 'Isle of Man', value: 'Isle of Man' },
      { label: 'Israel', value: 'Israel' },
      { label: 'Italy', value: 'Italy' },
      { label: 'Jamaica', value: 'Jamaica' },
      { label: 'Japan', value: 'Japan' },
      { label: 'Jersey', value: 'Jersey' },
      { label: 'Jordan', value: 'Jordan' },
      { label: 'Kazakhstan', value: 'Kazakhstan' },
      { label: 'Kenya', value: 'Kenya' },
      { label: 'Kiribati', value: 'Kiribati' },
      { label: 'Korea, Democratic People\'S Republic of', value: 'Korea, Democratic People\'S Republic of' },
      { label: 'Korea, Republic of', value: 'Korea, Republic of' },
      { label: 'Kuwait', value: 'Kuwait' },
      { label: 'Kyrgyzstan', value: 'Kyrgyzstan' },
      { label: 'Lao People\'S Democratic Republic', value: 'Lao People\'S Democratic Republic' },
      { label: 'Latvia', value: 'Latvia' },
      { label: 'Lebanon', value: 'Lebanon' },
      { label: 'Lesotho', value: 'Lesotho' },
      { label: 'Liberia', value: 'Liberia' },
      { label: 'Libyan Arab Jamahiriya', value: 'Libyan Arab Jamahiriya' },
      { label: 'Liechtenstein', value: 'Liechtenstein' },
      { label: 'Lithuania', value: 'Lithuania' },
      { label: 'Luxembourg', value: 'Luxembourg' },
      { label: 'Macao', value: 'Macao' },
      { label: 'Macedonia, The Former Yugoslav Republic of', value: 'Macedonia, The Former Yugoslav Republic of' },
      { label: 'Madagascar', value: 'Madagascar' },
      { label: 'Malawi', value: 'Malawi' },
      { label: 'Malaysia', value: 'Malaysia' },
      { label: 'Maldives', value: 'Maldives' },
      { label: 'Mali', value: 'Mali' },
      { label: 'Malta', value: 'Malta' },
      { label: 'Marshall Islands', value: 'Marshall Islands' },
      { label: 'Martinique', value: 'Martinique' },
      { label: 'Mauritania', value: 'Mauritania' },
      { label: 'Mauritius', value: 'Mauritius' },
      { label: 'Mayotte', value: 'Mayotte' },
      { label: 'Mexico', value: 'Mexico' },
      { label: 'Micronesia, Federated States of', value: 'Micronesia, Federated States of' },
      { label: 'Moldova, Republic of', value: 'Moldova, Republic of' },
      { label: 'Monaco', value: 'Monaco' },
      { label: 'Mongolia', value: 'Mongolia' },
      { label: 'Montserrat', value: 'Montserrat' },
      { label: 'Morocco', value: 'Morocco' },
      { label: 'Mozambique', value: 'Mozambique' },
      { label: 'Myanmar', value: 'Myanmar' },
      { label: 'Namibia', value: 'Namibia' },
      { label: 'Nauru', value: 'Nauru' },
      { label: 'Nepal', value: 'Nepal' },
      { label: 'Netherlands', value: 'Netherlands' },
      { label: 'Netherlands Antilles', value: 'Netherlands Antilles' },
      { label: 'New Caledonia', value: 'New Caledonia' },
      { label: 'New Zealand', value: 'New Zealand' },
      { label: 'Nicaragua', value: 'Nicaragua' },
      { label: 'Niger', value: 'Niger' },
      { label: 'Nigeria', value: 'Nigeria' },
      { label: 'Niue', value: 'Niue' },
      { label: 'Norfolk Island', value: 'Norfolk Island' },
      { label: 'Northern Mariana Islands', value: 'Northern Mariana Islands' },
      { label: 'Norway', value: 'Norway' },
      { label: 'Oman', value: 'Oman' },
      { label: 'Pakistan', value: 'Pakistan' },
      { label: 'Palau', value: 'Palau' },
      { label: 'Palestinian Territory, Occupied', value: 'Palestinian Territory, Occupied' },
      { label: 'Panama', value: 'Panama' },
      { label: 'Papua New Guinea', value: 'Papua New Guinea' },
      { label: 'Paraguay', value: 'Paraguay' },
      { label: 'Peru', value: 'Peru' },
      { label: 'Philippines', value: 'Philippines' },
      { label: 'Pitcairn', value: 'Pitcairn' },
      { label: 'Poland', value: 'Poland' },
      { label: 'Portugal', value: 'Portugal' },
      { label: 'Puerto Rico', value: 'Puerto Rico' },
      { label: 'Qatar', value: 'Qatar' },
      { label: 'Reunion', value: 'Reunion' },
      { label: 'Romania', value: 'Romania' },
      { label: 'Russian Federation', value: 'Russian Federation' },
      { label: 'RWANDA', value: 'RWANDA' },
      { label: 'Saint Helena', value: 'Saint Helena' },
      { label: 'Saint Kitts and Nevis', value: 'Saint Kitts and Nevis' },
      { label: 'Saint Lucia', value: 'Saint Lucia' },
      { label: 'Saint Pierre and Miquelon', value: 'Saint Pierre and Miquelon' },
      { label: 'Saint Vincent and the Grenadines', value: 'Saint Vincent and the Grenadines' },
      { label: 'Samoa', value: 'Samoa' },
      { label: 'San Marino', value: 'San Marino' },
      { label: 'Sao Tome and Principe', value: 'Sao Tome and Principe' },
      { label: 'Saudi Arabia', value: 'Saudi Arabia' },
      { label: 'Senegal', value: 'Senegal' },
      { label: 'Serbia and Montenegro', value: 'Serbia and Montenegro' },
      { label: 'Seychelles', value: 'Seychelles' },
      { label: 'Sierra Leone', value: 'Sierra Leone' },
      { label: 'Singapore', value: 'Singapore' },
      { label: 'Slovakia', value: 'Slovakia' },
      { label: 'Slovenia', value: 'Slovenia' },
      { label: 'Solomon Islands', value: 'Solomon Islands' },
      { label: 'Somalia', value: 'Somalia' },
      { label: 'South Africa', value: 'South Africa' },
      { label: 'South Georgia and the South Sandwich Islands', value: 'South Georgia and the South Sandwich Islands' },
      { label: 'Spain', value: 'Spain' },
      { label: 'Sri Lanka', value: 'Sri Lanka' },
      { label: 'Sudan', value: 'Sudan' },
      { label: 'Surilabel', value: 'Surilabel' },
      { label: 'Svalbard and Jan Mayen', value: 'Svalbard and Jan Mayen' },
      { label: 'Swaziland', value: 'Swaziland' },
      { label: 'Sweden', value: 'Sweden' },
      { label: 'Switzerland', value: 'Switzerland' },
      { label: 'Syrian Arab Republic', value: 'Syrian Arab Republic' },
      { label: 'Taiwan, Province of China', value: 'Taiwan, Province of China' },
      { label: 'Tajikistan', value: 'Tajikistan' },
      { label: 'Tanzania, United Republic of', value: 'Tanzania, United Republic of' },
      { label: 'Thailand', value: 'Thailand' },
      { label: 'Timor-Leste', value: 'Timor-Leste' },
      { label: 'Togo', value: 'Togo' },
      { label: 'Tokelau', value: 'Tokelau' },
      { label: 'Tonga', value: 'Tonga' },
      { label: 'Trinidad and Tobago', value: 'Trinidad and Tobago' },
      { label: 'Tunisia', value: 'Tunisia' },
      { label: 'Turkey', value: 'Turkey' },
      { label: 'Turkmenistan', value: 'Turkmenistan' },
      { label: 'Turks and Caicos Islands', value: 'Turks and Caicos Islands' },
      { label: 'Tuvalu', value: 'Tuvalu' },
      { label: 'Uganda', value: 'Uganda' },
      { label: 'Ukraine', value: 'Ukraine' },
      { label: 'United Arab Emirates', value: 'United Arab Emirates' },
      { label: 'United Kingdom', value: 'United Kingdom' },
      { label: 'United States', value: 'United States' },
      { label: 'United States Minor Outlying Islands', value: 'United States Minor Outlying Islands' },
      { label: 'Uruguay', value: 'Uruguay' },
      { label: 'Uzbekistan', value: 'Uzbekistan' },
      { label: 'Vanuatu', value: 'Vanuatu' },
      { label: 'Venezuela', value: 'Venezuela' },
      { label: 'Viet Nam', value: 'Viet Nam' },
      { label: 'Virgin Islands, British', value: 'Virgin Islands, British' },
      { label: 'Virgin Islands, U.S.', value: 'Virgin Islands, U.S.' },
      { label: 'Wallis and Futuna', value: 'Wallis and Futuna' },
      { label: 'Western Sahara', value: 'Western Sahara' },
      { label: 'Yemen', value: 'Yemen' },
      { label: 'Zambia', value: 'Zambia' },
      { label: 'Zimbabwe', value: 'Zimbabwe' }
    ],
    


};

export default data;
