import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Card,Modal,Form } from "react-bootstrap";
import { Link ,useHistory} from "react-router-dom";
import translations from"../../AppsMenu/Candidate/mulitlingue";
import { toast } from "react-toastify";
import swal from "sweetalert";

function TechQCMList() {
  const history = useHistory();

  const [listTechPer, setListTechPer] = useState([]);
  const token=localStorage.getItem("token");

  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
          );
          const data = await response.json();
          setListTechPer(
            data.some((permission) => permission.title === "list-tech-test")
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);
  const [showAccessDeniedModal, setShowAccessDeniedModal] = useState(true); 

  useEffect(() => {
    if (!listTechPer) {
      setShowAccessDeniedModal(true); 
    }
  }, [listTechPer]);
  const [tests, setTests] = useState([]);
  const [deletetest,setDeletetest] = useState([]);

  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
          );
          const data = await response.json();
          setDeletetest(
            data.some((permission) => permission.title === "delete-tech-test")
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);
  const [createTest,setCreateTest] = useState([]);

  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
          );
          const data = await response.json();
          setCreateTest(
            data.some((permission) => permission.title === "create-test")
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);
  const [lang, setLang] = useState(() => {
		const storedLang = localStorage.getItem('selectedLang');
		if (storedLang) {
		  const parsedLang = JSON.parse(storedLang);
		  if (parsedLang.language === 'Français') {
			return translations.fr;
		  }
		}
		return translations.en;
	  });
    useEffect(() => {
  
      const selectedLang = JSON.parse(localStorage.getItem('selectedLang'));
      if (selectedLang) {
        setLang(selectedLang);
      }
    }, []);

    useEffect(() => {
      const currentUser = JSON.parse(localStorage.getItem('userDetails'));
      const userId = currentUser.localId;
    
      const getUserCompanyID = async () => {
        const responseC = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
        const data = await responseC.json();
        const compId = data[0].companyID;
        return compId;
      };
    
      getUserCompanyID().then((compId) => {
        axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/techQCM/getAllTests`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }})
          .then(response => {
console.log("response.data :",response.data)            
	if (compId!=1) {
              const filteredTests = response.data.filter(test => test.companyID === compId  || test.companyID === null);
              setTests(filteredTests);
            } else {
              setTests(response.data);
            }
          })
          .catch(error => {
            console.error('Erreur lors de la récupération des tests:', error);
          });
      });
    }, []);
  const handleDelete = (testId) => {
    swal({
      title:lang.menu.deleteTitle ,
      text: lang.menu.deleteText,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
    if (testId) {
      const currentUser = JSON.parse(localStorage.getItem('userDetails'));
      const userId = currentUser.localId;
      axios.delete(`${process.env.REACT_APP_APP_DOMAIN}/api/techQCM/deleteTest/${testId}/${userId}`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }})
        .then(response => {
          console.log(response.data); 
          if(response.data.message ==="Impossible de supprimer ce test car il a été utilisé."){
            swal(lang.menu.suppImpo, {
              icon: "error",
            });
          }else{
            swal(lang.menu.deleteSuccessTest, {
              icon: "success",
            });
          setTests(prevTests => prevTests.filter(test => test.test_id !== testId));
        }

        })
        .catch(error => {
          console.error(error); 
        });
    }
  }else {
    // User canceled the delete action
    swal(lang.menu.testDeletMsgCancel, {
      icon: "info",
    });
  }
});
  };
  const handleIconClick = (timePerQuestion,test_id,nbre_question_globale) => {
    history.push(`/Test-QCM-For-Recruter/${test_id}`);

    localStorage.setItem('questionsNbr', nbre_question_globale);
    localStorage.setItem('timePerQuestion',timePerQuestion);
  };

  const [searchTerm, setSearchTerm] = useState('');
  const filterTestByTitle = () => {
    return tests.filter(test => test.test_name.toLowerCase().includes(searchTerm.toLowerCase()));
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  useEffect(() => {
    const deleteButtons = document.querySelectorAll(".deleteButton");
    
    deleteButtons.forEach(deleteButton => {
      deleteButton.addEventListener('click', () => handleDelete(deleteButton.dataset.testId));
    });
    
    return () => {
      deleteButtons.forEach(deleteButton => {
        deleteButton.removeEventListener('click', () => handleDelete(deleteButton.dataset.testId));
      });
    };
  }, []);
  
  

  const renderTests = () => {
    const groupedTests = filterTestByTitle().reduce((result, test) => {
      const { test_id, test_name, test_description, skill_id, companyID,nbre_question_globale,timePerQuestion } = test;
      console.log('test 1 :',test)
      if (!result[test_id]) {
        result[test_id] = {
          test_id,
          test_name,
          test_description,
          skills: [],
          companyID, 
          timePerQuestion,
          nbre_question_globale
        };
       

      }
      result[test_id].skills.push({
        id: skill_id,
        test_name,
      });
      return result;
    }, {});
  
    return Object.values(groupedTests).map((group) => {
      const { test_id, test_name, test_description,nbre_question_globale,timePerQuestion } = group;
      console.log('test 2 :',group)
      return (
        <Card key={test_id} style={{fontFamily: "Poppins" }}>
          <Card.Header>
            <Card.Title>{test_name}</Card.Title>
          </Card.Header>
          <Card.Body>
            <Card.Text>{test_description}</Card.Text>
          </Card.Body>
       





          <Card.Footer>
          <div className="test-card-icons">
            <Link to={`/TechQCMDetails/${test_id}`} > <i className="fas fa-eye test-details-icon fa-lg"></i>
</Link>
<Link to={`/UpdateTestTechniques/${test_id}`}>
                <i className="fas fa-edit fa-lg"></i> 
              </Link>
            <Link to={`/Test-QCM-For-Recruter/${test_id}`}> 
            <i
        className="fa fa-play fa-lg"
        onClick={() => handleIconClick(timePerQuestion,test_id,nbre_question_globale)}
      ></i>
</Link>

            { deletetest ? ( <button
                              style={{ border: "none" }}
              onClick={() => handleDelete(test_id)} 
              disabled={group.companyID === null} 
            >
                    <i className="fas fa-trash-alt fa-lg"></i>
            </button>) : <></>}
          </div>
          </Card.Footer>
        </Card>
      );
    });
  };
  
  

  return (
    
    <> {listTechPer ? (  
    <div>
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <h4 className="fs-20 font-w600 me-auto">{lang.menu.testTechniqueList}</h4>
        { createTest ? (  <Link to="/TechQCM" className="btn btn-primary">
          <i className="fas fa-plus me-2"></i>{lang.menu.newTest}
        </Link>) : <></>}
      </div>
      <div>
      <Form.Group controlId="formBasicSearch">
        <Form.Control
          type="text"
          placeholder={lang.menu.serachJob}
          value={searchTerm}
          onChange={handleSearch}
        />
      </Form.Group>
      <br/>
        {renderTests()}</div>
    </div>) : (
             <Modal show={showAccessDeniedModal} >
             <Modal.Header>
               <Modal.Title>{lang.menu.accessDenied}</Modal.Title>
             </Modal.Header>
             <Modal.Body>
 {lang.menu.YoudontHavePerm}
             </Modal.Body>
             <Modal.Footer>
         
             </Modal.Footer>
           </Modal>
          )}</>
  );
}

export default TechQCMList;
