import React, { useEffect, useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import swal from "sweetalert";
import "./updateSkillsCoefficient.css";
import { Button } from "react-bootstrap";
import translations from "../AppsMenu/Candidate/mulitlingue";
function UpdateSkillsCoefficient() {
  const { id } = useParams();
  const history = useHistory();
  const token=localStorage.getItem("token");

  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  useEffect(() => {
    const selectedLang = JSON.parse(localStorage.getItem("selectedLang"));
    if (selectedLang) {
      setLang(selectedLang);
    }
  }, []);

  const [jobData, setJobData] = useState({
    profile: { requiredQualifications: [], title: "" },
  });

  useEffect(() => {
    async function fetchProfileCoefficient() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_APP_DOMAIN}/api/update-coefficient/getprofilebyId/` +
            id, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
        );

        if (response.data[0].requiredQualifications) {
          const qualificationsString = response.data[0].requiredQualifications;

          // Use regular expressions to match and capture each qualification
          const qualificationRegex = /\('([^']+)'\s*,\s*(\d+)\)/g;

          const requiredQualifications = [];

          // Iterate over matches
          let match;
          while (
            (match = qualificationRegex.exec(qualificationsString)) !== null
          ) {
            const qualification = match[1];
            const coefficient = parseInt(match[2]);
            requiredQualifications.push({ qualification, coefficient });
          }

          setJobData((prevJobData) => ({
            ...prevJobData,
            profile: {
              ...prevJobData.profile,
              title: response.data[0].title,
              requiredQualifications,
            },
          }));
        }
      } catch (error) {
        console.error("Error fetching job profile:", error);
      }
    }

    fetchProfileCoefficient();
  }, [id]);

  function handleCoefficientChange(index, newValue) {
    const newCoefficient = isNaN(newValue) ? 0 : parseInt(newValue);

    setJobData((prevJobData) => {
      const updatedQualifications = [
        ...prevJobData.profile.requiredQualifications,
      ];
      updatedQualifications[index].coefficient = newCoefficient;
      return {
        ...prevJobData,
        profile: {
          ...prevJobData.profile,
          requiredQualifications: updatedQualifications,
        },
      };
    });
  }

  const handleUpdate = (event) => {
    event.preventDefault();
    axios
      .put(
        `${process.env.REACT_APP_APP_DOMAIN}/api/update-coefficient/updatebyId/` +
          id,
        jobData, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
      )

      .then((res) => {
        console.log(res);
        if(localStorage.getItem("updateProfile") ==1){
        swal(lang.menu.updateMsgSucc, {
          icon: "success",
        });
      }
        else{
          swal(lang.menu.createjobPMsgSucc, {
            icon: "success",
          });
        }
        history.push("/job-profile-list");
      })
      .catch((err) => {
        swal(lang.menu.deleteTitleWrong, lang.menu.deleteWrong, "error");
        console.log(err);
      });
  };

  return (
    <>
      <h1>{localStorage.getItem("updateProfile")==1 ? lang.menu.UpdateCoefficients : lang.menu.AddCoefficients}</h1>
      <br />
      <br />
      <div className="job-profile">
        <h1 className="job-title">{jobData.profile.title}</h1>
        {jobData.profile && jobData.profile.requiredQualifications ? (
          <form onSubmit={handleUpdate} className="coefficients-form">
            <div className="coefficients-list">
              {jobData.profile.requiredQualifications.map(
                (qualification, index) => (
                  <div className="coefficient-item" key={index}>
                    <label className="qualification-label">
                      {qualification.qualification}
                    </label>
                    <input
                      type="number"
                      value={String(qualification.coefficient)} 
                      onChange={(e) =>
                        handleCoefficientChange(index, e.target.value)
                      }
                      className="coefficient-input"
                    />
                  </div>
                )
              )}
            </div>
            <div className="button-container">
              <Button type="submit" className="save-button">
              {localStorage.getItem("updateProfile") ==1? lang.menu.SaveChanges : lang.menu.submit}
                
              </Button>
            </div>
          </form>
        ) : (
          <p className="loading-message">{lang.menu.loadingProfilejob}</p>
        )}
      </div>
    </>
  );
}

export default UpdateSkillsCoefficient;
